<template>
    <div class="container">
        <div class="header">
            <text v-if="forCreate">
                Create new ticket 
            </text>
            <text v-else>
                Add ticket details
            </text>
            <div class="cross" @click="closePopup()">
                <img src="@/assets/cross.svg" alt="cross"/>
            </div>
        </div>
        <div class="ticket-details"  v-if="forCreate">Add ticket details</div>
        <div class="flex gap-10 w-full" v-if="forCreate">
            <InpComponent
                class="flex-1"
                label="Subject"
                
                type="text"
                key_ref="subject"
                value=""
                @inputChange="changeInput"
            />
            <InpComponent
                class="flex-1"
                label="Severity"
                
                key_ref="severity"
                @inputChange="changeInput"

                type="options"
                :options="[
                    { text: 'Low', value: 'low' },
                    { text: 'Medium', value: 'medium' },
                    { text: 'High', value: 'high' },
                ]"
            />
        </div>
        <div>
            <CommentSection @changeinput="quillContent" class="markdown-content"/>
        </div>
        <div>
            <div class="header-of-section">Attach Files (optional)</div>
            <MultiFileUploader @sendFileInfo="getFiles" class="uploadfile" />
        </div>
        
        <div class="add-new-ticket-info flex gap-5">
            <button class="transparent cancel-btn" @click="closePopup()">Cancel</button>
            <button @click="ticketInfoUpdate()">Ok</button>
        </div>
    </div>
</template>


<script setup>
    import { ref, defineEmits, defineProps } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'

    import InpComponent from './InpComponent.vue';
    import CommentSection from '@/views/CommentSection.vue';
    import MultiFileUploader from '@/components/MultifileUploader.vue';
    const props = defineProps(['forCreate'])

    const emit = defineEmits(['close', 'ticketInfoUpdate', 'loading'])
    const store = useStore()
    const router = useRouter()
    const ticket = ref({
        subject: '',
        content: '',
        severity: '',
    })
    const fileList = ref([]);

    function ticketInfoUpdate() {
        emit('ticketInfoUpdate', {ticket: ticket.value, files: fileList.value})
    }
    function quillContent(text) {
        ticket.value.content = text.text
    }
    function changeInput(event) {
        const event_type = event[0];
        const event_value = event[1];
        ticket.value[event_type] = event_value;
    }
    function getFiles(event){
        fileList.value = event;
        console.log('event', event)
    }

    function closePopup(){
        emit('close')
    }
</script>


<style scoped lang="scss">
    .container {
        border-radius: .5rem;
        display: flex;
        flex-direction: column;
        width: 600px;
        border: 1px solid var(--border-color);
        min-height: 400px;
        padding: 20px;
        background-color: white;
    } 
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2rem;
        color: black;
    }
    .cancel-btn {
        margin-left: auto;
        margin-right: 10px;
    }
    .add-new-ticket-info {
        margin-top: 30px;
        button {
            width: fit-content;
            padding: 10px 30px;
        }
    }
    .cross {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
        img {
            width: 20px;
            height: 20px;
        }
    }
    .ticket-details {
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.5rem;
        color: black;
        padding-bottom: 20px;
        text-align: left;
    }
    .markdown-content {
        height: 200px;
        max-height: 200px;
        :nth-child(1) {
            text-align: left;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }
    .header-of-section {
        color: black;
        text-align: left;
        font-weight: 600;
        font-size: 16px;
    }

</style>