<template>
    <div class="sanction-response-item">
        <div v-if="isPopup" class="closable-click cross" @click="closePopup()">
            <img src="@/assets/cross.svg" alt="cross"/>
        </div>
        <div class='main-container' :class="isPopup ? 'popup' : ''" id="sanction-result" v-if="sanctioResponse && sanctioResponse.result">
            
            <div class='flex w-full apart section mb-20' v-if="sanctionRequest">
                <div class='title-section'>
                    Request Information: 
                    <b>
                        {{ sanctionRequest.general.surname }}
                        {{ sanctionRequest.general.firstname }}
                        {{ sanctionRequest.general.dob }}
                        {{ sanctionRequest.general.nationality }}
                    </b>
                </div>
            </div>
            <div>
                <div class='response-header'>
                Sanction Screening Result Information
                </div>
                <div v-if="sanctioResponse.result.length > 1 && !acknowledged" class="attention">Please note, that the search returned more than one document, Take a look and select which suits you best</div>
                <button class='confirm-button w-content' v-if="sanctioResponse.result.length > 1 && !acknowledged" @click="acknowledged= !acknowledged">I understand</button>
                
                <div class="w-full margin-top-20" v-if="sanctioResponse.result.length > 1" :class="`${!acknowledged ? 'disabled-area' : ''}`">
                    <div class="info-text w-full text-left" >Click on one to show in view</div>
                    <div class="person-container">
                        <div class="person-item" :class="`${visited[index] ? 'visited' : ''} ${index == currentIndex ? 'selected': ''}`" @click="selectView(index)" v-for="(person, index) in sanctioResponse.result">
                            {{ person.individual.firstname }} {{ person.individual.surname }} {{ person.individual.year_of_birth }}
                        </div>
                    </div>
                </div>
            </div>
            <div class='no-results' v-if="sanctioResponse.result.length == 0">
            <p>No results found, please try again with different search criteria or contact the administrator for further assistance</p>
            <p>Search depends on First & Last names, as well as, Nationality and Birthday date</p>
            </div>
            <div v-if="person" id="person-section">
                <div class='person flex column gap-40'>
                    <div class='section'>
                        <div class='title-section flex space-between'>
                            Individual 
                            <button class='w-content' v-if="isPopup" @click="saveAsPDF"> Save as PDF</button>
                        </div>
                        <div class='table-section'>
                            <table>
                                <tbody>
                                    <tr v-for="key in Object.keys(person.individual)">
                                        <th>{{key}}:</th>
                                        <td>{{person.individual[key]}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class='section'>
                        <div class='title-section'>
                            Addresses
                        </div>
                        <div v-for="(address, index) in person.addresses">
                            <div class='sub-title'>Address {{index + 1}}</div>
                            <div class='table-section' >
                                <table>
                                    <tbody>
                                        <tr v-for="key in Object.keys(address)">
                                            <th>{{key}}:</th>
                                            <td>{{address[key]}}</td>
                                        </tr>
                                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class='section'>
                        <div class='title-section'>
                            Sanctions
                        </div>
                        <div class='table-section' v-if="getCurrentSanctions(person).length">
                            <table>
                                <tbody>
                                    <tr v-for="sanction in getCurrentSanctions(person)">
                                        <th>{{sanction.body}}</th>
                                        <td>Current: {{sanction.current}}</td>
                                        <td>Country Code: {{sanction.countryCode}}</td>
                                    </tr>
                                            </tbody>
                            </table>
                        </div>
                        <div v-else class='not-found-section'>
                            No Sanctions found
                        </div>
                    </div>
                    <div class='section'>
                        <div class='title-section'>
                            Notes
                        </div>
                        <div class='table-section' v-if="person.notes.length">
                            <table>
                                <tbody>
                                    <tr v-for="note in person.notes">
                                        <th>{{note.note}}</th>
                                        <td>Source: {{note.source}}</td>
                                    </tr>
                                            </tbody>
                            </table>
                        </div>
                        <div v-else class='not-found-section'>
                            No Notes found
                        </div>
                    </div>
                    <div class='section'>
                        <div class='title-section'>
                            Flags
                        </div>
                        <div class='table-section' v-if="getFlags(person).length">
                            <table>
                                <tbody>
                                    <tr v-for="flag in getFlags(person)">
                                        <th>{{flag.data.rep}}</th>
                                    </tr>
                                            </tbody>
                            </table>
                        </div>
                        <div v-else class='not-found-section'>
                            No Flags found
                        </div>
                    </div>
                    <div class='section'>
                        <div class='title-section'>
                            Articles
                        </div>
                        <div class='table-section' v-if="person.articles.length">
                            <table>
                                <tbody>
                                    <tr v-for="acticle in person.articles">
                                        <th class="article-url">
                                            <div class="article-url-text">
                                                {{acticle.url}}
                                            </div>
                                        </th>
                                        <td>
                                            <div v-for="snippet of acticle.snippets">
                                                <div v-if="snippet.title" class='snippet-title'>{{snippet.title}}</div>

                                                <div>{{snippet.text}}</div>
                                                <div>{{snippet.terms}}</div>

                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else class='not-found-section'>
                            No Articles found
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            
            Error occured while fetching the data
            {{ sanctioResponse.message }}
        </div>
        <PopupCover v-if="sanctionScreaningRequestSuccess" @close="closePopup()">
            <div class="container-loading">
                <div class="loader-header">Document Successfuly Saved</div>
                <button @click="closePopup()" class="ok-button w-content" style="margin-left: auto;">
                    Ok
                </button>
            </div>
        </PopupCover>
    </div>
</template>

<script setup>
    import { onMounted, ref, defineEmits } from 'vue';
    import { jsPDF } from "jspdf";
    import "jspdf-autotable";
    import API from '@/api/index.js';
    import PopupCover from './PopupCover.vue';
    import store from '@/store';




    const emit = defineEmits(['close', 'add_sanction_screening']);
    const person = ref(null);
    const visited = ref({});
    const props = defineProps([
        'sanctionRequest', 
        'sanctioResponse', 
        'person_id',
        'isPopup', 
        'search_id',
        'canBeSaved',
    ]);
    const acknowledged = ref(false);
    const currentIndex = ref(null);
    const sanctionScreaningRequestSuccess = ref(false);
    
    onMounted(() => {
        if (props.sanctioResponse?.result?.length == 1) {
            person.value = props.sanctioResponse.result[0];
        }
    });

    function closePopup() {
        if (props.isPopup) {
            emit('close');
        } 
    }

    async function saveAsPDF() {
        if (!props.canBeSaved) {
            alert('Add download of PDF from frontend');
            return;
        }
        const resp = await API.saveSanctionAsPDF({
            person_id: props.person_id,
            sanctionIndex: currentIndex.value || 0,
            search_id: props.search_id
        });
        if (resp?.file){
            sanctionScreaningRequestSuccess.value = true; 
            
            const file = resp?.file;

            store.commit('updateFileById',{
                id: file.id, 
                updates: {
                    file: file.file,
                }
            })
            // store.dispatch('addNotification', { text: 'Document saved successfully', type: 'success' });
            // closePopup();

        } else {
            store.dispatch('addNotification', { text: 'Error saving document', type: 'error' });
        }
    }

    function selectView(index) {
        currentIndex.value = index;
        person.value = props.sanctioResponse.result[index];
        visited.value[index] = true;
    }
    
    const showJSON = ref(false);
    const getCurrentSanctions = (person) => {
        return person.sanction_types.filter(sanction => (sanction.current || sanction.found));
    }
    const getFlags = (person) => {
        return person.flags.filter(flag => flag.found);
    }
</script>

<style lang='scss' scoped>
    .main-container {
        box-sizing: border-box;
        position: relative;
        border-radius: 10px;
        padding: 20px;
        background-color: white;
        border: 1px solid var(--border-color);

        &.popup {
            height: 100%;
        }
    }
    .closable-click {
        position: absolute;
        top: -10px;
        right: -10px;
        cursor: pointer;
        z-index: 1000;
    }
    .sanction-response-item {
        position: relative;
    }
    .no-results {
        position: absolute;
        top: 45%;
        width: 100%;
    }
    .attention {
        color: red;
        text-align: left;
        font-size: 1.2rem;
        margin-bottom: 5px;
        padding-left: 7px;
    }
    
    .confirm-button {
        margin-left: 7px;
    }

    .info-text {
        padding-left: 7px;
    }

    .save {
        width: fit-content;
    }
    .response-header {
        text-align: left;
        color: black;
        width: 100%;
        font-size: 1.5rem;
        margin-bottom: 10px;
        padding-left: 5px;
    }
    .ok-button {
        padding: 10px 25px; 
    }
    .container-loading  {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 350px;
        height: 100px;
        padding: 25px;
        border-radius: 10px;
        background-color: white;
    }
    .loader-header {
        font-size: 24px;
        color: black;
        font-weight: bold;
    }

    .snippet-title {
        font-weight: 700;
        font-size: 1rem;
    }
    .article-url {
        vertical-align: baseline;
    }
    .title-section {
        padding-left: 5px;
        color: black;
        text-align: left;
        font-size: 1.5rem;
        border-bottom: 1px solid var(--border-color);
        margin-bottom: 10px;
        padding-bottom: 5px;
    }
    .not-found-section {
        padding: 10px;
        color: black;
        text-align: left;
        font-size: 1.2rem;
        background-color: white;
        border: 1px solid var(--border-color);
        padding: 10px;
    }
    .sub-title {
        padding-left: 5px;
        margin-top: 5px;

        border-bottom: 1px solid var(--border-color);
        margin-bottom: 5px;
        color: black;
        text-align: left;
        font-size: 1.2rem;

    }
    .table-section {
        background-color: white;
        // border-radius: 5px;
        width: 100%;
        border: 1px solid var(--border-color);
        padding: 10px;
        box-sizing: border-box;
    }
    table {
        width: 100%;
        border-collapse: collapse;
    }
    tr td, tr th {
        border-bottom: 1px solid var(--border-color);
        padding: 10px;
        color: black;
    }
    tr {
        text-align: left;
        &:nth-child(even) {
            background-color: var(--app-background);
        }
    }
    .toggle {
        width: 200px;
        height: 20px;
        background-color: #d4d4d7;
    }
    .article-url-text {
        max-width: 300px;
        width: 300px;
        word-wrap: break-word;
    }
    .person-container {
        max-height: 300px;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-bottom: 30px;
    }
    .person-item {
        padding: 2px 10px;
        background-color: white;
        border: 1px solid var(--border-color);
        border-radius: 5px;
        box-sizing: border-box;
        font-size: 1.2rem;
        cursor: pointer;

        &.visited {
            background-color: rgb(218 217 235);
        }
        &.selected {
            background-color: rgb(0 64 137 / 15%);
        }
    }
    .disabled-area {
        pointer-events: none;
        opacity: 0.5;
    }
</style>