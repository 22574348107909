<template>
    <div>
        <div  v-if="loading">
            Loading
        </div>
        <div class='flex w-full gap-5 column' v-else>
           
            <div v-if="reviewAction.actionType !== 'remove'" class='flex w-full gap-5'>
                <div class="flex-1">
                    <h3 class="header-small">
                        <text v-if="view == 'forConfirmation'">Current Version</text>
                        <text v-else>Previous Version</text>
                    </h3>
                    <div class="info-holder current-version">
                        <p class="field-label">General Fields</p>
                        <InpComponent
                            :disabled="true"
                            class="country-select flex-1"
                            label="Origin"
                            type="options"
                            :options="[{value: 'local', text: 'Resident'}, {value: 'abroad', text: 'Non-resident'}]"
                            :value="fieldChange?.location_type?.current || md.location_type.value"
                            :class="{changes: fieldChange?.location_type?.new != undefined}"

                        ></InpComponent>
                        <InpComponent
                            :disabled="true"
                            class="flex-1"
                            label="Director Type"
                            type="options"
                            :value="fieldChange?.entity_type?.current || md.entity_type.value"
                            :class="{changes: fieldChange?.entity_type?.new != undefined}"
                            :options="[{value: 'person', text: 'Individual'}, {value: 'entity', text: 'Legal entity'}]"
                            disabled="true"
                        ></InpComponent>
                    </div>
                </div>
                <div class="flex-1">
                    <h3 class="header-small">
                        <text>New Version</text>
                    </h3>
                    <div class="info-holder proposed-changes">
                        <p class="field-label">General Fields</p>
                        <InpComponent
                            :disabled="true"
                            class="country-select flex-1"
                            label="Origin"
                            type="options"
                            :options="[{value: 'local', text: 'Resident'}, {value: 'abroad', text: 'Non-resident'}]"
                            :value="fieldChange?.location_type?.new || md.location_type.value"
                            :class="{changes: fieldChange?.location_type?.new != undefined}"

                        ></InpComponent>
                        <InpComponent
                            :disabled="true"
                            class="flex-1"
                            label="Director Type"
                            type="options"
                            :options="[{value: 'person', text: 'Individual'}, {value: 'entity', text: 'Legal entity'}]"

                            :value="fieldChange?.entity_type?.new || md.entity_type.value"
                            :class="{changes: fieldChange?.entity_type?.new != undefined}"
                            disabled="true"
                        ></InpComponent>
                    </div>
                </div>
            </div>
            <div class="width-96">
                <ManagingDirrector2
                    :md_id="reviewAction.mbd_related"
                    :reviewMode="true"
                />
            </div>
        </div>
    </div>
</template>

<script setup >

import { ref, computed, onMounted, watch } from 'vue'
import ManagingDirrector2 from '@/components/ManagingDirrector2.vue'
import InpComponent from '@/components/InpComponent.vue'
import dayjs from 'dayjs';

import { useStore } from 'vuex';

import API from '@/api/index.js'

const props = defineProps(['reviewAction', 'view'])
const store = useStore();

const fieldChange = ref(JSON.parse(props.reviewAction.fields));
const md = computed(() => store.getters.getMdById(props.reviewAction.mbd_related));
const loading = ref(true);
onMounted(async() => {
    const mdResponse = await API.getMDById({id: props.reviewAction.mbd_related, dependencies: true});
    if (!mdResponse.status == 200) {
        return;
    }

    store.state.kyc_persons = [];
    store.state.kyc_files = [];
    store.state.kyc_ubos = [];
    store.state.kyc_entities = [];
    store.state.kyc_mds = [];
    store.state.kyc_files.push(...mdResponse.files);
    store.state.kyc_persons.push(...mdResponse.persons);
    store.state.kyc_entities.push(...mdResponse.entities);
    store.state.kyc_mds.push(...mdResponse.md);
    store.state.kyc_shareholders.push(...mdResponse.shareholders);
    store.state.kyc_ubos.push(...mdResponse.ubos);
    loading.value = false;

});



function renderTime(time) {
    if (!time) return '';
    return dayjs(time).format('MMM DD, YYYY [at] HH:mm');
}


</script>

<style lang="scss" scoped>

    .proposed-changes {
        margin-left: auto;
        .changes {
            background: #DEF7EC;
            &:deep(label) {
                background: #DEF7EC;
            }
        }
    }
    .current-version {
       
        .changes {
            background: #FDE8E8;
            &:deep(label) {
                background: #FDE8E8;
            }
        }
    }
</style>