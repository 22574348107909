<template>
    <div class="container">
        <div class="header">
            <text> 
                Document information
            </text>
            <div class="cross" @click="closePopup">
                <img src="@/assets/cross.svg" alt="cross"/>
            </div>
        </div>
        <div class="flex row gap-5 w-full">
            <div>
                <UploaderTable2 
                    style="width: 400px;"
                    :fileId="props.fileId"
                    :key="`file_${props.fileId}`"
                    :fullWidth="true"
                    :requestedFile="props.reviewMode"
                    :reviewMode="props.reviewMode"
                    :person_id="props.person_id"
                    :detailedView="true"
                />
            </div>
            <div class="info-holder flex gap-5 w-full">
                <div class="comment-info flex column gap-4">

                    <div class="flex column input-row w-full" v-if="commentPrefix != undefined && (sectionFile[`new_sanctions_found${commentPrefix}`] || sectionFile[`sanctions_found${commentPrefix}`]) == 'YES'" >
                        <div class="header-of-comments">Found Sanctions </div>
                        <div class="input-row flex w-full">
                            <CommentSection 
                                :attrId="`sanctions_comment${commentPrefix}`" 
                                class="markdown-content"
                                :disabled="reviewMode"
                                :commentSection="sectionFile[`new_sanctions_comment${commentPrefix}`] || sectionFile[`sanctions_comment${commentPrefix}`]"
                                @changeinput="quillContent" 
                            />
                        </div>
                    </div>

                    <div class="flex column input-row w-full" v-if="commentPrefix != undefined && (sectionFile[`new_pep_found${commentPrefix}`] || sectionFile[`pep_found${commentPrefix}`]) == 'YES'" >
                        <div class="header-of-comments">PEP</div>
                        <div class="input-row flex w-full">
                            <CommentSection 
                                :attrId="`pep_comment${commentPrefix}`" 
                                class="markdown-content"
                                :disabled="reviewMode"
                                :commentSection="sectionFile[`new_pep_comment${commentPrefix}`] || sectionFile[`pep_comment${commentPrefix}`]"
                                @changeinput="quillContent" 
                            />
                        </div>
                    </div>
                    <div class="flex column input-row w-full" v-if="commentPrefix != undefined && (sectionFile[`new_relevant_adverse_information_found${commentPrefix}`] || sectionFile[`relevant_adverse_information_found${commentPrefix}`]) == 'YES'" >
                        <div class="header-of-comments">Relevant Adverse Information</div>
                        <div class="input-row flex w-full">
                            <CommentSection 
                                :attrId="`relevant_adverse_information_comment${commentPrefix}`" 
                                class="markdown-content"
                                :disabled="reviewMode"
                                :commentSection="sectionFile[`new_relevant_adverse_information_comment${commentPrefix}`] || sectionFile[`relevant_adverse_information_comment${commentPrefix}`]"
                                @changeinput="quillContent" 
                            />
                        </div>
                    </div>
                    
                    <div class="flex column input-row w-full" v-if="showAccountManagerComment && sectionFile.documentType !== 'world_check'" >
                        <div class="header-of-comments">Account manager comments</div>
                        <div class="input-row flex w-full">
                            <CommentSection 
                                attrId='comment_data_handler' 
                                class="markdown-content"
                                :class="{disabled: accountManagerDisabled}"
                                :disabled="accountManagerDisabled || reviewMode"
                                :commentSection="sectionFile.new_comment_data_handler || sectionFile.comment_data_handler"
                                @changeinput="quillContent" 
                            />
                        </div>
                    </div>
                    <div class="flex column input-row w-full" v-if="showSupervisorComment && sectionFile.documentType !== 'world_check'">
                        <div class="header-of-comments">Supervisor comments</div>
                        <div class="input-row flex w-full">
                            <CommentSection  
                                attrId='comment_data_processor' 
                                class="markdown-content"
                                :class="{disabled: supervisorDisabled}"
                                :disabled="supervisorDisabled || reviewMode"
                                :commentSection="sectionFile.new_comment_supervisor || sectionFile.comment_supervisor"
                                @changeinput="quillContent"
                            />
                        </div>
                    </div>
                    <div class="flex column input-row w-full" v-if="showComplienceOfficerComment && sectionFile.documentType !== 'world_check'">
                        <div class="header-of-comments">Compliance officer comments</div>
                        <div class="input-row flex w-full">
                            <CommentSection 
                                @changeinput="quillContent" 
                                attrId='comment_compliance_officer' 
                                :class="{disabled: complienceOfficerDisabled}"
                                :disabled="complienceOfficerDisabled || reviewMode"
                                :commentSection="sectionFile.new_comment_compliance_officer || sectionFile.comment_compliance_officer"
                                class="markdown-content"
                            />
                        </div>
                    </div>
                    <div v-if="!showAccountManagerComment && !showSupervisorComment && !showComplienceOfficerComment" class="header-of-comments flex align-center justify-center h-full">
                        <div></div>
                    </div>
                </div>
            </div>
        </div>



        <div class="flex column gap-5 w-full" v-if="sectionFile.documentType == 'world_check' && ['COMPLIANCE_OFFICER'].includes(user.type)">
            <hr></hr>
            
            <div class="w-full text-left bold f-18">SUPERVISOR COMMENTS</div>
            <div class="flex row gap-5 w-full">
                <div class="checkmark-info">
                    <div class="world-check-info mb-20">
                        <div class="options flex column gap-10">
                        <div class="flex align-start gap-5">
                            <div class="option-desctions">
                            Sanctions found  
                            </div> 
                            <div class="flex row gap-5">
                            <div class="flex column align-start">
                                <div class="flex row gap-5"> <input :disabled="true" type="radio" :checked="isChecked(`sanctions_found_supervisor`, `YES`)" :name="`sanctions_found_supervisor-${randomId}-a`" @change="inputChange" :id="`YES-sanctions_found_supervisor-${randomId}-a`"> YES</div> 
                                <div class="flex row gap-5"> <input :disabled="true" type="radio" :checked="isChecked(`sanctions_found_supervisor`, `NO`)" :name="`sanctions_found_supervisor-${randomId}-a`" @change="inputChange" :id="`NO-sanctions_found_supervisor-${randomId}-a`"> NO</div> 
                            </div>
                            </div>
                        </div>
                        <div class="flex align-start gap-5">
                            <div class="option-desctions">
                            PEP  
                            </div> 
                            <div class="flex row gap-5">
                            <div class="flex column align-start">
                            <div class="flex row gap-5"> <input :disabled="true" type="radio" :checked="isChecked(`pep_found_supervisor`, `YES`)" :name="`pep_found_supervisor-${randomId}-a`" @change="inputChange" :id="`YES-pep_found_supervisor-${randomId}-a`"> YES</div> 
                            <div class="flex row gap-5"> <input :disabled="true" type="radio" :checked="isChecked(`pep_found_supervisor`, `NO`)" :name="`pep_found_supervisor-${randomId}-a`" @change="inputChange" :id="`NO-pep_found_supervisor-${randomId}-a`"> NO</div> 
                            </div>
                        </div>
                        </div>
                        <div class="flex align-start gap-5">
                            <div class="option-desctions">
                            Relevant Adverse Information  
                            </div> 
                            <div class="flex row gap-5">
                            <div class="flex column align-start">
                                <div class="flex row gap-5"> <input :disabled="true" type="radio" :checked="isChecked(`relevant_adverse_information_found_supervisor`, `YES`)" :name="`relevant_adverse_information_found_supervisor-${randomId}-a`" @change="inputChange" :id="`YES-relevant_adverse_information_found_supervisor-${randomId}-a`"> YES</div> 
                                <div class="flex row gap-5"> <input :disabled="true" type="radio" :checked="isChecked(`relevant_adverse_information_found_supervisor`, `NO`)" :name="`relevant_adverse_information_found_supervisor-${randomId}-a`" @change="inputChange" :id="`NO-relevant_adverse_information_found_supervisor${commentPrefix}-${randomId}-a`"> NO</div> 
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="info-holder h-content flex gap-5 w-full">
                    <div class="comment-info flex column gap-4">

                        <div class="flex column input-row w-full" v-if="sectionFile[`sanctions_found_supervisor`] == 'YES'" >
                            <div class="header-of-comments">Found Sanctions</div>
                            <div class="input-row flex w-full">
                                <CommentSection 
                                    :attrId="`sanctions_comment_supervisor`" 
                                    class="markdown-content"
                                    :disabled="true"
                                    :commentSection="sectionFile[`sanctions_comment_supervisor`]"
                                />
                            </div>
                        </div>

                        <div class="flex column input-row w-full" v-if="sectionFile[`pep_found_supervisor`] == 'YES'" >
                            <div class="header-of-comments">PEP</div>
                            <div class="input-row flex w-full">
                                <CommentSection 
                                    :attrId="`pep_comment_supervisor`" 
                                    class="markdown-content"
                                    :disabled="true"
                                    :commentSection="sectionFile[`pep_comment_supervisor`]"
                                />
                            </div>
                        </div>
                        <div class="flex column input-row w-full" v-if="sectionFile[`relevant_adverse_information_found_supervisor`] == 'YES'" >
                            <div class="header-of-comments">Relevant Adverse Information</div>
                            <div class="input-row flex w-full">
                                <CommentSection 
                                    :attrId="`relevant_adverse_information_comment_supervisor`" 
                                    class="markdown-content"
                                    :disabled="true"
                                    :commentSection="sectionFile[`relevant_adverse_information_comment_supervisor`]"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="flex column gap-5 w-full" v-if="sectionFile.documentType == 'world_check' && ['DATA_PROCESSOR','COMPLIANCE_OFFICER'].includes(user.type)">
            <hr></hr>
            
            <div class="w-full text-left bold f-18">ACCOUNT MANAGER COMMENTS</div>
            <div class="flex row gap-5 w-full">
                <div class="checkmark-info">
                    <div class="world-check-info mb-20">
                        <div class="options flex column gap-10">
                        <div class="flex align-start gap-5">
                            <div class="option-desctions">
                            Sanctions found  
                            </div> 
                            <div class="flex row gap-5">
                            <div class="flex column align-start">
                                <div class="flex row gap-5"> <input :disabled="true" type="radio" :checked="isChecked(`sanctions_found`, `YES`)" :name="`sanctions_found-${randomId}-a`" @change="inputChange" :id="`YES-sanctions_found-${randomId}-a`"> YES</div> 
                                <div class="flex row gap-5"> <input :disabled="true" type="radio" :checked="isChecked(`sanctions_found`, `NO`)" :name="`sanctions_found-${randomId}-a`" @change="inputChange" :id="`NO-sanctions_found-${randomId}-a`"> NO</div> 
                            </div>
                            </div>
                        </div>
                        <div class="flex align-start gap-5">
                            <div class="option-desctions">
                            PEP  
                            </div> 
                            <div class="flex row gap-5">
                            <div class="flex column align-start">
                            <div class="flex row gap-5"> <input :disabled="true" type="radio" :checked="isChecked(`pep_found`, `YES`)" :name="`pep_found-${randomId}-a`" @change="inputChange" :id="`YES-pep_found-${randomId}-a`"> YES</div> 
                            <div class="flex row gap-5"> <input :disabled="true" type="radio" :checked="isChecked(`pep_found`, `NO`)" :name="`pep_found-${randomId}-a`" @change="inputChange" :id="`NO-pep_found-${randomId}-a`"> NO</div> 
                            </div>
                        </div>
                        </div>
                        <div class="flex align-start gap-5">
                            <div class="option-desctions">
                            Relevant Adverse Information  
                            </div> 
                            <div class="flex row gap-5">
                            <div class="flex column align-start">
                                <div class="flex row gap-5"> <input :disabled="true" type="radio" :checked="isChecked(`relevant_adverse_information_found`, `YES`)" :name="`relevant_adverse_information_found-${randomId}-a`" @change="inputChange" :id="`YES-relevant_adverse_information_found-${randomId}-a`"> YES</div> 
                                <div class="flex row gap-5"> <input :disabled="true" type="radio" :checked="isChecked(`relevant_adverse_information_found`, `NO`)" :name="`relevant_adverse_information_found-${randomId}-a`" @change="inputChange" :id="`NO-relevant_adverse_information_found${commentPrefix}-${randomId}-a`"> NO</div> 
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="info-holder h-content flex gap-5 w-full">
                    <div class="comment-info flex column gap-4">

                        <div class="flex column input-row w-full" v-if="sectionFile[`sanctions_found`] == 'YES'" >
                            <div class="header-of-comments">Found Sanctions</div>
                            <div class="input-row flex w-full">
                                <CommentSection 
                                    :attrId="`sanctions_comment`" 
                                    class="markdown-content"
                                    :disabled="true"
                                    :commentSection="sectionFile[`sanctions_comment`]"
                                />
                            </div>
                        </div>

                        <div class="flex column input-row w-full" v-if="sectionFile[`pep_found`] == 'YES'" >
                            <div class="header-of-comments">PEP</div>
                            <div class="input-row flex w-full">
                                <CommentSection 
                                    :attrId="`pep_comment`" 
                                    class="markdown-content"
                                    :disabled="true"
                                    :commentSection="sectionFile[`pep_comment`]"
                                />
                            </div>
                        </div>
                        <div class="flex column input-row w-full" v-if="sectionFile[`relevant_adverse_information_found`] == 'YES'" >
                            <div class="header-of-comments">Relevant Adverse Information</div>
                            <div class="input-row flex w-full">
                                <CommentSection 
                                    :attrId="`relevant_adverse_information_comment`" 
                                    class="markdown-content"
                                    :disabled="true"
                                    :commentSection="sectionFile[`relevant_adverse_information_comment`]"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class='button-action w-full' v-if="['cosbaof', 'scfba'].includes(sectionFile.documentType)">
            <button class="button w-content" style="margin-left: auto;" @click="DeleteFileConfirm()">Delete Section</button>
        </div>
        <PopupCover v-if="deleteObject" @close="() => deleteObject = !deleteObject">
            <ConfirmationPopup 
                @confirm="popupConfirm"
                @cancel="() => deleteObject = !deleteObject"
                :title="'Delete Section '"
                :message="'Are you sure you want to delete this Section?'"
            />
        </PopupCover>
      
    </div>
</template>

<script setup>
    import { ref, defineEmits, defineProps, computed, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import CommentSection from '@/views/CommentSection.vue';
    import PopupCover from '@/components/PopupCover.vue';
    import ConfirmationPopup from '@/components/ConfirmationPopup.vue';
    import UploaderTable2 from '@/components/UploaderTable2.vue';
    const store = useStore()

    const props = defineProps([
        'fileId',
        'reviewMode',

    ])

    const sectionFile = computed(() => store.getters.getFileById(props.fileId));

    const emit = defineEmits([
        'close', 
    ])
    const deleteObject = ref(false);
    const commentPrefix = computed( () => store.getters.getWorldCheckEditablePrefix)

    const user = computed(() => store.state.user);

    const isChecked = computed(() => (key, value) => {
        return (sectionFile.value[`new_${key}`] || sectionFile.value[key]) == value;})

    const showAccountManagerComment = ref(false);
    const showSupervisorComment = ref(false);
    const showComplienceOfficerComment = ref(false);
    const shownInputsNumber = ref(0);

    const accountManagerDisabled = ref(true);
    const supervisorDisabled = ref(true);
    const complienceOfficerDisabled = ref(true);

    if (user.value && user.value.type == 'DATA_CONTROLLER') {
        showAccountManagerComment.value = true
        accountManagerDisabled.value = false
        shownInputsNumber.value = 1
    }
    if (user.value && user.value.type == 'DATA_PROCESSOR') {
        showAccountManagerComment.value = true
        showSupervisorComment.value = true;
        supervisorDisabled.value = false
        shownInputsNumber.value = 2
    }
    if (['COMPLIANCE_OFFICER', 'EXTERNAL_AUDITOR', 'INTERNAL_AUDITOR', 'TRUST_OFFICER'].includes(user.value.type)) {
        showComplienceOfficerComment.value = true
        showAccountManagerComment.value = true
        showSupervisorComment.value = true
        shownInputsNumber.value = 3
    }
    if (user.value.type == 'COMPLIANCE_OFFICER') {
        complienceOfficerDisabled.value = false
    }
    onMounted(() => {
     
    })
    function DeleteFileConfirm() {
        deleteObject.value = true;
    }
    function quillContent(event) {
        store.commit('updateFileById',{
            id: props.fileId, 
            updates: {
                [`new_${event.id}`]: event.structure,
            }
        })
    }
    function closePopup(event){
        event.stopPropagation();
        emit('close')
    }
</script>


<style scoped lang="scss">

    .input-row {
        > * {
            flex: 1;
        }
    }
    .info-section-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: self-start;
    }
    .info-section {
        text-align: left;
        line-height: 18px;
    }
    .name {
        font-size: 14px;
        color: rgb(116 119 127);
    }
    .value {
        font-size: 16px;
        color: black;
        font-weight: bold;
    }
    .options {
        input {
        width: 15px;
        }
    }
    .option-desctions {
        font-weight: bold;
        text-align: left;
        width: 150px;
    }
    .button-delete {
        align-items: flex-end;
    }
    .header-of-comments {
        text-align: left;
        font-size: 16px;
        color: black;
        font-weight: 600;
        padding-left: 5px;
    }
    .generate-password {
        border-radius: 5px;
        padding: 5px 10px;
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
        height: 26px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 2;
        &:hover {
            cursor: pointer;
            background-color: var(--button-hover-color-blue);
        }

    }
    .container {
        border-radius: .5rem;
        display: flex;
        flex-direction: column;
        width: 1300px;
        max-width: 95vw;
        border: 1px solid var(--border-color);
        max-height: 95vh;
        overflow-y: auto;
        padding: 20px;
        background-color: white;
    } 
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2rem;
        color: black;
    }
    hr {
        margin: 30px auto;
        width: 99%;
    }
    .cancel-btn {
        margin-left: auto;
        margin-right: 10px;
    }
    .checkmark-info {
        width: 590px;
    }
    .add-new-ticket-info {
        margin-top: auto;
        margin-left: auto;
        button {
            width: fit-content;
            padding: 10px 30px;
        }
    }
    .ticket-details {
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.5rem;
        color: black;
        padding-bottom: 20px;
        text-align: left;
    }
    .markdown-content {
        height: 200px;
        max-height: 200px;
        :nth-child(1) {
            text-align: left;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        &:deep(.ql-container){
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        &.disabled {
            background-color: #f5f5f5;
            opacity: 0.6;
        }
    }
    .header-of-section {
        color: black;
        text-align: left;
        font-weight: 600;
        font-size: 16px;
    }
    .info-section {
        width: 95%;
    }
    .comment {
        padding: 10px;
        background-color: #f5f5f5;
        border-radius: 5px;
        width: 100%;
        min-height: 60px;
        font-weight: 300;
        font-size: 14px;
        color: black;
        opacity: 0.6;
        border: 1px solid var(--border-color);
        &:deep(*) {
            margin: 0;
        }
    }
    .uploader-table {
        flex: 2;
    }
    .comment-info {
        flex: 3;
        justify-content: flex-end;
        height: fit-content;
        // max-height: 60vh;
        // overflow-y: auto;
    }

    .info-holder {
        max-height: 70vh;
        overflow: auto;
    } 


</style>