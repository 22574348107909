<template>
    <div>
        <div  v-if="loading">
            Loading
        </div>
        <div class='flex w-full gap-5 column' v-else>
            <div v-if="reviewAction.actionType !== 'remove'" class='flex w-full gap-5'>
                <div class="flex-1">
                    <h3 class="header-small">
                        <text v-if="view == 'forConfirmation'">Current Version</text>
                        <text v-else>Previous Version</text>
                    </h3>
                    <div class="info-holder current-version">
                        <p class="field-label">General Fields</p>

                        <InpComponent 
                            :disabled="true"
                            class="country-select"
                            label="Shareholder Type"
                            type="options"
                            :options="[{value: 'person', text: 'Individual'}, {value: 'entity', text: 'Corporate'}]"
                            placeholder="Local"
                            :value="fieldChange?.shareholder_type?.current || shareHolder.shareholder_type.value"
                            :class="{changes: fieldChange?.shareholder_type?.new != undefined}"

                        />
                        <InpComponent
                            :disabled="true"
                            label="Percentage of shares held"
                            type="number"
                            :value="fieldChange?.percentage_held?.current || shareHolder.percentage_held.value"
                            :class="{changes: fieldChange?.percentage_held?.new != undefined}"
                        />
                    </div>
                </div>
                <div class="flex-1">
                    <h3 class="header-small">
                        <text>New Version</text>
                    </h3>
                    <div class="info-holder proposed-changes">
                        <p class="field-label">General Fields</p>
                        <InpComponent 
                            :disabled="true"
                            class="country-select"
                            label="Shareholder Type"
                            type="options"
                            :options="[{value: 'person', text: 'Individual'}, {value: 'entity', text: 'Corporate'}]"
                            placeholder="Local"
                            :value="fieldChange?.shareholder_type?.new || shareHolder.shareholder_type.value"
                            :class="{changes: fieldChange?.shareholder_type?.new != undefined}"
                        />
                        <InpComponent
                            :disabled="true"
                            label="Percentage of shares held"
                            type="number"
                            :value="fieldChange?.percentage_held?.new || shareHolder.percentage_held.value"
                            :class="{changes: fieldChange?.percentage_held?.new != undefined}"
                        />
                    </div>
                </div>
            </div>
            <div v-if="reviewAction.actionType !== 'remove'">
                <h2 class="text-left m-0">Current Section State</h2>
            </div>
            <div class="width-96">
                <Shareholder
                    :shareholder_id="reviewAction.share_holder_related"
                    :reviewMode="true"
                />
            </div>
        </div>
    </div>
</template>

<script setup >

import { ref, computed, onMounted, watch } from 'vue'
import Shareholder from '@/components/Shareholder.vue'
import InpComponent from '@/components/InpComponent.vue'
import dayjs from 'dayjs';

import { useStore } from 'vuex';

import API from '@/api/index.js'

const props = defineProps(['reviewAction', 'view'])
const store = useStore();

const fieldChange = ref(JSON.parse(props.reviewAction.fields));
const shareHolder = computed(() => store.getters.getShareHolderById(props.reviewAction.share_holder_related));
const loading = ref(true);

onMounted(async() => {
    const shareHolderResponse =  await API.getShareHolderById(props.reviewAction.share_holder_related);
    if (!shareHolderResponse.status == 200) {
        return;
    }
    store.state.kyc_persons = [];
    store.state.kyc_files = [];
    store.state.kyc_ubos = [];
    store.state.kyc_entities = [];
    store.state.kyc_mds = [];
    store.state.kyc_shareholders = [];
    store.state.kyc_files.push(...shareHolderResponse.files);
    store.state.kyc_persons.push(...shareHolderResponse.persons);
    store.state.kyc_entities.push(...shareHolderResponse.entities);
    store.state.kyc_mds.push(...shareHolderResponse.md);
    store.state.kyc_shareholders.push(...shareHolderResponse.shareholders);
    store.state.kyc_ubos.push(...shareHolderResponse.ubos);

    loading.value = false;
});



function renderTime(time) {
    if (!time) return '';
    return dayjs(time).format('MMM DD, YYYY [at] HH:mm');
}


</script>

<style lang="scss" scoped>

    .proposed-changes {
        margin-left: auto;
        .changes {
            background: #DEF7EC;
            &:deep(label) {
                background: #DEF7EC;
            }
        }
    }
    .current-version {
       
        .changes {
            background: #FDE8E8;
            &:deep(label) {
                background: #FDE8E8;
            }
        }
    }
</style>