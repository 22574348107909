<template>
    <div>
        <div  v-if="loading">
            Loading
        </div>
        <div class="felx w-full gap-10 column" v-else> 
            <div class='flex w-full gap-5' >
                <div class="flex-1">
                    <h3 class="header-small">
                        <text v-if="view == 'forConfirmation'">Current Version</text>
                        <text v-else>Previous Version</text>
                    </h3>

                    <div class="info-holder current-version">
                        <p class="field-label">General Fields</p>
                        <InpComponent
                            v-if="!entity.share_holder_related"
                            :disabled="true"
                            class="country-select flex-1"
                            label="Is Supervised"
                            type="options"
                            :options="[{value: 'false', text: 'No'}, {value: 'true', text: 'Yes'}]"
                            :value="getFieldValue('supervised', 'current').toString()"
                            :class="{changes: fieldChange?.supervised?.new != undefined && fieldChange?.supervised?.current !== undefined}"
                        />
                        <InpComponent
                            :disabled="true"
                            class="flex-1"
                            label="Name of the Entity"
                            type="text"
                            :class="{changes: fieldChange?.name?.new != undefined && fieldChange?.name?.current}"
                            :value="getFieldValue('name', 'current')"
                            disabled="true"
                        />

                        <div class="flex-1">
                            <div class="passport-fields file-area">
                            <p class="field-label">Address fields</p>
                                <InpComponent
                                    label="Address Line 1"
                                    type="text"
                                    :class="{changes: fieldChange?.address_line_1?.new != undefined && fieldChange?.address_line_1?.current}"
                                    :disabled="true"
                                    :value="getFieldValue('address_line_1', 'current')"
                                />

                                <InpComponent
                                    label="Address Line 2 (optional)"
                                    :class="{changes: fieldChange?.address_line_2?.new != undefined && fieldChange?.address_line_2?.current}"
                                    type="text"
                                    :disabled="true"
                                    :value="getFieldValue('address_line_2', 'current')"
                                />

                                <InpComponent
                                    label="City / Town"
                                    type="text"
                                    :class="{changes: fieldChange?.city?.new != undefined && fieldChange?.city?.current}"
                                    :disabled="true"
                                    :value="getFieldValue('city', 'current')"
                                />
                                <InpComponent
                                    label="Country"
                                    type="options"
                                    :class="{changes: fieldChange?.address_country?.new != undefined && fieldChange?.address_country?.current}"
                                    :options="Utils && Utils.getCountryList()"
                                    :disabled="true"
                                    :value="getFieldValue('address_country', 'current')"
                                />
                                <InpComponent
                                    class="country-select"
                                    label="Zip"
                                    type="number"
                                    :class="{changes: fieldChange?.zip?.new != undefined && fieldChange?.zip?.current}"
                                    :disabled="true"
                                    :value="getFieldValue('zip', 'current')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-1">
                    <h3 class="header-small">
                        <text>New Version</text>
                    </h3>
                    <div class="info-holder proposed-changes">
                        <p class="field-label">General Fields</p>
                        <InpComponent
                            v-if="!entity.share_holder_related"
                            :disabled="true"
                            class="country-select flex-1"
                            label="Is Supervised"
                            type="options"
                            :options="[{value: 'false', text: 'No'}, {value: 'true', text: 'Yes'}]"
                            :value="getFieldValue('supervised', 'new').toString()"
                            :class="{changes: fieldChange?.supervised?.new != undefined}"
                        />

                        <InpComponent
                            :disabled="true"
                            class="flex-1"
                            label="Name of the Entity"
                            type="text"
                            :class="{changes: fieldChange?.name?.new != undefined}"
                            :value="getFieldValue('name', 'new')"
                            disabled="true"
                        />

                        <div class="flex-1">
                            <div class="passport-fields file-area">
                            <p class="field-label">Address fields</p>
                                <InpComponent
                                    label="Address Line 1"
                                    type="text"
                                    :class="{changes: fieldChange?.address_line_1?.new != undefined}"
                                    :disabled="true"
                                    :value="getFieldValue('address_line_1', 'new')"
                                />
                                <InpComponent
                                    label="Address Line 2 (optional)"
                                    :class="{changes: fieldChange?.address_line_2?.new != undefined}"
                                    type="text"
                                    :disabled="true"
                                    :value="getFieldValue('address_line_2', 'new')"
                                />
                                <InpComponent
                                    label="City / Town"
                                    type="text"
                                    :class="{changes: fieldChange?.city?.new != undefined}"
                                    :disabled="true"
                                    :value="getFieldValue('city', 'new')"
                                />
                                <InpComponent
                                    label="Country"
                                    type="options"
                                    :class="{changes: fieldChange?.address_country?.new != undefined}"
                                    :options="Utils && Utils.getCountryList()"
                                    :disabled="true"
                                    :value="getFieldValue('address_country', 'new')"
                                />
                                <InpComponent
                                    class="country-select"
                                    label="Zip"
                                    type="number"
                                    :class="{changes: fieldChange?.zip?.new != undefined}"
                                    :disabled="true"
                                    :value="getFieldValue('zip', 'new')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='mt-20' v-if="reviewAction.actionType !== 'remove'">
                <h2 class="text-left m-0">Current Section State</h2>
            </div>
            <div class="width-96">
                <EntityView2
                    :entity_id="props.reviewAction.legalEntity"
                    :key="`${props.reviewAction.legalEntity}_entity_view`"
                    :reviewMode="true"
                />
            </div>
        </div>
    </div>
</template>

<script setup >

import { ref, computed, onMounted, watch } from 'vue'
import UploaderTable2 from '@/components/UploaderTable2.vue'
import CommentSection from '@/views/CommentSection.vue'
import EntityView2 from '@/components/EntityView2.vue'
import InpComponent from '@/components/InpComponent.vue'
import dayjs from 'dayjs';
import Utils from '../utils/index.js'

import { useStore } from 'vuex';

import API from '@/api/index.js'

const props = defineProps(['reviewAction', 'view'])
const store = useStore();

const fieldChange = ref(JSON.parse(props.reviewAction.fields));
const entity = ref(null);
const loading = ref(true);
onMounted(async() => {
    const entityResponse = await API.getEntityById({id: props.reviewAction.legalEntity, dependencies: true});

    if (!entityResponse.status == 200) {
        return;
    }
    store.state.kyc_entities = [];
    store.state.kyc_files = [];
    entity.value = entityResponse.entity;
    store.state.kyc_entities.push(entityResponse.entity);
    store.state.kyc_files.push(...entityResponse.files);
    loading.value = false;

});

function getFieldValue(field, state) {
    if (
        state == 'current' && 
        fieldChange.value[field] && 
        fieldChange.value[field].current == undefined && 
        fieldChange.value[field].new
    ) {
        return null;
    }
    if (fieldChange.value[field] && (fieldChange.value[field][state] || ['false','true'].includes(fieldChange.value[field][state].toString()))) {
        return fieldChange.value[field][state];
    }
    return entity.value[field].value;
}

function renderTime(time) {
    if (!time) return '';
    return dayjs(time).format('MMM DD, YYYY [at] HH:mm');
}


</script>

<style lang="scss" scoped>

    .proposed-changes {
        margin-left: auto;
        .changes {
            background: #DEF7EC;
            &:deep(label) {
                background: #DEF7EC;
            }
        }
    }
    .current-version {
       
        .changes {
            background: #FDE8E8;
            &:deep(label) {
                background: #FDE8E8;
            }
        }
    }
</style>