<template>
    <div class="history-management-view">
        <div class="documentmanagement-header">{{ kycReviewInfo.title }}</div>
        <div class="subsidiary-container">
            <div class="subsidiary-content">
                <div class="sub-header" >
                    <h4 class="subsidiary-title">{{  kycReviewInfo.list_title }}</h4>
                    <div class="info flex gap-5 align-center">
                        <InpComponent
                            class="search-input"

                            type="text"
                            :value="searchVal"
                            placeholder="Search"
                            key_ref="search"
                            @inputChange="inputChangeFunc"
                        />
                        <button class="button" @click="addCompany()">
                            Add {{ kycReviewInfo.name }}
                        </button>
                    </div>
                </div>
                <table class="subsidiary-list" v-if="dataList && dataList.length">
                    <thead>
                        <tr>

                            <th class='ordering' :class="['name', 'first_name'].includes(sortingKey) ? 'sort-on' : ''" @click="sort(kycReviewInfo.type == 'corporate' ? 'name' : 'first_name' )">{{ kycReviewInfo.name || kycReviewInfo.first_name }} Name
                                <svg class='sort' :class="`orientation-${sorting[kycReviewInfo.type == 'corporate' ? 'name' : 'first_name']}`" xmlns="http://www.w3.org/2000/svg" width="320" height="512" viewBox="0 0 320 512"><path fill="currentColor" d="M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9S19 287.9 32 287.9h256c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z"/></svg>
                            </th>
                            <th class='ordering' :class="sortingKey == 'location_type' ? 'sort-on' : ''" @click="sort('location_type')" v-if="kycReviewInfo.type == 'corporate'">Company Type
                                <svg class='sort' :class="`orientation-${sorting['location_type']}`" xmlns="http://www.w3.org/2000/svg" width="320" height="512" viewBox="0 0 320 512"><path fill="currentColor" d="M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9S19 287.9 32 287.9h256c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z"/></svg>
                            </th>
                            <th class='ordering' :class="sortingKey == 'createdAt' ? 'sort-on' : ''" @click="sort('createdAt')">Created At
                                <svg class='sort' :class="`orientation-${sorting['createdAt']}`" xmlns="http://www.w3.org/2000/svg" width="320" height="512" viewBox="0 0 320 512"><path fill="currentColor" d="M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9S19 287.9 32 287.9h256c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z"/></svg>
                            </th>

                        </tr>
                    </thead>
                    <tbody class='table-content' v-if="dataList">
                        <tr class="account-list" 
                            v-for="company in dataList" 
                            :key="company._id" 
                            @click="openItem(company)">
                            
                            <td class="user-info"> 
                                {{ company.name || company.first_name }} {{ company.last_name }}
                            </td>
                            <td v-if="kycReviewInfo.type == 'corporate'" class="user-info"> 
                                {{  getCompanyType(company) }}
                            </td>
                            <td class="user-info"> 
                                {{  Utils.renderTime(company.createdAt) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class='w-full p-20' v-else>
                    No {{  kycReviewInfo.name_plural }} yet
                </div>
            </div>
        
            <PopupCover v-if="showAddPopup" @close="() => showAddPopup = false">
                <PopupBody @close="() => showAddPopup = false" v-if="!addingNewLoading"> 
                    <template #header>Add new {{ kycReviewInfo.name }}</template>
                    <template #body>
                        <div class="add-new-subsidiary-area" v-if="kycReviewInfo.type == 'corporate'">
                            <InpComponent 
                                :value="newCompany.name" 
                                :key_ref="'name'"
                                @inputChange="inputChangeFunc"
                                :label="`${kycReviewInfo.name} Name`"
                                :error="nameError"
                            />
                            <InpComponent
                                class="country-select flex-1"
                                label="Company Type"
                                type="options"
                                :options="[{value: 'local', text: 'Onshore'}, {value: 'abroad', text: 'Offshore'}]"
                                placeholder="Company Type"
                                :key_ref="'location_type'"
                                :error="locationTypeError"
                                :value="newCompany.location_type"
                                @inputChange="inputChangeFunc"
                            />
                            <button class="save-button" @click="saveCompany()">Save</button>
                        </div>
                        <div class="add-new-subsidiary-area" v-else>
                            <InpComponent 
                                :value="newCompany.first_name" 
                                :key_ref="'first_name'"
                                @inputChange="inputChangeFunc"
                                :label="`${kycReviewInfo.name} First Name`"
                                :error="nameError"
                            />
                            <InpComponent 
                                :value="newCompany.last_name" 
                                :key_ref="'last_name'"
                                @inputChange="inputChangeFunc"
                                :label="`${kycReviewInfo.name} Last Name`"
                                :error="lastnameError"
                            />
                            <InpComponent
                                class="country-select flex-1"
                                label="Client Type"
                                type="options"
                                :options="[{value: 'bank', text: 'Bank'}, 
                                    {value: 'credit_union', text: 'Credit Union'},
                                    {value: 'pention', text: 'Pension'},
                                    {value: 'insurance', text: 'Insurance'},
                                    {value: 'player', text: 'Player'}
                                ]"
                                placeholder="Company Type"
                                :key_ref="'account_holder_type'"
                                :error="locationTypeError"
                                :value="newCompany.account_holder_type"
                                @inputChange="inputChangeFunc"
                            />

                            <button class="save-button" @click="saveCompany()">Save</button>
                        </div>
                    </template>
                </PopupBody>
                <LoaderPopup v-else :message="`Adding New ${ kycReviewInfo.name }...`"/>
            </PopupCover>
        </div>
    </div>
</template>


<script setup>
        
    import { ref, defineEmits, computed, withDefaults, defineProps, watch, onMounted } from 'vue'
    import PopupCover from '@/components/PopupCover.vue';
    import PopupBody from '@/components/PopupBody.vue';
    import InpComponent from '@/components/InpComponent.vue';
    import LoaderPopup from '@/components/LoaderPopup.vue';
    import API from '../api/index.js'
    import { useRouter, useRoute } from 'vue-router'
    import { useStore } from 'vuex';
    import Utils from '@/utils/index.js';

    const store = useStore();
    const router = useRouter();
    const showAddPopup = ref(false);
    const dataList = ref([]);
    const addingNewLoading = ref(false);
    const nameError = ref(null);
    const lastnameError = ref(null);
    const locationTypeError = ref(null);
    const loadingPage = ref(true);
    const searchVal = ref('');
    const sorting = ref({createdAt: -1});
    const sortingKey = ref('createdAt');
    const debounce = ref(null);
    const validationIsOn = ref(false);
    const kycReviewInfo =ref({});
    const props = defineProps(['kyc_type']);
    const lastSearch = ref('');
    const newCompany = ref({
        name: '',
        location_type: '',
        account_holder_type: '',
        first_name: '',
        last_name: '',
    });

    const sortingState = ref({
        name: 1,
        first_name: 1,
        createdAt: 1,
        location_type: 1,
    })
    async function sort(key) {
        sortingKey.value = key;
        sortingState.value[key] = sortingState.value[key] * -1;
        sorting.value = { [key]: sortingState.value[key] };
        await doRequest()

    }
    function getCompanyType(company){
        if (company.company_type == 'corporate'){
            return company.location_type == 'local' ? 'Onshore' : 'Offshore';
        } else {
            return company.account_holder_type;
        }
    }

    async function doRequest(){
        loadingPage.value = true;
        const response = await API.getCompamniesList(kycReviewInfo.value.type, searchVal.value, sorting.value);
        dataList.value = response.companies;
        loadingPage.value = false;
        lastSearch.value = searchVal.value;
    }
    function handleSearch(search){
        searchVal.value = search;

        if (searchVal.value.length < 3){
            if (lastSearch.value && searchVal.value.length == 0){
                doRequest()
            }
            return 
        }
        if (debounce.value){
            clearTimeout(debounce.value);
        }
        debounce.value = setTimeout(async () => {
            await doRequest();
        }, 700);
    }

    function inputChangeFunc(event){
        console.log(event);
        if (event[0] == 'search'){
            handleSearch(event[1]);
        }
        
        newCompany.value[event[0]] = event[1];
        if (validationIsOn.value){
            validateFields();
        }
    }
    function validateFields(){
        nameError.value = null;
        validationIsOn.value = true;
        locationTypeError.value = null;
        lastnameError.value = null;
        let errorPresent = false;
        if (props.kyc_type == 'corporate') {
            if (!newCompany.value.name) {
                nameError.value = 'Name is required';
                errorPresent = true;
            }
            if (!newCompany.value.location_type) {
                locationTypeError.value = 'Company type is required';
                errorPresent = true;
            }
        } else {
            if (!newCompany.value.first_name) {
                nameError.value = 'First Name is required';
                errorPresent = true;
            }
            if (!newCompany.value.last_name) {
                lastnameError.value = 'Last Name is required';
                errorPresent = true;
            }
            if (!newCompany.value.account_holder_type) {
                locationTypeError.value = 'Client Type type is required';
                errorPresent = true;
            }
        }
      
        return errorPresent;
    }
    function openItem (item) {
        store.dispatch('setValue', { key: 'companyData', value: null});

        router.push({name: kycReviewInfo.value.item, params: {id: item._id}});
    }
    async function saveCompany() {
        if (validateFields()) {
            return;
        }
        addingNewLoading.value = true;
        newCompany.value.company_type = kycReviewInfo.value.type;
        const response = await API.createCompany(newCompany.value);
        if (response.status === 'success') {
            showAddPopup.value = false;
        } else {
            locationTypeError.value = 'Something went wrong';
            return;
        }
        addingNewLoading.value = false;
        router.push({name: kycReviewInfo.value.item, params: {id: response.company._id}});
    }

    function addCompany(){
        showAddPopup.value = true;
    }

    async function initiate(){
        loadingPage.value = true;
        if (props.kyc_type == 'corporate') {
            kycReviewInfo.value = {
                title: 'Corporate KYC',
                name: 'Company',
                name_plural: 'Companies',
                list_title: 'Company List',
                type: 'corporate',
                item: 'corporate_item'
            } 
        } else {
            kycReviewInfo.value = {
                title: 'Account Holder KYC',
                name: 'Account Holder',
                name_plural: 'Account Holders',
                list_title: 'Accounts List',
                type: 'account_holder',
                item: 'account_holder_item'
            }
        }
        document.title = `${kycReviewInfo.value.name} - KYC`;

        await doRequest();
    }

    onMounted(async () => {
        await initiate();

    })

    // watch props_kyc_type
    watch(async () => props.kyc_type, (newVal, oldVal) => {
        if (oldVal) {
            initiate();
        }
    })

</script>



<style lang="scss" scoped>
   .documentmanagement-header {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: left;
        color: black;
        padding-top: 20px;
    }
    .history-management-view {
        display: flex;
        padding: 20px;
        flex-direction: column;
        max-width: 1350px;
        margin: auto;
    }
    .subsidiary-container {
        width: 100%;
    }
    .subsidiary-content {
        min-width: 100%;
        border: 1px solid #ccc;
        border-radius: 10px;
        width: fit-content;
    }
    .sort {
        width: 13px;
        height: 16px;
        margin-left: 5px;
        margin-top: -2px;
        opacity: 0.8;
        &.orientation-1 {
            transform: rotateX(180deg);
            margin-bottom: -7px;
        }
    }
    .ordering {
        cursor: pointer;
        color: black;
        // font-size: 16px;
        font-weight: 600;
        &.sort-on {
            .sort {
                color: var(--primary-color);

            }
        }
    }
    
    .sub-header {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        padding: 20px;
        padding-left: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
    }
    
    .subsidiary-title {
        display: flex;
        justify-content: space-between;
        color: black;
        font-size: 20px;
    }
    
    .sub-header {
        display: flex;
        justify-content: space-between;
        button {
            width: fit-content;
            padding: 10px;
        }
    }
    .search-input {
        width: 300px;
        margin-bottom: 0;
    }
    .subsidiary-list {
        padding: 20px;
        width: 100%;
        border-collapse: collapse;
        th, td {
            // border: 1px solid #ccc;
            padding: 15px;
            text-align: left;
            border: none;
            padding-left: 30px;
            color: black;
        }
        td {
            padding-top: 19px;
            padding-bottom: 19px;
        }
        th {
            text-align: left;
            border: none;
        }
        tr {
            border-top: 1px solid #ccc;
        }
        tbody {
            tr {
                cursor: pointer;
                td {
                    background: white;
                }

                &:last-child {
                    td {
                        &:first-child {
                            border-bottom-left-radius: 10px;
                        }
                        &:last-child {
                            border-bottom-right-radius: 10px;
                        }
                    }
                }
                &:hover {
                    td {
                        background: #f9f9f9;
                    }
                }
            }
        }
    }
    
    .add-new td{
        padding-top: 0px;
        padding-bottom: 0px;
    
    }
    .save-button {
        padding: 10px 20px;
        width: fit-content;
        margin-left: auto;
    }
    h4 {
        margin: 0;
    }
    .add-new-subsidiary-area {
        justify-content: center;
        align-items: center;
    
    }
    .text-input {
        &:deep(input) {
            text-align: center;
        }
    }
    .more-actions {
    padding: 5px 0px;
    border-radius: 4px;
    width: 35px;
    height: 25px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background: #d9d9d9;

        .dropdown {
            display: flex;
            flex-direction: column;

        }
    }
    position: relative;

    .dropdown {
        display: none;
        position: absolute;
        top: 35px;
        right: 0;
        background: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        z-index: 100;
        div {
            padding: 10px;
            cursor: pointer;
            &:hover {
                background: #d9d9d9;
            }
        }
    }
}

</style>