<template>
    <div class="container" :class="reviewAction.objectType">
        <div class="header">
            <text v-if="view=='forConfirmation'"> 
                Action Required 
            </text>

            <text v-else> 
                History View 
            </text>
            <div class="cross" @click="closePopup">
                <img src="@/assets/cross.svg" alt="cross"/>
            </div>
        </div>

        <div class="info-holder w-full flex gap-5 column main-container" >
            <div>
                <div class="line-label">Date:</div>
                <div class="info-line">{{ renderTime(reviewAction.datetime) }} </div>
                <div class="line-label">Changes made by:</div>
                <div class="info-line">{{ reviewAction.user_name }} </div>
                <div v-if="reviewAction.handled_at" class="line-label" style='margin-top:20px;'>Handled At:</div>
                <div v-if="reviewAction.handled_at" class="info-line">{{ renderTime(reviewAction.handled_at)}}</div>
                <div v-if="reviewAction.actionType == 'remove'" class="line-label">Proposed Action:</div>
                <div v-if="reviewAction.actionType == 'remove'" class="info-line">Delete</div>

                <div v-if="reviewAction.handled_by_name" class="line-label">Handled By:</div>
                <div v-if="reviewAction.handled_by_name" class="info-line">{{ reviewAction.handled_by_name}}</div>
                
                <div v-if="reviewAction.actionTaken != 'skipped'">
                    <div v-if="view == 'forHistory'" class="line-label">Action Taken:</div>
                    <div v-if="view == 'forHistory'" class="info-line">{{ reviewAction.actionTaken}}
                        <span class="red" v-if="reviewAction.actionTaken == 'cascade_deleted'">
                            (Changes were not applied)
                        </span>

                    </div>
                </div>
                <div v-else>
                    <div v-if="view == 'forHistory'" class="line-label"></div>
                    <div v-if="view == 'forHistory'" class="info-line">Changes Made </div>
                </div>
            </div>
                <FileChangesView 
                    v-if="reviewAction.kyc_file"
                    :reviewAction="reviewAction"
                    :view="view"
                />
                <PersonChangeView
                    v-else-if="reviewAction.person"
                    :reviewAction="reviewAction"
                    :view="view"
                />
                <EntityChangeView
                    v-else-if="reviewAction.legalEntity"
                    :reviewAction="reviewAction"
                    :view="view"
                />
                <UBOChangeView
                    v-else-if="reviewAction.ubo_related"
                    :reviewAction="reviewAction"
                    :view="view"
                />
                
                <MDChangeView
                    v-else-if="reviewAction.objectType == 'mbd_related'"
                    :reviewAction="reviewAction"
                    :view="view"
                />
                <AccountNumberViewChange
                    v-else-if="reviewAction.objectType == 'account_number'"
                    :reviewAction="reviewAction"
                    :view="view"
                />
                <ShareholderChangeView
                    v-else-if="reviewAction.objectType == 'share_holder_related'"
                    :reviewAction="reviewAction"
                    :view="view"
                />
                <SubsidiaryChangeView
                    v-else-if="reviewAction.subsidiary"
                    :reviewAction="reviewAction"
                    :view="view"
                />
                <KycReviewViewChange
                    v-else-if="reviewAction.objectType == 'kyc_review'"
                    :reviewAction="reviewAction"
                    :view="view"
                />
                <TransactionReportChangeView
                    v-else-if="reviewAction.objectType == 'transaction_profile'"
                    :reviewAction="reviewAction"
                    :view="view"
                />
                <div v-else>
                    {{ reviewAction }}
                </div>

        </div>
        <div v-if="view=='forConfirmation' && (PermissionChecker.approveRequestChanges() || PermissionChecker.rejectRequestChanges())" class="add-new-ticket-info flex gap-5">
            <button v-if="PermissionChecker.rejectRequestChanges()" class="transparent cancel-btn" @click="denyChanges()" :disabled="requestSent">Deny</button>
            <button v-if="PermissionChecker.approveRequestChanges()" @click="approveChanges()" :disabled="requestSent">Approve</button>
        </div>
        
        <PopupCover v-if="showAffectedChanges" @close="showAffectedChanges = false">
            <PopupBody v-if="affectedChangesLoaded" class="container-popup" @close="showAffectedChanges = false">
                <template #header>
                    Following changes will be removed if you approve this request:
                </template>
                <template #body>
                    <div class="main-container flex column gap-5">
                        <div class="info-section-container">
                            <NotificationItem 
                                class="notification-item"
                                v-for="notification in affectedChanges" 
                                :key="'notification' + notification.datetime" 
                                @click="openCase(notification)"
                                :notification="notification"
                                >
                            </NotificationItem>
                        </div>
                        <div class="w-full flex gap-5 justify-end" style="margin-top: 20px;">
                            <button class='transparent w-content' @click="showAffectedChanges = false">Cancel</button>
                            <button class='w-content' @click="confirmDeleteApprove()">Confirm</button>
                        </div>
                    </div>

                </template>
            </PopupBody>

            <LoaderPopup v-else
                :hideHeader="true"
                :message="'Processing Request...'"
            />
        </PopupCover>
    </div>
</template>

<script setup>
    import { ref, defineEmits, defineProps, computed, onMounted } from 'vue'
    import { useStore } from 'vuex'

    import { PermissionChecker } from '@/utils/iam_utils.js'
    import FileChangesView from '@/components/FileChangesView.vue';
    import PersonChangeView from '@/components/PersonChangeView.vue';
    import EntityChangeView from '@/components/EntityChangeView.vue';
    import UBOChangeView from '@/components/UBOChangeView.vue';
    import MDChangeView from '@/components/MDChangeView.vue';
    import SubsidiaryChangeView from '@/components/SubsidiaryChangeView.vue';
    import ShareholderChangeView from '@/components/ShareholderChangeView.vue';
    import KycReviewViewChange from '@/components/KycReviewViewChange.vue';
    import AccountNumberViewChange from '@/components/AccountNumberViewChange.vue';
    import TransactionReportChangeView from '@/components/TransactionReportChangeView.vue';
    import PopupCover from '@/components/PopupCover.vue';
    import PopupBody from '@/components/PopupBody.vue';
    import LoaderPopup from '@/components/LoaderPopup.vue';

    import NotificationItem from '@/components/NotificationItem.vue';


    import API from '@/api/index';
    import dayjs from 'dayjs';

    const requestSent = ref(false);

    function renderTime(time) {
        if (!time) return '';
        return dayjs(time).format('MMM DD, YYYY [at] HH:mm');
    }
    const store = useStore();
    const emit = defineEmits(['close', 'deleteConfirm']);
    const props = defineProps([
        'reviewAction', 
        'view',
    ])
  
    const showAffectedChanges = ref(false);
    const affectedChangesLoaded = ref(false);
    const affectedChanges = ref(null);
    function closePopup() {
        emit('close')
    }

    async function confirmDeleteApprove(){
        requestSent.value = true;
        affectedChangesLoaded.value = false;
        const response = await API.approveChanges({id: props.reviewAction._id});
        if (response.status == 'success') {
            store.dispatch('addNotification', {
                type: 'success',
                text: 'Changes approved successfully'
            });
            emit('deleteConfirm')
            store.state.confirmationNeeded = store.state.confirmationNeeded.filter(el => el._id != props.reviewAction._id)
            store.state.notificationsCount -= 1;
        }
        requestSent.value = false;
    }

    async function approveChanges() {
        requestSent.value = true;
        affectedChangesLoaded.value = false;
        if (props.reviewAction.actionType == 'remove') {
            showAffectedChanges.value = true;
            let responseData = await API.getAffectedChanges({id: props.reviewAction._id});
            if (responseData.relatedChanges.length == 0) {
                showAffectedChanges.value = true;
                await confirmDeleteApprove();
            }
            affectedChanges.value = responseData.relatedChanges;

            affectedChangesLoaded.value = true;
            requestSent.value = false;
            return;
        }
        await confirmDeleteApprove();
     
    }

    async function denyChanges() {
        requestSent.value = true;
        const response = await API.denyChanges({id: props.reviewAction._id});
        if (response.status == 'success') {
            store.dispatch('addNotification', {
                type: 'success',
                text: 'Changes denied successfully'
            });
            store.state.confirmationNeeded = store.state.confirmationNeeded.filter(el => el._id != props.reviewAction._id)
            store.state.notificationsCount -= 1;
            closePopup();
        }
        requestSent.value = false;
    }
    function openCase(notification) {
        const link = `${window.location.origin}/workflow/${notification._id}`;
        window.open(link, '_blank');
    }

</script>


<style scoped lang="scss">

    .input-row {
        > * {
            flex: 1;
        }
    }
    .header {
        padding: 20px 20px 0px 20px;
    }
    .info-section-container {
        display: flex;
        flex-direction: column;
        align-items: self-start;
        width: 95%;
    }
    .notification-item {
        width: 100%;
        border: 1px solid var(--border-color);
    }
    .info-section {
        text-align: left;
        line-height: 18px;
    }
    .name {
        font-size: 14px;
        color: rgb(116 119 127);
    }
    .value {
        font-size: 16px;
        color: black;
        font-weight: bold;
    }
    .field-label {
        font-size: 14px;
        color: black;
        font-weight: 600;
        margin: 0;
        margin-bottom: 10px;
        text-align: left;
    }
    .button-delete {
        align-items: flex-end;
    }
    .header-small {
        text-align: left;
        color: black;
        font-size: 16px;
        margin: 0;
        margin-bottom: 10px;
    }
    .header-of-comments {
        text-align: left;
        font-size: 16px;
        color: black;
        font-weight: 600;
        padding-left: 5px;
    }
    .red {
        color: red;
    }
    .generate-password {
        border-radius: 5px;
        padding: 5px 10px;
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
        height: 26px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 2;
        &:hover {
            cursor: pointer;
            background-color: var(--button-hover-color-blue);
        }

    }
  
    .main-container {
        max-height: 80vh;
        overflow: auto;
        padding-left: 20px;
        box-sizing: border-box;
    }
    .container {
        border-radius: .5rem;
        display: flex;
        flex-direction: column;
        width: 1350px;
        max-width: 95vw;
        border: 1px solid var(--border-color);
        max-height: 100vh;
        overflow-y: auto;
        // padding: 20px;
        
        box-sizing: border-box;
        max-height: 95vh;
        background-color: white;

        &.kyc_review {
            width: 90vw;
            max-width: 1400px;
        }
    } 
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2rem;
        color: black;
    }
    .cancel-btn {
        margin-left: auto;
        margin-right: 10px;
    }
    .add-new-ticket-info {
        margin-top: auto;
        margin-left: auto;
        padding: 0 20px 20px 20px; 
        button {
            width: fit-content;
            padding: 10px 30px;
        }
    }
    .info-line {
        color: black;
        text-align: left;
        font-weight: 600;
        margin: 0;
    }
    .line-label {
        text-align: left;
        font-weight: 600;
        margin: 0;
        margin-top: 5px;
        font-size: 12px;
        color: rgb(151, 151, 151);
    }
    .container-popup.container {
        width: 1300px;
        max-width: 90vw;
    }
    .ticket-details {
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.5rem;
        color: black;
        padding-bottom: 20px;
        text-align: left;
    }
    .proposed-changes {
        max-width: 50%;
        margin-left: auto;
        .changes {
            background: #DEF7EC;
            &:deep(label) {
                background: #DEF7EC;
            }
        }
    }
    .current-version {
       
        .changes {
            background: #FDE8E8;
            &:deep(label) {
                background: #FDE8E8;
            }
        }
    }
    textarea {
        resize: none; 
        box-sizing: border-box;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        border-radius: 10px;
        min-height: 150px;
        max-height: 300px;
        height: fit-content;
        width: 100%
    }
    .markdown-content {
        height: 200px;
        max-height: 200px;
        :nth-child(1) {
            text-align: left;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        &:deep(.ql-container){
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        &.disabled {
            background-color: #f5f5f5;
            opacity: 0.6;
        }
    }
    .w-cover {
        width: 99%;
    }
    .header-of-section {
        color: black;
        text-align: left;
        font-weight: 600;
        font-size: 16px;
    }
    .info-section {
        width: 95%;
    }
    .comment {
        padding: 10px;
        background-color: #f5f5f5;
        border-radius: 5px;
        width: 100%;
        min-height: 60px;
        font-weight: 300;
        font-size: 14px;
        color: black;
        opacity: 0.6;
        border: 1px solid var(--border-color);
        &:deep(*) {
            margin: 0;
        }
    }
    .uploader-table {
        flex: 2;
    }
    .comment-info {
        flex: 3;
    }
    .info-holder {
        margin-bottom: 20px;
    }
</style>