<template>
    <div class="main-wrapper" v-if="loaded">
        <div class="header-section">
            <text v-if="view == 'forHistory'">
                History of Changes for  <i>{{ sectionName }}</i>
            </text>
            <text v-else>
                Changes waiting for confirmation for <i>{{ sectionName }}</i>
            </text>
        </div>
        <div class="notification-view">
            <div class='notification-container' v-if="loaded && confirmationNeeded.length">
                
                <NotificationItem 
                    v-for="notification in confirmationNeeded" 
                    :key="'notification' + notification.datetime" 
                    @click="openCase(notification)"
                    :notification="notification"
                    :view="view"
                    >
                </NotificationItem>
            </div>
            <div v-else-if="!loaded">
                Loading...
            </div>
            <div v-else>
                No items 
            </div>
            <PopupCover v-if="reviewAction" @close="close()">
                <ActionNeeded2
                    @deleteConfirm="deleteConfirm()"
                    :reviewAction="reviewAction"
                    :view="view"
                    @close="close()" 
                ></ActionNeeded2>
            </PopupCover>
        </div>
    </div>
    <div class='loading' v-else>
        <LoaderPopup 
            :hideHeader="true"
            :message="'Loading KYC information...'"
        />
    </div>
 
</template>

<script setup>
import { ref, computed, onMounted, watch, defineEmits, onUnmounted } from 'vue'

const emit = defineEmits(['updatingURL'])

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime);

import { PermissionChecker } from '@/utils/iam_utils';
import ActionNeeded2 from '@/dialogs/ActionNeeded2.vue'
import PopupCover from '@/components/PopupCover.vue'
import NotificationItem from '@/components/NotificationItem.vue'
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router'
import Utils from '@/utils/index.js'
import store from '../store';
import { mapState } from 'vuex';
import { VuejsPaginate } from 'vuejs-paginate';
import LoaderPopup from '@/components/LoaderPopup.vue'
import API from '../api/index.js'

const router = useRouter()
const route = useRoute()

const props = defineProps(['view', 'id', 'item_id'])
const reviewAction = ref(null);
const loaded = ref(false);

const companyData = computed(() => store.state.companyData);

const confirmationNeeded = computed(() => store.state.confirmationNeeded);
const notifications = ref([])
const notificationsCountTotal = ref(null);
const currentPage = ref(1);
const notificationId = ref(props.item_id);


const sectionName = computed(() => {
    if (companyData.value.company_type == 'corporate') {
        return companyData.value.name.value + ' Corporate';
    } 
    return companyData.value.first_name.value + " " + companyData.value.last_name.value + ' Account Holder';
})

async function loadData() {
    if (!companyData.value) {
        await API.loadDashboardData({company_id: props.id});
    }
    loaded.value = false;
    const response = await API.getNotificationList({page:currentPage.value, view: props.view, id: props.id});
    if (props.view == 'forConfirmation') {
        store.state.proposedChanges = response.notifications;
        await API.getNotificationCount({view: props.view, id: props.id});
        document.title = 'Changes waiting for confirmation';
    } else {
        document.title = 'History of changes';
    }
    
  

    store.state.confirmationNeeded = response.notifications;
    notifications.value = response.notifications;
    notificationsCountTotal.value = response.count;
    loaded.value = true;


}

onMounted(async() => {
    if (props.id) {
        await loadData();
    }
    if (props.item_id) {
       renderNotification();
    }
    
});
onUnmounted( () => {
    store.dispatch('setValue', { key: 'companyData', value: null })
})
async function deleteConfirm() {
    
    await loadData();
    const viewMap = {
        'forConfirmation': 'workflow-item',
        'forHistory': 'change-log-item'
    }
    router.push({name: viewMap[props.view], params: {id: props.id}});
    reviewAction.value = null;
}

function close() {
    const viewMap = {
        'forConfirmation': 'workflow-item',
        'forHistory': 'change-log-item'
    }
    router.push({name: viewMap[props.view], params: {id: props.id}});
    reviewAction.value = null;
}
function openCase(notification) {
    const viewMap = {
        'forConfirmation': 'workflow-item-inner',
        'forHistory': 'change-log-item-inner'
    }
    router.push({name: viewMap[props.view], params: { id: props.id, item_id: notification._id}});
}

watch(() => props.view, async (newVal, oldVal) => {
    if (oldVal){
        await loadData();
    }
});

async function renderNotification() {
    const result = await API.loadNotification(notificationId.value);
    
    reviewAction.value = result.notification;
}

watch(() => props.item_id, (newVal) => {
    notificationId.value = newVal;
    if (notificationId.value) {
        renderNotification();
    }
})



</script>

<style scoped lang="scss">
.main-wrapper {
    padding-left: 20px;
    padding-right: 20px;
}

.notification-view {
    border-radius: 10px;
    border: 1px solid var(--border-color);
    background-color: white;
    margin: auto;
    padding: 20px;
}
.notification-item {
    border-bottom: 1px solid var(--border-color);
    padding: 20px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
}
.notification-container {
    border-radius: 10px;
    border: 1px solid var(--border-color);
    background-color: white; 
}
.user-name {
    font-weight: bold;
    color: #595b61;
}
.notification-time {
    color: #74777F;
    font-size: 12px;
    display: flex;
    gap: 5px;
}
.notification-message {
    text-align: left;
    color: #181C22;
    font-size: 16px;
}
.notiifcation-info-conteiner {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
}
.time-relative {
    width: 100px;
    padding-right: 0;
}
.table-users {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid #e6eaed;
}
.is-read-false {
    background: #f9f9f9;
}
td {
    padding: 10px 30px;
    text-align: left;
}
th {
    text-align: left;
    padding: 10px 30px;
    background: #E6EAED;
}
.preview {
    padding: 10px;
    cursor: pointer;
    width: 30px;
}

.icon {
    background-color: gray;
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(224 226 236);
    position: relative;
    &.is-read-false {
        &::after {
            content: '';
            position: absolute;
            right: 10px;
            top: 10px;
            left: -15px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: rgb(224 226 236);
    
        }

    }
}
.change-item {
    padding: 2px 12px;
    background-color: var(--primary-color);
    color: white;
    border-radius: 3px;
    width: fit-content;
}
.pagination {
    display: flex;
    gap: 10px;

  &:deep(li) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

  }
  &:deep(.paginate-buttons) {
    height: 40px;

    width: 40px;
   
    border-radius: 20px;

    cursor: pointer;

    background-color: rgb(242, 242, 242);

    border: 1px solid rgb(217, 217, 217);

    color: black;
  }

  &:deep(.paginate-buttons:hover) {
    background-color: #d8d8d8;
  }

  &:deep(.active-page) {
    background-color: var(--primary-color);

    border: 1px solid var(--primary-color);

    color: white;
  }

  &:deep(.active-page:hover) {
    background-color: var(--primary-color);
  }
}

.notificaiton-read {
    width: 100%;
    text-align: right;
    cursor: pointer;
    text-decoration: underline;
}
</style>