<template>
    <div class="container">
        <div class="header">
            <text v-if="!objectInfo">
                Add new Subsidiary
            </text>
            <text v-else-if="editMode">
                Edit Subsidiary
            </text>
            <text v-else> 
                Subsidiary Details
            </text>
            <div class="cross" @click="editMode ? closeEditView() : closePopup()">
                <img src="@/assets/cross.svg" alt="cross"/>
            </div>
        </div>
        <div class="input-row flex gap-10 w-full" v-if="!objectInfo || (objectInfo && editMode)">
            <InpComponent 
                label="Subsidary Name"
                type="text" 
                
                key_ref="name"
                :value="object.name" 
                @inputChange="changeInput"
            />
            <InpComponent 
                label="Last Annual Account on File"
                type="options"

                :options="yearOptions" 
                :value="object.last_annual_account_on_file"
                key_ref="last_annual_account_on_file"
                @inputChange="changeInput"
            />
        </div>
        
        <div class="flex column input-row w-full" v-if="!objectInfo ">
            <div class="header-of-comments">Add comments (optional)</div>

            <div class="input-row flex w-full">
                <CommentSection @changeinput="quillContent" class="markdown-content"/>
            </div>
        </div>
        <div class='info-section-container' v-else-if="!editMode">
            <div class="info-section">
                <div class="name">Subsidiary name</div>
                <div class="value">{{ objectInfo.name }}</div>
            </div>
            <div class="info-section">
                <div class="name">Last Annual Account on File</div>
                <div class="value ">{{ objectInfo.last_annual_account_on_file }}</div>
            </div>
            <div class="info-section">
                <div class="name">Added at</div>
                <div class="value">{{ dayjs(objectInfo.snapshotDate).format('MMM DD, YYYY [at] HH:mm') }}</div>
            </div>
            <div class="info-section">
                <div class="name">Added by</div>
                <div class="value">{{ objectInfo.comment_by }}</div>
            </div>
            <div class="info-section">
                <div class="name">Comment</div>
                <div v-if="objectInfo.comment" class="value comment" v-html="objectInfo.comment"></div>
                <div v-else class="value comment">No comment present</div>
            </div>
        </div>
        <div v-if="!objectInfo " class="add-new-ticket-info flex gap-5">
            <button class="transparent cancel-btn" @click="closePopup()">Cancel</button>
            <button @click="InfoUpdate()">Create</button>
        </div>
        <div v-else-if="editMode " class="add-new-ticket-info flex gap-5">
            <button class="transparent cancel-btn" @click="closeEditView()">Cancel</button>
            <button @click="InfoEdit()">Save</button>
        </div>
        <div class="add-new-ticket-info view-actions flex gap-5" v-else-if="(PermissionChecker.updateSubsidiaries() || PermissionChecker.deleteSubsidiaries()) && !selectedSnapshot">
            <button v-if="PermissionChecker.deleteSubsidiaries()" class='transparent' @click="ShowDeletePopup">Delete</button>
            <button v-if="PermissionChecker.updateSubsidiaries()" @click="ObjectAction">Edit</button>
        </div>
        <PopupCover v-if="deleteObject" @close="() => deleteObject = !deleteObject">
            <ConfirmationPopup 
                @confirm="popupConfirm"
                @cancel="() => deleteObject = !deleteObject"
                :title="'Delete Subsidiary'"
                :message="'Are you sure you want to delete this subsidiary?'"
            />
        </PopupCover>
    </div>
</template>

<script setup>
    import { ref, defineEmits, defineProps, computed } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'


    import { PermissionChecker } from '@/utils/iam_utils';
    import InpComponent from './InpComponent.vue';
    import CommentSection from '@/views/CommentSection.vue';
    import PopupCover from '@/components/PopupCover.vue';
    import ConfirmationPopup from '@/components/ConfirmationPopup.vue';
    import API from '@/api/subsidiary';
    import dayjs from 'dayjs';

    const props = defineProps(['objectInfo'])


    const selectedSnapshot = computed(() => store.state.selectedSnapshot)

    const emit = defineEmits(['close', 'InfoUpdate', 'loading', 'delete_object'])
    const store = useStore()
    const router = useRouter()
    const deleteObject = ref(false);
    
    const editMode = ref(false); 
    const user = computed(() => store.state.user);

    const yearOptions = computed(() => store.state.yearOptions);
    const object = ref({
        comment: '',
        last_annual_account_on_file: '',
        name: '',
    })
    function quillContent(text) {
        object.value.comment = text.text
    }
    function InfoUpdate() {
        emit('InfoUpdate', {object: object.value})
    }
    function ObjectAction() {
        editMode.value = true;
        object.value.comment = props.objectInfo.comment;
        object.value.name = props.objectInfo.name;
        object.value.last_annual_account_on_file = props.objectInfo.last_annual_account_on_file;
        // emit('objectAction')
    }
    async function InfoEdit() {
        object.value._id = props.objectInfo._id;
        await API.editSubsidiary(object.value)
        props.objectInfo.comment = object.value.comment;
        props.objectInfo.name = object.value.name;
        props.objectInfo.last_annual_account_on_file = object.value.last_annual_account_on_file;
        editMode.value = false;
        object.value = {
            comment: '',
            last_annual_account_on_file: '',
            name: '',
        }
    }
    function popupConfirm(event){
        deleteObject.value = false;
        if (event) {
            emit('delete_object')
        }
    }

    function changeInput(event) {
        const event_type = event[0];
        const event_value = event[1];
        object.value[event_type] = event_value;
    }
    
    function ShowDeletePopup() {
        deleteObject.value = true;
    }
    function closeEditView(){
        editMode.value = false;
        object.value = {
            comment: '',
            last_annual_account_on_file: '',
            name: '',
        }
    }
    function closePopup(){
        emit('close')
    }
</script>


<style scoped lang="scss">

    .input-row {
        > * {
            flex: 1;
        }
    }
    .info-section-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: self-start;
    }
    .info-section {
        text-align: left;
        line-height: 18px;
    }
    .name {
        font-size: 14px;
        color: rgb(116 119 127);
    }
    .value {
        font-size: 16px;
        color: black;
        font-weight: bold;
    }
    .button-delete {
        align-items: flex-end;
    }
    .header-of-comments {
        text-align: left;
        font-size: 16px;
        color: black;
        font-weight: 600;
        padding-left: 5px;
    }
    .generate-password {
        border-radius: 5px;
        padding: 5px 10px;
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
        height: 26px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 2;
        &:hover {
            cursor: pointer;
            background-color: var(--button-hover-color-blue);
        }

    }
    .container {
        border-radius: .5rem;
        display: flex;
        flex-direction: column;
        width: 415px;
        border: 1px solid var(--border-color);
        min-height: 500px;
        padding: 20px;
        background-color: white;
    } 
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2rem;
        color: black;
    }
    .cancel-btn {
        margin-left: auto;
        margin-right: 10px;
    }
    .add-new-ticket-info {
        margin-top: auto;
        margin-left: auto;
        button {
            width: fit-content;
            padding: 10px 30px;
        }
    }
  
    .ticket-details {
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.5rem;
        color: black;
        padding-bottom: 20px;
        text-align: left;
    }
    .markdown-content {
        height: 200px;
        max-height: 200px;
        :nth-child(1) {
            text-align: left;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }
    .header-of-section {
        color: black;
        text-align: left;
        font-weight: 600;
        font-size: 16px;
    }
    .info-section {
        width: 95%;
    }
    .comment {
        padding: 10px;
        background-color: #f5f5f5;
        border-radius: 5px;
        width: 100%;
        min-height: 60px;
        font-weight: 300;
        font-size: 14px;
        color: black;
        opacity: 0.6;
        border: 1px solid var(--border-color);
        &:deep(*) {
            margin: 0;
        }
    }

</style>