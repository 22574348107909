<template>
    <div class="terms-page flex column">
        <div class="tc-header w-full flex row" v-if="withConfirm" >
            <h3>Terms and Conditions</h3>
            <img class='cross' src="@/assets/cross.svg" alt="cross" @click="closePopup()"/>
        </div>
        <div class='content' v-html="terms.text"></div>

        <div v-if="!loaded" class="flex justify-center align-center">
            <LoaderPopup :hideHeader="true" message="Loading.."/>
        </div>
        <div class='w-full' v-if="withConfirm && loaded">
            <button class='button' @click="confirmTC">I Read Terms and Conditions and ready to proceed</button>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, defineEmits } from 'vue';
    import API from '../api/index.js'
    import { useStore } from 'vuex';
    import CommentSection from '@/views/CommentSection.vue';
    import LoaderPopup from '@/components/LoaderPopup.vue';

    const emit = defineEmits(['confirmTC', 'closePopup']);
    const prop = defineProps(['withConfirm']);



    const store = useStore();
    const terms = ref([]);
    const loaded = ref(false);
    onMounted( async () => {
        document.title = 'Terms and Conditions';
        const data = await API.getTerms();
        console.log('data', data)
        terms.value = data.terms;
        if (terms.value.structure) {
            terms.value.structure = JSON.parse(terms.value.structure);
        }
        loaded.value = true;
    });
    function closePopup(){
        emit('closePopup');
    }
    function confirmTC(){
        emit('confirmTC');
    }



</script>


<style lang="scss" scoped>
    .terms-page {
        margin: 70px;
        padding: 30px;
        color: black;
        text-align: left;
        position: relative;
        background: white;
        border: 1px solid var(--border-color);
        height: fit-content;
        border-radius: 10px;
        min-width: 500px;
        min-height: 500px;
    }
    .tc-header {
        justify-content: center;
        align-items: center;
    }
    h3 {
        font-size: 24px;
        font-weight: 600;
        margin: 0;
    }
    .cross {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 20px;
    }
    button {
        width: fit-content;
        margin-left: auto;
    }
   
</style>