<template>
    <div class="subsidiary-container">
     <div class="subsidiary-content">
         <div class="sub-header" >
             <h4 class="subsidiary-title">Account list</h4>
             <button v-if="PermissionChecker.createUser()" @click="addAccount()">Add Account</button>
         </div>
         <table class="subsidiary-list">
             <thead>
                <tr>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Role</th>
                </tr>
             </thead>
             <tbody class='table-content' v-if="store.state.accounts">
                <tr class="account-list" 
                    v-for="account in store.state.accounts" 
                    :key="account._id" 
                    @click="openAccount(account)">
                    <td class="user-info"> 
                        <div class="icon-person">
                            {{ account.username.slice(0,2).toUpperCase() }}
                        </div>
                        <div class="user-name">
                            {{ account.username }}
                        </div>
                    </td>
                    <td>{{ account.email }}</td>
                    <td>{{ getUserType(account.type) }}</td>
                    <!-- <td>{{ account.type.replace('_', ' ') }} </td> -->
                 </tr>
             </tbody>
         </table>
     </div>
   
     <PopupCover v-if="showPopups" @close="closePopup">
        <UserPopup 
            @close="closePopup" 
            :userInfo="userInformation"
            @InfoUpdate="UserPopupHandler"
        />
    </PopupCover>
 </div>
 </template>
 
 <script setup>

import { ref, defineEmits, computed, onMounted } from 'vue'

import API from '../api/index'
import store from '@/store/index.js';
import PopupCover from '@/components/PopupCover.vue';
import UserPopup from '@/components/UserModal.vue';
 
import { PermissionChecker } from '@/utils/iam_utils';
import { useRouter, useRoute } from 'vue-router'

import {getUserType} from '@/utils/iam_utils'

const router = useRouter();
const route = useRoute();


const user = computed(() => store.state.user);
const emit = defineEmits(['commentChange'])
const userInformation = ref(null);
const showPopups = ref(false);


async function UserPopupHandler(event) {
    showPopups.value = false;
    const response = await API.createNewUser(event.user);
    if (!response) {
        return;
    }
    store.state.accounts.unshift(response.user)
    store.dispatch('addNotification', {
        type: 'success',
        text: 'User created sucessfullly'
    })
}

function closePopup() {
    showPopups.value = false;
    userInformation.value = null;

}

function openAccount(account) {
    router.push({name: 'company_users', params: {id: account._id}})

}

function addAccount() {
    showPopups.value = true;
}


onMounted(async() => {
    document.title = 'User Management';

    if (!user.value) {
        router.push('/login')
        return;
    }
    await API.getUserAccounts()
});

</script>
 
<style scoped lang="scss">

    .icon-person {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 30px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 12px;
        font-weight: 600;
        height: 30px;
        color: var(--primary-color);
        background: rgb(236, 240, 255);
    }
    .user-info {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .subsidiary-container {
        width: 100%;
    }
    
    .subsidiary-content {
        min-width: 100%;
        border: 1px solid #ccc;
        border-radius: 10px;
        width: fit-content;
    }
    
    .sub-header {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        padding: 20px;
        padding-left: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
    }
    
    .subsidiary-title {
        display: flex;
        justify-content: space-between;
        color: black;
        font-size: 20px;
    }
    
    .sub-header {
        display: flex;
        justify-content: space-between;
        button {
            width: fit-content;
            padding: 10px;
        }
    }
    .subsidiary-list {
        padding: 20px;
        width: 100%;
        border-collapse: collapse;
        th, td {
            // border: 1px solid #ccc;
            padding: 15px;
            text-align: left;
            border: none;
            padding-left: 30px;
            color: black;
        }
        td {
            padding-top: 19px;
            padding-bottom: 19px;
        }
        th {
            text-align: left;
            border: none;
        }
        tr {
            border-top: 1px solid #ccc;
        }
        tbody {
            tr {
                cursor: pointer;
                td {
                    background: white;
                }

                &:last-child {
                    td {
                        &:first-child {
                            border-bottom-left-radius: 10px;
                        }
                        &:last-child {
                            border-bottom-right-radius: 10px;
                        }
                    }
                }
                &:hover {
                    td {
                        background: #f9f9f9;
                    }
                }
            }
        }
    }
    
    .add-new td{
        padding-top: 0px;
        padding-bottom: 0px;
    
    }
    .save-button {
        padding: 10px 20px;
        width: fit-content;
        margin-left: auto;
    }
    h4 {
        margin: 0;
    }
    .add-new-subsidiary-area {
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .text-input {
        &:deep(input) {
            text-align: center;
        }
    }
 
 .more-actions {
    padding: 5px 0px;
    border-radius: 4px;
    width: 35px;
    height: 25px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
     cursor: pointer;
     &:hover {
         background: #d9d9d9;
 
         .dropdown {
             display: flex;
             flex-direction: column;
 
         }
     }
     position: relative;
 
     .dropdown {
        display: none;
        position: absolute;
        top: 35px;
        right: 0;
        background: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        z-index: 100;
        div {
            padding: 10px;
            cursor: pointer;
            &:hover {
                background: #d9d9d9;
            }
        }
     }
 }
 
 </style>