<template>
  <div class="register">
    <div class="register-wrap border-section">
      <div class="title flex align-center justify-center">
        <text>
          Register Account
        </text>
      </div>
      <form @submit.prevent="saveData">

        <InpComponent 
          v-model="username" 
          label="Username" 
          
          type="text" 
          key_ref="username"
          @inputChange="inputChangeFunc"
          :error="usernameError"
        />
        <InpComponent 
          v-model="username" 
          label="Email" 
          
          type="email" 
          key_ref="email"
          @inputChange="inputChangeFunc"
          :error="emailError"
        />
        <InpComponent 
          v-model="email" 
          label="Password" 
          
          type="password"
          key_ref="password"
          @inputChange="inputChangeFunc"
          :error="passwordError"
        />
        <button class='button main-color-bg' type="submit" @click="register('init')">Register</button>
        <router-link to="/login" class='login main-color'>Login</router-link>

      </form>
    </div>
    <PopupCover v-if="showPopupVal" @close="close()">
      <div>
        <TermsUser @closePopup="close()" @confirmTC="register('confirm')" class='terms-page' :withConfirm="true" @changeinput="quillContent"></TermsUser>
      </div>
    </PopupCover>
  </div>
</template>

<script>

import Utils from '../utils/index.js'
import API from '../api/index.js'
import { mapState } from 'vuex';
import InpComponent from '@/components/InpComponent.vue';
import { RouterLink } from 'vue-router';
import PopupCover from '@/components/PopupCover.vue';
import TermsUser from '@/views/TermsUser.vue';

export default {
  name: 'RegisterVue',
  components: {
    InpComponent,
    PopupCover,
    TermsUser
  },
  data() {
    return {
      // Define your local data properties here
      username: '',
      email: '',
      password: '',
      emailError: false,
      passwordError: false,
      usernameError: false,
      showPopupVal: false,
    }
  },
  methods: {
    inputChangeFunc(event) {
      console.log('inputChangeFunc', event)
      if (event[0] == 'email'){
        this.email = event[1];
        this.validateEmailField();
      } else if (event[0] == 'password') {
        this.password = event[1];
        this.validatePasswordField();
      } else if (event[0] == 'username') {
        this.username = event[1];
        this.validateUsernameField();
      }
    },
    showPopup(){
      this.showPopupVal = true;
    },
    close(){
      this.showPopupVal = false;
    },
    validateEmailField() {
      if (this.emailError) {
        if (Utils.validateEmail(this.email)) {
          this.emailError = false;
        }
      }
    },
    validatePasswordField() {
      if (this.passwordError) {
        if (Utils.validatePassword(this.password)) {
          this.passwordError = false;
        }
      }
    },
    validateUsernameField() {
      if (this.usernameError) {
        if (Utils.validateName(this.username)) {
          this.usernameError = false;
        }
      }
    },
    async register(state) {
      let error = false
      if (!Utils.validateEmail(this.email)) {
        this.emailError = 'Invalid email';
        error = true;
      }
      if (!Utils.validatePassword(this.password)) {
        this.passwordError = 'Password must be from 6 to 30 characters long';
        error = true;
      }
      if (!Utils.validateName(this.username)) {
        this.usernameError = 'Username must be from 3 to 20 characters long';
        error = true;
      }
      if (error) {
        return;
      }
      if (state == 'init') {
        this.showPopup();
        return;
      }
      else if (state == 'confirm') {
        this.close();
        const resp = await API.register({
          username: this.username,
          email: this.email,
          password: this.password
        })
        if (resp.error) {
          this.emailError = resp.error;
        }
        console.log('Registering user:', this.username, this.email, this.password);
      }


  
    },
  },
  mounted() {
    document.title = 'Register';
    if (localStorage.getItem('token')) {
      this.$router.push('/kyc')
    }
  },
  computed: {
    ...mapState(['user']),
  },
}
</script>

<style scoped lang="scss">

  .login {
    height: fit-content;
    text-align: right;
    margin: 2px;
    font-size: 11px;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
    margin-left: auto;
  }
  .error-item {
    color: red;
    margin: 0;
    height: fit-content;
    text-align: left;
    font-size: 9px;
    margin: 0;
    margin-left: -15px;
    margin-top: -5px;
  }
  .register {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 60px);
    color: #38314e;
    font-size: 23px;
    font-weight: bold;
    text-align: left;

  }
  .register-wrap {
    background-color: white;
    margin-top: -60px;
    border-radius: 10px;

    padding: 20px;
  }
  .terms-page {
    max-height: 80vh;
    max-width: 1000px;
    overflow-y: auto;
    font-size: 0.8rem;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 330px;

    > * {
      margin: 10px;
      width: 100%;
      height: 35px;
      font-size: 15px;
    }
    input {
      border: 1px solid #2b263a;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      border-radius: 0;
      outline: none;
      caret-color: #2b263a;
      background-color: white;
      color: #38314e;
      padding: 3px 10px;
      box-sizing: border-box;

      &.error {
        border: 1px solid red;
      }
    }
    button {
      height: fit-content;
      margin-bottom: 0;
    }
   
  }
  .title {
    text {
      font-size: 20px;
      font-weight: bold;
      margin: auto;
    }
  }

</style>