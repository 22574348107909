<template>
        <div :id="'id'+ person_id" class="main-person-wrapper flex column" :class="{ubo_person: sectionPerson.ubo_related}">
            <div
                v-if="!sectionPerson.ubo_related " 
                class='flex row gap-5 h-content align-start'>
                <div v-if="isOpen == false" class="flex flex-1 row gap-5" style="margin-right: auto;">
                    <InpComponent
                        :disabled="!canBeUpdated"
                        label="First Name"
                        type="text"
                        placeholder="Don"
                        :key_ref="'firstName'"
                        :value="sectionPerson.firstName.new_value || sectionPerson.firstName.value"
                        :error="sectionPerson.firstName.error"
                        :pendingChanges="hasChanges('firstName')"
                        @inputChange="inputChangeFunc"
                    />
                
                    <InpComponent
                        :disabled="!canBeUpdated"
                        label="Last Name"
                        type="text"
                        placeholder="Jon"
                        :key_ref="'lastName'"
                        :value="sectionPerson.lastName.new_value || sectionPerson.lastName.value"
                        :error="sectionPerson.lastName.error"
                        :pendingChanges="hasChanges('lastName')"
                        @inputChange="inputChangeFunc"
                    />
                    
                </div>

                <div class="flex row gap-5" style="margin-left: auto">
                    <button title='collapse & expand section' class='transparent w-content mb-15 button-holder' @click="toggleSection">
                        <img class="arrow-opener-section" :class="isOpen ? 'section-opened': ''" src="@/assets/arrow-opener.svg">
                    </button>
                    <button class='delete-bin transparent' @click="DeletePerson()" v-if="canBeDeleted">
                        <img class="delete-section" src="@/assets/delete.svg">
                    </button>
                </div>
            </div>

            <div class="w-full content-collapse" :id="'collapse_' + sectionPerson._id">
                <div class="flex col gap-30" v-if="sectionPerson.ubo_related">
                    <InpComponent
                        class="country-select flex-1"
                        label="Origin"
                        type="options"
                        :disabled="!canBeUpdated"
                        :options="[{value: 'local', text: 'Resident'}, {value: 'abroad', text: 'Non-resident'}]"
                        placeholder="Local"
                        :key_ref="'location_type'"
                        :pendingChanges="hasChanges('location_type')"
                        :value="sectionPerson.location_type.new_value || sectionPerson.location_type.value"
                        @inputChange="inputChangeFunc"
                    />
                    <div class="flex-1"></div>
                    <div class="flex-1"></div>
                </div>

                <div class="flex row gap-30" v-if="isOfficial">
                    <InpComponent
                        :disabled="!canBeUpdated"
                        class="flex-1"
                        label="Title"
                        type="options"
                        :options="[
                            { value: 'Director', text: 'Director' },
                            { value: 'Secretary', text: 'Secretary' },
                            { value: 'MD', text: 'MD' },
                            { value: 'Registered Agent', text: 'Registered Agent' },
                        ]"
                        
                        :pendingChanges="hasChanges('title')"
                        :key_ref="'title'"
                        :value="sectionPerson.title.new_value || sectionPerson.title.value"
                        :error="sectionPerson.title.error"
                        

                        @inputChange="inputChangeFunc"
                    />
                    <div class="flex-1"></div>
                    <div class="flex-1"></div>
                </div>

                <div class="flex row gap-30 wrap">
                    <div class="flex-1">
                        <div class="passport-fields file-area">
                        
                            <InpComponent
                                :disabled="!canBeUpdated"
                                label="First Name"
                                type="text"
                                placeholder="Don"
                                :key_ref="'firstName'"
                                :value="sectionPerson.firstName.new_value || sectionPerson.firstName.value"
                                :error="sectionPerson.firstName.error"
                                :pendingChanges="hasChanges('firstName')"
                                @inputChange="inputChangeFunc"
                            />
                        
                            <InpComponent
                                :disabled="!canBeUpdated"
                                label="Last Name"
                                :pendingChanges="hasChanges('lastName')"
                                type="text"
                                placeholder="Jon"
                                :key_ref="'lastName'"
                                :value="sectionPerson.lastName.new_value || sectionPerson.lastName.value"
                                :error="sectionPerson.lastName.error"
                                @inputChange="inputChangeFunc"
                            />

                            <InpComponent
                                :disabled="!canBeUpdated"
                                label="Date of Birth"
                                type="date"
                                :key_ref="'dateOfBirth'"
                                :pendingChanges="hasChanges('dateOfBirth')"
                                :value="sectionPerson.dateOfBirth.new_value || sectionPerson.dateOfBirth.value"
                                :error="sectionPerson.dateOfBirth.error"
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                :disabled="!canBeUpdated"
                                class="country-select"
                                label="Place of Birth"
                                type="options"
                                :options="Utils && Utils.getCountryList()"
                                placeholder="Curacao"
                                :pendingChanges="hasChanges('placeofBirth')"
                                :key_ref="'placeofBirth'"
                                :value="sectionPerson.placeofBirth.new_value || sectionPerson.placeofBirth.value"
                                :error="sectionPerson.placeofBirth.error"
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                :disabled="!canBeUpdated"
                                label="Passport Number"
                                type="text"
                                :pendingChanges="hasChanges('passportNumber')"
                                placeholder="number"
                                :key_ref="'passportNumber'"
                                :value="sectionPerson.passportNumber.new_value || sectionPerson.passportNumber.value"
                                :error="sectionPerson.passportNumber.error"
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                :disabled="!canBeUpdated"
                                class="country-select"
                                label="Issue country"
                                type="options"
                                :options="Utils && Utils.getCountryList()"
                                placeholder="Curacao"
                                :key_ref="'passportCountry'"
                                :value="sectionPerson.passportCountry.new_value || sectionPerson.passportCountry.value"
                                :error="sectionPerson.passportCountry.error"
                                :pendingChanges="hasChanges('passportCountry')"
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                :disabled="!canBeUpdated"
                                label="Issue Date"
                                type="date"
                                placeholder="2024/01/01"
                                :key_ref="'dateIssued'"
                                :pendingChanges="hasChanges('dateIssued')"
                                :value="sectionPerson.dateIssued.new_value || sectionPerson.dateIssued.value"
                                :error="sectionPerson.dateIssued.error"
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                :disabled="!canBeUpdated"
                                label="Expiry Date"
                                type="date"
                                placeholder="2024/01/01"
                                :key="`${sectionPerson._id}-dateExpiry`"
                                :key_ref="'dateExpiry'"
                                :pendingChanges="hasChanges('dateExpiry')"
                                :value="sectionPerson.dateExpiry.new_value || sectionPerson.dateExpiry.value"
                                :error="sectionPerson.dateExpiry.error"
                                @inputChange="inputChangeFunc"
                            />

                            <InpComponent
                                :disabled="!canBeUpdated"
                                label="Taxpayer Identification Number"
                                type="number"
                                placeholder="number"
                                :key_ref="'tinNumber'"
                                :pendingChanges="hasChanges('tinNumber')"
                                :value="sectionPerson.tinNumber.new_value || sectionPerson.tinNumber.value"
                                :error="sectionPerson.tinNumber.error"
                                @inputChange="inputChangeFunc"
                            />
                        </div>
                    </div>
                    <div class="file-area flex-1">
                        <div class="passport-fields ">
                            <InpComponent
                                label="Address Line 1"
                                type="text"
                                placeholder="123 Main St"
                                :key_ref="'address_line_1'"
                                :pendingChanges="hasChanges('address_line_1')"
                                :value="sectionPerson.address_line_1.new_value || sectionPerson.address_line_1.value"
                                :error="sectionPerson.address_line_1.error"
                                :disabled="!canBeUpdated"
                                @inputChange="inputChangeFunc"
                            />

                            <InpComponent
                                label="Address Line 2 (optional)"
                                type="text"
                                placeholder=""
                                :disabled="!canBeUpdated"
                                :pendingChanges="hasChanges('address_line_2')"
                                :key_ref="'address_line_2'"
                                :value="sectionPerson.address_line_2.new_value || sectionPerson.address_line_2.value"
                                :error="sectionPerson.address_line_2.error"
                                @inputChange="inputChangeFunc"
                            />

                            <InpComponent
                                label="City / Town"
                                type="text"
                                placeholder="London"
                                :pendingChanges="hasChanges('city')"
                                :key_ref="'city'"
                                :value="sectionPerson.city.new_value || sectionPerson.city.value"
                                :error="sectionPerson.city.error"
                                :disabled="!canBeUpdated"
                                @inputChange="inputChangeFunc"
                            />

                            <InpComponent
                                class="country-select"
                                label="Country"
                                type="options"
                                :options="Utils && Utils.getCountryList()"
                                placeholder="Canada"
                                :pendingChanges="hasChanges('address_country')"
                                :key_ref="'address_country'"
                                :value="sectionPerson.address_country.new_value || sectionPerson.address_country.value"
                                :disabled="!canBeUpdated"
                                :error="sectionPerson.address_country.error"
                                @inputChange="inputChangeFunc"
                            />

                            <InpComponent
                                label="Zip"
                                type="text"
                                placeholder="00000"
                                :key_ref="'zip'"
                                :value="sectionPerson.zip.new_value || sectionPerson.zip.value"
                                :error="sectionPerson.zip.error"
                                :disabled="!canBeUpdated"
                                :pendingChanges="hasChanges('zip')"
                                @inputChange="inputChangeFunc"
                            />

                            <InpComponent
                                v-if="sectionPerson.address_date_issues"
                                class="issue-date"
                                label="Issue Date"
                                type="date"
                                placeholder="2024/01/01"
                                :key_ref="'address_date_issues'"
                                :pendingChanges="hasChanges('address_date_issues')"
                                :value="sectionPerson.address_date_issues.new_value || sectionPerson.address_date_issues.value"
                                :error="sectionPerson.address_date_issues.error"
                                :disabled="!canBeUpdated"
                                @inputChange="inputChangeFunc"
                            />
                        </div>
                    </div>
                    
                    <div class='file-area flex-1' v-if="personLocationType == 'abroad'">

                        <div class="passport-fields ">

                            <InpComponent
                                :disabled="!canBeUpdated"
                                label="Document Type"
                                type="options"
                                :pendingChanges="hasChanges('secondPassportType')"
                                placeholder=""
                                :options="[{value: 'id_card', text: 'ID Card'}, {value: 'driver_license', text: 'Driver License'}]"
                                :key_ref="'secondPassportType'"
                                :value="sectionPerson.secondPassportType.new_value || sectionPerson.secondPassportType.value"
                                :error="sectionPerson.secondPassportType.error"
                                @inputChange="inputChangeFunc"
                            />

                            <InpComponent
                                :label="docLabel"
                                type="text"
                                :disabled="disabled_number || !canBeUpdated"
                                placeholder="number"
                                :key_ref="'secondPassportNumber'"
                                :pendingChanges="hasChanges('secondPassportNumber')"
                                :value="sectionPerson.secondPassportNumber.new_value || sectionPerson.secondPassportNumber.value"
                                :error="sectionPerson.secondPassportNumber.error"
                            @inputChange="inputChangeFunc"
                            />
                            
                            <InpComponent
                                :disabled="!canBeUpdated"
                                class="country-select"
                                label="Issue country"
                                type="options"
                                :options="Utils && Utils.getCountryList()"
                                placeholder="Curacao"
                                :key_ref="'secondPassportCountry'"
                                :pendingChanges="hasChanges('secondPassportCountry')"
                                :value="sectionPerson.secondPassportCountry.new_value || sectionPerson.secondPassportCountry.value"
                                :error="sectionPerson.secondPassportCountry.error"
                                @inputChange="inputChangeFunc"
                            />

                            <InpComponent
                                :disabled="!canBeUpdated"
                                label="Issue Date"
                                type="date"
                                placeholder="2024/01/01"
                                :key_ref="'secondDateIssued'"
                                :pendingChanges="hasChanges('secondDateIssued')"
                                :value="sectionPerson.secondDateIssued.new_value || sectionPerson.secondDateIssued.value"
                                :error="sectionPerson.secondDateIssued.error"
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                :disabled="!canBeUpdated"
                                label="Expiry Date"
                                type="date"
                                placeholder="2024/01/01"
                                :key_ref="'secondDateExpiry'"
                                :pendingChanges="hasChanges('secondDateExpiry')"
                                :value="sectionPerson.secondDateExpiry.new_value || sectionPerson.secondDateExpiry.value"
                                :error="sectionPerson.secondDateExpiry.error"
                            @inputChange="inputChangeFunc"
                            />
                        </div>
                    </div>
                    <div v-else class="flex-1">

                    </div>
                </div>
                <div class="table-wrap" style="margin-top: 40px;">
                    <table style="height: 100%;">
                        <TableHeaderRow 
                            :preview="reviewMode"
                        />

                            <tbody>
                                
                                <TableFileRow2 
                                    :id="'id'+ sectionPerson.files.passport_file"
                                    :fileId="sectionPerson.files.passport_file"
                                    :reviewMode="reviewMode"
                                />
                                <TableFileRow2 
                                    :id="'id'+ sectionPerson.files.address_file"
                                    :fileId="sectionPerson.files.address_file"
                                    :reviewMode="reviewMode"
                                />
                                
                                <TableFileRow2 
                                    :id="'id'+ sectionPerson.files.secondPassport_file"
                                    v-if="personLocationType == 'abroad'"
                                    :fileId="sectionPerson.files.secondPassport_file"
                                    :reviewMode="reviewMode"
                                />
                                <TableFileRow2 
                                    :id="'id'+ sectionPerson.files.source_of_wealth"
                                    v-if="source_of_wealth"
                                    :fileId="sectionPerson.files.source_of_wealth"
                                    :reviewMode="reviewMode"
                                />
                                <TableFileRow2 
                                    :id="'id'+ sectionPerson.files.bank_reference_letter"
                                    v-if="bank_reference_letter"
                                    :fileId="sectionPerson.files.bank_reference_letter"
                                    :reviewMode="reviewMode"
                                />
                                <TableFileRow2 
                                    :id="'id'+ sectionPerson.files.source_of_funds"
                                    v-if="source_of_funds"
                                    :fileId="sectionPerson.files.source_of_funds"
                                    :reviewMode="reviewMode"
                                />
                                <TableFileRow2 
                                    :id="'id'+ sectionPerson.files.google_search"
                                    v-if="google_search"
                                    :fileId="sectionPerson.files.google_search"
                                    :reviewMode="reviewMode"
                                    :person_id="person_id"
                                />
                                <TableFileRow2 
                                    :id="'id'+ sectionPerson.files.world_check"
                                    v-if="world_check"
                                    :fileId="sectionPerson.files.world_check"
                                    :reviewMode="reviewMode"
                                    :person_id="person_id"

                                />    
                        </tbody>
                    </table>
                </div>
            </div>
    </div>
</template>

<script setup>

    import { ref, defineEmits, computed, withDefaults, defineProps, watch, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import TableHeaderRow from '@/components/TableHeaderRow.vue'
    import InpComponent from '@/components/InpComponent.vue'
    import TableFileRow2 from '@/components/TableFileRow2.vue'
    import Utils from '@/utils/index.js'
    import { PermissionChecker } from '@/utils/iam_utils'

    const props = defineProps([
        'person_id',
        'source_of_wealth',
        'bank_reference_letter',
        'source_of_funds',
        'google_search',
        'world_check',
        'header',
        'reviewMode',
        'hideShare'
    ])

    const sectionPerson = computed(() => store.getters.getPersonById(props.person_id));
    const isOfficial = computed(() => !sectionPerson.value.ubo_related)
    const sectionMD = computed(() => store.getters.getMdById(sectionPerson.value.mbd_related))

    const notificationPresent = computed(() => props.reviewMode ?  [] : store.getters.getActionRequired(props.person_id, 'person'));

    const hasChanges = computed(() => (field) => {
        for (const item of notificationPresent.value) {
            let fields = JSON.parse(item.fields); 
            if (item.actionType == 'remove') {
                return true;
            }
            if (fields[field]?.new) {
                return true
            }
        }
    })

    const personLocationType = computed(() => {
        let value = sectionPerson.value.location_type.value
        let new_value = sectionPerson.value.location_type.new_value

        if (isOfficial.value && sectionMD.value) {
            value = sectionMD.value.location_type.value
            new_value = sectionMD.value.location_type.new_value
        }

        if (
            new_value === 'abroad' || 
            (value === 'abroad' && new_value === '')
        ) { return 'abroad' }

        return 'local'
        
    })
    const canBeUpdated = computed(() => {
        if (props.reviewMode) {
            return false;
        }
        if (sectionPerson.value.ubo_related && !sectionPerson.value.mbd_related) {
            return PermissionChecker.updateUBOSection()
        }
        if (sectionPerson.value.mbd_related) {
            return PermissionChecker.updatembdSection()
        }
    })
    const canDownloadFiles = computed(() => {
        if (sectionPerson.value.ubo_related && !sectionPerson.value.mbd_related) {
            return PermissionChecker.downloadUbOSection()
        }
        if (sectionPerson.value.mbd_related) {
            return PermissionChecker.downloadmbdSection()
        }
    });
    const canBeDeleted = computed(() => {
        if (sectionPerson.value.mbd_related && !props.reviewMode) {
            return PermissionChecker.deletembdSection()
        }
    })

    const HTMLElements = computed(() => {
        return document.getElementById('collapse_' + sectionPerson.value._id);
    })
    const isOpen = computed(() => store.getters.getToggleState(sectionPerson.value._id))
  
    const store = useStore();
    const user = computed(() => store.state.user)
    const docLabel = ref('')
  
    const disabled_number = computed(() => {
        return !(
            sectionPerson.value.secondPassportType.new_value.length
            || sectionPerson.value.secondPassportType.value.length
        )
    })
    
    function setLabel(value){
        if (value == 'id_card') {
            docLabel.value = 'ID Card Number'
        } else if (value == 'driver_license') {
            docLabel.value = 'Driver license Number'
        } else {
            docLabel.value = 'Document Number'
        }
    }

    function inputChangeFunc(event) {
        const event_type = event[0];
        const event_value = event[1];
        store.commit('updatePersonById',{
            id: props.person_id, 
            updates: {
                [`${event_type}`]: event_value,
            }
        })
        if (event_type == 'secondPassportType') {
            setLabel(event_value)
        }
    }

    async function DeletePerson() {
        if (window.confirm('Are you sure you want to delete this Official?')) {
            store.dispatch('deletePerson', {
                person_id: props.person_id,
            })
        }
    }   

    function toggleSection() {
        store.dispatch('toggleSection', {
            id: props.person_id, 
        })
    }
    
    function adjustHeight() {
        const section = HTMLElements.value;

        if (isOpen.value) {
            section.style.maxHeight = "none"; // Prevent transition issues
            section.style.overflowY = "visible"; // Prevent overflow
        } 
        else {
            section.style.maxHeight = "0"; // Collapse the section
            section.style.overflowY = "hidden"; // Hide overflow
        }
    }

    onMounted(() => {
        setLabel(sectionPerson.value.secondPassportType.value)
        adjustHeight()
    })

  
    
</script>

<style scoped lang="scss">

    .loader-header {
        font-size: 24px;
        color: black;
        font-weight: bold;
    }
    .content {
        max-height: 0;
        overflow-y: hidden;
        transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
    }
    .sanction-screanin-response {
        height: 90vh;
        min-height: 400px;
        width: 70vw;
        overflow: auto;
        background-color: white;
        padding: 20px;
        border-radius: 0.5rem;
    }
    // make rorating animation for the img inside .loader class
    .loader img {
        animation: rotate 1s linear infinite;
    }
    .loader-text {
        font-size: 18px;
        color: black;
    }
    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    .container-loading  {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 350px;
        height: 200px;
        background-color: white;
    }
    .file-area {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .dynamic-file {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 35px;
    }
    .flex {
        display: flex;
        &.row {
            flex-direction: row;
        }
        &.column {
            flex-direction: column;
        }
    }
    .header-info {
        width: 100%;
        text-align: left;
        font-size: 24px;
    }
    .passport-fields {
        margin-top: 20px;
    }
    
    textarea {
        resize: none; 
        box-sizing: border-box;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        width: 685px;
        height: 150px;
    }
    .add-new {
        margin-left: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .margin-top-40 {
        margin-top: 40px;
    }
    

    .margin-div-el {
        margin-top: 19px;
    }
    .main-person-wrapper {
        border-radius: 5px;
        background-color: white;
        &:not(.ubo_person) {
            padding: 20px;
            border: 1px solid var(--border-color);
        }
    }
    table {
        border-collapse: collapse;
    }
    thead {
        font-weight: bold;
    }
    td {
        padding: 10px;
        border: 1px solid var(--border-color);
    }

</style>