<template>
    <div class="kyc-view" >
        <div class="save-area" v-if="needToSave && !selectedSnapshot">
            <div class="save-area-inner">
                <button class="add-new fit-content no-margin" v-if="!savingData" @click="Save()"> Save </button>
                <button class="add-new fit-content no-margin" v-else disabled> Saving.. </button>
            </div>
        </div>
        <div class='snapshot-item' v-if="selectedSnapshot">
                <div>Viewing {{ selectedSnapshot.label }} snapshot of {{ dayjs(selectedSnapshot.snapshotDate, 'YYYY-MM-DD').format('DD/MM/YYYY') }}</div>
                <img @click="resetToCurrentView()" class='close-img' src="@/assets/cross.svg" alt="close" />
        </div>
        <div class="disclaimer" v-if="showDisclaimer.length">
            <div class="requirement">
                Automatic Notification will not be sent untill dashboard section is fully filled in  ({{ showDisclaimer.join(', ') }})
            </div>
        </div>
        <div class="flex justify-center">
            <div class="breadcrumbs mt-20 w-full" v-if="!reviewMode">
                <router-link v-if="pageInfo.goBack" :to="pageInfo.goBack" class="main-item">
                    {{ pageInfo.title }}
                </router-link>
                <img src="@/assets/arrow-opener.svg" alt="arrow-right"  class="p-10" />
                <div class="last-item" v-if="companyData">
                    {{ companyData?.name?.value || (`${companyData?.first_name?.value} ${companyData?.last_name?.value}` ) }}
                </div>
            </div>
        </div>
        <div class="header-section"
            v-if="companyData"
            :class="reviewMode ? 'review-mode' : ''"
        >
            <div class="kyc-header flex row space-between align-center">
                <div>
                    <div class="kyc-title">KYC Management</div>
                </div>
                <div class="flex gap-10">
                    <button @click="deleteReview()" v-if="!reviewMode && !selectedSnapshot" class="buttons">
                        Delete
                    </button>
                </div>
            </div>
        </div>
        <div 
            :class="reviewMode ? 'review-mode' : ''"
            class="kyc-fields-container flex column gap-40" v-if="companyData && banks">

                <!-- <TimeLine
                    v-if="PermissionChecker.viewAuditSection()"
                    :company_id="companyId"
                    :reviewMode="reviewMode || !!selectedSnapshot"
                    :key="`timeline-${companyId}`"
                /> -->
                <kycTabSetting 
                    v-if="PermissionChecker.viewDashboardSection()"
                    :companyData="companyData"
                    :reviewMode="reviewMode || !!selectedSnapshot"
                    :dataStructure="dataStructure"  
                    :company_id="companyId"
                    :key="`kycTabSetting-${companyId}`"
               
                    @changeCompanyData="companyUpdate"
                />
                <generalKYCView2
                    v-if="PermissionChecker.viewGeneralSection() && companyData.company_type === 'corporate'"
                    :company_id="companyId"
                    :reviewMode="reviewMode || !!selectedSnapshot"
                    :key="`generalKYCView2-${companyId}`"
                    :dataStructure="dataStructure" 
                />
                <div class="ubos" v-if="PermissionChecker.viewUboSection()" id="idshareholder_corporate">
                    <div class="flex row align-center mb-20">
                        <h3 class="doc-header" v-if="companyData.company_type === 'corporate'">
                            ShareHolders that own more than 10% of the company 
                            <ProgressBar 
                            :current="totalSharesReported" 
                            :total="100" 
                            :label="`%`"
                            :text="' of Shares Reported'"
                            />
                        </h3>
                        <h3 class="doc-header" v-else >
                            {{ accountHolderTitle }}
                        </h3>
                        <div class='flex row gap-5' style="margin-left: auto" >
                            
                            <button title='collapse & expand section' class='transparent w-content button-holder' @click="toggleSection(shareholder_corporate_name)">
                                <img class="arrow-opener-section" :class="isOpenShareholder ? 'section-opened': ''" src="@/assets/arrow-opener.svg">
                            </button>

                            <button 
                                v-if="PermissionChecker.createUBOSection() && companyData.company_type === 'corporate' && !reviewMode && !selectedSnapshot"
                                class="add-new no-margin fit-content" @click="addNewElementFunc('shareholder')">
                                Add Shareholder
                            </button>
                        </div>
                    </div>
                    <div v-if="companyData.company_type === 'corporate'" class="flex column gap-20 w-full content-collapse" :id="shareholder_corporate_collapse">
                        <Shareholder v-for="(shareholder, index) in corporateShareholders"
                            :shareholder_id="shareholder._id"
                            :reviewMode="reviewMode || !!selectedSnapshot"
                            :key="`index_shareholder-${shareholder._id}`"
                        />
                    </div>
                    <div v-else class="w-full content-collapse" :id="shareholder_corporate_collapse">
                        <UBO2
                            v-for="(ubo, index) in sectionUbos"
                            :ubo_id="ubo._id"
                            :company_id="companyId"
                            :reviewMode="reviewMode || !!selectedSnapshot"
                            :key="`index_ubo-${companyId}`"
                        />
                    </div>
                </div>
                <div class="ubos mt-20" v-if="PermissionChecker.viewmbdSection() && companyData.company_type === 'corporate'" id="idmd_corporate">
                    <div class="flex row align-center mb-20">
                        <h3 class="doc-header">Managing board of directors</h3>
                        <div class='flex row gap-5' style="margin-left: auto">
                            <button title='collapse & expand section' class='transparent w-content button-holder' @click="toggleSection(md_corporate_name)">
                                <img class="arrow-opener-section" :class="isOpenMD ? 'section-opened': ''" src="@/assets/arrow-opener.svg">
                            </button>
                            <button 
                                v-if="PermissionChecker.creatembdSection() && !reviewMode && !selectedSnapshot" 
                                class="add-new no-margin fit-content" @click="addNewElementFunc('md')">Add Managing Director
                            </button>
                        </div>
                    </div>
                    <div class="flex column gap-20 w-full content-collapse" :id="md_corporate_collapse">
                        <ManagingDirrector2 
                            v-for="md in managingDirectors"
                            :key="`index_mbd-${md._id}`"
                            :md_id="md._id"
                            :reviewMode="reviewMode || !!selectedSnapshot"
                        />
                    </div>

                </div>
                <div 
                    class="flex column gap-5"
                    v-if="showTransactionProfile">
                    <TransactionProfile
                        v-for="tr_profile in transactionProfile"
                        v-if="transactionProfile.length"
                        :transactionProfile_id="tr_profile._id"
                        :company_id="companyId"
                        :reviewMode="reviewMode || !!selectedSnapshot"
                    />
                </div>
        


            <!-- <div v-if="stateView === 'comment_section' && PermissionChecker.viewAuditSection()">
                <div class="cover-comments">
                    <CommentSection 
                        v-if="commentSection"
                        class="comment-section-block"
                        :commentSection="commentSection"
                        :disabled="!PermissionChecker.editAuditSection()"
                        @changeinput="changeComment"
                    />
                </div>

            </div>  
            <div v-else>
            </div> -->
        </div>
        <div class='loading' v-else>
            <LoaderPopup 
                :hideHeader="true"
                :message="'Loading KYC information...'"
            />
        </div>

        <AddNewElement
            v-if="addNewElement"
            :type="addNewElement"
            @close="addNewElement = null"
            :metaInfo="{
                company_id: companyId,
                remaining_shares: addNewElement == 'shareholder' ? 100 - totalSharesReported : null,
            }"
        />
     
        <PopupCover v-if="deleteObject" @close="() => deleteObject = !deleteObject">
            <ConfirmationPopup 
                @confirm="popupConfirm"
                @cancel="() => deleteObject = !deleteObject"
                :title="`Delete ${pageInfo.title}`"
                :message="`Are you sure you want to delete this ${pageInfo.title}? All the data for this review will be deleted.`"
            />
        </PopupCover>
       
      

    </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch, onBeforeUnmount } from 'vue'
import StructureChart from '@/components/StructureChart.vue'
import ManagingDirrector2 from '@/components/ManagingDirrector2.vue'

import { PermissionChecker } from '@/utils/iam_utils.js'
import API from '../api/index.js'
import Shareholder from '@/components/Shareholder.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import UBO2 from './UBO2.vue'
import LoaderPopup from '@/components/LoaderPopup.vue'
import generalKYCView2 from './generalKYCView2.vue'
import CommentSection from './CommentSection.vue'
import TransactionProfile from '@/components/TransactionProfile.vue'
import kycTabSetting from './kycTabSetting.vue'
import AddNewElement from '@/components/addNewElement.vue'
import dayjs from 'dayjs'
import { useRouter, useRoute } from 'vue-router'
import store from '../store';
import { onBeforeRouteLeave } from 'vue-router';
import PopupCover from '@/components/PopupCover.vue'
import ConfirmationPopup from '@/components/ConfirmationPopup.vue'
import PopupBody from '@/components/PopupBody.vue'
import TimeLine from './TimeLine.vue'

const deleteObject = ref(null);



const showStructure = ref(false);
const router = useRouter();
const route = useRoute();

const props = defineProps(['id', 'reviewMode'])

const queryParams = computed(() => route.query)
const snashotID = ref(queryParams.value.snapshot)

const confirmationNeeded = computed(() => store.state.confirmationNeeded);
const md_corporate_name = computed(() => props.id + '_md_corporate')
const md_corporate_collapse = computed(() => 'collapse_' + props.id + '_md_corporate')
const shareholder_corporate_name = computed(() => props.id + '_shareholder_corporate')
const shareholder_corporate_collapse = computed(() => 'collapse_' + props.id + '_shareholder_corporate')


const isOpenMD = computed(() => store.getters.getToggleState(md_corporate_name.value))
const isOpenShareholder = computed(() => store.getters.getToggleState(shareholder_corporate_name.value))






const HTMLElementShareholder = computed(() => document.getElementById(shareholder_corporate_collapse.value))
const HTMLElementMD = computed(() => document.getElementById(md_corporate_collapse.value))

const companyId = ref(props.id)
const corporateShareholders = computed(() => store.getters.shareholders(null))

const totalSharesReported = computed(() => {
    let total = 0;
    corporateShareholders.value.forEach(shareholder => {
        if (shareholder.percentage_held.value) {
            total += parseFloat(shareholder.percentage_held.value || 0);
        }
    })
    console.log('totalSharesReported', total)
    return total;
})

const needToSave = computed(() => store.getters.needToSave)
const savingData = computed(() => store.state.savingData)

const managingDirectors = computed(() => store.getters.managingDirectors(null))
const sectionUbos = computed(() => store.getters.getCorporateUbo)


const addNewElement = ref(null);
const loaded = computed(() => store.state.kycDataLoaded)
const selectedSnapshot = computed(() => store.state.selectedSnapshot)
const dataStructure = computed(() => store.state.dataStructureStore);
const companyData = computed(() => store.state.companyData);
const showDisclaimer = computed(() => {
    if(!companyData.value) return false;
    
    const corporate = {
        'name': 'Name',
        'risk_level': 'Risk level',
        'previous_review_date': 'Date previous compliance review',
        'next_review_date' : 'Date proposed compliance review',
        'date_final_compliance_review': 'Date final compliance review',
        'extension_profit_tax': 'Extension profit tax',
        'current_fiscal_year': 'Current fiscal year',
        'last_annual_account_on_file': 'Last annual account on file',
        'last_filed_profit_tax_on_file': 'Last filed profit tax on file',
    }

    const accountHolderFields = {
        'first_name': 'First_name',
        'last_name': 'Last name',
        'risk_level': 'Risk level',
        'previous_review_date': 'Previous review date',
        'next_review_date' : 'Next review date',
        'date_final_compliance_review': 'Date final compliance review',
    }
    let fileds = null;
    let requirements = []

    if(companyData.value.company_type === 'corporate') {
        fileds = corporate;
    }

    if(companyData.value.company_type === 'account_holder') {
        fileds = accountHolderFields;
    } 
    for (let key in fileds) {
        if (!companyData.value[key].value) {
            requirements.push(fileds[key])
        } else if (key == 'extension_profit_tax' && companyData.value[key].value === 'YES' && !companyData.value['extension_up_till'].value) {
            requirements.push('Extension up till')
        }
    }
    if ( companyData.value.location_type.value === 'abroad' &&
        !companyData.value.last_board_meeting.value
    ) {
        requirements.push('Last general meeting of shareholders')
    }
    return requirements
});


const pageInfo = computed(() => {
    if (!companyData.value) {
        return {
            title: 'KYC',
            goBack: null,
        }
    }
    if (companyData.value.company_type === 'corporate') {
        return  {
            title: 'Corporate KYC',
            goBack: '/corporate'
        }
    } else {
        return {
            title: 'Account Holder KYC',
            goBack: '/account-holder'
        }
    }
})

const user = computed(() => store.state.user);

const transactionProfile = computed(() => store.state.kyc_transactionalProfile);
const banks = computed(() => store.state.banks);


const showTransactionProfile = computed(() => {
    return (
        companyData.value.transaction_profile_report.new_value || 
        companyData.value.transaction_profile_report.value
    ) === 'YES'

    // if (companyData.value.company_type == 'corporate') {
    //     if (companyData.value.location_type.new_value){
    //         if (companyData.value.location_type.new_value == 'local') {
    //             return true;
    //         }
    //         return false
    //     }
    //     if (companyData.value.location_type.value == 'local') {
    //         return true;
    //     }

    // } else {
    //     let acocunt_holder_type = companyData.value.account_holder_type.new_value || companyData.value.account_holder_type.value;
    //     if ([ 'bank', 'credit_union'].includes(acocunt_holder_type)) {
    //         return true;
    //     }
    // }
    return false;
})


async function deleteReview(){
    deleteObject.value = true;
}
async function popupConfirm() {
    const goBack = pageInfo.value.goBack
    await store.dispatch('deleteKycSection', {company_id: companyId.value })
    router.push(goBack)
}

const accountHolderTitle = computed(() => {
    const labelsDict = {
        'bank': 'Account holder Bank',
        'credit_union': 'Account holder Credit Union',
        'pention': 'Account Holder Pension',
        'insurance': 'Account Holder Insurance',
        'player': 'Account Holder Player'
    }
    return labelsDict[companyData.value.account_holder_type] || 'Account holder'
})

async function loadData() {
    loaded.value = false;
    let id = null;
    if (snashotID.value) {
        id = snashotID.value
        let data = await API.getSnapshotList();
        const selected = data.snapshots.find(snapshot => snapshot._id === id);
        store.dispatch('setValue', { key: 'selectedSnapshot', value: selected }) 
        id = selected.snapshot_id
    } else {
        const response = await API.getNotificationList({page: 1, view: 'forConfirmation', id: companyId.value});
        store.state.confirmationNeeded = response.notifications;
    }
    const responseNew = await API.getKycFilesV2({snapshot_id: id, company_id: companyId.value});
    await API.loadDashboardData({company_id: companyId.value, snapshot_id: id});
    loaded.value = true;
    store.dispatch('setValue', { key: 'approvedActions', value: 0 })
}

async function resetToCurrentView(){
    snashotID.value = null;
    store.dispatch('setValue', { key: 'selectedSnapshot', value: null })
    router.replace({query: {}})
    await loadData();
}
const handleBeforeUnload = (event) => {
    if (!needToSave.value) return;
    event.preventDefault();
    event.returnValue = "You have unsaved changes. Are you sure you want to leave?";
};
 
function adjustHeight() {
    if (!HTMLElementShareholder.value) {
        return null
    }
    if (isOpenShareholder.value) {
        HTMLElementShareholder.value.style.maxHeight = "none"; // Prevent transition issues
        HTMLElementShareholder.value.style.overflowY = "visible"; // Prevent overflow
    } 
    else {
        HTMLElementShareholder.value.style.maxHeight = "0"; // Collapse the section
        HTMLElementShareholder.value.style.overflowY = "hidden"; // Hide overflow
    }
    if (!HTMLElementMD.value) {
        return null
    }
    if (isOpenMD.value) {
        HTMLElementMD.value.style.maxHeight = "none"; // Prevent transition issues
        HTMLElementMD.value.style.overflowY = "visible"; // Prevent overflow
    } 
    else {
        HTMLElementMD.value.style.maxHeight = "0"; // Collapse the section
        HTMLElementMD.value.style.overflowY = "hidden"; // Hide overflow
    }
}


function toggleSection(type) {
    store.dispatch('toggleSection', {
        id: type, 
    })
}


onMounted(async() => {
    if (!user.value) {
        router.push('/login')
        return;
    }

    store.dispatch('setValue', { key: 'currentSectionId', value: companyId.value })
    await loadData();
    adjustHeight()
    if (PermissionChecker.viewRequestLog()) {
        await API.getNotificationCount({view: 'forConfirmation', id: companyId.value});
    }
    document.title = `KYC - ${companyData.value?.name?.value || companyData.value?.first_name?.value} ${companyData.value?.last_name?.value}`
    window.addEventListener("beforeunload", handleBeforeUnload);
   
});

onUnmounted(() => {
    store.dispatch('setValue', { key: 'selectedSnapshot', value: null })
    window.removeEventListener("beforeunload", handleBeforeUnload);
});

onBeforeRouteLeave((to, from, next) => {
  console.log('onBeforeRouteLeave');
  if (!to.path.includes('workflow') && !to.path.includes('change-log')) {
    store.dispatch('setValue', { key: 'companyData', value: null })
  }
  if (needToSave.value) {
    const answer = window.confirm('You have unsaved changes. Are you sure you want to leave?');
    if (!answer) {
      next(false); // Stay on the page
      return;
    }
  }
  next(); // Proceed with navigation
});

function addNewElementFunc(type){
    addNewElement.value = type;
}

async function Save(){
    if (selectedSnapshot.value) {
        store.dispatch('addNotification', {text: 'You are viewing a snapshot, please go back to the current view to save changes', type: 'error'})
    } else {
        store.dispatch('saveKycValues')
    }
}


function companyUpdate(value) {
    companyData.value = value
}

</script>

<style scoped lang="scss">

    .notifications {
       position: relative;

    }
    .structure-button {
        white-space: nowrap;
    }
    .structure-view[class] {
        // &:deep(.container) {
            width: 90vw;
            height: 80vh;
        // }
    }
    .notification-counter {
        background: #f59f9f;
        border-radius: 50%;
        padding: 5px;
        top: -2px;
        right: -20px;
        position: absolute;
        min-height: 16px;
        min-width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .popup-content {
        position: relative;
        width: 100%;
        min-height: 150px;
    }
    .popup-close {
        position: absolute;
        right: 10px;
        top: -5px;
        cursor: pointer;
    }
    .content-popup {
        display: flex;
        justify-content: center;
        gap: 20px;
        flex: 1;
        align-items: center;


        .option-select {
            padding: 10px 20px;
            cursor: pointer;
            border: 1px solid #3c556e;
            border-radius: 5px;
        }
    }
    .popup-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        .option-select {
            padding: 10px 20px;
            height: fit-content;
            cursor: pointer;
            border: 1px solid #3c556e;
            border-radius: 5px;
        }
    }
    .loading {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 30px;
    }
    .cover-comments {
        border-radius: 10px;
        padding: 20px;
        border: 1px solid var(--border-color);
        background-color: white;

        &:deep(.editor-section) {
            min-height: 50vh;
        }
    }
    .header-section {
        padding: 0 20px;
        height: 50px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: -20px;
        max-width: 1350px;
        box-sizing: border-box;
        margin: auto;
    }
    .kyc-title {
        font-weight: 600;
    }
    .kyc-header {
        max-width: 1350px;
        width: 100%;
        text-align: left;
        font-size: 28px;
        position: relative;
        color: black;

        gap: 10px;
        display: flex;
        flex-direction: column;

        .tab-views {
            overflow: auto;
            font-size: 14px;
            display: flex;
            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
            }
            &::-webkit-scrollbar-thumb {
                background: #888;
            }
            &::-webkit-scrollbar-track {
                // background: red;
            }
            .tab {
                &:first-child {
                    padding-left: 0;
                }
                white-space: nowrap;
                padding: 10px 20px;
                cursor: pointer;
                font-weight: 600;
                &.active {
                    color: var(--primary-color);
                    border-bottom: 4px solid var(--primary-color);
                }
            }
        
        }
    }
    .kyc-fields-container {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        max-width: 1350px;
        margin: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        // align-items: center;
    }
    .kyc-fields-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
       
    }
    .kyc-item-section {
        max-width: 1300px;
        width: 100%;
        margin-bottom: 30px;
        h3 {
            text-align: left;
        }
        &.extra-margin {
            margin-bottom: 50px;
        }
    }
    .flex {
        display: flex;
        &.row {
            flex-direction: row;
        }
        &.column {
            flex-direction: column;
        }
    }
    .passport-fields {
        margin-top: 20px;
    }
    .gap30 {
        gap: 30px;
    }
    textarea {
        resize: none; 
        box-sizing: border-box;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        width: 685px;
        height: 150px;
    }
    .add-new {
        margin-left: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .no-margin[class] {
        margin-top: 0;
        margin-bottom: 0;
    }
    .no-margin-top {
        margin-top: 0;
    }
    .margin-top-20 {
        margin-top: 20px;
    }
    .header-of-section {
        font-size: 24px;
    }

    .snapshot-item {
        position: fixed;
        top: 60px;
        right: 0px;
        padding: 10px 20px;
        border-bottom-left-radius: 30px;
        border: 1px solid var(--border-color);
        z-index: 1001;
        font-weight: bold;
        cursor: pointer;
        background: white;
        color: black;
        padding-right: 30px;
    }

    .close-img {
        position: absolute;
        width: 12px;
        top: 7px;
        right: 5px;
        padding: 2px;

        cursor: pointer;
    }
    .comment-section-block {
        height: 75vh;
        max-height: 75vh;
    }
    .save-area {

        position: fixed;
     
        bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        left: calc(var(--sidebar-width) - 8px);
        width: calc(100% - var(--sidebar-width));
        z-index: 1000;

    }
    .save-area-inner {
        max-width: 1310px;
        width: 100%;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        background: white;
        border: 1px solid var(--border-color);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        justify-content: flex-start;
        align-items: center;
        padding: 5px;
        button {
            height: 35px;
            margin-left: unset;
        }
    }
    .loading {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 30px;
    }
    .review-mode[class] {
        padding: 0;
    }
    .disclaimer {
        color: #dc3434;
        background: white;
        font-weight: bold;
        font-size: 11px;
        padding: 10px;
    }
    .requirement {
        max-width: 1350px;
        margin: auto;
        // background: #f5f5f5;
    }

</style>