// import store from '../store';
import { instance } from './index.js'
import API from './index.js'
import store from '../store';





async function getSubsidiaries(company_id, snapshot_id) {

    const url = `${API.API_URL}subsidiaries/list?company_id=${company_id}${snapshot_id ? '&snapshot_id=' + snapshot_id : ''}`

    const response = await API.instance.get(url).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error loading subsidiaries.\nPlease try again or contact the support`});
        return null
    })
    store.dispatch('setValue', {key: 'subsidiaries', value: response.data.subsidiaries});

    return response.data;
}

async function addSubsidiary(subsidiary) {
    const response = await API.instance.post(`${API.API_URL}subsidiaries/create`, subsidiary).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error adding subsidiary.\nPlease try again or contact the support`});
        return null
    })
    store.dispatch('addNotification', {type: 'success', text: `Subsidiary ${subsidiary.name} added successfully`});
    return response.data;
}

async function editSubsidiary(subsidiary) {
    const response = await API.instance.post(`${API.API_URL}subsidiaries/edit`, subsidiary).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error editing subsidiary.\nPlease try again or contact the support`});
        return null
    })
    store.dispatch('addNotification', {type: 'success', text: `Subsidiary ${subsidiary.name} edited successfully`});
    return response.data;
}


export default {
    getSubsidiaries,
    addSubsidiary,
    editSubsidiary,
}