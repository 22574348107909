<template>
    <div>
        <div  v-if="loading">
            Loading
        </div>
        <div class='flex w-full column gap-5' v-else>
            <div class="flex row w-full gap-5">
                <div class="flex-1 current-version">
                    <UploaderTable2
                        :fileId="reviewAction.kyc_file"
                        requestedFile="true"
                        :fileFieldChange="fileFieldChange"
                        :fileToShow="fileToShow"
                        state="current"
                        :fullWidth="true"
                        reviewMode="true"
                    />
                        
                    <div class="extra-info ">
                        <!-- <div class="margins"></div> -->
                       
                    
                        <div class="flex column input-row w-full" v-if="showAccountManagerComment && sectionFile.documentType !== 'world_check'" >
                            <div class="header-of-comments">Account manager comments</div>
                            <div class="input-row w-full">
                                <CommentSection 
                                    attrId='comment_data_handler' 
                                    class="markdown-content disabled"
                                    :disabled="true"
                                    :commentSection="getFieldValue('comment_data_handler', 'current')"
                                    :class="{changes: hasChanges('comment_data_handler', 'current')}"
                                    @changeinput="quillContent" 
                                />
                            </div>
                        </div>

                        <div class="flex column input-row w-full" v-if="showSupervisorComment && sectionFile.documentType !== 'world_check'">
                            <div class="header-of-comments">Supervisor comments</div>
                            <div class="input-row w-full">
                                <CommentSection  
                                    attrId='comment_data_processor' 
                                    class="markdown-content disabled"
                                    :disabled="true"
                                    :commentSection="getFieldValue('comment_supervisor', 'current')"
                                    :class="{changes: hasChanges('comment_supervisor', 'current')}"
                                    @changeinput="quillContent"
                                />
                            </div>
                        </div>

                        <div class="flex column input-row w-full" v-if="showComplienceOfficerComment && sectionFile.documentType !== 'world_check'">
                            <div class="header-of-comments">Compliance officer comments</div>
                            <div class="input-row w-full">
                                <CommentSection 
                                    @changeinput="quillContent" 
                                    attrId='comment_compliance_officer' 
                                    :disabled="true"
                                    :commentSection="getFieldValue('comment_compliance_officer', 'current')"
                                    :class="{changes: hasChanges('comment_compliance_officer', 'current')}"
                                    class="markdown-content disabled"
                                />
                            </div>
                        </div>



                        <div class="flex column gap-5 w-full" v-if="sectionFile.documentType == 'world_check' && ['COMPLIANCE_OFFICER'].includes(user.type)">
                            <hr></hr>
                            
                            <div class="w-full text-left bold f-18">COMPLIANCE COMMENTS</div>
                            <div class="flex column gap-5 w-full">
                                <div class="checkmark-info">
                                    <div class="world-check-info mb-20">
                                        <div class="options flex column gap-10">
                                        <div class="flex align-start gap-5">
                                            <div class="option-desctions">
                                            Sanctions found  
                                            </div> 

                                            <div class="flex row gap-5">
                                                <div class="flex column align-start">
                                                    <div class="flex row gap-5" 
                                                        :class="{changes: hasChanges('sanctions_found_compliance', 'current','YES')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('sanctions_found_compliance','current') == 'YES'"
                                                            :name="`sanctions_found_compliance-${randomId}-c`" 
                                                            :id="`YES-sanctions_found_compliance-${randomId}-c`"
                                                        > YES
                                                    </div> 
                                                    <div class="flex row gap-5"
                                                        :class="{changes: hasChanges('sanctions_found_compliance', 'current','NO')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('sanctions_found_compliance','current') == 'NO'"
                                                            :name="`sanctions_found_compliance-${randomId}-c`" 
                                                            :id="`NO-sanctions_found_compliance-${randomId}-c`"
                                                        > NO
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-start gap-5">
                                            <div class="option-desctions">
                                            PEP  
                                            </div> 
                                            <div class="flex row gap-5">
                                                <div class="flex column align-start">
                                                    <div class="flex row gap-5" 
                                                        :class="{changes: hasChanges('pep_found_compliance', 'current','YES')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('pep_found_compliance','current') == 'YES'"
                                                            :name="`pep_found_compliance-${randomId}-c1`" 
                                                            :id="`YES-pep_found_compliance-${randomId}-c1`"
                                                        > YES
                                                    </div> 
                                                    <div class="flex row gap-5"
                                                        :class="{changes: hasChanges('pep_found_compliance', 'current','NO')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('pep_found_compliance','current') == 'NO'"
                                                            :name="`pep_found_compliance-${randomId}-c1`" 
                                                            :id="`NO-pep_found_compliance-${randomId}-c1`"
                                                        > NO
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-start gap-5">
                                            <div class="option-desctions">
                                            Relevant Adverse Information  
                                            </div> 
                                            <div class="flex row gap-5">
                                                <div class="flex column align-start">
                                                    <div class="flex row gap-5" 
                                                        :class="{changes: hasChanges('relevant_adverse_information_found_compliance', 'current','YES')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('relevant_adverse_information_found_compliance','current') == 'YES'"
                                                            :name="`relevant_adverse_information_found_compliance-${randomId}-c1`" 
                                                            :id="`YES-relevant_adverse_information_found_compliance-${randomId}-c1`"
                                                        > YES
                                                    </div> 
                                                    <div class="flex row gap-5"
                                                        :class="{changes: hasChanges('relevant_adverse_information_found_compliance', 'current','NO')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('relevant_adverse_information_found_compliance','current') == 'NO'"
                                                            :name="`relevant_adverse_information_found_compliance-${randomId}-c1`" 
                                                            :id="`NO-relevant_adverse_information_found_compliance-${randomId}-c1`"
                                                        > NO
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-holder h-content flex gap-5 w-full">
                                    <div class="comment-info w-full flex column gap-4">

                                        <div class="flex column input-row w-full" v-if="getFieldValue('sanctions_found_compliance','current') == 'YES'" >
                                            <div class="header-of-comments">Found Sanctions</div>
                                            <div class="input-row w-full">
                                                <CommentSection 
                                                    :attrId="`sanctions_comment_compliance`" 
                                                    class="markdown-content"
                                                    :disabled="true"
                                                    :commentSection="getFieldValue('sanctions_comment_compliance','current')"
                                                    :class="{changes: hasChanges('sanctions_comment_compliance', 'current')}"
                                                />
                                            </div>
                                        </div>

                                        <div class="flex column input-row w-full" v-if="getFieldValue('pep_found_compliance','current') == 'YES'" >
                                            <div class="header-of-comments">PEP</div>
                                            <div class="input-row w-full">
                                                <CommentSection 
                                                    :attrId="`pep_comment_compliance`" 
                                                    class="markdown-content"
                                                    :disabled="true"
                                                    :commentSection="getFieldValue('pep_comment_compliance','current')"
                                                    :class="{changes: hasChanges('pep_comment_compliance', 'current')}"
                                                />
                                            </div>
                                        </div>

                                        <div class="flex column input-row w-full" v-if="getFieldValue('relevant_adverse_information_found_compliance','current') == 'YES'" >
                                            <div class="header-of-comments">Relevant Adverse Information</div>
                                            <div class="input-row w-full">
                                                <CommentSection 
                                                    :attrId="`relevant_adverse_information_comment_compliance`" 
                                                    class="markdown-content"
                                                    :disabled="true"
                                                    :commentSection="getFieldValue('relevant_adverse_information_comment_compliance','current')"
                                                    :class="{changes: hasChanges('relevant_adverse_information_comment_compliance', 'current')}"
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex column gap-5 w-full" v-if="sectionFile.documentType == 'world_check' && ['DATA_PROCESSOR', 'COMPLIANCE_OFFICER'].includes(user.type)">
                            <hr></hr>
                            
                            <div class="w-full text-left bold f-18">SUPERVISOR COMMENTS</div>
                            <div class="flex column gap-5 w-full">
                                <div class="checkmark-info">
                                    <div class="world-check-info mb-20">
                                        <div class="options flex column gap-10">
                                        <div class="flex align-start gap-5">
                                            <div class="option-desctions">
                                            Sanctions found  
                                            </div> 
                                            <div class="flex row gap-5">
                                                <div class="flex column align-start">
                                                    <div class="flex row gap-5" 
                                                        :class="{changes: hasChanges('sanctions_found_supervisor', 'current','YES')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('sanctions_found_supervisor','current') == 'YES'"
                                                            :name="`sanctions_found_supervisor-${randomId}-s1`" 
                                                            :id="`YES-sanctions_found_supervisor-${randomId}-s1`"
                                                        > YES
                                                    </div> 
                                                    <div class="flex row gap-5"
                                                        :class="{changes: hasChanges('sanctions_found_supervisor', 'current','NO')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('sanctions_found_supervisor','current') == 'NO'"
                                                            :name="`sanctions_found_supervisor-${randomId}-s1`" 
                                                            :id="`NO-sanctions_found_supervisor-${randomId}-s1`"
                                                        > NO
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-start gap-5">
                                            <div class="option-desctions">
                                            PEP  
                                            </div> 
                                            <div class="flex row gap-5">
                                                <div class="flex column align-start">
                                                    <div class="flex row gap-5" 
                                                        :class="{changes: hasChanges('pep_found_supervisor', 'current','YES')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('pep_found_supervisor','current') == 'YES'"
                                                            :name="`pep_found_supervisor-${randomId}-s1`" 
                                                            :id="`YES-pep_found_supervisor-${randomId}-s1`"
                                                        > YES
                                                    </div> 
                                                    <div class="flex row gap-5"
                                                        :class="{changes: hasChanges('pep_found_supervisor', 'current','NO')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('pep_found_supervisor','current') == 'NO'"
                                                            :name="`pep_found_supervisor-${randomId}-s1`" 
                                                            :id="`NO-pep_found_supervisor-${randomId}-s1`"
                                                        > NO
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-start gap-5">
                                            <div class="option-desctions">
                                            Relevant Adverse Information  
                                            </div> 
                                            <div class="flex row gap-5">
                                                <div class="flex column align-start">
                                                    <div class="flex row gap-5" 
                                                        :class="{changes: hasChanges('relevant_adverse_information_found_supervisor', 'current','YES')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('relevant_adverse_information_found_supervisor','current') == 'YES'"
                                                            :name="`relevant_adverse_information_found_supervisor-${randomId}-s1`" 
                                                            :id="`YES-relevant_adverse_information_found_supervisor-${randomId}-s1`"
                                                        > YES
                                                    </div> 
                                                    <div class="flex row gap-5"
                                                        :class="{changes: hasChanges('relevant_adverse_information_found_supervisor', 'current','NO')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('relevant_adverse_information_found_supervisor','current') == 'NO'"
                                                            :name="`relevant_adverse_information_found_supervisor-${randomId}-s1`" 
                                                            :id="`NO-relevant_adverse_information_found_supervisor-${randomId}-s1`"
                                                        > NO
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-holder h-content flex gap-5 w-full">
                                    <div class="comment-info w-full flex column gap-4">

                                        <div class="flex column input-row w-full" v-if="getFieldValue('sanctions_found_supervisor','current') == 'YES'" >
                                            <div class="header-of-comments">Found Sanctions</div>
                                            <div class="input-row w-full">
                                                <CommentSection 
                                                    :attrId="`sanctions_comment_supervisor`" 
                                                    class="markdown-content"
                                                    :disabled="true"
                                                    :commentSection="getFieldValue('sanctions_comment_supervisor','current')"
                                                    :class="{changes: hasChanges('sanctions_comment_supervisor', 'current')}"
                                                />
                                            </div>
                                        </div>

                                        <div class="flex column input-row w-full" v-if="getFieldValue('pep_found_supervisor','current') == 'YES'" >
                                            <div class="header-of-comments">PEP</div>
                                            <div class="input-row w-full">
                                                <CommentSection 
                                                    :attrId="`pep_comment_supervisor`" 
                                                    class="markdown-content"
                                                    :disabled="true"
                                                    :commentSection="getFieldValue('pep_comment_supervisor','current')"
                                                    :class="{changes: hasChanges('pep_comment_supervisor', 'current')}"
                                                />
                                            </div>
                                        </div>

                                        <div class="flex column input-row w-full" v-if="getFieldValue('relevant_adverse_information_found_supervisor','current') == 'YES'" >
                                            <div class="header-of-comments">Relevant Adverse Information</div>
                                            <div class="input-row w-full">
                                                <CommentSection 
                                                    :attrId="`relevant_adverse_information_comment_supervisor`" 
                                                    class="markdown-content"
                                                    :disabled="true"
                                                    :commentSection="getFieldValue('relevant_adverse_information_comment_supervisor','current')"
                                                    :class="{changes: hasChanges('relevant_adverse_information_comment_supervisor', 'current')}"
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex column gap-5 w-full" v-if="sectionFile.documentType == 'world_check' && ['DATA_CONTROLLER','DATA_PROCESSOR','COMPLIANCE_OFFICER'].includes(user.type)">
                            <hr></hr>
                            
                            <div class="w-full text-left bold f-18">ACCOUNT MANAGER COMMENTS</div>
                            
                            <div class="flex column gap-5 w-full">
                                <div class="checkmark-info">
                                    <div class="world-check-info mb-20">
                                        <div class="options flex column gap-10">
                                        <div class="flex align-start gap-5">
                                            <div class="option-desctions">
                                            Sanctions found  
                                            </div> 
                                            <div class="flex row gap-5">
                                                <div class="flex column align-start">
                                                    <div class="flex row gap-5" 
                                                        :class="{changes: hasChanges('sanctions_found', 'current','YES')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('sanctions_found','current') == 'YES'"
                                                            :name="`sanctions_found-${randomId}-a2`" 
                                                            :id="`YES-sanctions_found-${randomId}-a2`"
                                                        > YES
                                                    </div> 
                                                    <div class="flex row gap-5"
                                                        :class="{changes: hasChanges('sanctions_found', 'current','NO')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('sanctions_found','current') == 'NO'"
                                                            :name="`sanctions_found-${randomId}-a2`" 
                                                            :id="`NO-sanctions_found-${randomId}-a2`"
                                                        > NO
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-start gap-5">
                                            <div class="option-desctions">
                                            PEP  
                                            </div> 
                                            <div class="flex row gap-5">
                                                <div class="flex column align-start">
                                                    <div class="flex row gap-5" 
                                                        :class="{changes: hasChanges('pep_found', 'current','YES')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('pep_found','current') == 'YES'"
                                                            :name="`pep_found-${randomId}-a2`" 
                                                            :id="`YES-pep_found-${randomId}-a2`"
                                                        > YES
                                                    </div> 
                                                    <div class="flex row gap-5"
                                                        :class="{changes: hasChanges('pep_found', 'current','NO')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('pep_found','current') == 'NO'"
                                                            :name="`pep_found-${randomId}-a2`" 
                                                            :id="`NO-pep_found-${randomId}-a2`"
                                                        > NO
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-start gap-5">
                                            <div class="option-desctions">
                                            Relevant Adverse Information  
                                            </div> 
                                            <div class="flex row gap-5">
                                                <div class="flex column align-start">
                                                    <div class="flex row gap-5" 
                                                        :class="{changes: hasChanges('relevant_adverse_information_found', 'current','YES')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('relevant_adverse_information_found','current') == 'YES'"
                                                            :name="`relevant_adverse_information_found-${randomId}-a2`" 
                                                            :id="`YES-relevant_adverse_information_found-${randomId}-a2`"
                                                        > YES
                                                    </div> 
                                                    <div class="flex row gap-5"
                                                        :class="{changes: hasChanges('relevant_adverse_information_found', 'current','NO')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('relevant_adverse_information_found','current') == 'NO'"
                                                            :name="`relevant_adverse_information_found-${randomId}-a2`" 
                                                            :id="`NO-relevant_adverse_information_found-${randomId}-a2`"
                                                        > NO
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-holder h-content flex gap-5 w-full">
                                    <div class="comment-info w-full flex column gap-4">
                                        <div class="flex column input-row w-full" v-if="getFieldValue('sanctions_found','current') == 'YES'" >
                                            <div class="header-of-comments">Found Sanctions</div>
                                            <div class="input-row w-full">
                                                <CommentSection 
                                                    :attrId="`sanctions_comment`" 
                                                    class="markdown-content"
                                                    :disabled="true"
                                                    :commentSection="getFieldValue('sanctions_comment','current')"
                                                    :class="{changes: hasChanges('sanctions_comment', 'current')}"
                                                />
                                            </div>
                                        </div>
                                        <div class="flex column input-row w-full" v-if="getFieldValue('pep_found','current') == 'YES'" >
                                            <div class="header-of-comments">PEP</div>
                                            <div class="input-row w-full">
                                                <CommentSection 
                                                    :attrId="`pep_comment`" 
                                                    class="markdown-content"
                                                    :disabled="true"
                                                    :commentSection="getFieldValue('pep_comment','current')"
                                                    :class="{changes: hasChanges('pep_comment', 'current')}"
                                                />
                                            </div>
                                        </div>
                                        <div class="flex column input-row w-full" v-if="getFieldValue('relevant_adverse_information_found','current') == 'YES'" >
                                            <div class="header-of-comments">Relevant Adverse Information</div>
                                            <div class="input-row w-full">
                                                <CommentSection 
                                                    :attrId="`relevant_adverse_information_comment`" 
                                                    class="markdown-content"
                                                    :disabled="true"
                                                    :commentSection="getFieldValue('relevant_adverse_information_comment','current')"
                                                    :class="{changes: hasChanges('relevant_adverse_information_comment', 'current')}"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-1 proposed-changes" v-if="reviewAction.actionType !== 'remove'">
                    <UploaderTable2
                        :fileId="reviewAction.kyc_file"
                        :fileFieldChange="fileFieldChange"
                        :fileToShow="fileToShow"
                        state="new"
                        :fullWidth="true"
                        requestedFile="true"
                        reviewMode="true"
                    />
                    <div class="extra-info">
                        <!-- <button title='collapse & expand section' class='transparent w-content button-holder' @click="toggleSection(md_corporate_name)">
                            <img class="arrow-opener-section" :class="isOpenMD ? 'section-opened': ''" src="@/assets/arrow-opener.svg">
                        </button> -->
                        <div class="flex column input-row w-full" v-if="showAccountManagerComment && sectionFile.documentType !== 'world_check'" >
                            <div class="header-of-comments">Account manager comments</div>
                            <div class="input-row w-full">
                                <CommentSection 
                                    attrId='comment_data_handler' 
                                    class="markdown-content disabled"
                                    :disabled="true"
                                    :commentSection="getFieldValue('comment_data_handler', 'new')"
                                    :class="{changes: hasChanges('comment_data_handler', 'new')}"
                                    @changeinput="quillContent" 
                                />
                            </div>
                        </div>
                        <div class="flex column input-row w-full" v-if="showSupervisorComment && sectionFile.documentType !== 'world_check'">
                            <div class="header-of-comments">Supervisor comments</div>
                            <div class="input-row w-full">
                                <CommentSection  
                                    attrId='comment_data_processor' 
                                    class="markdown-content disabled"
                                    :disabled="true"
                                    :commentSection="getFieldValue('comment_supervisor', 'new')"
                                    :class="{changes: hasChanges('comment_supervisor', 'new')}"
                                    @changeinput="quillContent"
                                />
                            </div>
                        </div>
                        <div class="flex column input-row w-full" v-if="showComplienceOfficerComment && sectionFile.documentType !== 'world_check'">
                            <div class="header-of-comments">Compliance officer comments</div>
                            <div class="input-row w-full">
                                <CommentSection 
                                    @changeinput="quillContent" 
                                    attrId='comment_compliance_officer' 
                                    :disabled="true"
                                    :commentSection="getFieldValue('comment_compliance_officer', 'new')"
                                    :class="{changes: hasChanges('comment_compliance_officer', 'new')}"
                                    class="markdown-content disabled"
                                />
                            </div>
                        </div>

                        <div class="flex column gap-5 w-full" v-if="sectionFile.documentType == 'world_check' && ['COMPLIANCE_OFFICER'].includes(user.type)">
                            <hr></hr>
                            
                            <div class="w-full text-left bold f-18">COMPLIANCE COMMENTS</div>
                            <div class="flex column gap-5 w-full">
                                <div class="checkmark-info">
                                    <div class="world-check-info mb-20">
                                        <div class="options flex column gap-10">
                                        <div class="flex align-start gap-5">
                                            <div class="option-desctions">
                                            Sanctions found  
                                            </div> 

                                            <div class="flex row gap-5">
                                                <div class="flex column align-start">
                                                    <div class="flex row gap-5" 
                                                        :class="{changes: hasChanges('sanctions_found_compliance', 'new','YES')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('sanctions_found_compliance','new') == 'YES'"
                                                            :name="`sanctions_found_compliance-${randomId}-c`" 
                                                            :id="`YES-sanctions_found_compliance-${randomId}-c`"
                                                        > YES
                                                    </div> 
                                                    <div class="flex row gap-5"
                                                        :class="{changes: hasChanges('sanctions_found_compliance', 'new','NO')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('sanctions_found_compliance','new') == 'NO'"
                                                            :name="`sanctions_found_compliance-${randomId}-c`" 
                                                            :id="`NO-sanctions_found_compliance-${randomId}-c`"
                                                        > NO
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-start gap-5">
                                            <div class="option-desctions">
                                            PEP  
                                            </div> 
                                            <div class="flex row gap-5">
                                                <div class="flex column align-start">
                                                    <div class="flex row gap-5" 
                                                        :class="{changes: hasChanges('pep_found_compliance', 'new','YES')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('pep_found_compliance','new') == 'YES'"
                                                            :name="`pep_found_compliance-${randomId}-c`" 
                                                            :id="`YES-pep_found_compliance-${randomId}-c`"
                                                        > YES
                                                    </div> 
                                                    <div class="flex row gap-5"
                                                        :class="{changes: hasChanges('pep_found_compliance', 'new','NO')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('pep_found_compliance','new') == 'NO'"
                                                            :name="`pep_found_compliance-${randomId}-c`" 
                                                            :id="`NO-pep_found_compliance-${randomId}-c`"
                                                        > NO
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-start gap-5">
                                            <div class="option-desctions">
                                            Relevant Adverse Information  
                                            </div> 
                                            <div class="flex row gap-5">
                                                <div class="flex column align-start">
                                                    <div class="flex row gap-5" 
                                                        :class="{changes: hasChanges('relevant_adverse_information_found_compliance', 'new','YES')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('relevant_adverse_information_found_compliance','new') == 'YES'"
                                                            :name="`relevant_adverse_information_found_compliance-${randomId}-c`" 
                                                            :id="`YES-relevant_adverse_information_found_compliance-${randomId}-c`"
                                                        > YES
                                                    </div> 
                                                    <div class="flex row gap-5"
                                                        :class="{changes: hasChanges('relevant_adverse_information_found_compliance', 'new','NO')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('relevant_adverse_information_found_compliance','new') == 'NO'"
                                                            :name="`relevant_adverse_information_found_compliance-${randomId}-c`" 
                                                            :id="`NO-relevant_adverse_information_found_compliance-${randomId}-c`"
                                                        > NO
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-holder h-content flex gap-5 w-full">
                                    <div class="comment-info w-full flex column gap-4">

                                        <div class="flex column input-row w-full" v-if="getFieldValue('sanctions_found_compliance','new') == 'YES'" >
                                            <div class="header-of-comments">Found Sanctions</div>
                                            <div class="input-row w-full">
                                                <CommentSection 
                                                    :attrId="`sanctions_comment_compliance`" 
                                                    class="markdown-content"
                                                    :disabled="true"
                                                    :commentSection="getFieldValue('sanctions_comment_compliance','new')"
                                                    :class="{changes: hasChanges('sanctions_comment_compliance', 'new')}"
                                                />
                                            </div>
                                        </div>

                                        <div class="flex column input-row w-full" v-if="getFieldValue('pep_found_compliance','new') == 'YES'" >
                                            <div class="header-of-comments">PEP</div>
                                            <div class="input-row w-full">
                                                <CommentSection 
                                                    :attrId="`pep_comment_compliance`" 
                                                    class="markdown-content"
                                                    :disabled="true"
                                                    :commentSection="getFieldValue('pep_comment_compliance','new')"
                                                    :class="{changes: hasChanges('pep_comment_compliance', 'new')}"
                                                />
                                            </div>
                                        </div>

                                        <div class="flex column input-row w-full" v-if="getFieldValue('relevant_adverse_information_found_compliance','new') == 'YES'" >
                                            <div class="header-of-comments">Relevant Adverse Information</div>
                                            <div class="input-row w-full">
                                                <CommentSection 
                                                    :attrId="`relevant_adverse_information_comment_compliance`" 
                                                    class="markdown-content"
                                                    :disabled="true"
                                                    :commentSection="getFieldValue('relevant_adverse_information_comment_compliance','new')"
                                                    :class="{changes: hasChanges('relevant_adverse_information_comment_compliance', 'new')}"
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex column gap-5 w-full" v-if="sectionFile.documentType == 'world_check' && ['DATA_PROCESSOR', 'COMPLIANCE_OFFICER'].includes(user.type)">
                            <hr></hr>
                            
                            <div class="w-full text-left bold f-18">SUPERVISOR COMMENTS</div>
                            <div class="flex column gap-5 w-full">
                                <div class="checkmark-info">
                                    <div class="world-check-info mb-20">
                                        <div class="options flex column gap-10">
                                        <div class="flex align-start gap-5">
                                            <div class="option-desctions">
                                            Sanctions found  
                                            </div> 
                                            <div class="flex row gap-5">
                                                <div class="flex column align-start">
                                                    <div class="flex row gap-5" 
                                                        :class="{changes: hasChanges('sanctions_found_supervisor', 'new','YES')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('sanctions_found_supervisor','new') == 'YES'"
                                                            :name="`sanctions_found_supervisor-${randomId}-s`" 
                                                            :id="`YES-sanctions_found_supervisor-${randomId}-s`"
                                                        > YES
                                                    </div> 
                                                    <div class="flex row gap-5"
                                                        :class="{changes: hasChanges('sanctions_found_supervisor', 'new','NO')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('sanctions_found_supervisor','new') == 'NO'"
                                                            :name="`sanctions_found_supervisor-${randomId}-s`" 
                                                            :id="`NO-sanctions_found_supervisor-${randomId}-s`"
                                                        > NO
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-start gap-5">
                                            <div class="option-desctions">
                                            PEP  
                                            </div> 
                                            <div class="flex row gap-5">
                                                <div class="flex column align-start">
                                                    <div class="flex row gap-5" 
                                                        :class="{changes: hasChanges('pep_found_supervisor', 'new','YES')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('pep_found_supervisor','new') == 'YES'"
                                                            :name="`pep_found_supervisor-${randomId}-s`" 
                                                            :id="`YES-pep_found_supervisor-${randomId}-s`"
                                                        > YES
                                                    </div> 
                                                    <div class="flex row gap-5"
                                                        :class="{changes: hasChanges('pep_found_supervisor', 'new','NO')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('pep_found_supervisor','new') == 'NO'"
                                                            :name="`pep_found_supervisor-${randomId}-s`" 
                                                            :id="`NO-pep_found_supervisor-${randomId}-s`"
                                                        > NO
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-start gap-5">
                                            <div class="option-desctions">
                                            Relevant Adverse Information  
                                            </div> 
                                            <div class="flex row gap-5">
                                                <div class="flex column align-start">
                                                    <div class="flex row gap-5" 
                                                        :class="{changes: hasChanges('relevant_adverse_information_found_supervisor', 'new','YES')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('relevant_adverse_information_found_supervisor','new') == 'YES'"
                                                            :name="`relevant_adverse_information_found_supervisor-${randomId}-s`" 
                                                            :id="`YES-relevant_adverse_information_found_supervisor-${randomId}-s`"
                                                        > YES
                                                    </div> 
                                                    <div class="flex row gap-5"
                                                        :class="{changes: hasChanges('relevant_adverse_information_found_supervisor', 'new','NO')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('relevant_adverse_information_found_supervisor','new') == 'NO'"
                                                            :name="`relevant_adverse_information_found_supervisor-${randomId}-s`" 
                                                            :id="`NO-relevant_adverse_information_found_supervisor-${randomId}-s`"
                                                        > NO
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-holder h-content flex gap-5 w-full">
                                    <div class="comment-info w-full flex column gap-4">

                                        <div class="flex column input-row w-full" v-if="getFieldValue('sanctions_found_supervisor','new') == 'YES'" >
                                            <div class="header-of-comments">Found Sanctions</div>
                                            <div class="input-row w-full">
                                                <CommentSection 
                                                    :attrId="`sanctions_comment_supervisor`" 
                                                    class="markdown-content"
                                                    :disabled="true"
                                                    :commentSection="getFieldValue('sanctions_comment_supervisor','new')"
                                                    :class="{changes: hasChanges('sanctions_comment_supervisor', 'new')}"
                                                />
                                            </div>
                                        </div>

                                        <div class="flex column input-row w-full" v-if="getFieldValue('pep_found_supervisor','new') == 'YES'" >
                                            <div class="header-of-comments">PEP</div>
                                            <div class="input-row w-full">
                                                <CommentSection 
                                                    :attrId="`pep_comment_supervisor`" 
                                                    class="markdown-content"
                                                    :disabled="true"
                                                    :commentSection="getFieldValue('pep_comment_supervisor','new')"
                                                    :class="{changes: hasChanges('pep_comment_supervisor', 'new')}"
                                                />
                                            </div>
                                        </div>

                                        <div class="flex column input-row w-full" v-if="getFieldValue('relevant_adverse_information_found_supervisor','new') == 'YES'" >
                                            <div class="header-of-comments">Relevant Adverse Information</div>
                                            <div class="input-row w-full">
                                                <CommentSection 
                                                    :attrId="`relevant_adverse_information_comment_supervisor`" 
                                                    class="markdown-content"
                                                    :disabled="true"
                                                    :commentSection="getFieldValue('relevant_adverse_information_comment_supervisor','new')"
                                                    :class="{changes: hasChanges('relevant_adverse_information_comment_supervisor', 'new')}"
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex column gap-5 w-full" v-if="sectionFile.documentType == 'world_check' && ['DATA_CONTROLLER','DATA_PROCESSOR','COMPLIANCE_OFFICER'].includes(user.type)">
                            <hr></hr>
                            
                            <div class="w-full text-left bold f-18">ACCOUNT MANAGER COMMENTS</div>
                            
                            <div class="flex column gap-5 w-full">
                                <div class="checkmark-info">
                                    <div class="world-check-info mb-20">
                                        <div class="options flex column gap-10">
                                        <div class="flex align-start gap-5">
                                            <div class="option-desctions">
                                            Sanctions found  
                                            </div> 
                                            <div class="flex row gap-5">
                                                <div class="flex column align-start">
                                                    <div class="flex row gap-5" 
                                                        :class="{changes: hasChanges('sanctions_found', 'new','YES')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('sanctions_found','new') == 'YES'"
                                                            :name="`sanctions_found-${randomId}-a`" 
                                                            :id="`YES-sanctions_found-${randomId}-a`"
                                                        > YES
                                                    </div> 
                                                    <div class="flex row gap-5"
                                                        :class="{changes: hasChanges('sanctions_found', 'new','NO')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('sanctions_found','new') == 'NO'"
                                                            :name="`sanctions_found-${randomId}-a`" 
                                                            :id="`NO-sanctions_found-${randomId}-a`"
                                                        > NO
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-start gap-5">
                                            <div class="option-desctions">
                                            PEP  
                                            </div> 
                                            <div class="flex row gap-5">
                                                <div class="flex column align-start">
                                                    <div class="flex row gap-5" 
                                                        :class="{changes: hasChanges('pep_found', 'new','YES')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('pep_found','new') == 'YES'"
                                                            :name="`pep_found-${randomId}-a`" 
                                                            :id="`YES-pep_found-${randomId}-a`"
                                                        > YES
                                                    </div> 
                                                    <div class="flex row gap-5"
                                                        :class="{changes: hasChanges('pep_found', 'new','NO')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('pep_found','new') == 'NO'"
                                                            :name="`pep_found-${randomId}-a`" 
                                                            :id="`NO-pep_found-${randomId}-a`"
                                                        > NO
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex align-start gap-5">
                                            <div class="option-desctions">
                                            Relevant Adverse Information  
                                            </div> 
                                            <div class="flex row gap-5">
                                                <div class="flex column align-start">
                                                    <div class="flex row gap-5" 
                                                        :class="{changes: hasChanges('relevant_adverse_information_found', 'new','YES')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('relevant_adverse_information_found','new') == 'YES'"
                                                            :name="`relevant_adverse_information_found-${randomId}-a`" 
                                                            :id="`YES-relevant_adverse_information_found-${randomId}-a`"
                                                        > YES
                                                    </div> 
                                                    <div class="flex row gap-5"
                                                        :class="{changes: hasChanges('relevant_adverse_information_found', 'new','NO')}"
                                                    > 
                                                        <input 
                                                            :disabled="true" 
                                                            type="radio" 
                                                            :checked="getFieldValue('relevant_adverse_information_found','new') == 'NO'"
                                                            :name="`relevant_adverse_information_found-${randomId}-a`" 
                                                            :id="`NO-relevant_adverse_information_found-${randomId}-a`"
                                                        > NO
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-holder h-content flex gap-5 w-full">
                                    <div class="comment-info w-full flex column gap-4">
                                        <div class="flex column input-row w-full" v-if="getFieldValue('sanctions_found','new') == 'YES'" >
                                            <div class="header-of-comments">Found Sanctions</div>
                                            <div class="input-row w-full">
                                                <CommentSection 
                                                    :attrId="`sanctions_comment`" 
                                                    class="markdown-content"
                                                    :disabled="true"
                                                    :commentSection="getFieldValue('sanctions_comment','new')"
                                                    :class="{changes: hasChanges('sanctions_comment', 'new')}"
                                                />
                                            </div>
                                        </div>
                                        <div class="flex column input-row w-full" v-if="getFieldValue('pep_found','new') == 'YES'" >
                                            <div class="header-of-comments">PEP</div>
                                            <div class="input-row w-full">
                                                <CommentSection 
                                                    :attrId="`pep_comment`" 
                                                    class="markdown-content"
                                                    :disabled="true"
                                                    :commentSection="getFieldValue('pep_comment','new')"
                                                    :class="{changes: hasChanges('pep_comment', 'new')}"
                                                />
                                            </div>
                                        </div>
                                        <div class="flex column input-row w-full" v-if="getFieldValue('relevant_adverse_information_found','new') == 'YES'" >
                                            <div class="header-of-comments">Relevant Adverse Information</div>
                                            <div class="input-row w-full">
                                                <CommentSection 
                                                    :attrId="`relevant_adverse_information_comment`" 
                                                    class="markdown-content"
                                                    :disabled="true"
                                                    :commentSection="getFieldValue('relevant_adverse_information_comment','new')"
                                                    :class="{changes: hasChanges('relevant_adverse_information_comment', 'new')}"
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div class='holder' v-if="reviewAction.person || reviewAction.legalEntity">
                <div class='mt-20' v-if="reviewAction.actionType !== 'remove'">
                    <h2 class="text-left">Attached to </h2>
                </div>
                <div v-if="reviewAction.person">
                    <PersonComp2
                        v-if="reviewAction.ubo_related"
                        :key="`${reviewAction.person}_person_view_`"
                        :person_id="reviewAction.person" 
                        :source_of_funds="true"
                        :source_of_wealth="true"
                        :bank_reference_letter="true"
                        :google_search="true"
                        :world_check="true"
                        :reviewMode="true"
                    />
                    <PersonComp2
                        v-else
                        :key="`${reviewAction.person}_person_view`"
                        :person_id="reviewAction.person" 
                        :isOfficial="true"
                        :bank_reference_letter="true"
                        :world_check="true"
                        :google_search="true"
                        :reviewMode="true"
                    />
                </div>
                <div v-if="reviewAction.legalEntity">
                    <EntityView2
                        :entity_id="reviewAction.legalEntity" 
                        :reviewMode="true"
                    />
                </div>

            </div>
        </div>
    </div>
</template>

<script setup >

import { ref, computed, onMounted, watch } from 'vue'
import UploaderTable2 from '@/components/UploaderTable2.vue'
import CommentSection from '@/views/CommentSection.vue'
import PersonComp2 from '@/components/PersonComp2.vue'
import EntityView2 from './EntityView2.vue'

import dayjs from 'dayjs';

import { useStore } from 'vuex';

import API from '@/api/index.js'

const props = defineProps(['reviewAction', 'view'])
const store = useStore();

const fileFieldChange = ref(JSON.parse(props.reviewAction.fields));
const fileToShow = ref(JSON.parse(props.reviewAction.fileInfo));
const sectionFile = ref(null)
const loading = ref(true);

const user = computed(() => store.state.user);


const showAccountManagerComment = ref(false);
const showSupervisorComment = ref(false);
const showComplienceOfficerComment = ref(false);
const shownInputsNumber = ref(0);

const randomId = ref(Math.random().toString(36).substring(7));


onMounted(async() => {
    
    const fileResponse = await API.getFileById(props.reviewAction.kyc_file);

    if (!fileResponse.status == 200) {
        return;
    }
    store.state.kyc_files = [];
    store.state.kyc_persons = [];
    store.state.kyc_entities = [];

    store.state.kyc_files.push(fileResponse.file);
    sectionFile.value = fileResponse.file;
    if (props.reviewAction.person) {
        const personResponse = await API.getPersonById({id: props.reviewAction.person, dependencies: true});

        if (!personResponse.status == 200) {
            return;
        }
        store.state.kyc_persons.push(personResponse.person);
        store.state.kyc_files.push(...personResponse.files); 
    } else if (props.reviewAction.legalEntity) {
        const entityResponse = await API.getEntityById({id: props.reviewAction.legalEntity, dependencies: true});
        if (!entityResponse.status == 200) {
            return;
        }
        store.state.kyc_entities.push(entityResponse.entity);
        store.state.kyc_files.push(...entityResponse.files); 
    } 
    loading.value = false;
    if (user.value && user.value.type == 'DATA_CONTROLLER') {
        showAccountManagerComment.value = true
        shownInputsNumber.value = 1
    }
    if (user.value && user.value.type == 'DATA_PROCESSOR') {
        showAccountManagerComment.value = true
        showSupervisorComment.value = true;
        shownInputsNumber.value = 2
    }
    if (['COMPLIANCE_OFFICER', 'EXTERNAL_AUDITOR', 'INTERNAL_AUDITOR', 'TRUST_OFFICER'].includes(user.value.type)) {
        showComplienceOfficerComment.value = true
        showAccountManagerComment.value = true
        showSupervisorComment.value = true
        shownInputsNumber.value = 3
    }
});

function toggleSection(){

}


function hasChanges(field, version, value = undefined) {
    if (version == 'current') {
        return (
            fileFieldChange.value[field]?.new != undefined 
            && fileFieldChange.value[field]?.current 
            && (value == undefined
                || (value && fileFieldChange.value[field]?.current == value)
            )
        )
    }
    return (
        fileFieldChange.value[field]?.new != undefined
        && (value == undefined
            || (value && fileFieldChange.value[field]?.new == value)
        )
    )
}


function getFieldValue(field, state) {
    if (
        state == 'current' && 
        fileFieldChange.value[field] && 
        !fileFieldChange.value[field].current && 
        fileFieldChange.value[field].new
    ) {
        return null;
    }
    if (fileFieldChange.value[field] && fileFieldChange.value[field][state]) {
        return fileFieldChange.value[field][state];
    }
    return sectionFile.value[field];
}


function renderTime(time) {
    if (!time) return '';
    return dayjs(time).format('MMM DD, YYYY [at] HH:mm');
}


</script>

<style lang="scss" scoped>

.holder {
    width: 97%;
}
.margins {
    width: 30px;
    height: 35px;
}
.header-of-comments {
    text-align: left;
    font-size: 16px;
    color: black;
    font-weight: 600;
    padding-left: 5px;
    margin-top: 10px;
}
.option-desctions {
    font-weight: bold;
    text-align: left;
    width: 150px;
}
.markdown-content {
    height: 200px;
    max-height: 200px;
    :nth-child(1) {
        text-align: left;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    &:deep(.ql-container){
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    &.disabled {
        background-color: #f5f5f5;
        opacity: 0.6;
    }
}


.proposed-changes {
    margin-left: auto;
    
    .changes {
        background: #DEF7EC;
        &:deep(label) {
            background: #DEF7EC;
        }
    }
}
.button-collapse {
    visibility: none;
}
.current-version {
    
    .changes {
        background: #FDE8E8;
        &:deep(label) {
            background: #FDE8E8;
        }
    }
}
</style>