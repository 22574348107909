<template>
    <PopupCover @close="() => handleClose()">
        <PopupBody v-if="!loading" @close="handleClose()">
            <template #header>Add new {{ getName(type) }}</template>
            <template #body>
                <div class="w-full">
                    <div class="add-new-subsidiary-area" v-if="type == 'shareholder'">
                        <InpComponent 
                            :value="data?.shareholder_type?.value" 
                            :key_ref="'shareholder_type'"
                            :options="[{value: 'person', text: 'Individual'}, {value: 'entity', text: 'Corporate'}]"
                            type="options"
                            @inputChange="inputChangeFunc"
                            :label="`Shareholder Type`"
                            :error="data?.shareholder_type?.error"
                        />
                        <InpComponent
                            :label="`Percentage of shares held (${props.metaInfo.remaining_shares}% remaining)`"
                            type="number"
                            :key_ref="'percentage_held'"
                            :value="data?.percentage_held?.value"
                            :error="data?.percentage_held?.error"
                            @inputChange="inputChangeFunc"
                        />
                    </div>
                   
                    <div class="add-new-subsidiary-area" v-if = "type == 'md'">
                        <InpComponent
                            class="country-select"
                            label="Origin"
                            type="options"
                            :options="[{value: 'local', text: 'Resident'}, {value: 'abroad', text: 'Non-resident'}]"
                            placeholder="Local"
                            :key_ref="'location_type'"
                            :value="data?.location_type.value"
                            :error="data?.location_type.error"
                            @inputChange="inputChangeFunc"
                        ></InpComponent>

                        <InpComponent 
                            class="country-select"
                            label="Director Type"
                            type="options"
                            :options="[{value: 'person', text: 'Individual'}, {value: 'entity', text: 'Legal entity'}]"
                            placeholder="Local"
                            :key_ref="'entity_type'"
                            :value="data?.entity_type.value"
                            :error="data?.entity_type.error"

                            @inputChange="inputChangeFunc"
                        ></InpComponent>
                    </div>
                    <div v-if="(
                        type == 'shareholder' && data?.shareholder_type?.value == 'entity'
                        ) || (type == 'md' && data?.entity_type.value == 'entity'
                    )">
                        <InpComponent
                            label="Company Name"
                            type="text"
                            :key_ref="'name'"
                            :value="data?.name?.value"
                            :error="data?.name?.error"
                            @inputChange="inputChangeFunc"
                        />
                    </div>
                    <div class="add-new-subsidiary-area" 
                    v-if = "(['person','official'].includes(type) || 
                        (type == 'shareholder' && data?.shareholder_type?.value == 'person') ||
                        (type == 'md' && data?.entity_type.value == 'person')
                        )">
                        <InpComponent
                            label="First Name"
                            type="text"
                            :key_ref="'first_name'"
                            :value="data?.first_name?.value"
                            :error="data?.first_name?.error"
                            @inputChange="inputChangeFunc"
                        />
                        <InpComponent
                            label="Last Name"
                            type="text"
                            :key_ref="'last_name'"
                            :value="data?.last_name?.value"
                            :error="data?.last_name?.error"
                            @inputChange="inputChangeFunc"
                        />
                    </div>
                    <div v-if="(
                        ['cosbaof', 'scfba'].includes(type)
                    )">
                        <InpComponent
                            label="Bank Name"
                            type="text"
                            :key_ref="'bank_name'"
                            :value="data?.bank_name?.value"
                            :error="data?.bank_name?.error"
                            @inputChange="inputChangeFunc"
                        />
                    </div>
                <button class="save-button" @click="confirmCreate()">Save</button>
                </div>
            </template>
        </PopupBody>
        <LoaderPopup v-else :message="`Adding New ${getName(type)}...`"/>

    </PopupCover>
</template>


<script setup>
    import { ref, computed, defineProps, defineEmits } from 'vue'
    import { useStore } from 'vuex'
    import PopupCover from './PopupCover.vue';
    import PopupBody from './PopupBody.vue';
    import InpComponent from './InpComponent.vue';
    import LoaderPopup from './LoaderPopup.vue';
    const emit = defineEmits(['close'])
    const props = defineProps(['type', 'metaInfo'])
    const validationIsOn = ref(false);
    const loading = ref(false);
    const store = useStore()

    const data = ref({
        shareholder_type: {
            value: '',
            error: ''
        },
        percentage_held: {
            value: null,
            error: ''
        },
        entity_type: {
            value: null,
            error: ''
        },
        location_type: {
            value: null,
            error: ''
        },
        name: {
            value: null,
            error: ''
        },
        first_name: {
            value: null,
            error: ''
        },
        last_name: {
            value: null,
            error: ''
        },
        bank_name: {
            value: null,
            error: ''
        },
    })
    
    function handleClose(){
        emit('close')
    }
    function inputChangeFunc(event) {
        const event_type = event[0];
        const event_value = event[1];

        data.value[event_type].value = event_value;
        if (validationIsOn.value) {
            validateInputs();
        }
    }
    function validateInputs() {
        validationIsOn.value = true;
        let validationPassed = true;
        for (const key in data.value) {
            data.value[key].error = '';
        }
        if (props.type == 'md') {
            if (!data.value['location_type'].value) {
                data.value['location_type'].error = 'Location type is required';
                validationPassed = false;
            }
            if (!data.value['entity_type'].value) {
                data.value['entity_type'].error = 'Director type is required';
                validationPassed = false;
            }
            

        } 
        if (props.type == 'shareholder') {
            if (!data.value.percentage_held.value) {
                data.value.percentage_held.error = 'Percentage of shares held is required'
                validationPassed = false;
            }
            if (!data.value.shareholder_type.value) {
                data.value.shareholder_type.error = 'Shareholder type is required'
                validationPassed = false;
            }
            if (data.value.percentage_held.value > 100) {
                data.value.percentage_held.error = 'Percentage of shares held must be less than 100'
                validationPassed = false;
            }
            if (data.value.percentage_held.value < 0) {
                data.value.percentage_held.error = 'Percentage of shares held must be greater than 0'
                validationPassed = false;
            }
            if (data.value.percentage_held.value > props.metaInfo.remaining_shares) {
                data.value.percentage_held.error = `Percentage of shares held must be less than ${props.metaInfo.remaining_shares}%`
                validationPassed = false;
            }
        } 
        if(
            ['person', 'official'].includes(props.type) ||
            (props.type == 'shareholder' && data.value.shareholder_type.value == 'person') ||
            (props.type == 'md' && data.value.entity_type.value == 'person')
        ) {
            console.log('data.value.first_name.value', data.value.first_name.value)
            if (!data.value.first_name.value) {
                data.value.first_name.error = 'First name is required'
                validationPassed = false;
            }
            if (!data.value.last_name.value) {
                data.value.last_name.error = 'Last name is required'
                validationPassed = false;
            }
        }
        if (
            (props.type == 'shareholder' && data.value.shareholder_type.value == 'entity') ||
            (props.type == 'md' && data.value.entity_type.value == 'entity')
        ) {
            if (!data.value.name.value) {
                data.value.name.error = 'Company name is required'
                validationPassed = false;
            }
        }
        if (['cosbaof', 'scfba'].includes(props.type)) {
            if (!data.value.bank_name.value) {
                data.value.bank_name.error = 'Bank name is required'
                validationPassed = false;
            }
            if (data.value.bank_name.value && data.value.bank_name.value.trim().length < 5) {
                data.value.bank_name.error = 'Bank name must be at least 5 characters'
                validationPassed = false;
            }
        }
        return validationPassed;
    }
    async function confirmCreate(){
        console.log('confirmCreate')
        const validattion = validateInputs();
        console.log('validattion', validattion)
        if (!validattion) {
            return;
        }
        loading.value = true;
        let sucess = false;
        if (props.type == 'md') {
            sucess = await store.dispatch('addNewMD', {
                ...props.metaInfo,
                location_type: data.value.location_type.value,
                entity_type: data.value.entity_type.value,
                name: data.value.name.value,
                firstName: data.value.first_name.value,
                lastName: data.value.last_name.value,
            })
        }
        else if (props.type == 'shareholder') {
            sucess = await store.dispatch('addNewShareHolder', {
                ...props.metaInfo,
                shareholder_type: data.value.shareholder_type.value,
                percentage_held: data.value.percentage_held.value,
                name: data.value.name.value,
                firstName: data.value.first_name.value,
                lastName: data.value.last_name.value,
            })
        } 
        else if (['person','official'].includes(props.type)) {
            sucess = await store.dispatch('addNewOfficial', {
                ...props.metaInfo,
                firstName: data.value.first_name.value,
                lastName: data.value.last_name.value,
            })
        } else if (['cosbaof', 'scfba'].includes(props.type)) {
            sucess = await store.dispatch('addNewBankDocument', {
                bank_name: data.value.bank_name.value,
                documentType: props.type
            })
        }
      
        loading.value = false;
        if (!sucess) {
            return;
        }
        store.dispatch('addNotification', {
            type: 'success',
            text: `${getName()} added successfully`
        })
        handleClose();
    }
    function getName(){
        if(props.type === 'official'){
            return 'Official'
        }
        if(props.type === 'person'){
            return 'Person'
        }
        
        else if(props.type === 'entity'){
            return 'Entity'
        }
        else if(props.type === 'shareholder'){
            return 'Shareholder'
        }
        else if(props.type === 'md'){
            return 'Managing Director'
        }
        else if(props.type === 'ubo'){
            return 'UBO'
        } else if(props.type === 'cosbaof'){
            return 'Copy of signed bank account opening forms'
        } else if(props.type === 'scfba'){
            return 'Signature card for bank account'
        }
    }
</script>

<style lang="scss" scoped>

</style>