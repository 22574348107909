<template>
    <div>
        <div  v-if="loading">
            Loading
        </div>
        <div class='flex w-full gap-5' v-else-if="subsidiary">
            <div class="current-version flex-1 flex column" v-if="reviewAction.actionType == 'remove'">
                <div>

                </div>
                <p class=field-label>Delete action requested on the following Subsidiary</p>

                <div class="line-label">Subsidiary Name</div>
                <div class="info-line">{{ subsidiary.name }} </div>

                <div class="line-label">Last Annual Account on File</div>
                <div class="info-line">{{ subsidiary.last_annual_account_on_file }} </div>

                <div class="line-label" v-if="subsidiary.comment">Comment upon adding</div>
                <div class="info-line"  v-if="subsidiary.comment" v-html="subsidiary.comment"></div>

            </div> 
            <div v-else class='flex w-full gap-5'>
                <div class="flex-1">
                    <h3 class="header-small">
                        <text v-if="view == 'forConfirmation'">Current Version</text>
                        <text v-else>Previous Version</text>
                    </h3>
                    <div class="info-holder current-version">
                        <div class="info-holder">
                            <div class="line-label">Subsidiary Name</div>
                            <div class="info-line" :class="{changes: fieldChange?.name?.new != undefined}">{{ fieldChange?.name?.current || subsidiary.name || '----'}} </div>
                            <div class="line-label">Last Annual Account on File</div>
                            <div class="info-line" :class="{changes: fieldChange?.last_annual_account_on_file?.current != undefined}">{{ fieldChange?.last_annual_account_on_file?.current || '----'}} </div>
                        </div>
                    </div>
                </div>
                <div class="flex-1">
                    <h3 class="header-small">
                        <text>New Version</text>
                    </h3>
                    <div class="info-holder proposed-changes">
                        <div class="info-holder">
                            <div class="line-label">Subsidiary Name</div>
                            <div class="info-line" :class="{changes: fieldChange?.name?.new != undefined}">{{ fieldChange?.name?.new || subsidiary.name || '----' }} </div>
                            <div class="line-label">Last Annual Account on File</div>
                            <div class="info-line" :class="{changes: fieldChange?.last_annual_account_on_file?.new != undefined}">{{ fieldChange?.last_annual_account_on_file?.new || subsidiary.last_annual_account_on_file || '----'}} </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            No Data Found
        </div>
    </div>
</template>

<script setup >

import { ref, computed, onMounted, watch } from 'vue'
import dayjs from 'dayjs';

import { useStore } from 'vuex';

import API from '@/api/index.js'

const props = defineProps(['reviewAction', 'view'])
const store = useStore();

const fieldChange = ref(JSON.parse(props.reviewAction.fields));
const subsidiary = ref(null);
const loading = ref(true);
onMounted(async() => {
    const subsidiaryResponse = await API.getSubsidiaryById({id: props.reviewAction.subsidiary, dependencies: true});
    if (!subsidiaryResponse.status == 200) {
        return;
    }
    subsidiary.value = subsidiaryResponse.subsidiary;
    loading.value = false;
});



function renderTime(time) {
    if (!time) return '';
    return dayjs(time).format('MMM DD, YYYY [at] HH:mm');
}


</script>

<style lang="scss" scoped>

    .proposed-changes {
        margin-left: auto;
        .changes {
            background: #DEF7EC;
            &:deep(label) {
                background: #DEF7EC;
            }
        }
    }
    .current-version {
       
        .changes {
            background: #FDE8E8;
            &:deep(label) {
                background: #FDE8E8;
            }
        }
    }
    .comment-info {
        flex: 3;
    }
    .info-holder {
        margin-bottom: 20px;
    }
    .info-line {
        color: black;
        text-align: left;
        font-weight: 600;
        margin: 0;
    }
    .line-label {
        text-align: left;
        font-weight: 600;
        margin: 0;
        margin-top: 5px;
        font-size: 12px;
        color: rgb(151, 151, 151);
    }
</style>