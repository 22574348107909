import axios from 'axios';
const API_URL = process.env.VUE_APP_API_BASE + '/api/v1/';

import { PermissionChecker } from '@/utils/iam_utils.js';


import store from '../store';
const instance = axios.create({
    // baseURL: 'http://your-api-base-url.com', // Replace with your API base URL
});

const setAuthToken = (token) => {
    instance.interceptors.request.use((config) => {
        // Add your custom headers here
        config.headers['Authorization'] = token; // Replace with your actual JWT token
      
        return config;
    });
}

if (localStorage.getItem('token')) {
    setAuthToken(localStorage.getItem('token'));
} else {
    store.dispatch('setValue', {key: 'loaded', value: true});
}

const saveUserChanges = async (user) => {
    try {
        const response = await instance.put(`${API_URL}save-user-changes`, user);
        if (response.data.error) {
            store.dispatch('addNotification', {type: 'error', text: response.data.error});
            return response;
        } else if (response.status == 200){
            store.dispatch('addNotification', {type: 'success', text: 'User changes applied successfully'});
        }
        // store.dispatch('setValue', {key: 'user', value: response.data});

        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'Error applying user changes'});
        return false
    }
}

const getFilesList = async () => {
    try {
        const response = await instance.get(`${API_URL}get-files-list`);
        store.dispatch('setValue', {key: 'fileList', value: response.data});
        console.log('response', response);

        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'Error loading user files list'});
        store
        return false
    }
}
const getUserBalance = async () => {
    try {
        const response = await instance.get(`${API_URL}get-balance`);
        store.dispatch('setValue', {key: 'credits_balance', value: response.data.credits_balance});
        console.log('response', response);
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'Error updating user balance'});
        return false
    }
}

const updateSavedData = async (id, data) => {
    let response = null
    try {
        response = await instance.post(`${API_URL}save-saved-info`, data).catch((error) => {
            if (error.response.status == 400) {
                store.dispatch('addNotification', {type: 'error', text: 'Error updating user information'});
                return {error: error.response.data.error};
            }
        });
        if (response && response.status == 200) {
            store.dispatch('addNotification', {type: 'success', text: 'Information updated successfully'});
        }
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'Error updating user information'});
        
        return false
    }
}

const getSavedInfo = async () => {
    try {
        const response = await instance.get(`${API_URL}get-saved-info`);
        console.log('response', response);
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'Error updating user balance'});
        return false
    }
}


const requestWallet = async () => {
    console.log('requestWallet');
    try {
        const response = await instance.post(`${API_URL}allocate-wallet`);
        store.dispatch('setValue', {key: 'wallet', value: {loaded: true, address: response.data.address}});
        console.log('response', response);
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'Error requesting wallet'});
        return false
    }

}

const getUser = async (id = '') => {
    let response = null;
    try {
        response = await instance.get(`${API_URL}get-user?id=${id}`).catch((error) => {
            console.log('error', error);
            if (error.response.status >= 400 && error.response.status < 500) {
                if (error.response.status == 500) {
                    store.dispatch('addNotification', {type: 'error', text: 'Error loading user data\n, Plese contact support'});
                }
                if (error.response.status == 403) { 
                    localStorage.removeItem('token');
                    setAuthToken(null);
                }
                return error.response;
            }
        });
        if (response) {
            if (id) {
                return {
                    ...response.data,
                }
            } else {
                console.log('response', response);
                store.dispatch('setValue', {key: 'user', value: response.data.user});
                store.dispatch('setValue', {key: 'credits_balance', value: response.data.credits_balance});
                store.dispatch('setValue', {key: 'userAddress', value: response.data.userAddress});
                store.dispatch('setValue', {key: 'userPhone', value: response.data.userPhone});
                store.dispatch('setValue', {key: 'userInformation', value: response.data.userInformation});
                store.dispatch('setValue', {key: 'userPermissions', value: response.data.permissions});
                PermissionChecker.setPermissions(response.data.permissions);

                store.dispatch('setValue', { 
                    key: 'wallet', value: { 
                        loaded: true, 
                        address: response.data.wallet 
                    }
                });
            }
        }
        return response;
    } catch (error) {
        console.log('response', response)
        store.dispatch('addNotification', {type: 'error', text: 'Error loading user data'});
        // return false
    }
}


const register = async ({
    password,
    username, 
    email
}) => {
    try {
        let error_ = null;
        const response = await instance.post(`${API_URL}register`, {
            password,
            username,
            email
        }).catch((error) => {
            if (error.response.status == 401) {
                error_ = {error: error.response.data.message};
            }
        });
        if (error_) {
            return error_;
        }
        
        setAuthToken(response.data.token);
        localStorage.setItem('token', response.data.token);
        window.location.reload();
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during user registration.\nPlease try again or contact support'});
        return false
    }
};


const verifyTwoFaCode = async (token) => {
    try {
        const response = await instance.post(`${API_URL}user/verify-otp`, {
            token
        }).catch((error) => {
            if (error.response.status == 400) {
                return {};
            }
        })
        return response;
    }
    catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during token verification.\nPlease try again or contact support'});
        return false
    }
}

const verifyToken = async (token) => { 
    try {
        const response = await instance.post(`${API_URL}verify-token`, {
            token
        }).catch((error) => {
            if (error.response.status == 400) {
                return {};
            }
        })
        return response;
    }
    catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during token verification.\nPlease try again or contact support'});
        return false
    }
}

const sendVerificationEmail = async (email) => {
    try {
        let error_ = null;
        const response = await instance.post(`${API_URL}send-verification-email`, {
            email
        }).catch((error) => {
            if (error.response.status == 401) {
                return {error: error.response.data.message};
            }
        });
        if (response.status == 200) {
            store.dispatch('addNotification', {type: 'success', text: 'Verification email sent successfully'});
        }
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error sending verification emaiil.\nPlease try again or contact support'});
        return false
    }

}


const login = async ({
    password,
    email,
    token
}) => {
    console.log('login')
    try {
        let error_ = null;
        const response = await instance.post(`${API_URL}login`, {
            password,
            email,
            token
        }).catch((error) => {
            console.log('error', error)
            if (error.response.data.message) {
                store.dispatch('addNotification', {type: 'error', text: error.response.data.message});
            } else {
                store.dispatch('addNotification', {type: 'error', text: 'There was an error during user login.\nPlease try again or contact the support'});
            }
        });

        if (error_) {
            return error_;
        }
        
        setAuthToken(response.data.token);
        return response;
    } catch (error) {
        // store.dispatch('addNotification', {type: 'error', text: 'There was an error during user login.\nPlease try again or contact the support'});
    }
};

const newDueDiligenceRequest = async (data) => {
    try {
        const response = await instance.post(`${API_URL}due-diligence`, data).catch((error) => {
            if (error.response.status != 200) {
                return error.response
            }
        });
        return response.data;
    } catch (error) {
        return false
    }
}

const getSansctionScreeningList = async () => {
    try {
        const response = await instance.get(`${API_URL}due-diligence/list`).catch((error) => {
            if (error.response.status == 401) {
                return error.response.data
            }
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during due diligence request.\nPlease try again or contact the support'});
        return false
    }
}

const getSansctionScreeningInfo = async (id) => {
    try {
        const response = await instance.get(`${API_URL}due-diligence/${id}`).catch((error) => {
            if (error.response.status == 401) {
                return error.response.data
            }
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during due diligence request.\nPlease try again or contact the support'});
        return false
    }
} 

const uploadFileGeneral = async (file, url, metadata) => {
    const headers = new Headers({
        'Authorization': localStorage.getItem('token'), // Example header for authentication
    });
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);
        if (metadata) {
            for (let key in metadata) {
                formData.append(key, metadata[key]);
            }
        }
        let response_info = null
        fetch(`${API_URL}${url}`, {
            method: 'POST',
            body: formData,
            headers
        })
        .then(response => {
            response_info = response
            return response.json()
        })
        .then(data => {

            resolve(data); 
            if (response_info.status == 500) {
                throw Error('Failed to upload the file')
            }
            if (response_info.status == 401) {
                throw Error('Failed to upload the file')
            }
            if (response_info.status == 403) {
                throw Error('Failed to upload the file')
            }
            console.log('File uploaded successfully:', data, response_info.status);
        })
        .catch(error => {
            console.error('Error uploading file:', error);
            store.dispatch('addNotification', {type: 'error', text: 'There was an error uploading the file or file information.\nPlease try again or contact the support'});
            reject(error);
        });
    })
}

const uploadSupportFile = async (file) => {
    const response = await uploadFileGeneral(file, 'support/upload-file')
    return response;
}

const uploadFile = async (file) => {
    const response = await uploadFileGeneral(file, 'upload-file')
    return response;
}

const uploadKYCFile = async (file, metadata) => {
    const response = await uploadFileGeneral(file, 'upload-kyc-file', metadata)
    return response;
}

const downloadSupportFile = async (file) => {
    
    const headers = new Headers({
        'Authorization': localStorage.getItem('token'), // Example header for authentication
    });
    fetch(`${API_URL}get-support-file/?id=${file._id}`,{
        method: 'GET',
        headers: headers,
    })
    .then(response => response.blob())
    .then(async (blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', `${file.filename}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    })
    .catch(error => {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error downloading the file.\nPlease try again or contact the support'});
        console.error('Error downloading file:', error);
    });
}

const downloadFile = async (fileId,selectedFile) => {

    const headers = new Headers({
        'Authorization': localStorage.getItem('token'), // Example header for authentication
    });
    fetch(`${API_URL}get-file/?id=${fileId}`,{
        method: 'GET',
        headers: headers,
    })
    .then(response => response.blob())
    .then(async (blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;

        let file_name = selectedFile.name.split('.')
        file_name.pop()
        file_name = file_name.join('')

        link.setAttribute('download', `${file_name}.${fileId == 'sample' ? 'xlsx' : 'zip'}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        await getUserBalance()
    })
    .catch(error => {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error downloading the file.\nPlease try again or contact the support'});
        console.error('Error downloading file:', error);
    });
}


const denyChanges = async (data) => {
    try {
        const response = await instance.post(`${API_URL}changes/deny`, data).catch((error) => {
            if (error.response.status == 401) {
                error_ = {error: error.response.data.message};
            }
            store.dispatch('addNotification', {type: 'error', text: `There was an error during request.\nPlease try again or contact the support`});
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during request.\nPlease try again or contact the support'});
        // return false
        return false
    }

}


const getAffectedChanges = async (data) => {
    let error_ = false;
    try {
        
        const response = await instance.post(`${API_URL}changes/affected`, data).catch((error) => {
            error_ = true
            store.dispatch('addNotification', {type: 'error', text: error?.response?.data?.message || `There was an error during request.\nPlease try again or contact the support`});
        });
        return response.data;

    } catch (error) {
        if (!error_) {
            store.dispatch('addNotification', {type: 'error', text: 'There was an error during request.\nPlease try again or contact the support'});
        }
        return false
    }

}



const approveChanges = async (data) => {
    let error_ = false;
    try {
        const response = await instance.post(`${API_URL}changes/approve`, data).catch((error) => {
            error_ = true
            store.dispatch('addNotification', {type: 'error', text: error?.response?.data?.message || `There was an error during request.\nPlease try again or contact the support`});
        });
        return response.data;
    } catch (error) {
        if (!error_) {
            store.dispatch('addNotification', {type: 'error', text: 'There was an error during request.\nPlease try again or contact the support'});
        }
        return false
    }
}

const createNewUbo = async (data) => {
    try {
        const response = await instance.post(`${API_URL}ubo/create`, data).catch((error) => {
            if (error.response.status == 401) {
                return error.response.data
            }
            store.dispatch('addNotification', {type: 'error', text: `There was an error during UBO creation.\nPlease try again or contact the support`});
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during UBO creation.\nPlease try again or contact the support'});
        return false
    }
}
const updateTransactionProfile = async (id, data2) => {
    try {
        const response = await instance.put(`${API_URL}transaction-profile/update/${id}`, data2).catch((error) => {
            if (error.response.status == 401) {
                return error.response.data
            }
            store.dispatch('addNotification', {type: 'error', text: `There was an error during transaction profile update.\nPlease try again or contact the support`});
        });
        console.log('1111111111111111 updateTransactionProfile', response)
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during transaction profile update.\nPlease try again or contact the support'});
        return false
    }
}

const updateAccountNumber = async (id, data) => {
    try {
        const response = await instance.put(`${API_URL}account-number/update/${id}`, data).catch((error) => {
            if (error.response.status == 401) {
                return error.response.data
            }
            store.dispatch('addNotification', {type: 'error', text: `There was an error during account number update.\nPlease try again or contact the support`});
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during account number update.\nPlease try again or contact the support'});
        return false
    }
}

const createNewSanctionRequest = async (data) => { 
    try {
        const response = await instance.post(`${API_URL}sanction-screening`, data).catch((error) => {
            if (error.response.status != 200) {
                return error.response
            }
        });
        return response.data;
    } catch (error) {
        return false
    }
}


const updateUBO = async (id, data) => {
    try {
        const response = await instance.put(`${API_URL}ubo/update/${id}`, data).catch((error) => {
            if (error.response.status == 401) {
                return error.response.data
            }
            store.dispatch('addNotification', {type: 'error', text: `There was an error during UBO update.\nPlease try again or contact the support`});
        });
        return response.data;
    }
    catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during UBO update.\nPlease try again or contact the support'});
        return false
    }
}
const updatePerson = async (id, data) => {
    try {
        const response = await instance.put(`${API_URL}person/update/${id}`, data).catch((error) => {
            if (error.response.status == 401) {
                return error.response.data
            }
            store.dispatch('addNotification', {type: 'error', text: `There was an error during person update.\nPlease try again or contact the support`});
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during person update.\nPlease try again or contact the support'});
        return false
    }
}
const updateEntity = async (id, data) => {
    try {
        const response = await instance.put(`${API_URL}entity/update/${id}`, data).catch((error) => {
            if (error.response.status == 401) {
                return error.response.data
            }
            store.dispatch('addNotification', {type: 'error', text: `There was an error during entity update.\nPlease try again or contact the support`});
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during entity update.\nPlease try again or contact the support'});
        return false
    }
}


const updateMD = async (id, data) => {
    try {
        const response = await instance.put(`${API_URL}md/update/${id}`, data).catch((error) => {
            if (error.response.status == 401) {
                return error.response.data
            }
            store.dispatch('addNotification', {type: 'error', text: `There was an error during MD update.\nPlease try again or contact the support`});
        })
        return response.data;
    } 
    catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during MD update.\nPlease try again or contact the support'});
        return false
    }
}


const updateFile = async (id, file, metadata) => {
    const response = await uploadFileGeneral(file, `file/update/${id}`, metadata)
    return response;
}

const createMD = async (data) => {
    try {
        const response = await instance.post(`${API_URL}md/create`, data).catch((error) => {
            if (error.response.status == 401) {
                return error.response.data
            }
            store.dispatch('addNotification', {type: 'error', text: `There was an error during MD creation.\nPlease try again or contact the support`});
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during MD creation.\nPlease try again or contact the support'});
        return false
    }
};

const createShareholder = async (data) => {
    try {
        const response = await instance.post(`${API_URL}shareholder/create`, data).catch((error) => {
            if (error.response.status == 401) {
                return error.response.data
            }
            store.dispatch('addNotification', {type: 'error', text: `There was an error during shareholder creation.\nPlease try again or contact the support`});
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during shareholder creation.\nPlease try again or contact the support'});
        return false
    }
}

const createPerson = async (data) => {
    try {
        const response = await instance.post(`${API_URL}person/create`, data).catch((error) => {
            if (error.response.status == 401) {
                return error.response.data
            }
            store.dispatch('addNotification', {type: 'error', text: `There was an error during person creation.\nPlease try again or contact the support`});
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during person creation.\nPlease try again or contact the support'});
        return false
    }
}

const loadDashboardData = async ({snapshot_id, company_id}) => {
    try {

        let path = `dashboard-data?company_id=${company_id}${snapshot_id ? '&snapshotId=' + snapshot_id : ''}`;

        const response = await instance.get(`${API_URL}${path}`).catch((error) => {
            if (error.response.status == 401) {
                return error.response.data
            }
            store.dispatch('addNotification', {type: 'error', text: `There was an error loading dashboard data.\nPlease try again or contact the support`});
        });
        store.dispatch('setValue', { key: 'companyData', value: response.data.companyData});
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error loading dashboard data.\nPlease try again or contact the support'});
        return false
    }
}

const updateShareholder = async (id, data) => {
    try {
        const response = await instance.put(`${API_URL}shareholder/update/${id}`, data).catch((error) => {
            if (error.response.status == 401) {
                return error.response.data
            }
            store.dispatch('addNotification', {type: 'error', text: `There was an error during shareholder update.\nPlease try again or contact the support`});
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during shareholder update.\nPlease try again or contact the support'});
        return false
    }
}

const getKycFilesV2 = async ({snapshot_id, company_id}) => {
    try {
        store.dispatch('setValue', { key: 'kyc_files', value: null});
        store.dispatch('setValue', { key: 'kyc_ubos', value: null});
        store.dispatch('setValue', { key: 'kyc_entities', value: null});
        store.dispatch('setValue', { key: 'kyc_mds', value: null});
        store.dispatch('setValue', { key: 'kyc_persons', value: null});
        store.dispatch('setValue', { key: 'listingServices', value: null});
        store.dispatch('setValue', { key: 'kyc_accountNumbers', value: null});
        store.dispatch('setValue', { key: 'kyc_transactionalProfile', value: null});
        store.dispatch('setValue', { key: 'kyc_shareholders', value: null});
        store.dispatch('setValue', { key: 'companyData', value: null});

        let path = `get-kyc-files-info_2?company_id=${company_id}${snapshot_id ? '&snapshotId=' + snapshot_id : ''}`;
        const response = await instance.get(`${API_URL}${path}`).catch((error) => {
            store.dispatch('addNotification', {type: 'error', text: 'There was an error during user login.\nPlease try again or contact the support'});
        });
        store.dispatch('setValue', { key: 'kyc_files', value: response.data.kycFiles});
        store.dispatch('setValue', { key: 'kyc_ubos', value: response.data.ubos});
        store.dispatch('setValue', { key: 'kyc_entities', value: response.data.entities});
        store.dispatch('setValue', { key: 'kyc_mds', value: response.data.mds});
        store.dispatch('setValue', { key: 'kyc_persons', value: response.data.persons});
        store.dispatch('setValue', { key: 'listingServices', value: response.data.listingServices});
        store.dispatch('setValue', { key: 'kyc_accountNumbers', value: response.data.account_number});
        store.dispatch('setValue', { key: 'kyc_transactionalProfile', value: response.data.transactional_profile});
        store.dispatch('setValue', { key: 'kyc_shareholders', value: response.data.shareholders});

        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during user login.\nPlease try again or contact the support'});
        return false
    }
}



async function getBanks(){
    try {
        const response = await instance.get(`${API_URL}banks/list`)
        store.dispatch('setValue', { key: 'banks', value: response.data.banks});
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during bank info request.\nPlease try again or contact the support'});
        return false
    }
}
async function DownloadKYCFile(id, name) {
    await generateViewToken();
    
    const headers = new Headers({
        'Authorization': localStorage.getItem('token'), // Example header for authentication
    });
    fetch(`${API_URL}get-kyc-file/?id=${id}&viewingToken=${store.state.viewingToken}`,{
        method: 'GET',
        headers: headers,
    })
    .then(response => {
        console.log('response', response)
        if (response.status != 200) {
            store.dispatch('addNotification', {type: 'error', text: 'There was an error downloading the file.\nPlease try again or contact the support'});
            return;
        }
        return response.blob()
    })
    .then(async (blob) => {
        if (!blob) {
            return;
        }
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        // await getUserBalance()
    })
    .catch(error => {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error downloading the file.\nPlease try again or contact the support'});
        console.error('Error downloading file:', error);
    });
}
async function deleteObject(object_id, type, objectName) {
    console.log('delete ubo', object_id)
    let error_ = null;

    const response = await instance.post(`${API_URL}delete-object`, {
        id: object_id,
        type,
    }).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error during ${objectName} deletion.\nPlease try again or contact the support`});
    });
    console.log('response', response)
    if (response.data.status != 'success') {
        store.dispatch('addNotification', {type: 'error', text: `There was an error during ${objectName} deletion.\nPlease try again or contact the support`});
        return false;
    } else {
        store.dispatch('addNotification', {type: 'success', text: `Deleted request has been submited successfully`});
        const response = await getNotificationList({ page: 1, view: 'forConfirmation', id: store.state.companyData._id });

        store.state.confirmationNeeded = response.notifications;
        store.state.notificationsCount = response.count;

    }
    if (error_) {
        return false;
    }
    return true
}



async function uploadComment(content) {
    console.log('uploadComment')
    let error_ = null;

    const response = await instance.post(`${API_URL}comment-upload`, {comment_structure: content}).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error during company settings update.\nPlease try again or contact the support`});
    });
    console.log('response', response)
    if (response && response.data.status != 'success') {
        store.dispatch('addNotification', {type: 'error', text: `There was an error during company settings update.\nPlease try again or contact the support`});
        return false;
    }
    if (error_) {
        return false;
    }
    return true
}



async function updateKycReviewInfo(id, settings) {
    let error_ = null;

    const response = await instance.post(`${API_URL}update/kyc-review/${id}`, settings).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error during company settings update.\nPlease try again or contact the support`});
    });
    if (response.data.status != 'success') {
        store.dispatch('addNotification', {type: 'error', text: `There was an error during company settings update.\nPlease try again or contact the support`});
        return false;
    }
    return response.data
}

async function forgotPassword(email) {
    let error_ = null;

    const response = await instance.post(`${API_URL}user/forgot-password-request`, {
        email: email
    }).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error sending email with reset link.\nPlease try again or contact the support`});
    })
    if (response && response.data && response.data.timeout) {
        store.dispatch('addNotification', {type: 'error', text: `You can send new email in ${Math.floor(response.data.timeout)} seconds`});
        return null
    }
    return response.data;
}

async function resetPassword(data) {
    let error_ = null;
    const response = await instance.post(`${API_URL}user/forgot-password-response`, data).catch((error) => {
        return error.response
    })
    return response;
}


async function createSnapshot(data) {
    let error_ = null;
    const response = await instance.post(`${API_URL}snapshot-save`, data).catch((error) => {
        return error.response
    })
    return response.data;
}


async function getUserAccounts() {
    console.log('getUserAccounts')
    let error_ = null;
    const response = await instance.get(`${API_URL}user/accounts`).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error loading users of the company.\nPlease try again or contact the support`});
    })
    store.dispatch('setValue', {key: 'accounts', value: response.data});

    return response.data;
}

async function deleteUser(user_id) {
    let error_ = null;
    const response = await instance.post(`${API_URL}user/account/delete`, {user_id}).catch((error) => {
        return error.response
    })
    return response;
}

async function createNewUser(user){ 
    try {
        const response = await instance.post(`${API_URL}user/account/create`, user).catch((error) => {
            if (error.response.data.message) {
                store.dispatch('addNotification', {type: 'error', text: error.response.data.message});
                return false;
            }
            store.dispatch('addNotification', {type: 'error', text: `There was an error creating user for the company.\nPlease try again or contact the support`});
        })
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error creating user for the company.\nPlease try again or contact the support'});
        return false
    }

}

async function getNotificationList({
    page=1,
    view='forConfirmation',
    id=null
}) {
    const limit = 200;

    const offset = (page - 1) * limit;
    const response = await instance.get(`${API_URL}notifications?offset=${offset}&limit=${limit}&view=${view}&company_id=${id}`).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error loading notifications.\nPlease try again or contact the support`});
    })
    return response.data;
}

async function markNotificationAsRead(notification_id) {
    const response = await instance.post(`${API_URL}notifications/read`, {notification_id}).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error updating notifications.\nPlease try again or contact the support`});
    })
    return response.data;
}


async function getNotificationCount({
    view = 'forConfirmation',
    id
}) {
    const response = await instance.get(`${API_URL}notifications/count?view=${view}&company_id=${id}`).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error loading notifications.\nPlease try again or contact the support`});
        return null
    })
    store.dispatch('setValue', {key: 'notificationsCount', value: response.data.count});
    console.log('response COUNT', response)

    return response.data;
}

async function generateViewToken() {
    const response = await instance.get(`${API_URL}generate-view/token`).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error generating view token.\nPlease try again or contact the support`});
        return null
    })
    store.dispatch('setValue', { key: 'viewingToken', value: response.data.token});
    
    setTimeout(() => {
        store.dispatch('setValue', { key: 'viewingToken', value: null});
    }, 60000)
}

async function createTicket(data) {
    const response = await instance.post(`${API_URL}support/create`, data).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error creating ticket.\nPlease try again or contact the support`});
    })
    return response.data;
}

async function getTickets(data) {
    const response = await instance.get(`${API_URL}support/get/tickets`).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error loading tickets.\nPlease try again or contact the support`});
    })
    return response.data;
}
async function getTicket(tikcetId) {
    const response = await instance.get(`${API_URL}support/get/ticket/${tikcetId}`).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error loading ticket.\nPlease try again or contact the support`});
    })
    return response.data;
}
async function sendTicketMessage(data) {
    const response = await instance.post(`${API_URL}support/message/create`, data).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error creating ticket.\nPlease try again or contact the support`});
    })
    return response.data;
}
async function updateTicketStatus(data) {
    const response = await instance.post(`${API_URL}support/ticket/update`, data).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error updating ticket.\nPlease try again or contact the support`});
    })
    return response.data;
}
async function sendContactInfoText(data) {
    const response = await instance.post(`${API_URL}contact-info-text`, data).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }

        store.dispatch('addNotification', {type: 'error', text: `There was an error sending contact info text.\nPlease try again later`});
        return null
    })
    return response.data;
}
async function getSnapshotList(){
    const response = await instance.get(`${API_URL}snapshots/list`).catch((error) => {
        store.dispatch('addNotification', {type: 'error', text: `There was an error loading snapshots.\nPlease try again or contact the support`});
    })
    return response.data;
}
const getTerms = async () => {
    try {
        const response = await instance.get(`${API_URL}get-terms`);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading terms', type: 'error'})
        return false

    }
}

const getUserInfo = async (user_id) => {
    try {
        const response = await instance.get(`${API_URL}user/account?id=${user_id}`);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading user info', type: 'error'})
        return false
    }
}
const loadPermissions = async (user_id) => {
    try {
        const response = await instance.get(`${API_URL}user/account/permissions?id=${user_id}`);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading user info', type: 'error'})
        return false
    }
}
const getRoles = async () => {
    try {
        const response = await instance.get(`${API_URL}get-roles`);
        store.dispatch('setValue', {key: 'roles', value: response.data.roles});
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading roles', type: 'error'})
        return false
    }
}

const updateUserPermissions = async (data) => {
    try {
        const response = await instance.post(`${API_URL}update-permissions`, data);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error updating permissions', type: 'error'})
        return false
    }
}

const updateUser = async (data) => {
    try {
        const response = await instance.post(`${API_URL}user/account/update`, data);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error updating user', type: 'error'})
        return false
    }
}
const changePassword = async (data) => {
    let error = null;
    try {
        const response = await instance.post(`${API_URL}user/account/change-password`, data).catch((error) => {
            return error.response
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error changing password', type: 'error'})
        return false
    }
}

const saveSanctionAsPDF = async (data) => {
    let error = null;
    try {
        const response = await instance.post(`${API_URL}save-sanction-as-pdf`, data).catch((error) => {
            return error.response
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error saving sanction as pdf', type: 'error'})
        return false
    }
}

const getFileById = async (id) => {
    try {
        const response = await instance.get(`${API_URL}get-kycfile/${id}`);
        return response.data;
    } catch (error) {
        
        store.dispatch('addNotification', {text: 'Error loading file', type: 'error'})
        return null;
    }
}

const loadTransactionProfileData = async (id) => {
    try {
        const response = await instance.get(`${API_URL}get-transactional-profile/${id}`);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading transaction profile', type: 'error'})
        return null;
    }
}

const getAccountNumberById = async (id) => {
    try {
        const response = await instance.get(`${API_URL}get-account-number/${id}`);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading account number', type: 'error'})
        return null;
    }
}
const getPersonById = async (data) => {
    try {
        const response = await instance.get(`${API_URL}get-person/${data.id}`);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading person', type: 'error'})
        return null;
    }
}
const getEntityById = async (data) => {
    try {
        const response = await instance.get(`${API_URL}get-entity/${data.id}`);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading entity', type: 'error'})
        return null;
    }
}

const getUBOById = async (id) => {
    try {
        const response = await instance.get(`${API_URL}get-ubo/${id}`);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading UBO', type: 'error'})
        return null;
    }
}

const getShareHolderById = async (id) => {
    try {
        const response = await instance.get(`${API_URL}get-shareholder/${id}`);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading shareholder', type: 'error'})
        return null;
    }
}

const getMDById = async ({ id }) => {
    try {
        const response = await instance.get(`${API_URL}get-md/${id}`);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading MD', type: 'error'})
        return null;
    }
} 

const getSubsidiaryById = async ({ id }) => {
    try {
        const response = await instance.get(`${API_URL}get-subsidiary/${id}`).catch((error) => {
            if (error.response.status == 404) {
                store.dispatch('addNotification', {type: 'error', text: `Associated object was not found`});
                return error.response
            }
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading subsidiary', type: 'error'})
        return null;
    }
}

const createCompany = async (data) => {
    try {
        const response = await instance.post(`${API_URL}create-company`, data);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error creating company', type: 'error'})
        return null;
    }
}

const getCompamniesList = async (type, search = '', ordering) => {

    const orderKey = Object.keys(ordering)[0];
    const orderValue = ordering[orderKey];
    try {
        const response = await instance.get(`${API_URL}companies/list?type=${type}&search=${search}&orderingKey=${orderKey}&orderingValue=${orderValue}`);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading companies', type: 'error'})
        return null;
    }
} 

const createAccountNumber = async (company_id) => {
    try {
        const response = await instance.post(`${API_URL}account-number/create`, {company_id});
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error creating account number', type: 'error'})
        return null;
    }
}
const loadNotification = async (id) => {
    try {
        const response = await instance.get(`${API_URL}notification/${id}`);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading notification', type: 'error'})
        return null;
    }
}

const updateListingServices = async (data) => {
    try {
        const response = await instance.put(`${API_URL}listing-services/update`, data);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error updating listing services', type: 'error'})
        return null;
    }
}

const completeReport = async (data) => {
    try {
        const response = await instance.post(`${API_URL}complete-report`, data);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error completing report', type: 'error'})
        return null;
    }
}

const createFileSection = async (data) => {
    try {
        const response = await instance.post(`${API_URL}file-section/create`, data);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error creating file section', type: 'error'})
        return null;
    }
}

const postError = async (data) => {
    try {
        const response = await instance.post(`${API_URL}error`, data).catch((error) => {
            if (error.response.status == 401) {
                return error.response.data
            }
            store.dispatch('addNotification', {type: 'error', text: `There was an error during error creation.\nPlease try again or contact the support`});
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during error creation.\nPlease try again or contact the support'});
        return false
    }
}



export default {
    updateUserPermissions,
    login,
    updateUser,
    register,
    getUser,
    deleteObject,
    uploadFile,
    downloadFile,
    DownloadKYCFile,
    saveUserChanges,
    requestWallet,
    getUserBalance,
    createNewUser,
    getNotificationList,
    instance,
    API_URL,
    getUserAccounts,
    createSnapshot,
    getSavedInfo,
    updateKycReviewInfo,
    getFilesList,
    sendVerificationEmail,
    verifyToken,
    uploadComment,
    uploadKYCFile,
    markNotificationAsRead,
    getKycFilesV2,
    updateKycReviewInfo,
    getNotificationCount,
    forgotPassword,
    resetPassword,
    createTicket,
    generateViewToken,
    getTickets,
    getTicket,
    sendTicketMessage,
    updateTicketStatus,
    downloadSupportFile,
    uploadSupportFile,
    sendContactInfoText,
    deleteUser,
    getSnapshotList,
    approveChanges,
    getAffectedChanges,
    denyChanges,
    getTerms,
    getUserInfo,
    loadPermissions,
    getRoles,
    changePassword,
    verifyTwoFaCode,
    newDueDiligenceRequest,
    getSansctionScreeningList,
    getSansctionScreeningInfo,
    saveSanctionAsPDF,
    createNewUbo,
    createPerson,
    createMD,
    loadDashboardData,
    updateMD,
    updateUBO,
    updatePerson,
    updateEntity,
    updateFile,
    getFileById,
    getPersonById,
    getEntityById,
    getUBOById,
    getMDById,
    getShareHolderById,
    getSubsidiaryById,
    createCompany,
    getCompamniesList,
    updateAccountNumber,
    updateTransactionProfile,
    getBanks,
    createAccountNumber,
    createShareholder,
    updateShareholder,
    loadNotification,
    updateListingServices,
    getAccountNumberById,
    loadTransactionProfileData,
    completeReport,
    createFileSection,
    postError,
    createNewSanctionRequest
};
