<template>
    <div class="flex column bg-white section wrapper " 
      :class="`${disabled ? 'disabled' : ''} ${noToolbar ? 'no-tool-bar': ''}`"
    >
      <div :id="elementId" class="editor-section">

      </div>
     
    </div>
</template>


<script setup>

import Quill from 'quill';
import "quill/dist/quill.snow.css";

import { ref, onMounted, defineEmits, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'

const store = useStore();
const user = computed(() => store.state.user);
const emit = defineEmits(['quillContent']);
const props = defineProps(['commentSection','attrId', 'disabled', 'noToolbar']);
const disabled = props.disabled || false;
const randomId = ref(Math.random().toString(36).substring(7));
const elementId = ref(props.attrId + randomId.value);

const initiaValue = ref(props.commentSection);

const router = useRouter()

const formats = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
  "formula",
];

watch(() => props.commentSection, (newVal, oldVal) => {
  if (
      newVal && 
      oldVal && 
      JSON.stringify(newVal) !== JSON.stringify(oldVal) && 
      JSON.stringify(quillSchema.value) !== JSON.stringify(newVal) &&
      newVal !== JSON.stringify(oldVal)

    ) {
    if (typeof newVal == 'string') {
      try {
        quill.setContents(JSON.parse(newVal));
      } catch (error) {
      }
    }
    else {
      quill.setContents(newVal);
    }
  }
})
const quillSchema = ref(null);
let quill = null;


onMounted(() => {
  const element = document.querySelector(`#${elementId.value}`);
  if (!element) {
    console.error('Element not found', elementId.value);
  }

    quill = new Quill(`#${elementId.value}`, {
      formats,
      theme: "snow",
      placeholder: !disabled ? 'Type here...' : '',

    });
    quill.enable(!disabled);

    quill.on("text-change", () => {
      console.log('text-change');
      let element = document.querySelector(`#${elementId.value} .ql-editor`);
      quillSchema.value = quill.getContents();
      // if (initiaValue.value != JSON.stringify(quill.getContents())) {
      emit('changeinput', {text: element.innerHTML, structure: quill.getContents() ,id: props.attrId});
      // }
    });
    if (props.commentSection) {
      if (typeof props.commentSection == 'string') {
        try {
          quill.setContents(JSON.parse(props.commentSection));
        } catch (error) {
        }
      }
      else {
        quill.setContents(props.commentSection);
      }
    }

});
</script>


<style lang="scss" scoped>
  #editor {
    height: calc(100vh - 400px);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .bg-white {
    background: white;
    background: white;
    border-radius: 10px;

  }

  .ql-toolbar {
    text-align: left;
  }

  .section {
    :nth-child(1) {
      text-align: left;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  } 
  .wrapper.disabled {
    opacity: 0.6;
    background-color: rgb(243, 243, 243);
  }

 
  // .wrapper.disabled .ql-toolbar {
  //   display: none;
  // }
  .pending-changes-container {
    input, select {
      border: 1px solid var(--primary-color);
    }
  }
  .pending-changes {
    position: absolute;
    right: -20px;
    top: 13px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      width: 18px;
      height: 18px;
    }
  }
</style>