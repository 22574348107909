<template>
    <div class="container structure-chart"  :class="`${hover_effect ? 'hover_effect': ''}`">
      
      <button class='download-img' v-if="!hover_effect" @click="DownloadAsImage">Download</button>
      <div class='wrapper-chart' :id="`structure-${id}`">
        <div class='main-el' :id="id"></div>
      </div>  
      <div v-if="hover_effect" class="hover-effect-area" @click="click">
        <button>Open Full Screen</button>
      </div>
    </div>
</template>

<script setup>
import { onMounted, ref, computed, watch, defineEmits } from "vue";
import ChartDrawer from "@/utils/chart_utils"
import html2canvas from "html2canvas";
import * as htmlToImage from 'html-to-image';
import { useStore } from "vuex";
const store = useStore();

const emit = defineEmits(["click"]);

const props = defineProps([
  'root',
  'shareholder_id',
  'type',
  'source',
  'mbd_id',
  'key_val',
  'name',
  'hover_effect',
  'scaleToFit',
])

const id = computed(() => `${props.type}-${props.mbd_id}-${props.shareholder_id}-${props.root}-${props.key_val}-${props.source}`)
const structure = computed( () => store.getters.getStructureForChart({
    type: props.type, 
    shareholder_id: props.shareholder_id,
    source: props.source,
    mbd_id: props.mbd_id,
    root: props.root,
}))

if (structure.value[0].someOtherStuff) {
  structure.value[0].children.push(...structure.value[0].someOtherStuff)
}

const width = ref(1000);
const height = ref(1000);
const treeData = ref({});

function click() {
    emit('click')
}
function DownloadAsImage() {

  const node = document.getElementById(`structure-${id.value}`);
  // const node = document.querySelector('.requirement');
  if (!node) {
    console.error("Element not found");
    return;
  }
  console.log('AAAAAAAAAA', node)
  // html2canvas(node, {
  //   scale: 2, // Increase quality
  //   useCORS: true, // Handle images properly
  //   backgroundColor: null, // Keep transparent background
  //   logging: true // Debugging purposes
  // }).then(canvas => {
  //   document.body.appendChild(canvas); // Append to body for debugging
  //   const link = document.createElement("a");
  //   link.href = canvas.toDataURL("image/png");
  //   link.download = "screenshot.png";
  //   link.click();
  // }).catch(error => console.error("Error rendering:", error));

  htmlToImage
  .toPng(node, { quality: 1 })
  .then(function (dataUrl) {
    var link = document.createElement('a');
    link.download = `${props.name.replaceAll(' ', '-')}.png`;
    link.href = dataUrl;
    link.click();
  });

}
onMounted(() => {
  renderChart();
})




function renderChart() {
  treeData.value = structure.value
  let chartDraw = new ChartDrawer('#' + id.value, treeData.value, props.scaleToFit, props.root, props.type);
  chartDraw.drawChart()
}

watch(structure, (newValue, oldValue) => {
  if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
    renderChart();
  }
}, { deep: true });

</script>

<style lang="scss">
  .download-img {
    
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: fit-content;
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1000;

  }

  .wrapper-chart {
    background: white;
  }
  .structure-chart {
    &.container {
      height: 100%;
      overflow: auto;
      margin: auto;
      width: 100%;
      position: relative;
      // padding: 20px;
      max-width: 100%;
      max-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .main-el {
      background: white;
      width: 100%;
    }
  
    .hover-effect-area {
      position: absolute;
      top: 0px;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      display: none;  
    }
    .hover_effect {
      width: fit-content;
      .main-el {
        margin-top: 20px;
        height: 200px;
        width: fit-content;
        padding: 20px;
        box-sizing: border-box;
      }
    }

    .main-el {
      position: relative;
      width: 100%;
    }
    .node {
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      z-index: 1;
    }
    .percentage {
      padding: 2px 5px;
      background-color: white;
      border-radius: 5px;
      font-size: 12px;
      color: black;
      border: 1px solid black;
      z-index: 1;
    }
    .node.official {
      box-shadow: none;
      background-color: none;
      background: unset;
    }
    .node.ubo {
      background-color: rgb(68, 114, 196);
      color: white;
      font-weight: bold;
      border: 2px solid rgb(13 72 174);
    }
    .line {
      position: absolute;
      height: 2px;
      background-color: black;
      transform-origin: left center;
    }
    .node.corporate_shareholder, .node.main_for_sh {
      background-color: rgb(68, 114, 196);
      color: white;
      font-weight: bold;
      border: 2px solid rgb(13 72 174);
      
      &.missing {

      }
    }
    .node.corporate {
      border: 2px solid rgb(179, 179, 179);
    }
    .node.missing {
      background-color: white;
      color: red;
      border: 2px solid red;
      font-weight: bold;
    }
    .node.missing.official {
      border: unset;
    }
    .coordinate {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 14px;
    }
    .moves-info {
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 14px;
    }
    
    &.hover_effect:hover {
      .main-el {
        filter: blur(1px);
      }
      .hover-effect-area {
        display: flex;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 10px;

        button {
          width: fit-content;
          &:hover {
            background: var(--primary-color);
            opacity: 0.85;
          }
        }
      }
    }
  }

</style>