import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import API from './api'
console.log('process.env', process.env)

import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";
import VueApexCharts from "vue3-apexcharts";

createApp(App).use(store).use(router).use(VueAwesomePaginate).use(VueApexCharts).mount('#app')


window.addEventListener('unhandledrejection', (event) => {
  API.postError({
    error_cought: event.reason.message,
    source: event.reason.stack,
    url: window.location.href,
  });
});

