<template>
    <div class="subsidiary-container">
        <div class="subsidiary-content">
            <div class="sub-header" >
                <h4 class="subsidiary-title">Sanction Screaning List</h4>
                <button @click="newRequestStart()">New Request</button>

            </div>
            <table class="subsidiary-list">
                <thead>
                    <tr>
                        <th>Search Info</th>
                        <th>Date</th>
                        <!-- <th>Role</th> -->
                    </tr>
                </thead>
                <tbody class='table-content' v-if="dataList">
                    <tr class="account-list" 
                        v-for="sanction in dataList" 
                        :key="sanction._id" 
                        @click="openSanction(sanction)">
                        <td class="user-info"> 
                                    <div class="user-name">
                                {{ JSON.parse(sanction.request).general.firstname }}
                                {{ JSON.parse(sanction.request).general.surname }}
                                {{ JSON.parse(sanction.request).general.dob.split('-').reverse().join('-') }}
                                <br>
                                {{ JSON.parse(sanction.request).general.nationality }}
                            </div>
                        </td>
                        <td>{{  Utils.renderTime(sanction.datetime) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    
        <PopupCover v-if="showPopups" @close="showPopups = false">
            <PopupBody @close="() => showPopups = false" v-if="!doingRequest"> 
                <template #header>Create New Sanction Screaning Request</template>
                <template #body>
                    <div>
                        <InpComponent
                            label="First Name"
                            type="text"
                            placeholder="Don"
                            :key_ref="'firstname'"
                            :value="sanctionObject.firstname.value"
                            :error="sanctionObject.firstname.error"
                            @inputChange="inputChangeFunc"
                        />
                        <InpComponent
                            label="Surname"
                            type="text"
                            placeholder="Jon"
                            :key_ref="'surname'"
                            :value="sanctionObject.surname.value"
                            :error="sanctionObject.surname.error"
                            @inputChange="inputChangeFunc"
                        />
                        <InpComponent
                            label="Date of Birth"
                            type="date"
                            placeholder="1990-10-10"
                            :key_ref="'dob'"
                            :value="sanctionObject.dob.value"
                            :error="sanctionObject.dob.error"
                            @inputChange="inputChangeFunc"
                        />
                        <InpComponent
                            class="country-select"
                            label="Nationality"
                            type="options"
                            :options="Utils && Utils.getCountryList()"
                            placeholder="Curacao"
                            :key_ref="'nationality'"
                            :value="sanctionObject.nationality.value"
                            :error="sanctionObject.nationality.error"
                            @inputChange="inputChangeFunc"
                        />
                        <div class="add-new-subsidiary-area">
                            <button class="save-button" @click="makeRequest()">Make Request</button>
                        </div>  
                    </div>
                </template>
            </PopupBody>
            <div class="container-loading" v-else>
                <div class="loader-header">Please Wait</div>
                <div class="loader" >
                    <img src="@/assets/web-loader.svg" width="60px" height="60px">
                </div>
                <div class="loader-text">Performing Sanction Screaning...</div>
            </div>

        </PopupCover>
        <PopupCover v-if="showSanctionScreeningPopup" @close="showSanctionScreeningPopup = false">
            <div class="sanction-screanin-response">
                <SanctionResponseItem 
                    :person_id="person_id"
                    :sanctioResponse="sanctionScreaning.data"
                    :search_id="sanctionScreaning.search_id"
                    :sanctionRequest="sanctionScreaning.request"
                    :isPopup="true"
                    @close="showSanctionScreeningPopup = false"
                    canBeSaved="false"
                />
            </div>
        </PopupCover>
    </div>
</template>
 
<script setup>

import { ref, defineEmits, computed, withDefaults, defineProps, watch, onMounted } from 'vue'

import API from '../api/index'
import store from '@/store/index.js';
import PopupCover from '@/components/PopupCover.vue';
import { useRouter, useRoute } from 'vue-router'
import InpComponent from './InpComponent.vue';
import PopupBody from './PopupBody.vue';

import SanctionResponseItem from './SanctionResponseItem.vue';

import Utils from '@/utils';
const router = useRouter();
const route = useRoute();

const dataList = ref(null);
const user = computed(() => store.state.user);
const emit = defineEmits(['delete_person', 'commentChange'])
const userInformation = ref(null);
const showPopups = ref(false);
const validationIsOn = ref(false);
const doingRequest = ref(false);
const showSanctionScreeningPopup = ref(false);

const sanctionScreaning = ref({
    data: null,
    request: null,
    search_id: null,
   
})

const sanctionObject = ref({
    firstname: {value: '', error: ''},
    surname: {value: '', error: ''},
    dob: {value: '', error: ''},
    nationality: {value: '', error: ''},
})

function newRequestStart(){
    showPopups.value = true;
}

function validateInputs(){
    let errors = false;
    sanctionObject.value.firstname.error = '';
    sanctionObject.value.surname.error = '';
    sanctionObject.value.dob.error = '';
    sanctionObject.value.nationality.error = '';

    if ((sanctionObject.value.firstname.value.trim()|| '').length < 3) {
        sanctionObject.value.firstname.error = 'First name must be at least 3 characters';
        errors = true;
    } 
    if ((sanctionObject.value.surname.value.trim()|| '').length < 3) {
        sanctionObject.value.surname.error = 'Surname must be at least 2 characters';
        errors = true;
    } 
    if (!sanctionObject.value.dob.value) {
        sanctionObject.value.dob.error = 'Date of birth is required';
        errors = true;
    }
    if (!sanctionObject.value.nationality.value) {
        sanctionObject.value.nationality.error = 'Nationality is required'; 
        errors = true;
    }
    return errors;
}


async function makeRequest() {
    validationIsOn.value = true;
    const validation = validateInputs();

    if (validation) {
        return;
    }

    const request = {
        firstname: sanctionObject.value.firstname.value,
        surname: sanctionObject.value.surname.value,
        dob: new Date(sanctionObject.value.dob.value).toISOString(),
        nationality: sanctionObject.value.nationality.value,
    }
    doingRequest.value = true;
    const response = await API.createNewSanctionRequest(request);
    if (!response?.data) {
        let error_message = 'There was an error while creating the request';
        if (response?.message) {
           error_message = response.message;
        }
        store.dispatch('addNotification', {
            type: 'error',
            text: error_message,
        });
        doingRequest.value = false;

        return;
    }
    sanctionScreaning.value.data = response.data;
    showSanctionScreeningPopup.value = true;
    doingRequest.value = false;
    alert('send here')
}


function closePopup() {
    console.log('close popup')
    if (doingRequest) {
        return
    }
    showPopups.value = false;
    userInformation.value = null;
    showSanctionScreeningPopup.value = false;
}

function openSanction(sanction) {
    // userInformation.value = account;
    // showPopups.value = true;
    router.push({name: 'sanction-screening-item', params: {id: sanction._id}})
}

function inputChangeFunc(event) {
    const event_type = event[0];
    const event_value = event[1];
    sanctionObject.value[event_type].value = event_value;
    if (validationIsOn.value) {
        validateInputs();
    }
}
onMounted(async() => {
    if (!user.value) {
        router.push('/login')
        return;
    }
    const response = await API.getSansctionScreeningList()
    dataList.value = response.data
});

</script>
 
<style scoped lang="scss">

    .icon-person {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 30px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 12px;
        font-weight: 600;
        height: 30px;
        color: var(--primary-color);
        background: rgb(236, 240, 255);
    }
    .user-info {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .subsidiary-container {
        width: 100%;
    }
    
    .subsidiary-content {
        min-width: 100%;
        border: 1px solid #ccc;
        border-radius: 10px;
        width: fit-content;
    }
    
    .sub-header {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        padding: 20px;
        padding-left: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
    }
    
    .subsidiary-title {
        display: flex;
        justify-content: space-between;
        color: black;
        font-size: 20px;
    }
    
    .sub-header {
        display: flex;
        justify-content: space-between;
        button {
            width: fit-content;
            padding: 10px;
        }
    }
    .subsidiary-list {
        padding: 20px;
        width: 100%;
        border-collapse: collapse;
        th, td {
            // border: 1px solid #ccc;
            padding: 15px;
            text-align: left;
            border: none;
            padding-left: 30px;
            color: black;
        }
        td {
            padding-top: 19px;
            padding-bottom: 19px;
        }
        th {
            text-align: left;
            border: none;
        }
        tr {
            border-top: 1px solid #ccc;
        }
        tbody {
            tr {
                cursor: pointer;
                td {
                    background: white;
                }

                &:last-child {
                    td {
                        &:first-child {
                            border-bottom-left-radius: 10px;
                        }
                        &:last-child {
                            border-bottom-right-radius: 10px;
                        }
                    }
                }
                &:hover {
                    td {
                        background: #f9f9f9;
                    }
                }
            }
        }
    }
    
    .add-new td{
        padding-top: 0px;
        padding-bottom: 0px;
    
    }
    .save-button {
        padding: 10px 20px;
        width: fit-content;
        margin-left: auto;
    }
    h4 {
        margin: 0;
    }
    .add-new-subsidiary-area {
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .text-input {
        &:deep(input) {
            text-align: center;
        }
    }
 
 .more-actions {
    padding: 5px 0px;
    border-radius: 4px;
    width: 35px;
    height: 25px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
     cursor: pointer;
     &:hover {
         background: #d9d9d9;
 
         .dropdown {
             display: flex;
             flex-direction: column;
 
         }
     }
     position: relative;
 
     .dropdown {
        display: none;
        position: absolute;
        top: 35px;
        right: 0;
        background: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        z-index: 100;
        div {
            padding: 10px;
            cursor: pointer;
            &:hover {
                background: #d9d9d9;
            }
        }
     }
}

.loader img {
  animation: rotate 1s linear infinite;
}
.loader-text {
  font-size: 18px;
  color: black;
}
@keyframes rotate {
  100% {
      transform: rotate(360deg);
  }
}
.container-loading  {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 200px;
  background-color: white;
}

.loader-header {
  font-size: 24px;
  color: black;
  font-weight: bold;
}
.sanction-screanin-response {
  height: 90vh;
  min-height: 400px;
  width: 70vw;
  overflow: auto;
  background-color: white;
  padding: 20px;
  border-radius: 0.5rem;
}
.upload-section {
  i {
    word-break: break-word;
  }
}
 
 </style>