<template>
    <div class="container w-full" :class="small ? 'small' : ''">
        <div class="progress-bar">
            <div class="progress-bar-fill" :style="{ width: `${percentage}%` }"></div>
            <div class="progress-bar-label">{{ percentage }}{{ label }}{{ text }}</div>
            <div class="progress-bar-label"></div>
        </div>
       
    </div>
</template>




<script setup>
    import { onMounted, ref, computed, watch } from "vue";

    const props = defineProps({
        current: {
            type: Number,
            default: 0
        },
        total: {
            type: Number,
            default: 0
        },
        label: {
            type: String,
            default: '%'
        },
        text: {
            type: String,
            default: ''
        },
        small: {
            type: Boolean,
            default: false
        }
    })
    const percentage = computed(() => {
        if (props.total === 0) return 0;
        return Math.round((props.current / props.total) * 100);
    });


</script>

<style lang="scss" scoped>

    .progress-bar {
        width: 100%;
        height: 20px;
        background-color: #e0e0e0;
        border-radius: 5px;
        padding: 2px;
        overflow: hidden;
        position: relative;
    }
    
 
    .progress-bar-fill {
        height: 100%;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: var(--primary-color);
        transition: width 0.3s ease-in-out;
    }

    .progress-bar-label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 13px;
        // color: black;
        font-weight: bold;
        color: white;
        mix-blend-mode: difference;
    }
    .small {
        .progress-bar {
            padding: 1px;
            height: 16px;
        }
    }
    * {
        box-sizing: border-box;
    }
</style>