<template>
    <div class="container">
        <div class="header">
            <slot name="header"></slot>
            <div class="cross" @click="closePopupConfirm()">
                <img src="@/assets/cross.svg" alt="cross"/>
            </div>
        </div>
        <slot name="body"></slot>
    </div>
</template>


<script setup>
    import { ref, defineEmits } from 'vue'
    
    const emit = defineEmits(['close']);
    function closePopupConfirm() {
        emit('close', false);
    }
</script>


<style scoped lang="scss">
 .container[class] {
        border-radius: .5rem;
        display: flex;
        flex-direction: column;
        width: 350px;
        border: 1px solid var(--border-color);
        min-height: 150px;
        padding: 20px;
        background-color: white;
    } 
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2rem;
        color: black;
    }
    .cancel-btn {
        margin-left: auto;
        margin-right: 10px;
    }
    .add-new-ticket-info {
        margin-top: auto;
        button {
            width: fit-content;
            padding: 10px 30px;
        }
    }
    .confirmation-buttons {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-top: auto;
    }
    .popup-body {
        max-height: 80vh;
        overflow-y: auto;
    }
</style>