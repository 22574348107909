<template>
    <div class="kyc-fields-area mb-40">
        <div class="chart-area w-full">
           
        </div>


        <div class="flex full-width gap-40 mb-40" v-if="localCompanyData">
            <div class=" flex column gap-40" style="flex: 2">
                <div class="p-20 bg-white">
                    <h3 class='mt-0' v-if="localCompanyData.company_type == 'corporate'"> Company Information </h3>
                    <h3 class='mt-0' v-else> Account Holder Information </h3>
                    <button 
                        @click="verifyReport"
                        class='mb-20' v-if="PermissionChecker.verifyReport() && !reviewMode">
                        Verify report
                    </button>
                    <div class="flex gap-40 full-width">
                        <div class="flex column flex-1">
                            <InpComponent
                                v-if="localCompanyData.company_type == 'corporate'"
                                class="country-select flex-1"
                                label="Company Type"
                                type="options"
                                :disabled="!canEditInputs"
                                :options="[{value: 'local', text: 'Onshore'}, {value: 'abroad', text: 'Offshore'}]"
                                placeholder="Company Type"
                                :key_ref="'location_type'"
                                :value="localCompanyData?.location_type.new_value || localCompanyData?.location_type.value"
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                v-else
                                class="country-select flex-1"
                                label="Client Type"company_id
                                type="options"
                                :disabled="!canEditInputs"
                                :options="[
                                    {value: 'bank', text: 'Bank'}, 
                                    {value: 'credit_union', text: 'Credit Union'},
                                    {value: 'pention', text: 'Pension'},
                                    {value: 'insurance', text: 'Insurance'},
                                    {value: 'player', text: 'Player'}
                                ]"
                                placeholder="Company Type"
                                :key_ref="'account_holder_type'"
                                :value="localCompanyData?.account_holder_type.new_value || localCompanyData?.account_holder_type.value"
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                v-for="(accountNumber, index) in accountNumbers"
                                :key="`account_holder_number_${accountNumber._id}`"
                                :disabled="!canEditInputs"
                                type="text" 
                                :key_ref="`account_number|${accountNumber._id}`"
                                :value="accountNumber.account_number.new_value || accountNumber.account_number.value" 
                                @inputChange="inputChangeFunc"
                                :label="getLabel"
                            />
                            <div @click="addAccountNumber()"
                                v-if="!reviewMode"
                                class="add-section flex align-center w-content">
                                <img src="@/assets/icon-addlink.svg"> Add Account Number
                            </div>
                            <InpComponent
                                v-if="localCompanyData.company_type == 'account_holder'"
                                :disabled="!canEditInputs"
                                type="text" 
                                key_ref="first_name"
                                :value="localCompanyData?.first_name.new_value || localCompanyData?.first_name.value" 
                                @inputChange="inputChangeFunc"
                                label="First Name"/>

                            <InpComponent
                                v-if="localCompanyData.company_type == 'account_holder'"
                                :disabled="!canEditInputs"
                                type="text" 
                                key_ref="last_name"
                                :value="localCompanyData?.last_name.new_value || localCompanyData?.last_name.value" 
                                @inputChange="inputChangeFunc"
                                label="Last Name"/>

                            <InpComponent
                                v-if="localCompanyData.company_type == 'corporate'"
                                :disabled="!canEditInputs"
                                type="text" 
                                key_ref="name"
                                :value="localCompanyData?.name.new_value || localCompanyData?.name.value" 
                                @inputChange="inputChangeFunc"
                                label="Name"/>

                            <InpComponent
                                v-if="localCompanyData.company_type == 'corporate'"
                                :disabled="!canEditInputs"
                                type="text" 
                                key_ref="chamber_of_commerce"
                                :value="localCompanyData?.chamber_of_commerce.new_value || localCompanyData?.chamber_of_commerce.value" 
                                @inputChange="inputChangeFunc"
                                label="Chamber of Commerce"/>
                            <InpComponent
                                v-if="localCompanyData.company_type == 'corporate'"
                                :disabled="!canEditInputs"
                                type="text" 
                                key_ref="activity"
                                :value="localCompanyData?.activity.new_value || localCompanyData?.activity.value" 
                                @inputChange="inputChangeFunc"
                                label="Activity"
                            />
                            <InpComponent
                                v-if="localCompanyData.company_type == 'corporate'"
                                label="Address Line 1"
                                type="text"
                                placeholder="123 Main St"
                                :key_ref="'address_line_1'"
                                :value="localCompanyData.address_line_1.value"
                                :error="localCompanyData.address_line_1.error"
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                v-if="localCompanyData.company_type == 'corporate'"
                                label="Address Line 2 (optional)"
                                type="text"
                                placeholder=""
                                :key_ref="'address_line_2'"
                                :value="localCompanyData.address_line_2.value"
                                :error="localCompanyData.address_line_2.error"
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                v-if="localCompanyData.company_type == 'corporate'"
                                label="City / Town"
                                type="text"
                                placeholder="London"
                                :key_ref="'city'"
                                :value="localCompanyData.city.value"
                                :error="localCompanyData.city.error"
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                v-if="localCompanyData.company_type == 'corporate'"
                                class="country-select"
                                label="Country"
                                type="options"
                                :options="Utils && Utils.getCountryList()"
                                placeholder="Canada"
                                :key_ref="'address_country'"
                                :value="localCompanyData.address_country.value"
                                :error="localCompanyData.address_country.error"
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                v-if="localCompanyData.company_type == 'corporate'"
                                label="Zip"
                                type="text"
                                placeholder="00000"
                                :key_ref="'zip'"
                                :value="localCompanyData.zip.value"
                                :error="localCompanyData.zip.error"
                                @inputChange="inputChangeFunc"
                            />
                        </div>
                    </div>
                </div>



                  <div class="flex column gap-40 w-full">
         
            
            <div class="flex column gap-40 h-fit"
                style="flex: 1;"
            >
                <div class="flex column section inner-section border-rounded-gray">
                    <div class="label-like"> Allow sanction screaning via third party API</div>
                    <div class="flex row gap-10 mb-15" >
                        <div class="flex align-center justify-center">
                            <input 
                                :disabled="!canEditInputs"
                                type="radio" 
                                id="YES-sanction_screening_available" 
                                style="margin: 0 5px"
                                name="sanction_screening_available"
                                value="YES" 
                                @change="handleChange('sanction_screening_available', 'YES')"
                            >
                            <label for="YES">YES</label>
                        </div>
                        <div class="flex">
                            <input 
                                :disabled="!canEditInputs"
                                type="radio" 
                                id="NO-sanction_screening_available" 
                                style="margin: 0 5px"
                                name="sanction_screening_available"
                                value="NO" 
                                @change="handleChange('sanction_screening_available', 'NO')"
                            >
                            <label for="NO">NO</label>
                        </div>
                    </div>
                </div>
                <div class="flex column section inner-section border-rounded-gray" v-if="localCompanyData.company_type !== 'account_holder'">
                    <div class="label-like"> Transaction Profile Present</div>
                    <div class="flex row gap-10 mb-15" >
                        
                        <div class="flex align-center justify-center">
                            <input 
                                :disabled="!canEditInputs"
                                type="radio" 
                                id="YES-transaction_profile_report" 
                                style="margin: 0 5px"
                                name="transaction_profile_report"
                                value="YES" 
                                @change="handleChange('transaction_profile_report', 'YES')"
                            >
                            <label for="YES">YES</label>
                        </div>

                        <div class="flex">
                            <input 
                                :disabled="!canEditInputs"
                                type="radio" 
                                id="NO-transaction_profile_report" 
                                style="margin: 0 5px"
                                name="transaction_profile_report"
                                value="NO" 
                                @change="handleChange('transaction_profile_report', 'NO')"
                            >
                            <label for="NO">NO</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>





            </div>
            <div class="flex column gap-40" style="flex: 3">
                <!-- v-if="localCompanyData && localCompanyData.company_type == 'corporate'" -->
                <ChartComponent
                    v-if="localCompanyData && localCompanyData.company_type == 'corporate'"
                    :dataStructure="props.dataStructure"
                    class="flex justify-center align-center"
                ></ChartComponent>
                <div class="flex gap-40">
                    <ChartComponent
                        v-if="localCompanyData && localCompanyData.company_type == 'account_holder'"
                        :dataStructure="props.dataStructure"
                        class="flex justify-center align-center"
                    ></ChartComponent>

                    <div class="section inner-section flex-1 kyc-review-date border-rounded-gray">
                        <h3>Kyc Review</h3>
                        <div class="flex column">
                            <InpComponent
                                :disabled="!canEditInputs"
                                type="date" 
                                
                                label="Date previous compliance review"
                                key_ref="previous_review_date"
                                :value="localCompanyData?.previous_review_date.new_value || localCompanyData?.previous_review_date.value" 
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                :disabled="!canEditInputs || currentNextReviewMode == 'automatic'"
                                type="date"  
                                :key="`next_review_date_input`"
                                :label="`Date proposed compliance review ${currentNextReviewMode != 'manual' ? '(calculated automatically)' : ''}`"
                                key_ref="next_review_date"
                                :value="localCompanyData?.next_review_date.new_value || localCompanyData?.next_review_date.value" 
                                @inputChange="inputChangeFunc"
                            />
                            <div v-if="!reviewMode" @click="changeReviewDateCalculation()" class="add-section flex align-center w-content">
                                <img class='flip' src="@/assets/flip.svg"> 
                                <text v-if="currentNextReviewMode == 'automatic'">
                                    Set Review date manually
                                </text>
                                <text v-else>
                                    Set Review date automatically
                                </text>
                            </div>
                            <InpComponent
                                :disabled="!canEditInputs"
                                type="date" 
                                
                                label="Date final compliance review"
                                key_ref="date_final_compliance_review"
                                :value="localCompanyData?.date_final_compliance_review.new_value || localCompanyData?.date_final_compliance_review.value" 
                                @inputChange="inputChangeFunc"
                            />
                        </div>
                    </div>
                   

                <div v-if="localCompanyData.company_type !== 'account_holder'" class="section inner-section flex-1 border-rounded-gray">
                        <h3 class="">Annual Accounts</h3>
                        <div class="flex column">
                            <div class="label-like"> Annual accounts confirm calendar year</div>
                            <div class="flex row gap-10 mb-15" >
                                <div class="flex align-center justify-center">
                                    <input 
                                        :disabled="!canEditInputs"
                                        type="radio" 
                                        id="YES-annual_account_conform_calendar_year" 
                                        style="margin: 0 5px"
                                        name="annual_account_conform_calendar_year"
                                        value="YES" 
                                        :checked="localCompanyData?.annual_account_conform_calendar_year == 'YES'" 
                                        @change="handleChange('annual_account_conform_calendar_year', 'YES')"
                                    >
                                    <label for="YES-annual_account_conform_calendar_year">YES</label>
                                </div>
                                <div class="flex">
                                    <input 
                                        :disabled="!canEditInputs"
                                        type="radio" 
                                        id="NO-annual_account_conform_calendar_year" 
                                        style="margin: 0 5px"
                                        name="annual_account_conform_calendar_year"
                                        value="NO" 
                                        :checked="localCompanyData?.annual_account_conform_calendar_year == 'NO'" 
                                        @change="handleChange('annual_account_conform_calendar_year', 'NO')"
                                    >
                                    <label for="NO-annual_account_conform_calendar_year">NO</label>
                                </div>
                            </div>
                            <InpComponent
                                :disabled="!canEditInputs"
                                type="options"
                                :options="yearOptions"  
                                label="Current fiscal year"
                                key_ref="current_fiscal_year"
                                :value="localCompanyData?.current_fiscal_year.new_value || localCompanyData?.current_fiscal_year.value" 
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                :disabled="!canEditInputs"
                                v-if="(localCompanyData?.annual_account_conform_calendar_year.new_value || localCompanyData?.annual_account_conform_calendar_year.value) == 'NO'"
                                type="date"
                                label="Fiscal Year runs from"
                                key_ref="fiscal_year_starts_on"
                                :value="localCompanyData?.fiscal_year_starts_on.new_value || localCompanyData?.fiscal_year_starts_on.value" 
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                :disabled="!canEditInputs"
                                v-if="(localCompanyData?.annual_account_conform_calendar_year.new_value || localCompanyData?.annual_account_conform_calendar_year.value) == 'NO'"
                                type="date"
                                label="Fiscal Year runs up till"
                                key_ref="fiscal_year_ending_on"
                                :value="localCompanyData?.fiscal_year_ending_on.new_value || localCompanyData?.fiscal_year_ending_on.value" 
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                :disabled="!canEditInputs"
                                type="options"
                                :options="yearOptions"  
                                label="Last Annual Accounts on file"
                                key_ref="last_annual_account_on_file"
                                :value="localCompanyData?.last_annual_account_on_file.new_value || localCompanyData?.last_annual_account_on_file.value" 
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                :disabled="!canEditInputs"
                                type="date"
                                label="Last General Meeting of Shareholders"
                                key_ref="last_board_meeting"
                                :value="localCompanyData?.last_board_meeting.new_value || localCompanyData?.last_board_meeting.value" 
                                @inputChange="inputChangeFunc"
                            />
                    </div>
                </div>
                </div>
                <div class="flex gap-40">
                <div v-if="localCompanyData.company_type !== 'account_holder'" class="section inner-section flex-1 border-rounded-gray">
                    <h3>Profit Tax</h3>
                    <div class="flex column">
                        <InpComponent
                            :disabled="!canEditInputs"
                            type="options"
                            :options="yearOptions"                               
                            label="Last filed Profit Tax on file "
                            key_ref="last_filed_profit_tax_on_file"
                            :value="localCompanyData?.last_filed_profit_tax_on_file.new_balue || localCompanyData?.last_filed_profit_tax_on_file.value" 
                            @inputChange="inputChangeFunc"
                        />
                        <div class="label-like"> Extension Profit Tax</div>

                        <div class="flex row gap-10 mb-15" >
                            <div class="flex align-center justify-center">
                                <input 
                                    :disabled="!canEditInputs"
                                    type="radio" 
                                    id="YES-extension_profit_tax" 
                                    style="margin: 0 5px"
                                    name="extension_profit_tax"
                                    value="YES" 
                                    @change="handleChange('extension_profit_tax', 'YES')"
                                >
                                <label for="YES">YES</label>
                            </div>
                            <div class="flex">
                                <input 
                                    :disabled="!canEditInputs"
                                    type="radio" 
                                    id="NO-extension_profit_tax" 
                                    style="margin: 0 5px"
                                    name="extension_profit_tax"
                                    value="NO" 
                                    @change="handleChange('extension_profit_tax', 'NO')"
                                >
                                <label for="NO">NO</label>
                            </div>
                        </div>


                        <InpComponent
                            :disabled="!canEditInputs"
                            v-if="(localCompanyData?.extension_profit_tax.new_value || localCompanyData?.extension_profit_tax.value) == 'YES'"
                            type="date"
                             
                            label="Extension up till"
                            key_ref="extension_up_till"
                            :value="localCompanyData?.extension_up_till.new_value || localCompanyData?.extension_up_till.value" 
                            @inputChange="inputChangeFunc"
                        />
                    </div>
                </div>
                <div class="section inner-section risk-section flex-1 border-rounded-gray">
                    <h3 class="header">Risk Classification</h3>
                    <div class="flex row gap-10 sections">
                        <div class="flex align-center">
                            <input class='mt-0' :disabled="!canEditInputs" type="radio" id="low-risk_level" name="risk_level" value="low" @change="handleChange('risk_level', 'low')">
                            <label for="low">LOW</label>
                        </div>
                        <div class="flex align-center">
                            <input class='mt-0' :disabled="!canEditInputs" type="radio" id="medium-risk_level" name="risk_level" value="medium" @change="handleChange('risk_level', 'medium')">
                            <label for="medium">MEDIUM</label>
                        </div>
                        <div class="flex align-center">
                            <input class='mt-0' :disabled="!canEditInputs" type="radio" id="high-risk_level" name="risk_level" value="high" @change="handleChange('risk_level', 'high')">
                            <label for="high">HIGH</label>
                        </div>
                    </div>
                </div>
                <div class="flex column section inner-section border-rounded-gray flex-1" v-if="localCompanyData.company_type == 'account_holder'">
                    <div class="label-like"> Transaction Profile Present</div>
                    <div class="flex row gap-10 mb-15" >
                        
                        <div class="flex align-center justify-center">
                            <input 
                                :disabled="!canEditInputs"
                                type="radio" 
                                id="YES-transaction_profile_report" 
                                style="margin: 0 5px"
                                name="transaction_profile_report"
                                value="YES" 
                                @change="handleChange('transaction_profile_report', 'YES')"
                            >
                            <label for="YES">YES</label>
                        </div>

                        <div class="flex">
                            <input 
                                :disabled="!canEditInputs"
                                type="radio" 
                                id="NO-transaction_profile_report" 
                                style="margin: 0 5px"
                                name="transaction_profile_report"
                                value="NO" 
                                @change="handleChange('transaction_profile_report', 'NO')"
                            >
                            <label for="NO">NO</label>
                        </div>
                    </div>
                </div>
                
            </div>

               

            </div>
        
        </div>
      
        <PopupCover v-if="reportSave">
            <LoaderPopup />
        </PopupCover>

        <PopupCover v-if="showPopups" @close="showPopups = false">
            <SnapshotPopup 
                @close="showPopups = false" 
                :companySelector="false"
                @InfoUpdate="createObjectPopupHandler"
            />
        </PopupCover>
    </div>

</template>

<script setup>

import InpComponent from '@/components/InpComponent.vue'
import { PermissionChecker } from '@/utils/iam_utils'
import Utils from '@/utils/index.js'
import { ref, defineEmits, onMounted, computed } from 'vue' 
import LoaderPopup from '@/components/LoaderPopup.vue'
import SnapshotPopup from '@/components/SnapshotPopup.vue'
import PopupCover from '@/components/PopupCover.vue'

import ChartComponent from '@/components/ChartComponent.vue'

import API from '@/api/index.js'
import store from '@/store'

const emit = defineEmits(['changeCompanyData'])

const yearOptions = computed(() => store.state.yearOptions);
const reportSave = ref(false);
const showPopups = ref(false);

const props = defineProps(['companyData', 'yearLabel', 'reviewMode']);
const localCompanyData = computed(() => store.state.companyData);
const canEditInputs = computed(() => PermissionChecker.updateDashboardSection() && !props.reviewMode)
const user = computed(() => store.state.user)
const accountNumbers = computed(() => store.state.kyc_accountNumbers)

const currentNextReviewMode = computed(() => {
    return localCompanyData.value.next_review_date_type.new_value || localCompanyData.value.next_review_date_type.value;
})
const getLabel = computed(() => {
    const labelsDict = {
        'bank': 'Account Number',
        'credit_union': 'Account Number',
        'pention': 'Pension Number',
        'insurance': 'Policy Number',
        'player': 'Player ID'
    }
    return labelsDict[localCompanyData.value.account_holder_type] || 'Account Holder Number'
})


async function createObjectPopupHandler(event) {
    showPopups.value = false;
    reportSave.value = true;
    await API.completeReport({company_id: localCompanyData.value._id, ...event.object})
    reportSave.value = false;
}


async function verifyReport(object){
    showPopups.value = true;
}

const locationType = computed(() => {
    if (localCompanyData.value.location_type.new_value) {
        return localCompanyData.value.location_type.new_value;
    } else {
        return localCompanyData.value.location_type.value;
    }
})

function inputChangeFunc(event) {
    const event_type = event[0];
    const event_value = event[1];
    const parts = event_type.split('|');
    if (parts.length > 1 && parts[0] === 'account_number') {
        store.commit('updateAccountNumberById', {
            id: parts[1], 
            updates: {
                account_number: event_value
            }
        })
        return;
    }
    store.commit('updateKycReviewInfo', {
        id: localCompanyData.value._id, 
        updates: {
            [event_type]: event_value
        }
    })
    if (event_type == 'previous_review_date' && currentNextReviewMode.value == 'automatic') {
        let new_date = getNextReviewDate();
        if (new_date) {
            store.commit('updateKycReviewInfo', {
                id: localCompanyData.value._id, 
                updates: {
                    next_review_date: new_date
                }
            })
        }
    }
}


function getNextReviewDate(){
    let prevDate = localCompanyData.value.previous_review_date.new_value || localCompanyData.value.previous_review_date.value; 
    if (!prevDate) {
        return;
    }
    let current_risk_level = localCompanyData.value.risk_level.new_value || localCompanyData.value.risk_level.value;
    let next_review_date_map = {
        'low': 3,
        'medium': 2,
        'high': 1
    };
    let nextDate = new Date(prevDate);
    nextDate.setFullYear(nextDate.getFullYear() + next_review_date_map[current_risk_level]);
    store.commit('updateKycReviewInfo', {
        id: localCompanyData.value._id, 
        updates: {
            next_review_date: nextDate.toISOString().split('T')[0]
        }
    })
}


function changeReviewDateCalculation(){
    const new_value = currentNextReviewMode.value == 'automatic' ? 'manual' : 'automatic'; 
    store.commit('updateKycReviewInfo', {
        id: localCompanyData.value._id, 
        updates: {
            next_review_date_type: new_value
        }
    })
    if (new_value == 'automatic') {
        getNextReviewDate();
    }
}

function handleChange(field, event) {
    store.commit('updateKycReviewInfo', {
        id: localCompanyData.value._id, 
        updates: {
            [field]: event
        }
    })
    if (field == 'risk_level' && currentNextReviewMode.value == 'automatic') {
        getNextReviewDate();
    }
}

function addAccountNumber(){
    console.log('addAccountNumber')
    store.dispatch('addAccountNumber', {
        id: localCompanyData.value._id
    })
}

onMounted(() => {
    const fields = [
        'sanction_screening_available',
        'risk_level',
        'extension_profit_tax',
        'annual_account_conform_calendar_year',
        'sanction_screening_available',
        'transaction_profile_report',
    ];
    for (let field of fields) {
        if (localCompanyData.value[field].value) {
            const element_id = `${localCompanyData.value[field].value}-${field}`;
            let element = document.getElementById(`${element_id}`);
            if (element) {
                element.checked = true;
            }
       }
    }
})

</script>

<style lang="scss" scoped>
    .add-section {
        text-align: right;
        color: var(--primary-color);
        font-size: 12px;
        margin-top: -12px;
        margin-bottom: 12px;
        justify-content: flex-end;
        gap: 5px;
        margin-left: auto;
        cursor: pointer;
    }
    .table-users {
        width: 100%;
        border-collapse: collapse;
        border-bottom: 1px solid #e6eaed;
    }
    .header {
        margin-bottom: 0;
    }
    .risk-section {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .section {
        background: white;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        border: 1px solid var(--border-color);
        box-sizing: border-box;
    }
    .sections {
        padding-top: 10px;
    }
    .kyc-fields-area {
        // border: 1px solid #e0e0e0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        // background-color: white;
        box-sizing: border-box;

        border-radius: 5px;
        color: black;
    }
    .flip {
        height: 15px;
    }
    h3 {
        text-align: left;
    }

    td {
        padding: 10px 30px;
        text-align: left;
    }
    th {
        text-align: left;
        padding: 10px 30px;
        background: #F3F5F6;
    }

    .address[class] {
        max-width: unset;
        
    }
    .border-rounded-gray {
        border: 1px solid var(--border-color);
        h3 {
            text-align: left;
            margin-top: 0;
        }
    }
    .chart-area {
        padding-bottom: 20px;
    }
    .seciont-info {
        background-color: white;
        padding: 20px;
        border: 1px solid var(--border-color);
        border-radius: 5px;
    }
    .bg-white {
        background-color: white;
        border: 1px solid var(--border-color);
        border-radius: 5px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        min-height: 100px;
    }
    
</style>