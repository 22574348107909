<template>
    <div>

        <div  v-if="loading">
            Loading
        </div>
         
        <div class='flex w-full gap-5' v-else>
            <div class="flex-1">
                <UBO2
                    :ubo_id="reviewAction.ubo_related"
                    :key="`index_ubo-${reviewAction.ubo_related}`"
                    :reviewMode="true"
                />
            </div>
        </div>
    </div>
</template>

<script setup >

import { ref, computed, onMounted, watch } from 'vue'
import UBO2 from '@/views/UBO2.vue'
import dayjs from 'dayjs';

import { useStore } from 'vuex';

import API from '@/api/index.js'

const props = defineProps(['reviewAction', 'view'])
const store = useStore();
const ubo = ref(null);

const loading = ref(true);
onMounted(async() => {
    const uboResponse = await API.getUBOById(props.reviewAction.ubo_related);
    loading.value = false;

    if (!uboResponse.status == 200) {
        return;
    }
    ubo.value = uboResponse.ubo;
    store.state.kyc_files = [];
    store.state.kyc_persons = [];
    store.state.kyc_ubos = [];

    store.state.kyc_files.push(...uboResponse.files);
    store.state.kyc_persons.push(uboResponse.person);
    store.state.kyc_ubos.push(uboResponse.ubo);
});



function renderTime(time) {
    if (!time) return '';
    return dayjs(time).format('MMM DD, YYYY [at] HH:mm');
}


</script>

<style lang="scss" scoped>
</style>