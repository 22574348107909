<template>
    <div class="wrapper-main">
        <div class="flex column gap40">
            <div class="type-selector" v-if="!sectionMD.share_holder_related">
                <InpComponent
                    class="country-select"
                    label="Origin"
                    type="options"
                    :options="[{value: 'local', text: 'Resident'}, {value: 'abroad', text: 'Non-resident'}]"
                    placeholder="Local"
                    :pendingChanges="hasChanges('location_type')"
                    :disabled="!canBeUpdated"
                    :key_ref="'location_type'"
                    :value="sectionMD.location_type.new_value || sectionMD.location_type.value"
                    @inputChange="inputChangeFunc"
                ></InpComponent>

                <InpComponent 
                    class="country-select"
                    label="Director Type"
                    type="options"
                    :options="[{value: 'person', text: 'Individual'}, {value: 'entity', text: 'Legal entity'}]"
                    placeholder="Local"
                    :pendingChanges="hasChanges('entity_type')"
                    :disabled="!canBeUpdated"
                    :key_ref="'entity_type'"
                    :value="sectionMD.entity_type.new_value || sectionMD.entity_type.value"
                    @inputChange="inputChangeFunc"
                ></InpComponent>
                <div class='flex row gap-5 h-content align-start' style="margin-left: auto" >
                    <button title='collapse & expand section' class='transparent w-content mb-15 button-holder' @click="toggleSection('')">
                        <img class="arrow-opener-section" :class="isOpen ? 'section-opened': ''" src="@/assets/arrow-opener.svg">
                    </button>

                    <button class='delete-bin transparent' @click="DeleteMBD()" v-if="canBeDeleted">
                        <img class="delete-section" src="@/assets/delete.svg">
                    </button>
                </div>
            </div>
            <div class="w-full content-collapse" :id="'collapse_' + sectionMD._id">
               
                <div v-if="getCurrentEntityType != 'person'">
                    <EntityView2
                        :entity_id="entityOfMD._id"
                        :key="`${entityOfMD._id}_entity_view`"
                        :reviewMode="props.reviewMode"
                    />
                </div>
                <div class='officials margin-top-20' v-if="!sectionMD.share_holder_related" :id="`id-officials${sectionMD._id}`">
                    <div class="flex row align-center space-between">
                        <h3 class="doc-header">Official(s) </h3>
                        <div class="flex row gap-5 h-content align-start mb-20">
                            <button title='collapse & expand section' class='transparent w-content button-holder' @click="toggleSection('officials_')">
                                <img class="arrow-opener-section" :class="isOpenOfficial ? 'section-opened': ''" src="@/assets/arrow-opener.svg">
                            </button>
                            <button v-if="PermissionChecker.creatembdSection() && !reviewMode" class="add-new no-margin fit-content" @click="addNewElementFunc('official')">Add Official</button>
                        </div>
                    </div>
                    <div class="person-wrapper content-collapse" :id="'collapse_officials_' + sectionMD._id">
                        <PersonComp2
                            v-for="(person, index ) in offiсials"
                            :person_id="person._id"
                            :key="`${person.id}_person_view`"
                            :bank_reference_letter="true"
                            :world_check="true"
                            :google_search="true"
                            :reviewMode="props.reviewMode"
                        ></PersonComp2>
                    </div>
                </div>
                <div v-else class='officials margin-top-20' :id="`id-md${sectionMD._id}`">
                    <div class="flex row align-center space-between mb-20">
                        <h3 class="doc-header">Managing Board of Director(s) <i>{{ `${parentShareholderName ? ' of ' + parentShareholderName: ''}`}}</i> </h3>
                        <div class='flex row gap-5' style="margin-left: auto">
                            <button title='collapse & expand section' class='transparent w-content button-holder' @click="toggleSection('md_')">
                                <img class="arrow-opener-section" :class="isOpenMD ? 'section-opened': ''" src="@/assets/arrow-opener.svg">
                            </button>
                            <button v-if="PermissionChecker.creatembdSection() && !reviewMode" class="add-new no-margin fit-content" @click="addNewElementFunc('md')">Add Managing Director</button>
                        </div>
                    </div>
                    <div class="person-wrapper flex column gap-20 content-collapse" :id="'collapse_md_' + sectionMD._id">
                        <ManagingDirrector2 
                            v-for="md in shareHolderMd"
                            :key="`index_mbd-${md._id}`"
                            :md_id="md._id"
                            :reviewMode="props.reviewMode"
                        />
                    </div>
                </div>
                <div v-if="getCurrentEntityType != 'person' && ((!isSupervised && isAbroad) || (sectionMD.share_holder_related))" class='officials margin-top-20'
                    :id="'id-sh-h' + sectionMD._id"
                >
                    <div class="flex row align-center mb-20">
                        <div class="flex column gap-5px">
                            <h3 class="doc-header">
                                Share Holders that own more than 10% of <i>{{ `${parentShareholderName ? ' ' + parentShareholderName: 'the company'}`}}</i>
                            </h3>
                            <ProgressBar
                                v-if="shareholders.length"
                                :total="100"
                                :current="totalSharesReported"
                                :text="' of Shares Reported'"
                                />
                        </div>
                        <div class='flex row gap-5' style="margin-left: auto">

                            <button title='collapse & expand section' class='transparent w-content button-holder' @click="toggleSection('shareholder_')">
                                <img class="arrow-opener-section" :class="isOpenShareholder ? 'section-opened': ''" src="@/assets/arrow-opener.svg">
                            </button>

                            <button 
                                v-if="PermissionChecker.creatembdSection() && !props.reviewMode" 
                                class="add-new no-margin fit-content" 
                                @click="addNewElementFunc('shareholder')">
                                Add ShareHolder
                            </button>
                        </div>
                    </div>
                    <div class="flex column gap-20 content-collapse" :id="'collapse_shareholder_' + sectionMD._id">
                        <Shareholder v-for="(shareholder, index) in shareholders"
                            :shareholder_id="shareholder._id"
                            :key="`index_shareholder-${shareholder._id}`"
                            :reviewMode="props.reviewMode"
                        />
                    </div>

                </div>
            </div> 
        </div> 

        <AddNewElement
            v-if="addNewElement"
            :type="addNewElement"
            :metaInfo="{
                mbd_related: props.md_id,
                company_id: currentSectionId,
                remaining_shares: addNewElement == 'shareholder' ? 100 - totalSharesReported : null,
            }"
            @close="addNewElement = null"
        />
        <PopupCover v-if="deleteObject" @close="() => deleteObject = !deleteObject">
            <ConfirmationPopup 
                @confirm="popupConfirm"
                @cancel="() => deleteObject = !deleteObject"
                :title="'Delete Managing Director'"
                :message="'Are you sure you want to delete this Managing Director? All the data related to this Managing Director will be deleted.'"
            />
        </PopupCover>
    </div>
</template>

<script setup>
    import InpComponent from '@/components/InpComponent.vue'
    import ProgressBar from '@/components/ProgressBar.vue'
    
    import Utils from '@/utils/index.js'
    import { useStore } from 'vuex'
    import { ref, computed, defineProps, defineEmits, onMounted } from 'vue'
    import PersonComp2 from './PersonComp2.vue'
    import EntityView2 from './EntityView2.vue'
    import Shareholder from '@/components/Shareholder.vue'
    import ManagingDirrector2 from '@/components/ManagingDirrector2.vue'
    import ConfirmationPopup from './ConfirmationPopup.vue'
    import PopupCover from './PopupCover.vue'

    import API from '../api/index'
    import { PermissionChecker } from '@/utils/iam_utils'
    import AddNewElement from './addNewElement.vue'
    const store = useStore()
    const user = computed(() => store.state.user)

    const deleteObject = ref(false);
    const addNewElement = ref(null);

    const props = defineProps([
        'md_id',
        'mbd', 
        'preview',
        'reviewMode',
    ])
    const isOpen = computed(() => store.getters.getToggleState(props.md_id))
    const isOpenOfficial = computed(() => store.getters.getToggleState('officials_' + props.md_id))
    const isOpenMD = computed(() => store.getters.getToggleState('md_' + props.md_id))
    const isOpenShareholder = computed(() => store.getters.getToggleState('shareholder_' + props.md_id))


    const sectionMD = computed(() => store.getters.getMdById(props.md_id))

    const HTMLElements = computed(() => {
        return document.getElementById('collapse_' + sectionMD.value._id);
    })
    const HTMLElementOfficial = computed(() => {
        const id = 'collapse_' + 'officials_' + props.md_id;
        return document.getElementById(id);
    })
    const HTMLElementMD = computed(() => {
        const id = 'collapse_' + 'md_' + props.md_id;
        return document.getElementById(id);
    })
    const HTMLElementShareholder = computed(() => {
        const id = 'collapse_' + 'shareholder_' + props.md_id;
        return document.getElementById(id);
    })
   

    const offiсials = computed(() => store.getters.getOfficialsOfMd(props.md_id))
    const entityOfMD = computed(() => store.getters.getEntityOfMD(props.md_id))
    const shareholders = computed(() => store.getters.shareholders(props.md_id))
    const shareHolderMd = computed(() => store.getters.getShareholderMDs(props.md_id))
    
    const totalSharesReported = computed(() => {
        let total = 0;
        shareholders.value.forEach(shareholder => {
            if (shareholder.percentage_held.value) {
                total += parseFloat(shareholder.percentage_held.value || 0);
            }
        })
        console.log('totalSharesReported', total)
        return total;
    })

    const parentShareholderName = computed(() => {
        if (!entityOfMD.value) return ''
        return entityOfMD.value?.name?.new_value || entityOfMD.value?.name?.value
    })

    const currentSectionId = computed(() => store.state.currentSectionId)

    const getCurrentLocationType = computed(() => (sectionMD.value['location_type'].new_value || sectionMD.value['location_type'].value))
    const getCurrentEntityType = computed(() => (sectionMD.value['entity_type'].new_value || sectionMD.value['entity_type'].value))
    const canBeUpdated = computed(() => PermissionChecker.updatembdSection() && !props.reviewMode)
    const canBeDeleted = computed(() => {
    
        return PermissionChecker.deletembdSection() && !props.reviewMode && !sectionMD.value.share_holder_related
    })


    const notificationPresent = computed(() => props.reviewMode ?  [] : store.getters.getActionRequired(props.md_id, 'mbd_related'))
    const hasChanges = computed(() => (field) => {
        for (const item of notificationPresent.value) {
            let fields = JSON.parse(item.fields); 
            if (item.actionType == 'remove') {
                return true;
            }
            if (fields[field]?.new) {
                return true
            }
        }
    })

    const isSupervised = computed(() => {
        return (entityOfMD.value.supervised.new_value || 
            (entityOfMD.value.supervised.value.toString() == 'true' && entityOfMD.value.supervised.new_value === '')
        )
    })
    const isAbroad = computed(() => {
        return (
            (sectionMD.value.location_type.new_value === 'abroad') ||
            (sectionMD.value.location_type.value === 'abroad' && sectionMD.value.location_type.new_value === '' )
        )
    })


    function inputChangeFunc(event) {
     
        const event_type = event[0];
        const event_value = event[1];

        store.commit('updateManagingDirrectorById',{
            id: props.md_id, 
            updates: {
                [`${event_type}`]: event_value,
            }
        })
    }

    function addNewElementFunc(type) {
        addNewElement.value = type
    }
    function DeleteMBD(){
        deleteObject.value = true
    }
    async function popupConfirm(){
        await store.dispatch('deleteManagingDirector', {
            md_id: props.md_id, 
        })
    }
    function toggleSection(type = '') {
        store.dispatch('toggleSection', {
            id: type + props.md_id, 
        })
    }
    
    function adjustHeight() {
      
        if (isOpen.value) {
            HTMLElements.value.style.maxHeight = "none"; // Prevent transition issues
            HTMLElements.value.style.overflowY = "visible"; // Prevent overflow
        } 
        else {
            HTMLElements.value.style.maxHeight = "0"; // Collapse the section
            HTMLElements.value.style.overflowY = "hidden"; // Hide overflow
        }

        if (HTMLElementOfficial.value) {
            if (isOpenOfficial.value) {
                HTMLElementOfficial.value.style.maxHeight = "none"; // Prevent transition issues
                HTMLElementOfficial.value.style.overflowY = "visible"; // Prevent overflow
            } 
            else {
                HTMLElementOfficial.value.style.maxHeight = "0"; // Collapse the section
                HTMLElementOfficial.value.style.overflowY = "hidden"; // Hide overflow
            }
        }
      
        if (HTMLElementMD.value) {
            if (isOpenMD.value) {
                HTMLElementMD.value.style.maxHeight = "none"; // Prevent transition issues
                HTMLElementMD.value.style.overflowY = "visible"; // Prevent overflow
            } 
            else {
                HTMLElementMD.value.style.maxHeight = "0"; // Collapse the section
                HTMLElementMD.value.style.overflowY = "hidden"; // Hide overflow
            }
        }
        
        if (HTMLElementShareholder.value) {
            if (isOpenShareholder.value) {
                HTMLElementShareholder.value.style.maxHeight = "none"; // Prevent transition issues
                HTMLElementShareholder.value.style.overflowY = "visible"; // Prevent overflow
            } 
            else {
                HTMLElementShareholder.value.style.maxHeight = "0"; // Collapse the section
                HTMLElementShareholder.value.style.overflowY = "hidden"; // Hide overflow
            }
        }
        
    }

    onMounted(() => {
        adjustHeight()
    })

</script>

<style scoped lang="scss">
.officials {
    border-radius: 5px;
    padding: 20px;
    background-color: var(--app-background);
    border: 1px solid var(--border-color);
}


.type-selector {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.wrapper-main {
    border-radius: 5px;
    background-color: white;
    max-width: 1350px;
    // width: 100%;
    margin: auto;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
}

.header-section {
        background-color: white;
        height: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .kyc-header {
        max-width: 1350px;
        width: 100%;
        text-align: left;
        font-size: 28px;
    }
    .kyc-fields-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .kyc-fields-area {
        max-width: 700px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: baseline;

    }
    .kyc-item-section {
        width: 700px;
        margin-bottom: 30px;
        h3 {
            text-align: left;
        }
        &.extra-margin {
            margin-bottom: 50px;
        }
    }
    .flex {
        display: flex;
        &.row {
            flex-direction: row;
        }
        &.column {
            flex-direction: column;
        }
    }
    .passport-fields {
        margin-top: 20px;
    }
    .gap30 {
        gap: 30px;
    }
    .person-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    textarea {
        resize: none; 
        box-sizing: border-box;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        width: 685px;
        height: 150px;
    }
    .add-new {
        margin-left: auto;
    }
    .margin-top-20 {
        margin-top: 20px;
    }
    .margin-top-40 {
        margin-top: 40px;
    }
    .entity_person_header {
        width: 100%;
        text-align: left;
    }
</style>