<template>
    <div>
        <div  v-if="loading">
            Loading
        </div>
        <div class='flex w-full gap-5' v-else>
            <div class='flex w-full gap-5'>
                <div class="flex-1">
                    <h3 class="header-small">
                        <text v-if="view == 'forConfirmation'">Current Version</text>
                        <text v-else>Previous Version</text>
                    </h3>
                    <div class="info-holder current-version">
                        <p class="field-label">General Fields</p>

                        <InpComponent
                            label="Address Line 1"
                            type="text"
                            :disabled="true"
                            placeholder="123 Main St"
                            :value="getFieldValue('account_number', 'current')"
                            :class="{changes: hasChanges('account_number', 'current')}"
                        />
                    </div>
                </div>
                <div class="flex-1">
                    <h3 class="header-small">
                        <text>New Version</text>
                    </h3>
                    <div class="info-holder proposed-changes">
                        <p class="field-label">General Fields</p>
                        <InpComponent
                            label="Address Line 1"
                            type="text"
                            :disabled="true"
                            placeholder="123 Main St"
                            :value="getFieldValue('account_number', 'new')"
                            :class="{changes: hasChanges('account_number', 'new')}"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup >

import { ref, computed, onMounted, watch } from 'vue'
import InpComponent from '@/components/InpComponent.vue'
import { useStore } from 'vuex';
import API from '@/api/index.js'

const props = defineProps(['reviewAction', 'view'])
const store = useStore();

const fieldChange = ref(JSON.parse(props.reviewAction.fields));
const accountNumber = ref(null);
const loading = ref(true);
onMounted(async() => {
    console.log('account_number', props.reviewAction.account_number);
    const accountNumberResponse = await API.getAccountNumberById(props.reviewAction.account_number);
    if (!accountNumberResponse.status == 200) {
        return;
    }
    accountNumber.value = accountNumberResponse.accountNumber;
    loading.value = false;

});


function hasChanges(field, version) {
    if (version == 'current') {
        return fieldChange.value[field]?.new != undefined && fieldChange.value[field]?.current
    }
    return fieldChange.value[field]?.new != undefined;

}

function getFieldValue(field, state) {
    if (
        state == 'current' && 
        fieldChange.value[field] && 
        !fieldChange.value[field].current && 
        fieldChange.value[field].new
    ) {
        return null;
    }
    if (fieldChange.value[field] && fieldChange.value[field][state]) {
        return fieldChange.value[field][state];
    }
    return companyData.value[field].value;
}

</script>

<style lang="scss" scoped>

    .proposed-changes {
        margin-left: auto;
        .changes {
            background: #DEF7EC;
            &:deep(label) {
                background: #DEF7EC;
            }
        }
    }
    .current-version {
       
        .changes {
            background: #FDE8E8;
            &:deep(label) {
                background: #FDE8E8;
            }
        }
    }
</style>