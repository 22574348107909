<template>
  <div id="app" v-if="loaded">
    <div class='popup-dialog'>
      <div v-for="(item, index) in notifications" :class="`popup-item ${item.type}`">
        <img src="@/assets/circle-approved.svg" v-if="item.type == 'success'">
        <img src="@/assets/warning.svg" v-else>
        <div class="text">
          {{item.text}}
        </div>

      </div>
    </div>
    <Header/>
    <SideBar v-if="user"/>
    <div class="main-view" :class="user ? 'sidebar-active': ''">
      <router-view class="view"/>
      <PopupCover v-if="user && !user.two_factor_setup_completed && user.two_factor_secret">
        <TwoFaPopup>

        </TwoFaPopup>
      </PopupCover>
    </div>
  </div>
  <div class='loading-screen' v-else>
    <h1>Loading...</h1>
  </div>

</template>


<script setup>

import { mapState } from 'vuex';
import Header from './components/Header.vue'
import SideBar from './components/SideBar.vue'
import API from './api/index.js'
import { useRouter, useRoute } from 'vue-router'
import { PermissionChecker } from './utils/iam_utils';
import PopupCover from './components/PopupCover.vue'
import TwoFaPopup from './components/TwoFaPopup.vue'

const router = useRouter()

import { onMounted, watch, computed, ref } from 'vue'

import store from './store';


const user = computed(() => store.state.user);
const notifications = computed(() => store.state.notifications);
const loaded = ref(computed(() => store.state.loaded));


onMounted(async () => {
  store.dispatch('loadcolapsedSections');
  if (localStorage.getItem('token')) {
    let authResponse = await API.getUser();
    if (authResponse.status == 200) {
      await API.getRoles();
      loaded.value = true;
      await store.dispatch('setValue', {key: 'loaded', value: true});
      await API.getBanks();
      
    } else {
      redirect()
      await store.dispatch('setValue', {key: 'loaded', value: true});
    }
  } else {
    console.log("BBBBBBBBBB")
    redirect()
    await store.dispatch('setValue', {key: 'loaded', value: true});
  }
})


async function redirect(){
  if (!['/login','/register', '/contact', '/forgot-password'].includes(window.location.pathname)) {
    await router.push('/login')
  }
}


watch(user, async (newUser, oldUser) => {
  if (!newUser && loaded.value) {
    router.push('/login')
  }
})

</script><style lang="scss">

:root {
  --primary-color: rgb(0 115 239);
  --border-color: rgb(210 212 223);
  --app-background: #F6F6FC;
  --sidebar-text-color: rgb(116 119 127);
  --sidebar-width: 250px;
  --button-hover-color-blue: rgb(244 246 255);
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #f3f5f6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3D5873;
  font-size: 2em;
}
.header-section {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
  color: black;
  padding-top: 20px;
}

.main-view {
  padding-top: 60px;
  &.sidebar-active {
    padding-left: var(--sidebar-width);
    width: calc(100% - var(--sidebar-width));
  }
  height: fit-content;
}
.delete-bin {
  max-height: 37px;
}
.view {
  box-sizing: border-box;
  height: calc(100vh - 60px);
  overflow-y: auto;
}

.gap-20 {
  gap: 20px;
}
.button-holder {
  position: relative;
}

.delete-section {
  width: 16px;
  height: 16px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  background-color: #F6F6FC;
}
.border-section {
  border: 1px solid #d2d4df;
}
.main-color-bg {
  background: rgb(0 115 239);
}
.main-color {
  color: rgb(0 115 239);
}
.apart {
  justify-content: space-between;
}
.popup-dialog {
  position: fixed;
  bottom: 10px;
  right: 30px;
  width: 320px;
  z-index: 9999;
  overflow-y: auto;
  // max-height: calc(100vh - 30px);
  overflow: hidden;
}
.pointer {
  cursor: pointer;
}
.h-fit {
  height: fit-content;
}

.align-start {
  align-items: flex-start;
}
.bold {
  font-weight: bold;
}
.f-18 {
  font-size: 18px;
}
.arrow-opener-section {
  transition: 0.3s all;
  width: 15px;
  height: 15px;
}
.section-opened {
  transform: rotate(90deg);
}
.popup-item {
  display: flex;
  align-items: center;
  position: relative;
  background: #2D3038;
  color: white;
  gap: 10px;
  text-align: left;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  
  // top: 20px;
  right: -300px; /* Initially off-screen */
  padding: 15px;
  background-color: #333;
  color: white;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.2s ease-out forwards, slideOut 0.2s ease-in 3s forwards;  
  &.error {
      background: #8B0000;
      animation: slideIn 0.2s ease-out forwards, slideOut 0.2s ease-in 6s forwards;  

  }
}

@keyframes slideIn {
  0% {
    right: -300px;
    opacity: 0;
  }
  90% {
    right: 10px;
    opacity: 1;
  }
  100% {
    right: 0px;
    opacity: 1;
  }
}

/* Slide out to the right */
@keyframes slideOut {
  0% {
    right: 0px;
    opacity: 1;
  }
  10% {
    right: 5px;
    opacity: 1;
  }
  70% {
    opacity: 0.9;
  }
  100% {
    right: -300px;
    opacity: 0;
  }
}


html, body {
  margin: 0;
  padding: 0;
  height: 100vh;
  // width: 100vw;
  background: white;
  background-color: #f3f5f6;
  color: black;
  box-sizing: border-box;

  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-feature-settings: normal;
  font-variation-settings: normal;
}

nav {
  padding: 30px;

  a:not(.link-item) {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #3c556e;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: -3px;
        right: -3px;
        height: 4px;
        background: #3c556e;
      }
    }

  }
}
.doc-header {
  text-align: left;
  margin: 0;
  color: black;
}

.margin-top-20 {
  margin-top: 20px;
}
.p-20 {
  padding: 20px;
}
.mt-0 {
  margin-top: 0;
}
button {
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px ;
  width: 100%;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  &.transparent {
    background: white;
    color: rgb(24 28 34);

    border: 1px solid var(--border-color);
    &:hover {
      background: #F6F6FC
    }
  }
  &.fit-content {

    width: fit-content;
    padding: 10px 15px;
  }

  cursor: pointer;
  &:hover {
    background: #00408985;
  }
  &:disabled {
    background: #ccc;
    color: #666;
    cursor: not-allowed;
  }
}


.label-like {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-15 {
  margin-bottom: 15px;
}
.font-normal {
  font-weight: normal;
}
.section-top {
  background: white;
  padding: 20px;
  box-shadow:  0 0 10px rgba(0, 0, 0, 0.1);  
  border-radius: 10px 10px 0 0;
  margin-bottom: -5px;
}
td input {
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  height: 39px;
  border-radius: unset;
  padding: 0px;
  font-size: 16px;
  border: none;
  background: transparent;
  color: black;
  caret-color: black;
  outline: 1px solid blue;
}
td   select {
  max-width: 190px;
}
.gap-5 {
  gap: 20px;
}
.gap-4 {
  gap: 16px;
}
.margin-top-80 {
  margin-top: 80px;
}
.margin-top-40 {
  margin-top: 40px;
}
.space-between {
  justify-content: space-between;
}
.wrap {
  flex-wrap: wrap;
}
.flex {
  display: flex;
} 
.column {
  flex-direction: column;
} 
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.gap-40 {
  gap: 40px;
}
.gap-10 {
  gap: 10px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-15 {
  margin-top: 20px;
}
.mt-40 {
  margin-top: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-80 {
  margin-bottom: 80px;
}
.items-center {
  align-items: center;
}
.full-width {
  width: 100%;
}
.gap-5px {
  gap: 5px;
}
.gap-30 {
  gap: 30px;
}
.justify-between {
  justify-content: space-between;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-5 {
  flex: 5;
}
.justify-end {
  justify-content: flex-end;
}
.align-end {
  align-items: flex-end;
}
.width-96 {
  width: 96%;
}
.width-1of3 {
  width: 33%;
}
.border-color {
  border-color: var(--border-color);
}
.border-rounded-gray {
  border-radius: 10px;
  padding: 24px;
  @apply border-color;
}
.w-full {
  width: 100%;
}
.w-content {
  width: fit-content;
}
.h-full {
  height: 100%;
}
.m-0 {
  margin: 0;
}
.p-10 {
  padding: 10px;
}
.header-of-tables {
  font-size: 16px;
  font-weight: bold;
  color:  black;
  background-color: var(--app-background);

  td {
    border: none !important;
  }
}
.table-wrap {
    width: 100%;
    overflow: visible;
    border: 1px solid var(--border-color);
    border-radius: 10px;

    table {
      width: 100%;
    }
}
.wrapper.disabled[class], .wrapper.no-tool-bar[class] {
  .editor-section[class] {
    border-top: 1px solid var(--border-color);
  }
}


.vue3-2fa-code-input {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}
.vue3-2fa-code-input-box {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.375rem;
  border: 1px solid rgb(229 231 235);
  background-color: transparent;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
  var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-sizing: border-box;
}
.vue3-2fa-code-input-box:focus {
  outline: none;
  --tw-border-opacity: 1;

  // --tw-ring-offset-shadow: 0 0 0 2px rgb(0 115 239 / 0.5);
  border-color: rgb(0 115 239 / var(--tw-border-opacity));

  // --tw-ring-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
}
.cross {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
}
.text-left {
  text-align: left;
}
.header-small {
  text-align: left;
  color: black;
  font-size: 16px;
  margin: 0;
  margin-bottom: 10px;
}
.field-label {
  font-size: 14px;
  color: black;
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
  text-align: left;
}

.breadcrumbs {
  max-width: 1325px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  .main-item {
    color: var(--primary-color);
    font-weight: bold;
    text-decoration: none;
  }
  .last-item {
    color: rgb(116 119 127);
    font-weight: bold;
  }
}
.content-collapse {
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
}
.mb--30 {
  margin-bottom: -30px;
}
</style>
