<template>
    <div class="wrapper-main-sh-h">
        <div class="flex column gap40">
            <div class="type-selector padding-sides align-start">
               
                <InpComponent 
                    class="country-select"
                    label="Shareholder Type"
                    type="options"
                    :options="[{value: 'person', text: 'Individual'}, {value: 'entity', text: 'Corporate'}]"
                    placeholder="Local"
                    :pendingChanges="hasChanges('shareholder_type')"
                    :disabled="!canBeUpdated"
                    :key_ref="'shareholder_type'"
                    :value="sectionShareHolder.shareholder_type.new_value || sectionShareHolder.shareholder_type.value"
                    @inputChange="inputChangeFunc"
                ></InpComponent>
                <InpComponent
                    :disabled="!canBeUpdated"
                    label="Percentage of shares held"
                    :pendingChanges="hasChanges('percentage_held')"
                    type="number"
                    :key_ref="'percentage_held'"
                    :value="sectionShareHolder.percentage_held.new_value || sectionShareHolder.percentage_held.value"
                    :error="sectionShareHolder.percentage_held.error"
                    @inputChange="inputChangeFunc"
                />
                <div class='flex row gap-5 h-content align-start' style="margin-left: auto" >
                    <button title='collapse & expand section' class='transparent w-content mb-15 button-holder' @click="toggleSection">
                        <img class="arrow-opener-section" :class="isOpen ? 'section-opened': ''" src="@/assets/arrow-opener.svg">
                    </button>
                    <button class='delete-bin transparent' @click="deleteShareholder()" v-if="canBeDeleted">
                        <img class="delete-section" src="@/assets/delete.svg">
                    </button>
                </div>
            </div>
            <div class="w-full content" :id="'collapse_' + sectionShareHolder._id">
                <div v-if="getCurrentEntityType == 'entity'">
                    <ManagingDirrector2
                        v-for="(md, index ) in managingDirectors"
                        :md_id="md._id"
                        :key="`${md._id}_managing_director_view`"
                        :reviewMode="props.reviewMode"
                    />
                </div>
                <UBO2
                    v-else-if="getCurrentEntityType == 'person'" 
                    v-for="(ubo, index) in sectionUBOs"
                    :ubo_id="ubo._id"
                    :key="`index_mbd-${ubo._id}`"
                    :reviewMode="props.reviewMode"
                />
            </div>
        </div> 
        <PopupCover v-if="deleteObject" @close="() => deleteObject = !deleteObject">
            <ConfirmationPopup 
                @confirm="popupConfirm"
                @cancel="() => deleteObject = !deleteObject"
                :title="'Delete Shareholder'"
                :message="'Are you sure you want to delete this shareholder? All the data related to this shareholder will be deleted.'"
            />
        </PopupCover>
    </div>
</template>

<script setup>
    import InpComponent from '@/components/InpComponent.vue'
    
    import { useStore } from 'vuex'
    import { ref, computed, defineProps, defineEmits, onMounted } from 'vue'
    import ManagingDirrector2 from './ManagingDirrector2.vue'
    import UBO2 from '@/views/UBO2.vue'
    import { PermissionChecker } from '@/utils/iam_utils'
    import StructureChart from '@/components/StructureChart.vue'

    import PopupCover from '@/components/PopupCover.vue'
    import ConfirmationPopup from '@/components/ConfirmationPopup.vue'

    const store = useStore()
    const emit = defineEmits(['update_mbd'])

    const props = defineProps([
        'shareholder_id',
        'mbd', 
        'preview',
        'reviewMode',
    ])

    const notificationPresent = computed(() => props.reviewMode ?  [] : store.getters.getActionRequired(props.shareholder_id, 'share_holder_related'))
    const hasChanges = computed(() => (field) => {
        for (const item of notificationPresent.value) {
            let fields = JSON.parse(item.fields); 
            if (item.actionType == 'remove') {
                return true;
            }
            if (fields[field]?.new) {
                return true
            }
        }
    })

    const isOpen = computed(() => store.getters.getToggleState(props.shareholder_id))
    const deleteObject = ref(false);
    const sectionShareHolder = computed(() => store.getters.getShareHolderById(props.shareholder_id))
    const managingDirectors = computed(() => store.getters.managingDirectors(sectionShareHolder.value._id))
    const sectionUBOs = computed(() => store.getters.UBOs(sectionShareHolder.value._id))
    
    const HTMLElements = computed(() => {
        return document.getElementById('collapse_' + sectionShareHolder.value._id);
    })
    const getCurrentEntityType = computed(() => (sectionShareHolder.value['shareholder_type'].new_value || sectionShareHolder.value['shareholder_type'].value))
    const canBeUpdated = computed(() => !props.reviewMode)
    const canBeDeleted = computed(() => !props.reviewMode)

    function inputChangeFunc(event) {
     
        const event_type = event[0];
        const event_value = event[1];

        store.commit('updateShareHolderById',{
            id: props.shareholder_id, 
            updates: {
                [`${event_type}`]: event_value,
            }
        })
    }

    async function deleteShareholder() {
        deleteObject.value = true
      
    }

    function popupConfirm() {
        store.dispatch('deleteShareHolder', {
            shareholder_id: props.shareholder_id, 
        })
    }
    
    function toggleSection() {
        store.dispatch('toggleSection', {
            id: props.shareholder_id, 
        })
    }
    
    function adjustHeight() {
      const section = HTMLElements.value;
      if (isOpen.value) {
            section.style.maxHeight = "none"; // Prevent transition issues
            section.style.overflowY = "visible"; // Prevent overflow
        } 
        else {
            section.style.maxHeight = "0"; // Collapse the section
            section.style.overflowY = "hidden"; // Hide overflow
        }
    }

    onMounted(() => {
        adjustHeight()
    })

    
</script>

<style scoped lang="scss">

.content {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
}

.officials {
    border-radius: 5px;
    padding: 20px;
    background-color: var(--app-background);
    border: 1px solid var(--border-color);
}
.type-selector {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.wrapper-main-sh-h {
    border-radius: 5px;
    background-color: white;
    max-width: 1350px;
    width: 100%;
    margin: auto;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    padding: 20px 1px;
    padding-bottom: 1px;
}
.padding-sides {
    padding: 0 20px;
}
.header-section {
        background-color: white;
        height: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .kyc-header {
        max-width: 1350px;
        width: 100%;
        text-align: left;
        font-size: 28px;
    }
    .kyc-fields-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .kyc-fields-area {
        max-width: 700px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: baseline;

    }
    .kyc-item-section {
        width: 700px;
        margin-bottom: 30px;
        h3 {
            text-align: left;
        }
        &.extra-margin {
            margin-bottom: 50px;
        }
    }
    .flex {
        display: flex;
        &.row {
            flex-direction: row;
        }
        &.column {
            flex-direction: column;
        }
    }
    .passport-fields {
        margin-top: 20px;
    }
    .gap30 {
        gap: 30px;
    }
    .person-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    textarea {
        resize: none; 
        box-sizing: border-box;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        width: 685px;
        height: 150px;
    }
    .add-new {
        margin-left: auto;
    }
    .margin-top-20 {
        margin-top: 20px;
    }
    .margin-top-40 {
        margin-top: 40px;
    }
    .entity_person_header {
        width: 100%;
        text-align: left;
    }
    
</style>