<template>
    <div class="main-box">
        <div class="section-header flex row justify-between">
            <div>Transaction Profile 


                <span class='font-normal' v-if="!isOpen">
                    {{ accountNumber.account_number.new_value || accountNumber.account_number.value || ''}}
                    {{ sectionTransactionProfile.currency.new_value || sectionTransactionProfile.currency.value }}
                </span>
            </div>
            <div class="flex row gap-10 align-start">
                <button title='collapse & expand section' class='transparent w-content mb-15 button-holder' @click="toggleSection">
                    <img class="arrow-opener-section" :class="isOpen ? 'section-opened': ''" src="@/assets/arrow-opener.svg">
                </button>
                <button class='delete-bin w-content transparent' @click="DeleteTransactionProfile()" v-if="!reviewMode">
                    <img class="delete-section" src="@/assets/delete.svg">
                </button>
            </div>
        </div>
        <div class="w-full content" :id="'collapse_' + sectionTransactionProfile._id">
            <div class="mt-15 p-20 flex gap-30">
                <div class="section-title-area ">
                    Client Information
                </div>
                <div class="section fields top-info flex-1">
                    <InpComponent
                        label="Customer Name"
                        type="text"
                        :disabled="true"
                        :value="customerName"
                    />
                    <InpComponent
                        label="Account Number"
                        type="text"
                        :disabled="true"
                        :value="`${accountNumber.account_number.new_value || accountNumber.account_number.value || ''}`"
                    />
                    <InpComponent
                        class="flex-1"
                        label="Bank Name"
                        type="options"
                        :disabled="inputsDisabled"
                        :options="bankOptions"
                        :key_ref="'name_bank'"
                        :value="sectionTransactionProfile.name_bank.new_value || sectionTransactionProfile.name_bank.value"
                        @inputChange="inputChangeFunc"
                    />
                    <InpComponent
                        class="flex-1"
                        label="Currency"
                        type="options"
                        :disabled="inputsDisabled"
                        :options="currencyListOption"
                        :key_ref="'currency'"
                        :value="sectionTransactionProfile.currency.new_value || sectionTransactionProfile.currency.value"
                        @inputChange="inputChangeFunc"
                    />
                </div>
            </div>
            <div class="mt-15 p-20 flex gap-30">
                <div class="section-title-area ">
                    Jurisdictions
                </div>
                <div class="section fields top-info flex column gap-20 flex-1">
                    <div class="comment-area w-full">
                        <div class="comment-header mb-10">
                            Please list all countries of expected incoming payments
                        </div>
                        <CommentSection
                            attrId="expected_transaction_incoming_info"
                            class="comment-section"
                            :commentSection="sectionTransactionProfile.expected_transaction_incoming_info.new_value || sectionTransactionProfile.expected_transaction_incoming_info.value"
                            @changeinput="quillContent" 
                            :disabled="inputsDisabled"
                        />
                    </div>
                    <div class="comment-area w-full">
                        <div class="comment-header mb-10">
                            Please list all countries of expected outgoing payments
                        </div>
                        <CommentSection
                            attrId="expected_transaction_outgoing_info"
                            class="comment-section"
                            :commentSection="sectionTransactionProfile.expected_transaction_outgoing_info.new_value || sectionTransactionProfile.expected_transaction_outgoing_info.value"
                            @changeinput="quillContent" 
                            :disabled="inputsDisabled"
                        />
                    </div>
                </div>
            </div>
            <div class="mt-15 p-20 flex gap-30">
                <div class="section-title-area ">
                    Expected turnover
                </div>
                <div class="section fields top-info flex column gap-10 flex-1">
                    <div class="comment-header mb-10">
                        Please, provide information about incoming payments
                    </div>
                    <div class="flex column gap-20">
                        <div class="checkbox-area">
                            <div class="checkbox-header">
                                Average incoming transaction value/size
                            </div>
                            <div class="options">
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" type="radio" name="transaction_value_incoming" @change="inputChange" id="less_than_3_000-transaction_value_incoming">
                                    <div class="option-desctions">
                                        Less than 3,000 {{ renderValueCurrency }}
                                    </div>
                                </div>
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" type="radio" name="transaction_value_incoming" @change="inputChange" id="3_001_10_000-transaction_value_incoming">
                                    <div class="option-desctions">
                                        From 3 001 {{ renderValueCurrency }} to 10 000 {{ renderValueCurrency }}
                                    </div>
                                </div>
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" type="radio" name="transaction_value_incoming" @change="inputChange" id="10_001_50_000-transaction_value_incoming">
                                    <div class="option-desctions">
                                        From 10 001 {{ renderValueCurrency }} to 50 000 {{ renderValueCurrency }}
                                    </div>
                                </div>
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" type="radio" name="transaction_value_incoming" @change="inputChange" id="more_than_50_000-transaction_value_incoming">
                                    <div class="option-desctions">
                                        More than 50 000 {{ renderValueCurrency }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="checkbox-area">
                            <div class="checkbox-header">
                                Planned monthly incoming payments turnover using on <b>{{ sectionTransactionProfile.name_bank.new_value || sectionTransactionProfile.name_bank.value }}</b> account 
                            </div>
                            <div class="options">
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" 
                                        type="radio" 
                                        name="monthly_turnover_incoming" 
                                        @change="inputChange" 
                                        id="less_than_10_000-monthly_turnover_incoming">
                                    <div class="option-desctions">
                                        Less than 10,000 {{ renderValueCurrency }}
                                    </div>
                                </div>
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" 
                                        type="radio" 
                                        name="monthly_turnover_incoming" 
                                        @change="inputChange" 
                                        id="10_001_50_000-monthly_turnover_incoming">
                                    <div class="option-desctions">
                                        From 10 001 {{ renderValueCurrency }} to 50 000 {{ renderValueCurrency }}
                                    </div>
                                </div>
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" 
                                        type="radio" 
                                        name="monthly_turnover_incoming" 
                                        @change="inputChange" 
                                        id="50_001_100_000-monthly_turnover_incoming">
                                    <div class="option-desctions">
                                        From 50 001 {{ renderValueCurrency }} to 100 000 {{ renderValueCurrency }}
                                    </div>
                                </div>
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" 
                                        type="radio" 
                                        name="monthly_turnover_incoming" 
                                        @change="inputChange" 
                                        id="more_than_100_000-monthly_turnover_incoming">
                                    <div class="option-desctions">
                                        More than 100 000 {{ renderValueCurrency }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="checkbox-area">
                            <div class="checkbox-header">
                                Monthly frequency of incoming payments
                            </div>
                            <div class="options">
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" 
                                        type="radio" 
                                        name="monthly_frequency_of_transaction_incoming" 
                                        @change="inputChange" 
                                        id="less_than_10-transaction_value_incoming">
                                    <div class="option-desctions">
                                        Up to 10
                                    </div>
                                </div>
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" 
                                        type="radio" 
                                        name="monthly_frequency_of_transaction_incoming" 
                                        @change="inputChange" 
                                        id="11_50-monthly_frequency_of_transaction_incoming">
                                    <div class="option-desctions">
                                        From 11 to 50
                                    </div>
                                </div>
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" 
                                        type="radio" 
                                        name="monthly_frequency_of_transaction_incoming" 
                                        @change="inputChange" 
                                        id="51_100-monthly_frequency_of_transaction_incoming">
                                    <div class="option-desctions">
                                        From 51 to 100
                                    </div>
                                </div>
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" 
                                        type="radio" 
                                        name="monthly_frequency_of_transaction_incoming" 
                                        @change="inputChange" 
                                        id="more_than_100-monthly_frequency_of_transaction_incoming">
                                    <div class="option-desctions">
                                        More than 100       
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="mt-15 p-20 flex gap-30">
                <div class="section-title-area ">
                </div>
                <div class="section fields top-info flex column gap-10 flex-1">
                    <div class="comment-header mb-10">
                        Please, provide information about outgoing payments
                    </div>
                    <div class="flex column gap-20">
                        <div class="checkbox-area">
                            <div class="checkbox-header">
                                Average outgoing transaction value/size
                            </div>
                            <div class="options">
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" type="radio" name="transaction_value_outgoing" @change="inputChange" id="less_than_3_000-transaction_value_outgoing">
                                    <div class="option-desctions">
                                        Less than 3,000 {{ renderValueCurrency }}
                                    </div>
                                </div>
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" type="radio" name="transaction_value_outgoing" @change="inputChange" id="3_001_10_000-transaction_value_outgoing">
                                    <div class="option-desctions">
                                        From 3 001 {{ renderValueCurrency }} to 10 000 {{ renderValueCurrency }}
                                    </div>
                                </div>
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" type="radio" name="transaction_value_outgoing" @change="inputChange" id="10_001_50_000-transaction_value_outgoing">
                                    <div class="option-desctions">
                                        From 10 001 {{ renderValueCurrency }} to 50 000 {{ renderValueCurrency }}
                                    </div>
                                </div>
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" type="radio" name="transaction_value_outgoing" @change="inputChange" id="more_than_50_000-transaction_value_outgoing">
                                    <div class="option-desctions">
                                        More than 50 000 {{ renderValueCurrency }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="checkbox-area">
                            <div class="checkbox-header">
                                Planned monthly outgoing payments turnover using on <b>{{ sectionTransactionProfile.name_bank.new_value || sectionTransactionProfile.name_bank.value }}</b> account 
                            </div>
                            <div class="options">
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" 
                                        type="radio" 
                                        name="monthly_turnover_outgoing" 
                                        @change="inputChange" 
                                        id="less_than_10_000-monthly_turnover_outgoing">
                                    <div class="option-desctions">
                                        Less than 10,000 {{ renderValueCurrency }}
                                    </div>
                                </div>
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" 
                                        type="radio" 
                                        name="monthly_turnover_outgoing" 
                                        @change="inputChange" 
                                        id="10_001_50_000-monthly_turnover_outgoing">
                                    <div class="option-desctions">
                                        From 10 001 {{ renderValueCurrency }} to 50 000 {{ renderValueCurrency }}
                                    </div>
                                </div>
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" 
                                        type="radio" 
                                        name="monthly_turnover_outgoing" 
                                        @change="inputChange" 
                                        id="50_001_100_000-monthly_turnover_outgoing">
                                    <div class="option-desctions">
                                        From 50 001 {{ renderValueCurrency }} to 100 000 {{ renderValueCurrency }}
                                    </div>
                                </div>
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" 
                                        type="radio" 
                                        name="monthly_turnover_outgoing" 
                                        @change="inputChange" 
                                        id="more_than_100_000-monthly_turnover_outgoing">
                                    <div class="option-desctions">
                                        More than 100 000 {{ renderValueCurrency }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="checkbox-area">
                            <div class="checkbox-header">
                                Monthly frequency of outgoing payments
                            </div>
                            <div class="options">
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" 
                                        type="radio" 
                                        name="monthly_frequency_of_transaction_outgoing" 
                                        @change="inputChange" 
                                        id="less_than_10-monthly_frequency_of_transaction_outgoing">
                                    <div class="option-desctions">
                                        Up to 10
                                    </div>
                                </div>
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" 
                                        type="radio" 
                                        name="monthly_frequency_of_transaction_outgoing" 
                                        @change="inputChange" 
                                        id="11_50-monthly_frequency_of_transaction_outgoing">
                                    <div class="option-desctions">
                                        From 11 to 50
                                    </div>
                                </div>
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" 
                                        type="radio" 
                                        name="monthly_frequency_of_transaction_outgoing" 
                                        @change="inputChange" 
                                        id="51_100-monthly_frequency_of_transaction_outgoing">
                                    <div class="option-desctions">
                                        From 51 to 100
                                    </div>
                                </div>
                                <div class="flex gap-10 align-center">
                                    <input :disabled="inputsDisabled" 
                                        type="radio" 
                                        name="monthly_frequency_of_transaction_outgoing" 
                                        @change="inputChange" 
                                        id="more_than_100-monthly_frequency_of_transaction_outgoing">
                                    <div class="option-desctions">
                                        More than 100       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-15 p-20 flex gap-30">
                <div class="section-title-area ">
                </div>
                <div class="section fields top-info flex column gap-10 flex-1">
                    <div class="flex column gap-20">
                        <div class="checkbox-area">
                            <div class="checkbox-header mb-10">
                                Main counterparties for outgoing payments (name/jurisdiction)
                            </div>
                            <InpComponent
                                class="flex-1"
                                label="Name *"
                                type="text"
                                key_ref="outgoing_counterparty"
                                :value="sectionTransactionProfile.outgoing_counterparty.new_value || sectionTransactionProfile.outgoing_counterparty.value"
                                @inputChange="inputChangeFunc"
                                :disabled="inputsDisabled"
                            />
                            <div class="checkbox-header remark">
                                * - Mandatory field
                            </div>
                            <InpComponent
                                class="flex-1"
                                label="Name"
                                type="text"
                                key_ref="outgoing_counterparty_2"
                                :value="sectionTransactionProfile.outgoing_counterparty_2.new_value || sectionTransactionProfile.outgoing_counterparty_2.value"
                                @inputChange="inputChangeFunc"
                                :disabled="inputsDisabled"
                            />
                            <InpComponent
                                class="flex-1"
                                label="Name"
                                type="text"
                                key_ref="outgoing_counterparty_3"
                                :value="sectionTransactionProfile.outgoing_counterparty_3.new_value || sectionTransactionProfile.outgoing_counterparty_3.value"
                                @inputChange="inputChangeFunc"
                                :disabled="inputsDisabled"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-15 p-20 flex gap-30">
                <div class="section-title-area ">
                </div>
                <div class="section fields top-info flex column gap-10 flex-1">
                    <div class="flex column gap-20">
                        <div class="checkbox-area">
                            <div class="checkbox-header mb-10">
                                Main counterparties for incoming payments (name/jurisdiction)
                            </div>
                            <InpComponent
                                class="flex-1"
                                label="Name *"
                                type="text"
                                :key_ref="'incoming_counterparty'"
                                :value="sectionTransactionProfile.incoming_counterparty.new_value || sectionTransactionProfile.incoming_counterparty.value"
                                @inputChange="inputChangeFunc"
                                :disabled="inputsDisabled"
                            />
                            <div class="checkbox-header remark">
                                * - Mandatory field
                            </div>
                            <InpComponent
                                class="flex-1"
                                label="Name"
                                type="text"
                                :key_ref="'incoming_counterparty_2'"
                                :value="sectionTransactionProfile.incoming_counterparty_2.new_value || sectionTransactionProfile.incoming_counterparty_2.value"
                                @inputChange="inputChangeFunc"
                                :disabled="inputsDisabled"
                            />
                            <InpComponent
                                class="flex-1"
                                label="Name"
                                type="text"
                                :key_ref="'incoming_counterparty_3'"
                                :value="sectionTransactionProfile.incoming_counterparty_3.new_value || sectionTransactionProfile.incoming_counterparty_3.value"
                                @inputChange="inputChangeFunc"
                                :disabled="inputsDisabled"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-15 p-20 flex gap-30">
                <div class="section-title-area ">
                </div>
                <div class="comment-area w-full">
                    <div class="comment-header mb-10">
                        Comments to the transaction profile
                    </div>
                    <CommentSection
                        attrId="comments"
                        class="comment-section big"
                        :commentSection="sectionTransactionProfile.comments.new_value || sectionTransactionProfile.comments.value"
                        @changeinput="quillContent" 
                        :disabled="inputsDisabled"
                    />
                </div>
            </div>
        </div>
        <PopupCover v-if="deleteObject" @close="() => deleteObject = !deleteObject">
            <ConfirmationPopup 
                @confirm="popupConfirm"
                @cancel="() => deleteObject = !deleteObject"
                :title="'Delete Transaction Profile'"
                :message="'Are you sure you want to delete this revision? Associated Account number will be deleted as well'"
            />
        </PopupCover>
    </div>
</template>

<script setup>
    import { ref, computed, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import InpComponent from './InpComponent.vue'
    import CommentSection from '@/views/CommentSection.vue'
    import PopupCover from '@/components/PopupCover.vue'
    import ConfirmationPopup from '@/components/ConfirmationPopup.vue'

    const store = useStore()
    const props = defineProps(['transactionProfile_id', 'company_id', 'reviewMode'])
    const sectionTransactionProfile = computed( () => store.getters.getTransactionRevewialProfileById(props.transactionProfile_id))
    const accountNumber = computed( () => store.getters.getAccountNumberById(sectionTransactionProfile.value.account_number))
    const companyData = computed(() => store.state.companyData)
    const bankOptions = ref(null) 
    const deleteObject = ref(null)

    const canBeEdited = computed(() => !props.reviewMode)
    const isOpen = computed(() => store.getters.getToggleState(props.transactionProfile_id))

    const HTMLElements = computed(() => {
        return document.getElementById('collapse_' + sectionTransactionProfile.value._id);
    })
    const currencyListOption = ref([
        {value: 'USD', text: 'USD'},
        {value: 'EUR', text: 'EUR'},
        {value: 'GBP', text: 'GBP'},
        {value: 'JPY', text: 'JPY'},
    ])
    const renderValueCurrency = computed(() => {
        return sectionTransactionProfile.value.currency.new_value || sectionTransactionProfile.value.currency.value
    })
    const inputsDisabled = ref(!canBeEdited.value);

    function inputChangeFunc(event) {
        const event_type = event[0];
        const event_value = event[1];
        store.commit('updateTransactionProfileById',{
            id: props.transactionProfile_id, 
            updates: {
                [`${event_type}`]: event_value,
            }
        })
    }

    const customerName = computed(() => {
        if (companyData.value.company_type === 'corporate') {
            return companyData.value.name.new_value || companyData.value.name.value
        }
        return `${companyData.value.first_name.new_value || companyData.value.first_name.value} ${companyData.value.last_name.new_value || companyData.value.last_name.value}`
    })

    onMounted(() => {
        bankOptions.value = store.state.banks.map(bank => {
            return {
                value: bank.name,
                text: bank.name,
            }
        })
        const options = [
            'transaction_value_incoming',
            'monthly_turnover_incoming',
            'monthly_frequency_of_transaction_incoming',
            'transaction_value_outgoing',
            'monthly_turnover_outgoing',
            'monthly_frequency_of_transaction_outgoing'
        ];
        options.forEach(option => {
            if (sectionTransactionProfile.value[option].value) {
                let element = document.getElementById(`${sectionTransactionProfile.value[option].value}-${option}`);
                if (element) {
                    element.checked = true;
                }
            }
        })
        adjustHeight()

     
    })
    function quillContent(event) {
        store.commit('updateTransactionProfileById',{
            id: props.transactionProfile_id, 
            updates: {
                [event.id]: event.structure,
            }
        })
    }
    async function inputChange(event) {
        let name = event.target.name;
        let value = event.target.id.split('-')[0];
        console.log('name', name, 'value', value)
        store.commit('updateTransactionProfileById', {
            id: props.transactionProfile_id, 
            updates: {
                [`${name}`]: value,
            }
        })
    }
    function DeleteTransactionProfile(){
        deleteObject.value = true;
    }
    async function popupConfirm(){
        const result = await store.dispatch('deleteTransactionProfile', {
            transactionProfile_id: props.transactionProfile_id,
        })
    }
    function toggleSection() {
        store.dispatch('toggleSection', {
            id: props.transactionProfile_id, 
        })
    }
    
    function adjustHeight() {
        const section = HTMLElements.value;

        if (isOpen.value) {
            section.style.maxHeight = "none"; // Prevent transition issues
            section.style.overflowY = "visible"; // Prevent overflow
        } 
        else {
            section.style.maxHeight = "0"; // Collapse the section
            section.style.overflowY = "hidden"; // Hide overflow
        }
    }


</script>

<style lang="scss" scoped>
.main-box {
    background: white;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
}
.checkbox-header {
    text-align: left;
    font-size: 13px;
}
.content {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
}
.remark {
    margin-top: -15px;
    margin-bottom: 15px;
}
.section-header {
    text-align: left;
    font-size: 20px;
    color: black;
    font-weight: 600;
}
.top-info {
    // max-width: 400px;
}
.section-title-area {
    text-align: left;
    min-width: 300px;
    font-weight: 600;
}
.comment-section {
    &:deep(.editor-section) {
        min-height: 100px;
    }
    &.big {
        &:deep(.editor-section) {
            min-height: 250px;
        }
    }
}
.comment-header {
    text-align: left;
    font-size: 14px;
    font-weight: 600;
}
.options input {
    margin: 5px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin-left: 0;
}
</style>