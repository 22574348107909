<template>
    <div class="main-container">
        <div class="title">Profile Settings</div>
        <div class="tab-list mb-20">
            <div class="tab" :class="currentView == 'profile' ? 'active': ''" @click="setTab('profile')">General Information</div>
            <div class="tab" :class="currentView == 'security' ? 'active': ''" @click="setTab('security')">Security</div>
        </div>
        <div class="content">
            <div class="title-section">
                <h4 class='section-title' v-if="currentView == 'profile'">
                    Personal Details
                </h4>
                <h4 class='section-title' v-else>
                    Security
                </h4>
            </div> 
            
            <div class='reset-passpord-view' v-if="currentView == 'profile'">
                <div class="line-label">Username</div>
                <div class="info-line">{{ store.state.user.username }} </div>
    
                <div class="line-label">Email</div>
                <div class="info-line">
                    <span>{{ store.state.user.email }}</span> 
                    <div class='vefiried' v-if="store.state.user.email_verified">
                        Verified ✔
                    </div>
                    <div class="flex w-full row email-confirm" v-else>
                        <div class="not-vefiried">
                            Not verified ✘
                        </div>
                        <button :disabled="verifySent" class='fit-content button main-color-bg' @click="resendEmail()">Verify Now</button>
                    </div>
                </div>

                <div class="line-label">Role</div>
                <div class="info-line">{{ store.state.user.type }} </div>
            </div>
            <div class='reset-passpord-view' v-else>
                <div class="register-wrap border-section">
                    <div class="title flex align-center justify-center">
                        <text>
                        Change Password
                        </text>
                    </div>
                    <form @submit.prevent="saveData">
                        <InpComponent 
                            :value="currentPassword"
                            label="Current Password" 
                            
                            type="password" 
                            key_ref="currentPassword"
                            @inputChange="inputChangeFunc"
                            :error="currentPasswordError"
                        />
                        <InpComponent 
                            :value="newPassword"

                            label="New Password" 
                            
                            type="password" 
                            key_ref="newPassword"
                            @inputChange="inputChangeFunc"
                            :error="newPasswordError"
                        />
                        <InpComponent 
                            :value="confirmPassword"
                            label="Confirm Password" 
                            
                            type="password"
                            key_ref="confirmPassword"
                            @inputChange="inputChangeFunc"
                            :error="confirmPasswordError"
                        />
                        <button class='button main-color-bg' type="submit" @click="changePassword()">Apply</button>
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>


<script setup>
import { ref, defineEmits, defineProps, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

import InpComponent from '@/components/InpComponent.vue';
import API from '../api/index.js'
import {getUserType} from '@/utils/iam_utils';


const currentView = ref('profile');

const currentPassword = ref('');
const newPassword = ref('');
const confirmPassword = ref('');
const verifySent = ref(false);

const currentPasswordError = ref('');
const newPasswordError = ref('');
const confirmPasswordError = ref('');
const store = useStore();

function inputChangeFunc(event) {
    if (event[0] == 'currentPassword'){
        currentPassword.value = event[1];
    } else if (event[0] == 'newPassword') {
        newPassword.value = event[1];
    } else if (event[0] == 'confirmPassword') {
        confirmPassword.value = event[1];
    }
    console.log(event);
    if (validateOn.value) {
        validate();
    }
}

async function resendEmail() {
    verifySent.value = true;
    const response = await API.sendVerificationEmail();
    if (response.error) {
        store.dispatch('addNotification', {text: response.error, type: 'error'});
        return;
    }
}
const validateOn = ref(false);
function validate(){
    currentPasswordError.value = '';
    newPasswordError.value = '';
    confirmPasswordError.value = '';
    let errors = false;
    validateOn.value = true;
    if (newPassword.value.length < 8) {
        newPasswordError.value = 'Password must be at least 8 characters long';
        errors = true;
    }
    if (newPassword.value == currentPassword.value) {
        newPasswordError.value = 'New password must be different from the current password';
        errors = true;
    }
    if (newPassword.value != confirmPassword.value) {
        confirmPasswordError.value = 'Passwords do not match';
        errors = true;
    } 
    if (!currentPassword.value) {
        currentPasswordError.value = 'Please enter your current password';
        errors = true;
    }
    return errors;
}

async function changePassword() {
    if (validate()) {
        return;
    }

    const response = await API.changePassword({
        currentPassword: currentPassword.value,
        newPassword: newPassword.value,
        confirmPassword: confirmPassword.value
    });
    if (response.status == 'error') {
        store.dispatch('addNotification', {text: response.message, type: 'error'});
        return;
    } else {
        store.dispatch('addNotification', {text: 'Password changed successfully', type: 'success'});
    }
    currentPassword.value = '';
    newPassword.value = '';
    confirmPassword.value = '';
    
    console.log('change password');
}

// inputChangeFunc(event) {
//       console.log('inputChangeFunc', event)
//       if (event[0] == 'email'){
//         this.email = event[1];
//         this.validateEmailField();
//       } else if (event[0] == 'password') {
//         this.password = event[1];
//         this.validatePasswordField();
//       } else if (event[0] == 'username') {
//         this.username = event[1];
//         this.validateUsernameField();
//       }
//     },

function setTab(tab){
    currentView.value = tab;
}

</script>

<style lang="scss" scoped>

.reset-passpord-view {
    padding: 20px;
    
}
.main-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    padding-top: 20px;
    max-width: 1350px;
    margin: auto;
    
}

.register-wrap {
    max-width: 330px;
    border-radius: 10px;
    border: 1px solid var(--border-color);
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--border-color);
    background: white;
    border-radius: 15px;
    overflow: hidden;
    
}
.title-section {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid rgb(224 226 236);

}
.section-title {
    display: flex;
    justify-content: space-between;
    color: black;
    font-size: 20px;
    margin: 0;
}
.tab-list {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgb(224 226 236);
  
}
.tab {
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: black;
    &.active {
        color: var(--primary-color);
        border-bottom: 2px solid var(--primary-color);
    }
}

.info-line {
    color: black;
    text-align: left;
    font-weight: 600;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 5px;

    div {
        font-size: 12px;
        color: white;
        padding: 4px 8px;
        border-radius: 5px;
        align-items: center;
        &.vefiried {
            background-color: green;
        }
        &.not-vefiried {
            background-color: red;
        }
    }
}
.line-label {
    text-align: left;
    font-weight: 600;
    margin: 0;
    margin-top: 5px;
    font-size: 12px;
    color: rgb(151, 151, 151);
}


.title {
    font-size: 28px;
    font-weight: 600;
    color: black;
}
.login {
    height: fit-content;
    text-align: right;
    margin: 2px;
    font-size: 11px;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
    margin-left: auto;
  }
  .error-item {
    color: red;
    margin: 0;
    height: fit-content;
    text-align: left;
    font-size: 9px;
    margin: 0;
    margin-left: -15px;
    margin-top: -5px;
  }
  .register {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 60px);
    color: #38314e;
    font-size: 23px;
    font-weight: bold;
    text-align: left;

  }
  .register-wrap {
    background-color: white;
    border-radius: 10px;

    padding: 20px;
  }
  .terms-page {
    max-height: 80vh;
    max-width: 1000px;
    overflow-y: auto;
    font-size: 0.8rem;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 330px;

    > * {
      margin: 10px;
      width: 100%;
      height: 35px;
      font-size: 15px;
    }
    input {
      border: 1px solid #2b263a;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      border-radius: 0;
      outline: none;
      caret-color: #2b263a;
      background-color: white;
      color: #38314e;
      padding: 3px 10px;
      box-sizing: border-box;

      &.error {
        border: 1px solid red;
      }
    }
    button {
      height: fit-content;
      margin-bottom: 0;
    }
   
  }
  .title {
    text {
      font-size: 20px;
      font-weight: bold;
      margin: auto;
    }
  }
  .email-confirm {
    justify-content: space-between;
  }
</style>