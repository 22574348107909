<template>
       
    <div class="main-corporate-item flex column gap30" :id="'id' + sectionEntity._id"
        :class="`${sectionMD?.share_holder_related ? '' : ''}`"
    >
        <button title='collapse & expand section' class='button-collapse transparent w-content mb-15 button-holder' @click="toggleSection">
            <img class="arrow-opener-section" :class="isOpen ? 'section-opened': ''" src="@/assets/arrow-opener.svg">
        </button>
        <div v-if="!isOpen" class="mb--30">
            <InpComponent
                class="name-input"
                label="Name of the Entity"
                :pendingChanges="hasChanges('name')"
                type="text"
                placeholder="Corporation Inc."
                
                :disabled="!canBeUpdated"
                :key_ref="'name'"
                :key="`name-${sectionEntity._id}`"
                :value="sectionEntity.name.value"
                :error="sectionEntity.name.error"
                @inputChange="inputChangeFunc"
            ></InpComponent>
        </div>
        <div class="main-holder content-collapse flex column" :id="'collapse_' + sectionEntity._id">
            <div class="flex row">
                <div class="flex column">
                    <InpComponent 
                        v-if="!sectionMD?.share_holder_related"
                        class="country-select"
                        label="Supervised"
                        type="options"
                        :options="[{value: false, text: 'No'}, {value: true, text: 'Yes'}]"
                        placeholder="Local"
                        :pendingChanges="hasChanges('supervised')"
                        
                        :disabled="!canBeUpdated"
                        :key_ref="'supervised'"
                        :key="`supervised-${sectionEntity._id}`"
                        :value="sectionEntity.supervised.value"
                        @inputChange="inputChangeFunc"
                    ></InpComponent>
                    <InpComponent
                        class="name-input"
                        label="Name of the Entity"
                        type="text"
                        placeholder="Corporation Inc."
                        :pendingChanges="hasChanges('name')"
                        
                        :disabled="!canBeUpdated"
                        :key_ref="'name'"
                        :key="`name-${sectionEntity._id}`"
                        :value="sectionEntity.name.value"
                        :error="sectionEntity.name.error"
                        @inputChange="inputChangeFunc"
                    ></InpComponent>
                    <InpComponent
                        class="name-input"
                        label="Address Line 1"
                        type="text"
                        placeholder="123 Main St"
                        :pendingChanges="hasChanges('address_line_1')"
                        :key_ref="'address_line_1'"
                        :value="sectionEntity.address_line_1.value"
                        :error="sectionEntity.address_line_1.error"
                        :disabled="!canBeUpdated"
                        @inputChange="inputChangeFunc"
                    ></InpComponent>

                    <InpComponent
                        label="Address Line 2 (optional)"
                        class="name-input"
                        type="text"
                        placeholder=""
                        :pendingChanges="hasChanges('address_line_2')"
                        :key_ref="'address_line_2'"
                        :value="sectionEntity.address_line_2.value"
                        :disabled="!canBeUpdated"
                        :error="sectionEntity.address_line_2.error"
                        @inputChange="inputChangeFunc"
                    ></InpComponent>

                    <InpComponent
                        label="City / Town"
                        class="name-input"
                        type="text"
                        placeholder="London"
                        :pendingChanges="hasChanges('city')"
                        :key_ref="'city'"
                        :value="sectionEntity.city.value"
                        :error="sectionEntity.city.error"
                        :disabled="!canBeUpdated"
                        @inputChange="inputChangeFunc"
                    ></InpComponent>

                    <InpComponent
                        class="country-select"
                        label="Country"
                        type="options"
                        :options="Utils && Utils.getCountryList()"
                        :pendingChanges="hasChanges('address_country')"
                        placeholder="Canada"
                        :key_ref="'address_country'"
                        :value="sectionEntity.address_country.value"
                        :disabled="!canBeUpdated"
                        :error="sectionEntity.address_country.error"
                        @inputChange="inputChangeFunc"
                    ></InpComponent>

                    <InpComponent
                        label="Zip"
                        class="name-input"
                        type="text"
                        placeholder="00000"
                        :pendingChanges="hasChanges('zip')"
                        :key_ref="'zip'"
                        :value="sectionEntity.zip.value"
                        :error="sectionEntity.zip.error"
                        :disabled="!canBeUpdated"
                        @inputChange="inputChangeFunc"
                    ></InpComponent>
                </div>
                <div v-if="shareholder" class="entity-structure flex column">
                    <h3 class="header-small flex-1 ">
                        Shareholder Structure for {{ sectionEntity.name.new_value || sectionEntity.name.value }}
                    </h3>
                    
                    <StructureChart
                        :shareholder_id="shareholder._id"
                        :type="'shareholder'"  
                        @click="showStructure = true"   
                        key_val='entity'    
                        :name="`Shareholder Structure for ${sectionEntity.name.new_value || sectionEntity.name.value }`"
                        :hover_effect="true"
                        :scaleToFit="true"
                    />
                </div>
                <div v-else-if="!reviewMode" class="entity-structure flex column" :id="'id-md' + sectionEntity._id">
                    <h3 class="header-small flex-1 ">
                        Managing Director Structure for {{ sectionEntity.name.new_value || sectionEntity.name.value }}
                    </h3>
                    <StructureChart
                        :mbd_id="sectionEntity.mbd_related"
                        :type="'entity'"  
                        :source="'entity'"
                        @click="showStructure = true"   
                        key_val='entity'   
                        :name="`Managing Director Structure for ${sectionEntity.name.new_value || sectionEntity.name.value }`"
                        :hover_effect="true"
                        :scaleToFit="true"
                    />
                </div>
            </div>
            <PopupCover v-if="showStructure" @close="showStructure = false">
                <PopupBody 
                    class="structure-view"
                    @close="() => showStructure = false"> 
                    <template #header>Shareholder Structure for {{ sectionEntity.name.new_value || sectionEntity.name.value }}</template>
                    <template #body>
                        <StructureChart 
                            v-if="shareholder"
                            :shareholder_id="shareholder._id"
                            :type="'shareholder'"     
                            key_val='entity_full' 
                            :name="`Shareholder Structure for ${sectionEntity.name.new_value || sectionEntity.name.value }`"

                        />
                        <StructureChart 
                            v-else
                            :mbd_id="sectionEntity.mbd_related"
                            :type="'entity'"     
                            :source="'entity'"
                            key_val='entity_full'
                            :name="`Shareholder Structure for ${sectionEntity.name.new_value || sectionEntity.name.value }`"
                        />
                    </template>
                </PopupBody>
            
            </PopupCover>
            <div class="table-wrap" style="margin-top: 40px;">
                <table style="height: 100%;">
                    <TableHeaderRow/>
                    <tbody>
                        <TableFileRow2
                            :fileId="sectionEntity.files.entity_aoi"
                            :reviewMode="props.reviewMode"
                        />
                        <TableFileRow2
                            :fileId="sectionEntity.files.entity_coc"
                            :reviewMode="props.reviewMode"
                        />
                        <TableFileRow2 
                            :fileId="sectionEntity.files.entity_members"
                            :reviewMode="props.reviewMode"
                        />
                        <TableFileRow2
                            :fileId="sectionEntity.files.entity_rodo"
                            :reviewMode="props.reviewMode"
                        />
                        <TableFileRow2
                            :fileId="sectionEntity.files.entity_soi"
                            :reviewMode="props.reviewMode"
                        />
                        <TableFileRow2 
                            v-if="isSupervised && isAbroad && !sectionMD?.share_holder_related"
                            :fileId="sectionEntity.files.entity_license"
                            :reviewMode="props.reviewMode"
                        />
                    </tbody>
                </table>
            </div>
        </div>
        <AddNewElement
            v-if="addNewElement"
            :type="addNewElement"
            :metaInfo="{
                mbd_related: sectionEntity.mbd_related,
                company_id: currentSectionId,
            }"
            @close="addNewElement = null"
        />
    </div>
</template>

<script setup>

import { ref, defineEmits, computed, withDefaults, defineProps, onMounted } from 'vue'
import { useStore } from 'vuex'
import TableFileRow2 from '@/components/TableFileRow2.vue'
import { PermissionChecker } from '@/utils/iam_utils'
import TableHeaderRow from '@/components/TableHeaderRow.vue'

import InpComponent from '@/components/InpComponent.vue'
import Utils from '@/utils/index.js'
import StructureChart from '@/components/StructureChart.vue'
import AddNewElement from './addNewElement.vue'
import PopupCover from '@/components/PopupCover.vue'
import PopupBody from '@/components/PopupBody.vue'

const addNewElement = ref(null)
const store = useStore()
const showStructure = ref(false);

const user = computed(() => store.state.user)
const props = defineProps([
    'entity_id',
    'reviewMode'
])

const isOpen = computed(() => store.getters.getToggleState(props.entity_id))



const sectionEntity = computed(() => store.getters.getEntityById(props.entity_id))
const sectionMD =     computed(() => store.getters.getMdById(sectionEntity.value.mbd_related))

const shareholder = computed(() => store.getters.getShareHolderById(sectionMD?.value?.share_holder_related))

const currentSectionId = computed(() => store.state.currentSectionId)

const notificationPresent = computed(() => props.reviewMode ?  [] : store.getters.getActionRequired(props.entity_id, 'legalEntity'))
const hasChanges = computed(() => (field) => {
    for (const item of notificationPresent.value) {
        let fields = JSON.parse(item.fields); 
        if (item.actionType == 'remove') {
            return true;
        }
        if (fields[field]?.new) {
            return true
        }
    }
})
const canBeUpdated = computed(() => {
    if (props.reviewMode) {
        return false;
    }
    return PermissionChecker.updatembdSection()
})

const isSupervised = computed(() => {
    return (sectionEntity.value.supervised.new_value || 
        (sectionEntity.value.supervised.value.toString() == 'true' && sectionEntity.value.supervised.new_value === '')
    )
})
const isAbroad = computed(() => {
    return (
        (sectionMD.value.location_type.new_value === 'abroad') ||
        (sectionMD.value.location_type.value === 'abroad' && sectionMD.value.location_type.new_value === '' )
    )
})

const HTMLElements = computed(() => {
    return document.getElementById('collapse_' + sectionEntity.value._id);
})


function toggleSection() {
    store.dispatch('toggleSection', {
        id: sectionEntity.value._id,
    })
}

function adjustHeight() {
    const section = HTMLElements.value;
    if (isOpen.value) {
        section.style.maxHeight = "none"; // Prevent transition issues
        section.style.overflowY = "visible"; // Prevent overflow
    } 
    else {
        section.style.maxHeight = "0"; // Collapse the section
        section.style.overflowY = "hidden"; // Hide overflow
    }
}

onMounted(() => {
    adjustHeight()
})


function inputChangeFunc(event) {
    const event_type = event[0];
    const event_value = event[1];
    store.commit('updateEntityById',{
        id: props.entity_id, 
        updates: {
            [`${event_type}`]: event_value,
        }
    })
}


</script>

<style scoped lang="scss">
.flex {
    display: flex;
    &.row {
        flex-direction: row;
    }
    &.column {
        flex-direction: column;
    }
}
.passport-fields {
    margin-top: 20px;
}
.name-input {
    max-width: 340px;

}
.supervision {

}
.main-corporate-item {
    position: relative;
}
.button-collapse {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 10;
}
.structure-view[class] {
// &:deep(.container) {
    width: 90vw;
    height: 80vh;
// }
}
.flex-start {
    align-items: flex-start;

}
.gap30 {
    gap: 30px;
}
textarea {
    resize: none; 
    box-sizing: border-box;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    padding: 15px;
    width: 685px;
    height: 150px;
}
.add-new {
    margin-left: auto;
}
.margin-top-40 {
    margin-top: 40px;
}
.margin-top-20 {
    margin-top: 20px;
}
.country-select {
    max-width: 340px;
}
.ubo_subsection {
    padding: 20px;
    background-color: var(--app-background);
    border: 1px solid var(--border-color);
    margin-bottom: 40px;
    border-radius: 5px;
}
table {
    border-collapse: collapse;
}
thead {
    font-weight: bold;
}
td {
    padding: 10px;
    border: 1px solid var(--border-color);
    
}
.entity-structure {
    padding: 20px;
    padding-top: 0;
    width: 100%;
    max-width: 100%;
    height: 290px;
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:deep(svg) {
        
        width: 100%;
        height: 250px;
        max-width: 700px;
    } 
}
.header-small {
    // position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
}
</style>