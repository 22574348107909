<template>
    <div class="subsidiary-container">
    <div class="subsidiary-content">
        <div class="sub-header" >
            <h4 class="subsidiary-title">Reviews History list</h4>
            <!-- <button @click="addObject()">Create version</button> -->
        </div>
        <table class="subsidiary-list">
            <thead>
                <tr>
                    <th>Name (label)</th>
                    <th>Date</th>
                </tr>
            </thead>
            <tbody class='table-content' v-if="store.state.labelYear">
                <tr class="account-list" v-for="snapshot in store.state.labelYear" :key="snapshot._id" @click="openObject(snapshot)">
                    <td class="user-info"> 
                        <div class="icon-person">
                            <img src="@/assets/history.svg" alt="kyc" />
                        </div>
                        <div class="user-name">
                            {{ snapshot.label }}
                        </div>
                    </td>
                    <td>{{ dayjs(snapshot.snapshotDate, 'YYYY-MM-DD').format('DD/MM/YYYY') }}</td>
                </tr>
            </tbody>
         </table>
     </div>
 
    
    <PopupCover v-if="showPopups" @close="closePopup">
        <SnapshotPopup 
            @close="closePopup" 
            :objectInfo="objectInformation"
            @InfoUpdate="createObjectPopupHandler"
            @objectAction="objectActionHandler"
            @delete_object="deleteObjectHandler"
        />
    </PopupCover>
 </div>
 </template>
 
 <script setup>

import { ref, defineEmits, computed, withDefaults, defineProps, watch, onMounted } from 'vue'
import InpComponent from '@/components/InpComponent.vue'

import API from '../api/index'
import store from '@/store/index.js';
import PopupCover from '@/components/PopupCover.vue';
import SnapshotPopup from '@/components/SnapshotPopup.vue';
import dayjs from 'dayjs'
 

import { useRouter, useRoute } from 'vue-router'

const router = useRouter();

const user = computed(() => store.state.user);
const emit = defineEmits(['delete_person', 'commentChange'])
const objectInformation = ref(null);
const showPopups = ref(false);


async function createObjectPopupHandler(event) {
    showPopups.value = false;
    const response = await API.createSnapshot(event.object);
    store.state.labelYear.unshift(response.snapshot)
}
async function objectActionHandler(event) {
    showPopups.value = false;

    const typeMap = {
        'corporate': 'corporate',
        'account_holder': 'account-holder',
    }


    store.dispatch('setValue', {key: 'selectedSnapshot', value: objectInformation.value});
    router.push(`${typeMap[event.object.company_type]}/${event.object.company_id}?snapshot=${objectInformation.value._id}`)
}

function closePopup() {
    showPopups.value = false;
    objectInformation.value = null;
}
async function deleteObjectHandler() {
    showPopups.value = false;
    const result = await API.deleteObject(objectInformation.value._id, 'revision', 'Document Revision');
    if (result) {
        store.state.labelYear = store.state.labelYear.filter(object => object._id !== objectInformation.value._id)
        store.dispatch('addNotification', {text: 'Document Revision has been deleted successfully', type: 'success'})
    }
    objectInformation.value = null;
}
function openObject(object) {
    objectInformation.value = object;
    showPopups.value = true;
}

function addObject() {
    showPopups.value = true;
}

onMounted(async() => {
    if (!user.value) {
        router.push('/login')
        return;
    }
});

</script>
 
<style scoped lang="scss">
    
    .icon-person {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 30px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 600;
        height: 30px;
        color: var(--primary-color);
        background: rgb(236, 240, 255);
    }
    .user-info {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .subsidiary-container {
        width: 100%;
    }
    
    .subsidiary-content {
        min-width: 100%;
        border: 1px solid #ccc;
        border-radius: 10px;
        width: fit-content;
    }
    
    .sub-header {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        padding: 20px;
        padding-left: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
    }
    
    .subsidiary-title {
        display: flex;
        justify-content: space-between;
        color: black;
        font-size: 20px;
    }
    
    .sub-header {
        display: flex;
        justify-content: space-between;
        button {
            width: fit-content;
            padding: 10px;
        }
    }
    .subsidiary-list {
        padding: 20px;
        width: 100%;
        border-collapse: collapse;
        th, td {
            // border: 1px solid #ccc;
            padding: 15px;
            text-align: left;
            border: none;
            padding-left: 30px;
            color: black;
        }
        td {
            padding-top: 19px;
            padding-bottom: 19px;
        }
        th {
            text-align: left;
            border: none;
        }
        tr {
            border-top: 1px solid #ccc;
        }
        tbody {
            tr {
                cursor: pointer;
                td {
                    background: white;
                }

                &:last-child {
                    td {
                        &:first-child {
                            border-bottom-left-radius: 10px;
                        }
                        &:last-child {
                            border-bottom-right-radius: 10px;
                        }
                    }
                }
                &:hover {
                    td {
                        background: #f9f9f9;
                    }
                }
            }
        }
    }
    
    .add-new td{
        padding-top: 0px;
        padding-bottom: 0px;
    
    }
    .save-button {
        padding: 10px 20px;
        width: fit-content;
        margin-left: auto;
    }
    h4 {
        margin: 0;
    }
    .add-new-subsidiary-area {
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .text-input {
        &:deep(input) {
            text-align: center;
        }
    }
 
 .more-actions {
    padding: 5px 0px;
    border-radius: 4px;
    width: 35px;
    height: 25px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
     cursor: pointer;
     &:hover {
         background: #d9d9d9;
 
         .dropdown {
             display: flex;
             flex-direction: column;
 
         }
     }
     position: relative;
 
     .dropdown {
        display: none;
        position: absolute;
        top: 35px;
        right: 0;
        background: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        z-index: 100;
        div {
            padding: 10px;
            cursor: pointer;
            &:hover {
                background: #d9d9d9;
            }
        }
     }
 }
 
</style>
