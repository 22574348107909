import { createStore } from 'vuex'
import API from '@/api/index.js'

import { new_comments } from '@/utils/kyc_utils.js'
function copy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export default createStore({
  state: {
    userPermissions: null,
    user: null,
    userAddress: null,
    userPhone: null,
    userInformation: null,
    loaded: false,
    notificationsCount: null,
    validationOn: false,
    credits_balance: null,
    response_text: null,
    notifications: [],
    sanctionScreeningRequestLoading: false,
    labelYear: null,
    roles: null,
    objectMap: null,
    approvedActions: 2,
    colapsedSections: {},
    confirmationNeeded: null,
    selectedSnapshot: null,
    kycDataLoaded: false,
    dataStructureStore: null,
    companyData: null,
    commentSection: null,
    commentSectionInitial: null,
    banks: null,
    savingData: false,
    proposedChanges: null,

    currentSectionId: null,
    kyc_files: [],
    kyc_ubos: [],
    kyc_entities: [],
    kyc_mds: [],
    kyc_persons: [],
    kyc_accountNumbers: [],
    kyc_transactionalProfile: [],
    kyc_shareholders: [],
    listingServices: null,
    subsidiaries: null,

    yearOptions: [
      {value: '2021', text: '2021'},
      {value: '2022', text: '2022'},
      {value: '2023', text: '2023'},
      {value: '2024', text: '2024'},
      {value: '2025', text: '2025'},
      {value: '2026', text: '2026'},
      {value: '2027', text: '2027'},
      {value: '2028', text: '2028'},
      {value: '2029', text: '2029'},
      {value: '2030', text: '2030'},
      {value: '2031', text: '2031'},
      {value: '2032', text: '2032'},
      {value: '2033', text: '2033'},
      {value: '2034', text: '2034'},
      {value: '2036', text: '2036'},
      {value: '2037', text: '2037'},
    ],
    corporateStructure: [],
    accounts: null,
    viewingToken: null,
    fileList: null,
    wallet: {
      loaded: false,
      address: null,
    }
  },
  getters: {
    getFileId: state => (documentType) => {
      let file = state.kyc_files.find(f => f.documentType === documentType);
      if (file) {
        return file.id;
      }
    },
    getFilesId: state => (documentType) => {
      
      let files = state.kyc_files.filter(f => f.documentType === documentType);
      let ids = [];
      if (files) {
        files.forEach(f => {
          ids.push(f.id);
        });
      }
      return ids;
    },
    getFileById: state => (file_id) => {
      if( !file_id ) {
        return
      }
      let fileId = file_id?.value;
      if (!fileId) {
        fileId = file_id;
      }
      return state.kyc_files.find(f => f.id === fileId);
    },
    getFilesMapID: state => {
      let filesMap = {};
      state.kyc_files.forEach(f => {
        filesMap[f.id] = f;
      })
      return filesMap;
    },
    getWorldCheckEditablePrefix: state => {
      let accountTypeMap = {
        'DATA_CONTROLLER': '',
        'DATA_PROCESSOR': '_supervisor',
        'COMPLIANCE_OFFICER': '_compliance',
      }
      console.log('getWorldCheckEditablePrefix', state.user.type, accountTypeMap[state.user.type])
      return accountTypeMap[state.user.type];
    },
    
    getCompanyType: state => {
      if (!state.companyData) {
        return null;
      }
      return state.companyData.location_type.new_value || state.companyData.location_type.value;
    },
    getActionRequired: state => (id, objectType) => {
      let objId = id?.value || id;
      let confirmationNeeded = state.confirmationNeeded.filter( obj => obj.objectType === objectType && obj[objectType] === objId);
      return confirmationNeeded;
    },

    getCorporateFilesReadiness: state => {
      return state.kyc_files.filter( file => !file.person && !file.legalEntity && !file.mbd_related && !file.ubo_related)
    },
    getFiles: state => (documentType) => {
      return state.kyc_files.filter(f => f.documentType === documentType);
    },
    getCorporateUbo: state => {
      return state.kyc_ubos.filter(ubo => !ubo.mbd_related);
    },
    shareholders: state => (md_id = null) => {
      return state.kyc_shareholders.filter(shareholder => shareholder.mbd_related == md_id);
    },
    getUboById: state => (ubo_id) => {
      return state.kyc_ubos.find(ubo => ubo._id === ubo_id);
    },
    getPersonOfUboById: state => (ubo_id) => {
      return state.kyc_persons.find(person => person.ubo_related === ubo_id);
    },
    getPersonById: state => (person_id) => {
      return state.kyc_persons.find(person => person._id === person_id);
    },
    managingDirectors: state => (share_holder_related = null, mbd_related = null) => {
      return state.kyc_mds.filter(md => md.share_holder_related == share_holder_related && md.mbd_related == mbd_related);
    },
    getMdById: state => (md_id) => {
      return state.kyc_mds.find(md => md._id === md_id);
    },
    checkmarksConform: state => (file_id) => {
      let userType = state.user.type;
      let same = true
      let file = state.kyc_files.find(f => f.id === file_id);
      if (!file) {
        return false
      }
      if (file.documentType !== 'world_check') {
        return true
      }
      if (userType == 'DATA_CONTROLLER') {
        return true
      }
      let pep_found = file?.new_pep_found || file?.pep_found;
      let sanctions_found = file?.new_sanctions_found || file?.sanctions_found;
      let relevant_adverse_information_found = file?.new_relevant_adverse_information_found || file?.relevant_adverse_information_found
      
      let pep_found_supervisor = file?.new_pep_found_supervisor || file?.pep_found_supervisor
      let sanctions_found_supervisor = file?.new_sanctions_found_supervisor || file?.sanctions_found_supervisor
      let relevant_adverse_information_found_supervisor = file?.new_relevant_adverse_information_found_supervisor || file?.relevant_adverse_information_found_supervisor

      let pep_found_compliance = file?.new_pep_found_compliance || file?.pep_found_compliance
      let sanctions_found_compliance = file?.new_sanctions_found_compliance || file?.sanctions_found_compliance
      let relevant_adverse_information_found_compliance = file?.new_relevant_adverse_information_found_compliance || file?.relevant_adverse_information_found_compliance

      console.log('DIFF', file, {
        
        pep_found,
        sanctions_found,
        relevant_adverse_information_found,
        pep_found_supervisor,
        sanctions_found_supervisor,
        relevant_adverse_information_found_supervisor,
        pep_found_compliance,
        sanctions_found_compliance,
        relevant_adverse_information_found_compliance,
      })
      
      if (['DATA_PROCESSOR','COMPLIANCE_OFFICER'].includes(userType)) {
        same = (
          pep_found_supervisor == pep_found &&
          sanctions_found_supervisor == sanctions_found &&
          relevant_adverse_information_found_supervisor == relevant_adverse_information_found
        )
        console.log('same', same)
      }
      if (userType == 'COMPLIANCE_OFFICER') {

        same = same && (
          pep_found_compliance == pep_found &&
          sanctions_found_compliance == sanctions_found &&
          relevant_adverse_information_found_compliance == relevant_adverse_information_found
        )
      }
      return same
    },
    getOfficialsOfMd: state => (md_id) => {
      return state.kyc_persons.filter(person => person.mbd_related === md_id && !person.ubo_related);
    },
    getEntityOfMD: state => (md_id) => {
      return state.kyc_entities.find(entity => entity.mbd_related === md_id);
    },
    getEntityById: state => (entity_id) => {
      return state.kyc_entities.find(entity => entity._id === entity_id);
    },
    UBOs: state => (share_holder_related) => {
      return state.kyc_ubos.filter(ubo => ubo.share_holder_related === share_holder_related);
    },
    getMdUBOs: state => (md_id) => {
      return state.kyc_ubos.filter(ubo => ubo.mbd_related === md_id);
    },
    getSubsidiaryById: state => (subsidiary_id) => {
      if (state.subsidiaries === null) {
        return null;
      }
      return state.subsidiaries.find(subsidiary => subsidiary._id === subsidiary_id);
    },
    getTransactionRevewialProfileById: state => (id) => {
      return state.kyc_transactionalProfile.find(trans_prof => trans_prof._id === id);
    },
    getAccountNumberById: state => (id) => {
      return state.kyc_accountNumbers.find(account => account._id === id);
    },
    getShareholderMDs: state => (md_id) => {
      return state.kyc_mds.filter(md => md.mbd_related === md_id);
    },
    getToggleState: state => (id) => {
      if (state.colapsedSections[id] != undefined) {
        return state.colapsedSections[id];
      }
      return true;
    },
    getShareHolderById: state => (id) => {
      return state.kyc_shareholders.find(shareholder => shareholder._id === id);
    },
    getUboofShareholderById: state => (id) => {
      return state.kyc_ubos.find(ubo => ubo.share_holder_related === id);
    },
    getMDofShareholderById: state => (id) => {
      return state.kyc_mds.find(md => md.share_holder_related === id);
    },
    needToSave: state => {
      let mdFields = ['entity_type', 'location_type'];
      let personFields = [
        'location_type',
        'percentage_held',
        'title',
        'placeofBirth',
        'firstName',
        'lastName',
        'tinNumber',
        'passportNumber',
        'passportCountry',
        'dateIssued',
        'dateExpiry',
        'dateOfBirth',
        'secondPassportType',
        'secondPassportNumber',
        'secondPassportCountry',
        'secondDateIssued',
        'secondDateExpiry',
        'address_line_1',
        'address_line_2',
        'address_date_issues',
        'city',
        'address_country',
        'zip'
      ];
      let entityFields = [
        'supervised',
        'name',
        'address_line_1',
        'address_line_2',
        'city',
        'address_country',
        'zip',
      ]
      if (!state.kyc_mds) {
        return false
      }
      for(let md of state.kyc_mds) {
        for (let field of mdFields) {
          let newValue = md[field].new_value.trim ? md[field].new_value.trim() : md[field].new_value;
          if (newValue !== md[field].value && newValue) {
            return true;
          }
        }
      }
      for (let person of state.kyc_persons) {
        for (let key of personFields) {
          let newValue = person[key].new_value.trim ? person[key].new_value.trim() : person[key].new_value;
          if (newValue !== person[key].value && newValue) {
            return true;
          }
        }
      }
      for (let entity of state.kyc_entities) {
        for (let key of entityFields) {
          let newValue = entity[key].new_value.trim ? entity[key].new_value.trim() : entity[key].new_value;
          if (
              newValue !== entity[key].value && 
              newValue.toString().length > 0
            ) {
              return true;
          }
        }
      }
      for (let file of state.kyc_files) {
        const ObjectToSend = {}
        let attachedFile = null
        const newFIleUploaded = file.new_file;
        if (newFIleUploaded) {
          attachedFile = file.file
        }
        const metaFields = [
          'dateIssued', 'fiscal_year_ending_on', 'year', 'bank_name', 
          'pep_found',
          'relevant_adverse_information_found',
          'sanctions_found',
          'pep_found_supervisor',
          'sanctions_found_supervisor',
          'relevant_adverse_information_found_supervisor',
          'pep_found_compliance',
          'sanctions_found_compliance',
          'relevant_adverse_information_found_compliance',

        ]

        const comments = [
          'comment_data_handler', 
          'comment_compliance_officer', 
          'comment_supervisor',
          'sanctions_comment',
          'pep_comment',
          'relevant_adverse_information_comment',
          'sanctions_comment_supervisor',
          'pep_comment_supervisor',
          'relevant_adverse_information_comment_supervisor',
          'sanctions_comment_compliance',
          'pep_comment_compliance',
          'relevant_adverse_information_comment_compliance',
        ]
        
        for (let field of metaFields) {
          if (file['new_' + field] && file['new_' + field].trim() !== file[field]) {
            ObjectToSend[field] = file['new_' + field]
          }
        }
        for (let field of comments) {
          if (new_comments(file, field)) {
            ObjectToSend[field] = JSON.stringify(file['new_' + field])
          }
        }

        if (attachedFile || Object.keys(ObjectToSend).length > 0) {
          return true;
        }
      }
      for (let accountNumber of state.kyc_accountNumbers) {
        let fields = [
          'account_number',
        ]
        for (let field of fields) {
          let newValue = accountNumber[field].new_value.trim ? accountNumber[field].new_value.trim() : accountNumber[field].new_value;
          if (newValue !== accountNumber[field].value && newValue) {
            return true;
          }
        }
      }
      for (let transactionProfile of state.kyc_transactionalProfile) {
        let fields = [
          'name_bank',
          'currency',
          'transaction_value_incoming',
          'monthly_turnover_incoming',
          'monthly_frequency_of_transaction_incoming',
          'transaction_value_outgoing',
          'monthly_turnover_outgoing',
          'monthly_frequency_of_transaction_outgoing',
          'outgoing_counterparty',
          'outgoing_counterparty_2',
          'outgoing_counterparty_3',
          'incoming_counterparty',
          'incoming_counterparty_2',
          'incoming_counterparty_3',
        ]
        const comments = [
          'comments',
          'expected_transaction_incoming_info',
          'expected_transaction_outgoing_info',
        ]
        for (let field of fields) {
          let newValue = transactionProfile[field].new_value.trim ? transactionProfile[field].new_value.trim() : transactionProfile[field].new_value;
          if (newValue !== transactionProfile[field].value && newValue) {
            console.log('transactionProfile', field, newValue, transactionProfile[field].value)
            return true;
          }
        }
        console.log('transactionProfile', transactionProfile)
        for (let field of comments) {
          let result = new_comments(transactionProfile, field);
          console.log('transactionProfile', result, field) 
          if (new_comments(transactionProfile, field)) {
            return true;
          }
        }
      }
      for (let shareholder of state.kyc_shareholders) {
        let fields = [
          'percentage_held',
          'shareholder_type',
        ]
        for (let field of fields) {
          if (shareholder[field].new_value !== shareholder[field].value && shareholder[field].new_value) {
            return true;
          }
        }
      }
      {
        for (let key in state.companyData) {
          if (['_id', 'company_type'].includes(key)) {
            continue
          }
          let newValue = state.companyData[key].new_value.trim ? state.companyData[key].new_value.trim() : state.companyData[key].new_value;
          if (newValue !== state.companyData[key].value && newValue) {
            return true;
          }
        }
      }
      if (state.listingServices?.new_value && state.listingServices?.new_value !== state.listingServices?.value) {
        return true;
      }
      return false
    },
    getStructureForChart: (state, getters)  => ({ id, type, source = 'shareholder', root = false, shareholder_id, mbd_id, full = false}) => {
      if (!type) return null;

      if (type === "shareholder") {
        let shareholders = []
        if (shareholder_id) {
          shareholders.push(getters.getShareHolderById(shareholder_id))
        } else {
          shareholders = getters.shareholders(id);

        }
        if (!shareholders) return null;

        const resultShares = shareholders.map(shareholder => {
          let jsShare = copy(shareholder);
          jsShare.type = 'shareholder';
          jsShare.children = [];
          jsShare.percentage_held = jsShare.percentage_held.new_value || jsShare.percentage_held.value;
          jsShare.shareholder_type = shareholder.shareholder_type.new_value || shareholder.shareholder_type.value;
          if (jsShare.shareholder_type === 'person') {
            let ubo = getters.getUboofShareholderById(shareholder._id);
            let person = getters.getPersonOfUboById(ubo._id);
            let person_copy = copy(person);
            let element = {
              _id: person_copy._id,
              name: `${person_copy.firstName.value} ${person_copy.lastName.value}`,
              type: 'ubo',
            }
            if (full) {
              element = {
                ...person_copy,
                type: 'ubo',
              };
            }

            jsShare.children.push(element);
            return jsShare

          } else {
            // always a single one for shareholder (there can only be one entity inside a shareholder)
            let md = getters.getMDofShareholderById(shareholder._id);
            let shareholderResults = getters.getStructureForChart({ id: md._id, type: "entity", source: 'shareholder', mbd_id: md._id, full })
            
            return {
              ...jsShare,
              children: shareholderResults
            };
          } 
        })


        if (root) {
          if (!id) {
            let mds = getters.getStructureForChart({ id: id, type: "entity", source: 'entity', full })
            
            console.log('aaaaaaaaaa', mds, resultShares)

            let resutl = [{
              name: state.companyData.name.value,
              type: 'main_for_sh',
              children: [...resultShares],
              someOtherStuff: mds
            }]
            return resutl
          }
        }
        return resultShares


      } else if (type === "entity") {
        let mdsResult = [];
        if (mbd_id) {
          mdsResult.push(getters.getMdById(mbd_id))
        } else  {
          mdsResult = getters.managingDirectors(id);
        }

        if (!mdsResult) return null;

        let resMDs = mdsResult.map(md => {
          let jsMD = copy(md);
          jsMD.type = source == 'shareholder' ? 'corporate_shareholder' : 'corporate';
          jsMD.children = [];
          jsMD.entity_type = jsMD.entity_type.new_value || jsMD.entity_type.value;
          let entity = getters.getEntityOfMD(jsMD._id);
          md.name = entity.name.value;
          jsMD.supervised = entity.supervised.value;
          
          if (full) {
            jsMD.entity = entity;
          }

          let officials = getters.getOfficialsOfMd(jsMD._id);
          for (let official of officials) {
            let copyOfficial = copy(official);
            if (full) {
              jsMD.children.push({
                type: 'official',
                ...copyOfficial
              });
            } else {
              jsMD.children.push({
                type: 'official',
                name: `${copyOfficial.firstName.value} ${copyOfficial.lastName.value}`,
              });
            }
          }
          getters.shareholders(jsMD._id).forEach(shareholderEl => {
            jsMD.children.push(...getters.getStructureForChart({ id: md._id, shareholder_id: shareholderEl._id, type: "shareholder", source: 'entity', full }))
          })
          getters.getShareholderMDs(jsMD._id).forEach(mdel => {
            jsMD.children.push(...getters.getStructureForChart({ id: mdel._id, type: "entity", source: 'entity', mbd_id: mdel._id, full }))
          })
          return jsMD
        })

        if (root) {
          if (!id) {

            const result = [{
              name: state.companyData.name.value,
              type: 'main_for_md',
              children: resMDs,
            }]
            console.log('_result__', result)
            return result
          }
        }
        return resMDs;
      }
    }
  },
  mutations: {
    setValue(state, {key, value}) {
      state[key] = value;
    },
    updateListingServices(state, listingServices) {
      state.listingServices.new_value = listingServices;
    },
    resetListingServices(state, value) {
      state.listingServices.new_value = '',
      state.listingServices.value = value
    },
    addUboObjects(state, uboResponse) {
      state.kyc_files.unshift(...uboResponse.files);
      state.kyc_persons.unshift(uboResponse.person);
      state.kyc_ubos.unshift(uboResponse.ubo);
    },
    addPersonObject(state, officialResponse) {
      state.kyc_files.unshift(...officialResponse.files);
      state.kyc_persons.unshift(officialResponse.person);
    },
    addMDObject(state, mdResponse) {
      state.kyc_files.unshift(...mdResponse.files);
      state.kyc_entities.unshift(mdResponse.entity);
      if (mdResponse.person) {
        console.log('aaaaaaaavfawa', mdResponse.person)
        state.kyc_persons.unshift(mdResponse.person);
      }
      console.log('aaaaaaaaaaaddMDObject', state.kyc_persons[0])
      state.kyc_mds.unshift(mdResponse.md);
    },
    addShareholderObject(state, shareholderResponse) {
      state.kyc_files.unshift(...shareholderResponse.files);
      state.kyc_entities.unshift(shareholderResponse.entity);
      state.kyc_persons.unshift(...shareholderResponse.person);
      state.kyc_mds.unshift(shareholderResponse.md);
      state.kyc_shareholders.unshift(shareholderResponse.shareHolder);
      state.kyc_ubos.unshift(shareholderResponse.ubo);
    },
    addNotification(state, notification) {
      const timeout = notification.type == 'error' ? 7_000 : 3_000;
      const id = Math.floor(Math.random() * 1000);
      state.notifications.push({...notification, id});
      setTimeout(() => {
        state.notifications = state.notifications.filter(n => n.id !== id);
      }
      , timeout);
    },
    updateFileById(state, payload) {
      const { id, updates, full = false } = payload;
      
      let fileId = id?.value;
      if (!fileId) {
        fileId = id;
      }
      const itemIndex = state.kyc_files.findIndex(item => item.id === fileId);
      if (itemIndex !== -1) {
        if (full) {
          state.kyc_files[itemIndex] = updates;
        } else {
          state.kyc_files[itemIndex] = { ...state.kyc_files[itemIndex], ...updates };
        }
      }
    },
    updateShareHolderById(state, payload) {
      const { id, updates, full = false } = payload;
      const itemIndex = state.kyc_shareholders.findIndex(item => item._id === id);
      if (itemIndex !== -1) {
        for (const key in updates) {
          if (full) {
            state.kyc_shareholders[itemIndex][key] = updates[key];
          } else {
            state.kyc_shareholders[itemIndex][key].new_value = updates[key];
          }
        }
      }
    },
    updateEntityById(state, payload) {
      const { id, updates, full = false } = payload;
      console.log('updateEntityById', id, updates)
      const itemIndex = state.kyc_entities.findIndex(item => item._id === id);
      if (itemIndex !== -1) {
        for (const key in updates) {
          if (full) {
            console.log('full', key, updates[key])
            state.kyc_entities[itemIndex][key] = updates[key];
          } else {
            state.kyc_entities[itemIndex][key].new_value = updates[key];
          }
        }
      }
    },
    updatePersonById(state, payload) {
      const { id, updates, full = false } = payload;

      const itemIndex = state.kyc_persons.findIndex(item => item._id === id);
      console.log('itemIndex', itemIndex, id)
      if (itemIndex !== -1) {
        for (const key in updates) {
          if (full) {
            state.kyc_persons[itemIndex][key] = updates[key];
          } else {
            state.kyc_persons[itemIndex][key].new_value = updates[key];
          }
        }
      }
    },
    updateManagingDirrectorById(state, payload) {
      const { id, updates, full = false } = payload;

      const itemIndex = state.kyc_mds.findIndex(item => item._id === id);
      if (itemIndex !== -1) {
        for (const key in updates) {
          if (full) {
            state.kyc_mds[itemIndex][key] = updates[key];
          } else {
            state.kyc_mds[itemIndex][key].new_value = updates[key];
          }
        }
      }
    },
    updateAccountNumberById(state, payload) {
      const { id, updates, full = false } = payload;

      const itemIndex = state.kyc_accountNumbers.findIndex(item => item._id === id);
      if (itemIndex !== -1) {
        for (const key in updates) {
          if (full) {
            state.kyc_accountNumbers[itemIndex][key] = updates[key];
          } else {
            state.kyc_accountNumbers[itemIndex][key].new_value = updates[key];
          }
        }
      }
    },
    updateTransactionProfileById(state, payload) {
      const { id, updates, not_new = false, full = false } = payload;
    
      const fieldToUpdate = not_new ? 'value' : 'new_value';

      console.log('updateTransactionProfileById', fieldToUpdate, id, JSON.parse(JSON.stringify(updates)))
      const itemIndex = state.kyc_transactionalProfile.findIndex(item => item._id === id);
      if (itemIndex !== -1) {
        for (const key in updates) {
          if (full) {
            if (key === 'expected_transaction_outgoing_info') {
              console.log('expected_transaction_outgoing_info', updates[key], JSON.parse(JSON.stringify(updates[key])))
            }
            state.kyc_transactionalProfile[itemIndex][key] = JSON.parse(JSON.stringify(updates[key]));
          } else {
            state.kyc_transactionalProfile[itemIndex][key][fieldToUpdate] = updates[key];
          }
        }
      }
    },
    updateKycReviewInfo(state, payload) {
      const {updates, full = false} = payload;
      console.log('updateKycReviewInfo', updates)
      for (const key in updates) {
        if (full) {
          state.companyData[key] = updates[key];
        } else {
          state.companyData[key].new_value = updates[key];
        }
      }
    },
    addAccountNumberMutations(state, accountNumber) {
      state.kyc_accountNumbers.push(accountNumber);
    },
    addTransactionProfileMutations(state, transactionProfile) {
      state.kyc_transactionalProfile.push(transactionProfile);
    }
  },
  actions: {
    setValue({ commit }, newValue) {
      commit('setValue', newValue);
    },
    addNotification({ commit }, notification) {
      commit('addNotification', notification);
    },
    async addNewUbo({ commit }, ubo) {
      let uboCreateResponse = await API.createNewUbo(ubo);
      commit('addUboObjects', uboCreateResponse);
    },
    async addNewOfficial({commit}, official) {
      let officialCreateResponse = await API.createPerson(official);
      commit('addPersonObject', officialCreateResponse);
      return true;
    },
    async addNewMD({commit}, obj) {
      let managindDirrectorResponse = await API.createMD(obj);
      commit('addMDObject', managindDirrectorResponse);
      return true;
    },
    async addNewShareHolder({commit}, obj) {
      let shareholderResponse = await API.createShareholder(obj);
      commit('addShareholderObject', shareholderResponse);
      return true
    },
    async addNewBankDocument({state, commit}, {documentType, bank_name}) {
      const fileResponse = await API.createFileSection({documentType, bank_name, company_id: state.companyData._id});
      commit('setValue', {key: 'kyc_files', value: [fileResponse.file, ...state.kyc_files]});
      return true
    },
    async addAccountNumber({commit}, {id}) {
      let accountNumberResponse = await API.createAccountNumber(id);
      commit('addAccountNumberMutations', accountNumberResponse.accountNumber);
      commit('addTransactionProfileMutations', accountNumberResponse.transactionalProfile);
    },
    loadcolapsedSections({ commit }) {
      const colapsedSections = localStorage.getItem('colapsedSections');
      if (colapsedSections) {
        commit('setValue', {key: 'colapsedSections', value: JSON.parse(colapsedSections)});
      }
    },
    saveColapsedSections({ state }, _) {
      localStorage.setItem('colapsedSections', JSON.stringify(state.colapsedSections));
    },
    toggleSection({state, dispatch}, {id}) {
      const section = document.getElementById('collapse_' + id);
      console.log('toggleSection', id, section)
      let isOpenSection = state.colapsedSections[id] != undefined ? state.colapsedSections[id] : true;

      state.colapsedSections[id] = !isOpenSection;
      isOpenSection = !isOpenSection;
      dispatch('saveColapsedSections')
      if (isOpenSection) {
        section.style.maxHeight = section.scrollHeight + "px";
        section.style.overflowY = "hidden"; 
        setTimeout(() => {
        if (isOpenSection) {
            section.style.maxHeight = "none";
            section.style.overflowY = "visible";
        }
        }, 300); 
      } else {
        section.style.maxHeight = section.scrollHeight + "px"; 
        section.style.overflowY = "hidden";
        requestAnimationFrame(() => {
          section.style.maxHeight = "0"; 
        });
      }
    },
    async saveKycValues({state, commit}) {
      // TODO validate
      commit('setValue', {key: 'savingData', value: true});
      let updatesMade = false;
      let mdFields = ['entity_type', 'location_type'];
      
      let personFields = [
        'location_type',
        'percentage_held',
        'placeofBirth',
        'title',
        'firstName',
        'lastName',
        'tinNumber',
        'passportNumber',
        'passportCountry',
        'dateIssued',
        'dateExpiry',
        'dateOfBirth',
        'secondPassportType',
        'secondPassportNumber',
        'secondPassportCountry',
        'secondDateIssued',
        'secondDateExpiry',
        'address_line_1',
        'address_line_2',
        'address_date_issues',
        'city',
        'address_country',
        'zip'
      ];

      let entityFields = [
        'supervised',
        'name',
        'address_line_1',
        'address_line_2',
        'city',
        'address_country',
        'zip',
      ]

      for(let md of state.kyc_mds) {
        let fieldsToSend = {};
        for (let field of mdFields) {
          let newValue = md[field].new_value.trim ? md[field].new_value.trim() : md[field].new_value;

          if (newValue !== md[field].value && newValue) {
            fieldsToSend[field] = newValue;
          }
        }
        if (Object.keys(fieldsToSend).length > 0) {
          const response = await API.updateMD(md._id, fieldsToSend);
          updatesMade = true;
          commit('updateManagingDirrectorById', {id: md._id, updates: response.md, full: true});
        }
      }
      for (let person of state.kyc_persons) {
        let fieldsToSend = {};
        for (let key of personFields) {
          let newValue = person[key].new_value.trim ? person[key].new_value.trim() : person[key].new_value;

          if (newValue !== person[key].value && newValue) {
            fieldsToSend[key] = newValue;
          }
        }
        if (Object.keys(fieldsToSend).length > 0) {
          const response = await API.updatePerson(person._id, fieldsToSend);
          updatesMade = true;
          commit('updatePersonById', {id: person._id, updates: response.person, full: true});
        }
      }
      for (let entity of state.kyc_entities) {
        let fieldsToSend = {};
        for (let key of entityFields) {
          let newValue = entity[key].new_value.trim ? entity[key].new_value.trim() : entity[key].new_value;

          if (
              newValue !== entity[key].value && 
              ( 
                newValue.toString().length > 0
              )
            ) {
              fieldsToSend[key] = newValue;
          }
        }
        if (Object.keys(fieldsToSend).length > 0) {
          const response = await API.updateEntity(entity._id, fieldsToSend);
          updatesMade = true;
          commit('updateEntityById', {id: entity._id, updates: response.entity, full: true});
        }
      }
      for (let file of state.kyc_files) {

        const ObjectToSend = {}
        let attachedFile = null
        const newFIleUploaded = file.new_file;
        if (newFIleUploaded) {
          attachedFile = file.file
        }
        
        const metaFields = [
          'dateIssued', 'fiscal_year_ending_on', 'year', 'bank_name', 
          'pep_found',
          'relevant_adverse_information_found',
          'sanctions_found',
          'pep_found_supervisor',
          'sanctions_found_supervisor',
          'relevant_adverse_information_found_supervisor',
          'pep_found_compliance',
          'sanctions_found_compliance',
          'relevant_adverse_information_found_compliance',
        ]

        const comments = [
          'comment_data_handler', 
          'comment_compliance_officer', 
          'comment_supervisor',
          'sanctions_comment',
          'pep_comment',
          'relevant_adverse_information_comment',
          'sanctions_comment_supervisor',
          'pep_comment_supervisor',
          'relevant_adverse_information_comment_supervisor',
          'sanctions_comment_compliance',
          'pep_comment_compliance',
          'relevant_adverse_information_comment_compliance',
        ]
        
        for (let field of metaFields) {
          if (file['new_' + field] && file['new_' + field].trim() !== file[field]) {
            ObjectToSend[field] = file['new_' + field]
          }
        }
        for (let field of comments) {
          if (new_comments(file, field)) {
            ObjectToSend[field] = JSON.stringify(file['new_' + field])
          }
        }
      
       
        if (attachedFile || Object.keys(ObjectToSend).length > 0) {
          const response = await API.updateFile(file.id, attachedFile, ObjectToSend);
          updatesMade = true;
          commit('updateFileById', {id: file.id, updates: response.file, full: true});
        }
      }
      for (let accountNumber of state.kyc_accountNumbers) {
        let fields = [
          'account_number',
        ]
        let fieldsToSend = {};
        for (let field of fields) {
          let newValue = accountNumber[field].new_value.trim ? accountNumber[field].new_value.trim() : accountNumber[field].new_value;
          if (newValue !== accountNumber[field].value && newValue) {
            fieldsToSend[field] = newValue;
          }
        }
        if (Object.keys(fieldsToSend).length > 0) {
          const response = await API.updateAccountNumber(accountNumber._id, fieldsToSend);
          updatesMade = true;
          commit('updateAccountNumberById', {id: accountNumber._id, updates: response.accountNumber, full: true});
        }
      }
      for (let transactionProfile of state.kyc_transactionalProfile) {
        let fields = [
          'name_bank',
          'currency',
          'expected_transaction_incoming_info',
          'expected_transaction_outgoing_info',
          'transaction_value_incoming',
          'monthly_turnover_incoming',
          'monthly_frequency_of_transaction_incoming',
          'transaction_value_outgoing',
          'monthly_turnover_outgoing',
          'monthly_frequency_of_transaction_outgoing',
          'outgoing_counterparty',
          'outgoing_counterparty_2',
          'outgoing_counterparty_3',
          'incoming_counterparty',
          'incoming_counterparty_2',
          'incoming_counterparty_3',
          'comments',
        ]
        let fieldsToSend = {};
        for (let field of fields) {
          let newValue = transactionProfile[field].new_value.trim ? transactionProfile[field].new_value.trim() : transactionProfile[field].new_value;
          if (newValue !== transactionProfile[field].value && newValue) {

            let value = newValue;
            if (['comments', 'expected_transaction_incoming_info', 'expected_transaction_outgoing_info'].includes(field)) {
              value = JSON.stringify(value);  
            }
            fieldsToSend[field] = value;
          }
        }
        if (Object.keys(fieldsToSend).length > 0) {
          const response = await API.updateTransactionProfile(transactionProfile._id, fieldsToSend);
          updatesMade = true;
          console.log('22222 response', JSON.parse(JSON.stringify(response)))
          commit('updateTransactionProfileById', {id: transactionProfile._id, updates: JSON.parse(JSON.stringify(response.transactionProfile)), full: true});
        }
      }
      for (let shareholder of state.kyc_shareholders) {
        let fields = [
          'percentage_held',
          'shareholder_type',
        ]
        let fieldsToSend = {};
        for (let field of fields) {
          if (shareholder[field].new_value !== shareholder[field].value && shareholder[field].new_value) {
            fieldsToSend[field] = shareholder[field].new_value;
          }
        }
        if (Object.keys(fieldsToSend).length > 0) {
          const response = await API.updateShareholder(shareholder._id, fieldsToSend);
          updatesMade = true;
          commit('updateShareHolderById', {id: shareholder._id, updates: response.shareholder, full: true});
        }
      }
      {
        let fieldsToSend = {};
        console.log('state.companyData', state.companyData)

        for (let key in state.companyData) {
          if (['_id', 'company_type'].includes(key)) {
            continue
          }
          let newValue = state.companyData[key].new_value.trim ? state.companyData[key].new_value.trim() : state.companyData[key].new_value;
          
          if (newValue !== state.companyData[key].value && newValue) {
            fieldsToSend[key] = newValue;
          }

        }
        console.log('fieldsToSend', fieldsToSend)
        if (Object.keys(fieldsToSend).length > 0) {
          const response = await API.updateKycReviewInfo(state.companyData._id, fieldsToSend);
          updatesMade = true;
          console.log('response', response)
          commit('updateKycReviewInfo', {updates: response.companyData, full: true});
        }
      }
      if (state.listingServices?.new_value && state.listingServices.new_value !== state.listingServices.value) {
        const response = await API.updateListingServices({ value: state.listingServices.new_value, company_id: state.companyData._id });
        updatesMade = true;
        commit('resetListingServices', state.listingServices.new_value);
      }
      
      if (updatesMade) {
        commit('addNotification', {type: 'success', text: 'Data saved successfully'});
      }
      commit('setValue', {key: 'savingData', value: false});
      const info = await API.getNotificationList({page: 1, view: 'forConfirmation', id: state.companyData._id});
      commit('setValue', {key: 'confirmationNeeded', value: info.notifications});
      commit('setValue', {key: 'notificationsCount', value: info.notifications.length});
    },
    async deleteUbo({state, commit}, {ubo_id}) {
      const ubo = state.kyc_ubos.find(ubo => ubo._id === ubo_id);
      if (ubo) {
        const result = await API.deleteObject(ubo_id, 'ubo', 'UBO');
        if (result) {
          commit('setValue', {key: 'kyc_ubos', value: state.kyc_ubos.filter(ubo => ubo._id !== ubo_id)});
        }
      }
    },
    async deletePerson({state, commit}, {person_id}) {
      const person = state.kyc_persons.find(person => person._id === person_id);
      if (person) {
        const result = await API.deleteObject(person_id, 'person', 'Individual');
        if (result) {
          commit('setValue', {key: 'kyc_persons', value: state.kyc_persons.filter(person => person._id !== person_id)});
        }
      }
    },

    // getPersonRelatedElements({state}, {id}){
    //   let sourceElement = state.proposedChanges.find(element => element.person === id);
    //   return sourceElement;
    // },

    // getRelatedElements({state, dispatch}, {id}){
    //   let sourceElement = state.proposedChanges.find(element => element._id === id);
    //   if (!sourceElement) {
    //     return null;
    //   }
    //   if (sourceElement.type === 'kyc_file') {
    //     return state.proposedChanges.filter(proposition => proposition.kyc_file === sourceElement._id);
    //   }
    //   if (sourceElement.type === 'person') {
    //     return dispatch('getPersonRelatedElements', {id: sourceElement._id});
    //   }

    // },
    async deleteShareHolder({state, commit}, {shareholder_id}) {
      const shareholder = state.kyc_shareholders.find(shareholder => shareholder._id === shareholder_id);
      if (shareholder) {
        const result = await API.deleteObject(shareholder_id, 'share_holder_related', 'Shareholder');
        if (result) {
          commit('setValue', {key: 'kyc_shareholders', value: state.kyc_shareholders.filter(shareholder => shareholder._id !== shareholder_id)});
        }
      }
    },
    async deleteTransactionProfile({state, commit}, {transactionProfile_id}) {
      const transactionProfile = state.kyc_transactionalProfile.find(transactionProfile => transactionProfile._id === transactionProfile_id);
      if (transactionProfile) {
        const result = await API.deleteObject(transactionProfile_id, 'transaction_profile', 'Transaction Profile');
        if (result) {
          commit('setValue', {key: 'kyc_transactionalProfile', value: state.kyc_transactionalProfile.filter(transactionProfile => transactionProfile._id !== transactionProfile_id)});
        }
      }
    },
    async deleteManagingDirector({state, commit}, {md_id}) {
      const md = state.kyc_mds.find(md => md._id === md_id);
      if (md) {
        const result = await API.deleteObject(md_id, 'mbd_related', 'Managing Director');
        if (result) {
          commit('setValue', {key: 'kyc_mds', value: state.kyc_mds.filter(md => md._id !== md_id)});
        }
      }
    },
    async deleteKycSection({state, commit}, {company_id}) {
      const result = await API.deleteObject(company_id, 'kyc_review', 'KYC Review');
      if (result) {
        commit('setValue', {key: 'companyData', value: null});
      } 
    },

  },
  modules: {
  }
})
