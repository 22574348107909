<template>
    <div>

        <div class="flex row space-between section-top">
            <h3 class="doc-header mb-0">Corporate Documents</h3>
            <button title='collapse & expand section' class='transparent w-content button-holder' @click="toggleSection">
                <img class="arrow-opener-section" :class="isOpen ? 'section-opened': ''" src="@/assets/arrow-opener.svg">
            </button>
        </div>
        <div class="w-full content" :id="corporateSection_collapse">
            <div class="kyc-fields-area mb-20" v-if="PermissionChecker.viewListingService() && !hiddenSection">
                <div class="kyc-item-section flex column ">
                    <h3 class="black">Listing of services provided by trust service provider to company</h3>
                    <textarea :value="listingService.value" 
                        :disabled="!PermissionChecker.updateListingService() && !reviewMode"
                        class='border-color' placeholder="Write a comment here"
                        @input="textareaUpdates"
                    ></textarea>
                </div>
            </div>
        
            <div class="kyc-fields-area mb-20">
                <div class="table-wrap">
                <table style="height: 100%;">
                    <TableHeaderRow />
                    <tbody>
                        <TableFileRow2 :id="'idkyc-ma-1'" :key="`company_id_${getFileId('kyc-ma-1')}`" :reviewMode="reviewMode" :fileId="getFileId('kyc-ma-1')" v-if="!hiddenSection"/>
                        <TableFileRow2 :id="'idkyc-ppa-2'" :reviewMode="reviewMode" :fileId="getFileId('kyc-ppa-2')" v-if="!hiddenSection"/>
                        <TableFileRow2 :id="'idkyc-feeibtbvdna-3'" :reviewMode="reviewMode" :fileId="getFileId('kyc-feeibtbvdna-3')" v-if="!hiddenSection"/>
                        <TableFileRow2 :id="'idkyc-lteabibtdoea-4'" :reviewMode="reviewMode" :fileId="getFileId('kyc-lteabibtdoea-4')"/>
                        <TableFileRow2 :id="'idkyc-mlibtdoea-4-1'" :reviewMode="reviewMode" :fileId="getFileId('kyc-mlibtdoea-4-1')"/>
                        <TableFileRow2 :id="'idkyc-aoi-5'" :reviewMode="reviewMode" :fileId="getFileId('kyc-aoi-5')"/>
                        <TableFileRow2 :id="'idkyc-attaoi-5-1'" :reviewMode="reviewMode" :fileId="getFileId('kyc-attaoi-5-1')"/>
                        <TableFileRow2 :id="'idkyc-reftcoc-5-2'" :reviewMode="reviewMode" :fileId="getFileId('kyc-reftcoc-5-2')"/>
                        <TableFileRow2 :id="'idkyc-oc-6'" :reviewMode="reviewMode" :fileId="getFileId('kyc-oc-6')"/>
                        <TableFileRow2 :id="'idkyc-sr-7'" :reviewMode="reviewMode" :fileId="getFileId('kyc-sr-7')"/>
                        <TableFileRow2 :id="'idkyc-reftcoc-7-1'" :reviewMode="reviewMode" :fileId="getFileId('kyc-reftcoc-7-1')"/>
                        <TableFileRow2 :id="'idkyc-sof-8'" :reviewMode="reviewMode" :fileId="getFileId('kyc-sof-8')"/>
                        <TableFileRow2 :id="'idkyc-ruling'" :reviewMode="reviewMode" :fileId="getFileId('kyc-ruling')"/>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="kyc-fields-area mb-20">
                <div class="flex header-of-section">
                    <h3>
                        Copy of signed bank account opening forms
                    </h3>
                <button class="add-new fit-content" @click="addnewDocument('cosbaof')" v-if="!reviewMode">Add New</button>
                </div>
                <div class="table-wrap" id="idcosbaof">
                    <table style="height: 100%;">
                        <tbody>
                            <TableFileRow2
                                v-for="(item, index) in cosbaofFilesId"
                                :fileId="item"
                                :reviewMode="reviewMode"
                                :key="`cosbaof-${index}`"
                            />
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="kyc-fields-area mb-20">
                <div class="flex header-of-section">
                    <h3>
                        Signature card for bank account
                    </h3>
                <button class="add-new fit-content" @click="addnewDocument('scfba')" v-if="!reviewMode">Add New</button>
                </div>
                <div class="table-wrap" id="idscfba">
                    <table style="height: 100%;">
                        <tbody>
                            <TableFileRow2
                                v-for="(item, index) in scfbaFilesId"
                                :fileId="item"
                                :reviewMode="reviewMode"
                                :header="`Signature card for bank account ${item?.bank_name ? '(' + item?.bank_name + ')' : ''}`"
                                :key="`scfba-${item}`"
                            />
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="kyc-fields-area mb-20">
                <div class="flex header-of-section">
                    <h3>
                        Annual Accounts
                    </h3>
                </div>
                <div class="table-wrap">
                    <table style="height: 100%;">
                        <tbody>
                            <TableFileRow2 id="idkyc-aa-y1-12" :reviewMode="reviewMode" :fileId="getFileId('kyc-aa-y1-12')"/>
                            <TableFileRow2 id="idkyc-aa-y2-12" :reviewMode="reviewMode" :fileId="getFileId('kyc-aa-y2-12')"/>
                            <TableFileRow2 id="idkyc-dogmos-13" v-if="companyType != 'local'"  :reviewMode="reviewMode" :fileId="getFileId('kyc-dogmos-13')"/>
                            <TableFileRow2 id="idkyc-fpt-14" :reviewMode="reviewMode" :fileId="getFileId('kyc-fpt-14')"/>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="kyc-fields-area mb-20">
                <Subsidiaries class="mb-10"/>

                <div class="flex header-of-section">
                    <h3>
                        Subsidiary Documents
                    </h3>
                </div>
                <div class="table-wrap" id="idsubsidiaries">
                    <table style="height: 100%;" v-if="subsidiariesFilesId.length">
                        <tbody>
                            <TableFileRow2 v-for = "(item, index) in subsidiariesFilesId"
                                :fileId="item"
                                :reviewMode="reviewMode"
                            />
                        </tbody>
                    </table>
                    <div class='p-20' v-else> No Subsidiaries added</div>
                </div>
            </div>
        </div>
        <AddNewElement
            v-if="addNewElement"
            :type="addNewElement"
            @close="addNewElement = null"
        />
    </div>
</template>


<script setup>
import { ref, computed, defineEmits, onMounted, defineProps } from 'vue'
import TableFileRow2 from '@/components/TableFileRow2.vue'
import TableHeaderRow from '@/components/TableHeaderRow.vue'
import { PermissionChecker } from '@/utils/iam_utils';
import Subsidiaries from '@/components/Subsidiaries.vue';
import store from '@/store/index.js';
 
import AddNewElement from '@/components/addNewElement.vue';


const companyData = computed(() => store.state.companyData);

const addNewElement = ref(null);


const corporateSection_collapse_name = computed(() => companyData.value._id  + 'corporate_section')
const corporateSection_collapse = computed(() => 'collapse_' + companyData.value._id + 'corporate_section');

const isOpen = computed(() => store.getters.getToggleState(corporateSection_collapse_name.value));
const companyType = computed(() => store.getters.getCompanyType);

const HTMLElements = computed(() => {
    return document.getElementById(corporateSection_collapse.value);
})

const hiddenSection = computed(() => {
    if (companyData.value.location_type.value === 'local') {
        return true;
    }
    return false
});

const getFileId = (type) => {
    return computed(() => store.getters.getFileId(type));
}

const scfbaFilesId = computed(() => store.getters.getFilesId('scfba')) 
const cosbaofFilesId = computed(() => store.getters.getFilesId('cosbaof')) 
const subsidiariesFilesId = computed(() => store.getters.getFilesId('subsidiaries'))
const listingService = computed(() => store.state.listingServices)

const props = defineProps(['company_id', 'reviewMode'])

function addnewDocument(structure) {
    addNewElement.value = structure;
}

function textareaUpdates(value) {
    store.commit('updateListingServices', value.target.value)
}

function toggleSection() {
    store.dispatch('toggleSection', {
        id: corporateSection_collapse_name.value, 
    })
}

function adjustHeight() {
    const section = HTMLElements.value;

    if (isOpen.value) {
        section.style.maxHeight = "none"; // Prevent transition issues
        section.style.overflowY = "visible"; // Prevent overflow
    } 
    else {
        section.style.maxHeight = "0"; // Collapse the section
        section.style.overflowY = "hidden"; // Hide overflow
    }
}

onMounted(() => {
    adjustHeight()
})
</script>

<style scoped lang="scss">
    .header-of-section {
        justify-content: space-between;
        align-items: center;
        width: 100%;
        button {
            margin-top: 0;
        }
        h3 {
            color: black;
        }   
    }
    .content {
        max-height: 0;
        overflow-y: hidden;
        transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
    }
    .header-section {
        padding-left: 20px;
        background-color: white;
        height: 125px;
        display: flex;
        padding-left: 35px;
        align-items: center;
        justify-content: center;
    }
    
    .kyc-header {
        max-width: 1350px;
        width: 100%;
        text-align: left;
        font-size: 28px;
        position: relative;

        .tab-views {
            font-size: 14px;
            position: absolute;
            left: 0;
            top: 40px;
            display: flex;
            .tab {
                padding: 10px 20px;
                cursor: pointer;
                &.active {
                    background-color: #f5f5f5;
                }
            }
        
        }
    }
    .kyc-fields-container {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .kyc-fields-area {
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        background-color: white;
        border-radius: 10px;
        padding: 20px;
    }
    h3 {
        margin: 0px;
        margin-bottom: 4px;
    }
    .kyc-item-section {
        position: relative;
        width: 100%;
        margin-top: 15px;
        h3 {
            position: absolute;
            background: var(--app-background);
            border-radius: 5px;
            border: 1px solid var(--border-color);
            padding: 0 5px;
            left: -1.5px;
            border-bottom-left-radius: 0;
            color: #000;
            font-weight: 600;
            text-align: left;
            max-width: 86%;
            top: -12px;
            left: 0px;
            font-size: 14px;
            z-index: 2;
            text-align: left;
        }
        &.extra-margin {
            margin-bottom: 50px;
        }
    }
    .flex {
        display: flex;
        &.row {
            flex-direction: row;
        }
        &.column {
            flex-direction: column;
        }
    }
    .passport-fields {
        margin-top: 20px;
    }
    .gap30 {
        gap: 30px;
    }
    .black {
        color: black;
    }
    textarea {
        resize: none; 
        box-sizing: border-box;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        border-radius: 10px;
        height: 150px;

        &:focus {
            outline: none;
            border: 1px solid var(--primary-color);
        }
    }
    .data-holder-comment {
        height: 120px;
        width: 500px;
    }
    .document-comments {
        vertical-align: bottom;
        
    }
    .add-new {
        margin-left: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .margin-top-20 {
        margin-top: 20px;
    }
    .margin-top-negative {
        margin-top: -40px;
        margin-bottom: 5px;
    }
    .limited-width {
        
    }


table {
    border-collapse: collapse;
    width: 100%;
}

thead {
    font-weight: bold;
}
td {
    padding: 10px;
    border: 1px solid var(--border-color);
}

.no-border > td {
    border: none;
}
.margin-20 {
    height: 20px;
}

</style>