import { createRouter, createWebHistory } from 'vue-router'
import ContactView from '../views/ContactView.vue'

import RegisterVue from '../views/RegisterView.vue'
import LoginView from '../views/LoginView.vue'
import AdminView from '../admin_views/AdminView.vue'
import UsersView from '../admin_views/UsersView.vue'
import DashboardView from '../admin_views/DashboardView.vue'
import UserView from '../admin_views/UserView.vue'
import PlatofrmControlView from '../admin_views/PlatformControlView.vue'
import WalletView from '../views/WalletView.vue'
import VerifyView from '../views/VerifyView.vue'

import KYCView from '../views/KycView.vue'
import ResetPasswordView from '../views/ResetPassword.vue'
import SupportTicket from '../views/SupportView.vue'
import TicketView from '../views/TicketView.vue'
import UserManagementView from '../views/UserManagementView.vue'
import SnapshotView from '../views/SnapshotView.vue'
import SubsidiaryView from '../views/SubsidiaryView.vue'
import TermsPage from '../admin_views/TermsPage.vue'
import TermsUser from '../views/TermsUser.vue'

import userViewNormal from '../views/UserView.vue'
import UserProfile from '../views/UserProfile.vue'
import SanctionsView from '../views/SanctionsView.vue'
import SanctionView from '@/views/SanctionView.vue'

import ActionRequired from '@/views/ActionRequired.vue'
import KycReviewItem from '@/views/KycReviewItem.vue'

import UserInfo from '@/views/UserInfo.vue'
import FileListView from '@/views/FileListView.vue'
import SavedView from '@/views/SavedView.vue'


const routes = [

  {
    path: '/profile-settings',
    name: 'profile-settings',
    component: UserProfile
  },
  {
    path: '/help-center',
    name: 'help-center',
    component: SupportTicket
  },
  {
    path: '/user-management',
    name: 'user-management',
    component: UserManagementView,
  },
  {
    path: '/corporate',
    name: 'corporate',
    props: {
      kyc_type: 'corporate'
    },
    component: KycReviewItem
  },
  {
    path: '/corporate/:id/',
    name: 'corporate_item',
    props: route => ({ id: route.params.id, kyc_type: 'corporate' }),
    component: KYCView
  }, 
  {
    path: '/account-holder',
    name: 'account-holder',
    props: {
      kyc_type: 'account_holder'
    },
    component: KycReviewItem
  }, 
  {
    path: '/account-holder/:id/',
    name: 'account_holder_item',
    props: route => ({ id: route.params.id, kyc_type: 'account_holder' }),
    component: KYCView
  },
  {
    path: '/document-history',
    name: 'document-history',
    component: SnapshotView
  },
  {
    path: '/subsidiary',
    name: 'subsidiary',
    component: SubsidiaryView,
  },
  {
    path: '/sanction-screening-lookups',
    name: 'sanction-screening-lookups',
    component: SanctionsView
  },
  {
    path: '/sanction-screening-lookups/:id/',
    name: 'sanction-screening-item',
    props: true,
    component: SanctionView,
  },
  {
    path: '/user-management',
    name: 'user-management',
    component: UserManagementView,
  },
  {
    path: '/user-management/users/:id/',
    name: 'company_users',
    props: true,
    component: userViewNormal,
  },
  {
    path: '/help-center/:id',
    name: 'single_ticket',
    props: true,
    component: TicketView,
  },
  {
    path: '/change-log',
    name: 'change-log',
    props: {
      view: 'forHistory'
    },
    component: ActionRequired,
  },
  {
    path: '/change-log/:id/',
    name: 'change-log-item',
    props: route => ({ id: route.params.id, view: 'forHistory' }),
    component: ActionRequired,
  },
  {
    path: '/change-log/:id/:item_id',
    name: 'change-log-item-inner',
    props: route => ({ 
      id: route.params.id, view: 'forHistory',
      item_id: route.params.item_id
    }),
    component: ActionRequired,
  },
  // {
  //   path: '/workflow',
  //   name: 'workflow',
  //   props: {
  //     view: 'forConfirmation'
  //   },
  //   component: ActionRequired,
  // },
  {
    path: '/workflow/:id/',
    name: 'workflow-item',
    props: route => ({ id: route.params.id, view: 'forConfirmation' }),
    component: ActionRequired,
  },
  {
    path: '/workflow/:id/:item_id',
    name: 'workflow-item-inner',
    props: route => ({ 
      id: route.params.id, 
      view: 'forConfirmation',
      item_id: route.params.item_id
    }),
    component: ActionRequired,
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterVue
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ResetPasswordView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: WalletView
  },
  {
    path: '/verify',
    name: 'verify',
    component: VerifyView
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: DashboardView,
      },
      {
        path: 'users/:id/',
        name: 'bo_user',
        props: true,
        component: UserView,
      },
      {
        path: 'users',
        name: 'bo_users',
        component: UsersView,
      },
      {
        path: 'platform_control',
        name: 'platform_control',
        component: PlatofrmControlView,
      },
      {
        path: 'terms-admin',
        name: 'terms-admin',
        component: TermsPage,
      }
    ]
  },
  {
    path: '/terms-and-conditions',
    name: 'terms',
    component: TermsUser
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/fiu/file-list',
    name: 'file-list',
    component: FileListView,
  },
  {
    path: '/fiu/user-info',
    name: 'user-info',
    component: UserInfo
  },
  {
    path: '/fiu/saved-info',
    name: 'saved',
    component: SavedView
  },
  {
    path: '/fiu/convert',
    name: 'convert',
    component: () => import(/* webpackChunkName: "about" */ '../views/ConverFileView.vue')
  }
]



const router = createRouter({
  mode: 'history',
  base: '/',
  history: createWebHistory(),
  routes
})

export default router
