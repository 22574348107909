<template>
    <div class="main-section" v-if="loaded">
        <div class="content">
            <div class="breadcrumbs">
                <router-link to="/user-management">User Management</router-link>  > {{ userInformation.username }}
            </div>
            <div class="user-name">
            {{ userInformation.username }}
            </div>
            <div class="content-section" v-if="PermissionChecker.viewUserPermissions()">
                <div class="tab-holder">
                    <div class="tab" :class="tabState == 'general' ? 'active': ''" @click="changeView('general')">General</div>
                    <div class="tab" :class="tabState == 'user_permissions' ? 'active': ''" @click="changeView('user_permissions')">User Permissions</div>
                </div>
            </div>
            <div class="general-view" v-if="tabState == 'general'">
                <div class="card">
                    <div class="header">
                        <div class="title">Personal details</div>
                            <div class="buttons-holder flex gap-4">
                                <!-- <button class="common-button secondary"> Reset 2FA </button>-->
                            <button v-if="PermissionChecker.updateUser()" @click="showEdit()"class="common-button secondary">Edit User</button>
                            <button v-if="PermissionChecker.deleteUser() && userInformation.type != 'ADMINISTRATOR' " @click="deleteUser()"class="common-button secondary">Delete User</button>
                        </div> 
                    </div>
                    <div class='info-section-container'>
                        <div class="info-section">
                            <div class="name">Username</div>
                            <div class="value">{{ userInformation.username }}</div>
                        </div>

                        <div class="info-section">
                            <div class="name">Email</div>
                            <div class="value">{{ userInformation.email }}</div>
                        </div>

                        <div class="info-section">
                            <div class="name">Role</div>
                            <div class="value">{{ getUserType(userInformation.type) }}</div>
                        </div>
                            </div>
                </div>
            </div>
            <div class="general-view" v-if="tabState == 'user_permissions' && PermissionChecker.viewUserPermissions()">
                <RoleManagement 
                    :user_id="userInformation._id"
                    :user="userInformation"
                    ></RoleManagement>
            </div>
        </div>


        <PopupCover v-if="showEditPopup" @close="closePopup">
            <UserPopup 
                @close="closePopup" 
                :userInfo="userInformation"
                @InfoUpdate="UserPopupHandler"
            />
        </PopupCover>

        <PopupCover v-if="deletePerson" @close="() => deletePerson = !deletePerson">
            <ConfirmationPopup 
                @confirm="popupConfirm"
                @cancel="() => deletePerson = !deletePerson"
                :title="'Delete User'"
                :message="'Are you sure you want to delete this user?'"
            />
        </PopupCover>
    </div>
    <div v-else>
        LOADING...
    </div>

</template>

<script setup>

import { ref, defineEmits, defineProps, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { PermissionChecker } from '@/utils/iam_utils';
import ConfirmationPopup from '@/components/ConfirmationPopup.vue';
import RoleManagement from '@/components/RoleManagement.vue';
import PopupCover from '@/components/PopupCover.vue';
import UserPopup from '@/components/UserModal.vue';

import {getUserType} from '@/utils/iam_utils';

import API from '@/api/index.js'

const store = useStore()
const router = useRouter();
const route = useRoute();
const user = computed(() => store.state.user);
const props = defineProps(['id'])
const userId = props.id;

const showEditPopup = ref(false);
const tabState = ref('general');


const loaded = ref(false);
const userInformation = ref(null);
const userRoles = ref(null);
const deletePerson = ref(false);


onMounted(async () => {
    const response = await API.getUserInfo(userId);
    userInformation.value = response.user;
    document.title = 'User Management ' + userInformation.value.username;

    loaded.value = true;
})

function popupConfirm(event){
    deletePerson.value = false;
    if (event) {
        deletePersonHandler()
    }
}

function deleteUser() {
    deletePerson.value = true;
}
function changeView(tab) {
    tabState.value = tab;
}


function showEdit(){
    showEditPopup.value = true;
    userInformation.value = {
        _id: userInformation.value._id,
        username: userInformation.value.username,
        email: userInformation.value.email,
        type: userInformation.value.type,
    }
}
function closePopup() {
    showEditPopup.value = false;
}
async function UserPopupHandler(event){
    showEditPopup.value = false;
    const resp = await API.updateUser(event.user);
    if (!resp) {
        return;
    }
    store.dispatch('addNotification', {
        type: 'success',
        text: 'User updated successfully',
    });
    userInformation.value = event.user;

}
async function deletePersonHandler() {
    deletePerson.value = false;
    const response = await API.deleteUser(userInformation.value._id);
    store.state.accounts = store.state.accounts.filter(account => account._id !== userInformation.value._id)
    store.dispatch('addNotification', {text: 'User deleted successfully', type: 'success'})
    router.push('/user-management')
}
</script>

<style lang="scss" scoped>

.main-section {
    margin: 0 20px;
    padding-top: 20px;
}
.breadcrumbs {
    padding-left: 0;
}
.user-name {
    font-size: 32px;
    font-weight: 600;
    color: black;
}

.content {
    margin: auto;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .breadcrumbs {
        font-size: 14px;
        margin-bottom: 10px;
        font-weight: bold;
        a {
            color: var(--primary-color);
            text-decoration: none;
        }
    }
    .content-section {
        display: flex;
        gap: 20px;
    }
    .general-view {
        border: 1px solid var(--border-color);
        border-radius: 10px;
        margin-top: 20px;
        overflow: hidden;
    }
}



.tab-holder {
            overflow: auto;
            font-size: 14px;
            display: flex;
            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
            }
            &::-webkit-scrollbar-thumb {
                background: #888;
            }
            &::-webkit-scrollbar-track {
                // background: red;
            }
            .tab {
                &:first-child {
                    padding-left: 0;
                }
                white-space: nowrap;
                padding: 10px 20px;
                cursor: pointer;
                font-weight: 600;
                &.active {
                    color: var(--primary-color);
                    border-bottom: 4px solid var(--primary-color);
                }
            }
        
        }


        .card {
            background: white;
            width: 100%;
            
            .header {
                align-items: center;
                border-bottom-width: 1px;
                height: 72px;
                justify-content: space-between;
                border-color: rgb(224 226 236 / var(--tw-border-opacity));
                padding: 0 1.5rem;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid var(--border-color);

            }
            .title {
                font-size: 1.25rem;
                font-weight: 700;
                line-height: 1.75rem;
                color: black;
            }
        }
        .general-view {
            width: 100%;
        }
        .buttons-holder {
            width: 220px;
        }
        .info-section-container {
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: self-start;
        }
        .info-section {
            text-align: left;
            line-height: 18px;
        }
        .name {
            font-size: 14px;
            color: rgb(116 119 127);
        }
        .value {
            margin-top: 2px;
            font-size: 16px;
            color: black;
            font-weight: bold;
        }
</style>