function WorldCheckMapFunction(type) {
    console.log('WorldCheckMapFunction', type)
    const worldCheckMap = {
        sanctions_found: 'Found sanctions',
        pep_found: 'Found PEP',
        relevant_adverse_information_found: 'Relevant Adverse Information',

        sanctions_found_supervisor: 'Found sanctions',
        pep_found_supervisor: 'Found PEP',
        relevant_adverse_information_found_supervisor: 'Relevant Adverse Information',

        sanctions_found_compliance: 'Found sanctions',
        pep_found_compliance: 'Found PEP',
        relevant_adverse_information_found_compliance: 'Relevant Adverse Information',
    }
    return worldCheckMap[type] || type;
}

function getCommentForType(type) {
    const typeToCommentMap = {
      sanctions_found: 'sanctions_comment',
      sanctions_found_supervisor: 'sanctions_comment_supervisor',
      pep_found_supervisor: 'pep_comment_supervisor',
      relevant_adverse_information_found_supervisor: 'relevant_adverse_information_comment_supervisor',
      pep_found: 'pep_comment',
      relevant_adverse_information_found: 'relevant_adverse_information_comment',
      sanctions_found_compliance: 'sanctions_comment_compliance',
      pep_found_compliance: 'pep_comment_compliance',
      relevant_adverse_information_found_compliance: 'relevant_adverse_information_comment_compliance',
    }
    return typeToCommentMap[type];
  }

module.exports = {
    WorldCheckMapFunction,
    getCommentForType,
}