<template>
    <div class="history-management-view">
        <div class="documentmanagement-header">
            Requested Sanction Screaning 
        </div>
     
        <Sanctions />
    </div>
</template>

<script setup>
    import Sanctions from '@/components/Sanctions.vue'

    import { onMounted } from 'vue'


    onMounted(() => {
        document.title = 'Requested Sanction Screaning';
        // Check if the user is logged in
        if (!localStorage.getItem('token')) {
            // Redirect to the login page if not logged in
            window.location.href = '/login';
        }
    });
    
</script>

<style lang="scss" scoped>

    .documentmanagement-header {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: left;
        color: black;
        padding-top: 20px;
    }
    .history-management-view {
        display: flex;
        padding: 20px;
        flex-direction: column;
        max-width: 1350px;
        margin: auto;
    }

</style>