<template>
    <div class="sanction-holder">
        <div class="flex justify-center">
            <div class="breadcrumbs mt-20 w-full" >
                <router-link to="/sanction-screening-lookups" class="main-item">
                    Requested Sanction Screaning 
                </router-link>
                <img src="@/assets/arrow-opener.svg" alt="arrow-right"  class="p-10" />
                <div class="last-item" v-if="sanctionRequest">
                    {{ `${sanctionRequest?.general?.surname || ''} ${sanctionRequest?.general?.firstname || ''}   ${sanctionRequest?.general?.nationality || ''}` }}
                </div>
            </div>
        </div>       
        <SanctionResponseItem
            class="sanction-item"
            v-if="loaded" 
            :sanctioResponse="sanctioResponse"
            :sanctionRequest="sanctionRequest"
            :isPopup="false"
            />
        <div v-else>
            Loading...
        </div>
    </div>
</template>

<script setup>
    import { ref, defineEmits, defineProps, computed, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter, useRoute } from 'vue-router'
    import { PermissionChecker } from '@/utils/iam_utils';
    import SanctionResponseItem from '@/components/SanctionResponseItem.vue';
    import API from '@/api/index.js'

    const props = defineProps(['id'])
    const userId = props.id;

    const sanctionRequest = ref(null);
    const sanctioResponse = ref(null);
    const sanctionInformation = ref(null);
    const loaded = ref(false);
 
    onMounted(async () => {
        const response = await API.getSansctionScreeningInfo(userId);
        sanctionInformation.value = response.data;
        sanctioResponse.value = JSON.parse(response.data.response);
        sanctionRequest.value = JSON.parse(response.data.request);
        loaded.value = true;
    })

</script>

<style lang='scss' scoped>
    .sanction-item {
        height: 100%;
        padding: 20px;
    }
    .breadcrumbs {
        max-width: none;
        text-align: left;
        padding-left: 25px;
        box-sizing: border-box;
    }
    .sanction-holder {
        height: 94vh;
        padding: 20px;
        box-sizing: border-box;
    }
    .response-header {
        text-align: left;
        color: black;
        width: 100%;
        font-size: 1.5rem;
        margin-bottom: 10px;
        padding-left: 5px;
    }
    .snippet-title {
        font-weight: 700;
        font-size: 1rem;
    }
    .article-url {
        vertical-align: baseline;
    }
    .title-section {
        padding-left: 5px;
        color: black;
        text-align: left;
        font-size: 1.5rem;
        border-bottom: 1px solid var(--border-color);
        margin-bottom: 10px;
    }
    .not-found-section {
        padding: 10px;
        color: black;
        text-align: left;
        font-size: 1.2rem;
        background-color: white;
        border: 1px solid var(--border-color);
        padding: 10px;
    }
    .sub-title {
        padding-left: 5px;
        margin-top: 5px;

        border-bottom: 1px solid var(--border-color);
        margin-bottom: 5px;
        color: black;
        text-align: left;
        font-size: 1.2rem;

    }
    .table-section {
        background-color: white;
        // border-radius: 5px;
        width: 100%;
        border: 1px solid var(--border-color);
        padding: 10px;
        box-sizing: border-box;
    }
    table {
        width: 100%;
        border-collapse: collapse;
    }
    tr td, tr th {
        border-bottom: 1px solid var(--border-color);
        padding: 10px;
        color: black;
    }
    tr {
        text-align: left;
        &:nth-child(even) {
            background-color: var(--app-background);
        }
    }
    .toggle {
        width: 200px;
        height: 20px;
        background-color: #d4d4d7;
    }
</style>