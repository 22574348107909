<template>
    <div class="wallet-section">
        <div class='wraper'>
            <div class='usdt-wallet'>
                <h2>USDT Wallet</h2>
                <div class='wallet-info'>
                    <div class='texts'>
                        <p><b>Address:</b> </p>
                        <p class='paragraph'> {{wallet.address || 'No wallet address found, please Generate a wallet'}}
                            <button v-if='!wallet.address' @click='requestWallet()' class="generate-wallet">Generate Wallet</button>
                            <button v-else @click='copyToClipBoard()'>Copy</button>    
                        </p>

                    </div>
                   </div>
            </div>
        </div>
        <div></div>
    </div>
  </template>
  
  <script>
  
  import API from '@/api/index.js'
  import { mapState } from 'vuex';
  export default {
    data() {
      return {
      };
    },
    computed: {
        ...mapState(['wallet'])
    },
    methods: {
      async requestWallet() {
        const resp = await API.requestWallet();
      },
      copyToClipBoard() {
        const el = document.createElement('textarea');
        el.value = this.wallet.address;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }
    },
    mounted() {
      document.title = 'Wallet';
    },
  };
  </script>
  
  
  
  <style scoped lang="scss">
  
  .wallet-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 60px);
    color: #091440;
  }
  .texts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
  }
  .wallet-info {
    display: flex;
    align-items: center;
    gap: 10px;
    .paragraph {
        position: relative;
        margin: 0;
        padding: 15px 10px;
        border-radius: 10px;
        padding-right: 150px;
        height: 34px;
        border-radius: 5px;
        box-shadow: inset 3px 3px 5px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 3px 0 rgba(255, 255, 255, 0.5);
    }
    button {
      position: absolute;
      right: 10px;
      height: 30px;
      margin-left: 10px;
      padding: 0 10px;
      width: 80px;
      &.generate-wallet {
        width: 122px;
      }
    }
    p {
      padding: 0 10px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .drop-area {
    border: 2px dashed #ccc;
    padding: 40px 20px;
  
    text-align: center;
    cursor: pointer;
  }
  .about {
    color: white;
  }
  .drag-over {
    border-color: #2196F3;
  }
  .button-holder {
    width: 150px;
    margin: 25px auto;
    height: 30px;
      button {
        height: 30px;
      }
  }
  .error-container {
    height: 50vh;
    background-color: #f8d7da; /* Light red background color */
    color: #721c24; /* Dark red text color */
    border: 1px solid #f5c6cb; /* Border color */
    padding: 15px;
    overflow-y: auto;
    margin: 10px 0;
    border-radius: 5px;
    text-align: left;
  }
  .error-message {
    font-weight: bold;
  }
  .error-header {
    margin-top: 0;
  }
  
  </style>