
function fileFields(name){
    return {
        title: name,
        file: null,
        new_file: '',
        error: '',
        comment_data_handler: '',
        new_comment_data_handler: '',
    }
}
function commentHasContent(file, comment, type = 'new') {
    let prefix  = 'new_'
    if (type === 'old') {
        prefix = ''
    }
    let commentJSON = file[`new_${comment}`];
    console.log('commentJSON', commentJSON)
    if (!commentJSON) {
        return false
    }
    if (commentJSON.ops.length  === 1 && commentJSON.ops[0].insert == '\n') {
        return false
    }
    return true
}
function new_comments(file, comment) {
    let new_comment = file[`new_${comment}`];
    let new_commentJSON = file[`new_${comment}`]
    const old_comment = file[comment];
  
    if (!new_comment) {
      return false
    }
    new_comment = JSON.stringify(new_comment);
    if (!old_comment) {
      if (new_commentJSON.ops.length  === 1 && new_commentJSON.ops[0].insert == '\n') {
        return false
      }
    }
    if (new_comment == old_comment) {
      return false
    }
    return new_comment
  }
function getFileName(documentType, person, entiry){
    console.log('getFileName', documentType, person, entiry)
    if (person) {
        console.log('personStructure___________-', personStructure)
        return personStructure[documentType].title;
    }
    if (entiry) {
        return legalEntity[documentType].title;
    }
    if (documentType === 'cosbaof') {
        return 'Copy of signed bank account opening forms';
    }
    if (documentType === 'scfba') {
        return 'Signature card for bank account';
    }
    if (documentType === 'subsidiaries') {
        return 'List of subsidiaries';
    }
    return dataStructure[documentType].title;
}


const personStructure2 = {
    '_id': '',
    'location_type': {value: '', error: '', new_value: ''},
    'percentage_held': {value: '', error: '', new_value: '', type: 'number', label: 'Percentage held'},
    'title': {value: '', error: '', new_value: '', type: 'text', label: 'Title'},
    
    'files': {
        'passport_file': '',
        'secondPassport_file': '',
        'address_file': '',
        'bank_reference_letter': '',
        'source_of_wealth': '',
        'source_of_funds': '',
        'google_search': '',
        'world_check': '',
    },

    'firstName': { value: '', error: '', new_value: '', type: 'text', label: 'First Name'},
    'lastName': { value: '', error: '', new_value: '', type: 'text', label: 'Last Name'},
    'tinNumber': { value: '', error: '', new_value: '', type: 'text', label: 'TIN Number'},
    'passportNumber': { value: '', error: '', new_value: '', type: 'number', label: 'Passport Number'},
    'passportCountry': { value: '', error: '', new_value: '', type: 'text', label: 'Passport Country'},
    'dateIssued': { value: '', error: '', new_value: '', type: 'date', label: 'Date Issued'},
    'dateExpiry': { value: '', error: '', new_value: '', type: 'date', label: 'Date Expiry'},
    'dateOfBirth': { value: '', error: '', new_value: '', type: 'date', label: 'Date of Birth'},
    
    'secondPassportType': {value: '', error: '', new_value: '', type: 'options', label: 'Document Type'},
    'secondPassportNumber': {value: '', error: '', new_value: '', type: 'number', label: 'Document Number'},
    'secondPassportCountry': { value: '', error: '', new_value: '', type: 'text', label: 'Document Country'},
    'secondDateIssued': {value: '', error: '', new_value: '', type: 'date', label: 'Date Issued'},
    'secondDateExpiry': {value: '', error: '', new_value: '', type: 'date', label: 'Date Expiry'},

    'address_line_1': {value: '', error: '', new_value: '', type: 'text', label: 'Address Line 1'},
    'address_line_2': {value: '', error: '', new_value: '', type: 'text', label: 'Address Line 2'},
    'address_date_issues': {value: '', error: '', new_value: '', type: 'date', label: 'Date Issued'},
    'city': {value: '', error: '', new_value: '', type: 'text', label: 'City'},
    'address_country': {value: '', error: '', new_value: '', type: 'options', label: 'Country'},
    'zip': {value: '', error: '', new_value: '', type: 'number', label: 'Zip Code'},

    'placeofBirth': {value: '', error: '', new_value: '', type: 'text', label: 'Place of Birth'},
    'ubo_related': '',
    'mbd_related': '',
}

const legalEntityStructure2 = {
    '_id': '',
    'files': {
        'entity_aoi': '',
        'entity_coc': '',
        'entity_members': '',
        'entity_soi': '',
        'entity_rodo': '',
        'entity_license': '',
    },
    'supervised': {value: '', error: '', new_value: ''},
    'name': {value: '', error: '', new_value: ''},
    'address_line_1': {value: '', error: '', new_value: ''},
    'address_line_2': {value: '', error: '', new_value: ''},
    'city': {value: '', error: '', new_value: ''},
    'address_country': {value: '', error: '', new_value: ''},
    'zip': {value: '', error: '', new_value: ''},

    'mbd_related': '',
}

const personStructure = {
    'id': '',
    'location_type': {value: '', error: '', new_value: ''},
    'percentage_held': {value: '', error: '', new_value: '', type: 'number', label: 'Percentage held'},
    'title': {value: '', error: '', new_value: '', type: 'text', label: 'Title'},
    'passport_file': {
        ...fileFields('Certified copy of valid passport'),
        firstName: { value: '', error: '', new_value: '', type: 'text', label: 'First Name'},
        lastName: { value: '', error: '', new_value: '', type: 'text', label: 'Last Name'},
        tinNumber: { value: '', error: '', new_value: '', type: 'text', label: 'TIN Number'},
        passportNumber: { value: '', error: '', new_value: '', type: 'number', label: 'Passport Number'},
        passportCountry: { value: '', error: '', new_value: '', type: 'text', label: 'Passport Country'},
        dateIssued: { value: '', error: '', new_value: '', type: 'date', label: 'Date Issued'},
        dateExpiry: { value: '', error: '', new_value: '', type: 'date', label: 'Date Expiry'},
        dateOfBirth: { value: '', error: '', new_value: '', type: 'date', label: 'Date of Birth'},
    },

    'secondPassport_file': {
        ...fileFields('Certified copy of valid passport'),
        secondPassportType: {value: '', error: '', new_value: '', type: 'options', label: 'Document Type'},
        secondPassportNumber: {value: '', error: '', new_value: '', type: 'number', label: 'Document Number'},
        secondPassportCountry: { value: '', error: '', new_value: '', type: 'text', label: 'Document Country'},
        secondDateIssued: {value: '', error: '', new_value: '', type: 'date', label: 'Date Issued'},
        secondDateExpiry: {value: '', error: '', new_value: '', type: 'date', label: 'Date Expiry'},
    },

    'address_file': {
        ...fileFields('Certified copy of proof of address \n(not older then 3 months)'),
        address_line_1: {value: '', error: '', new_value: '', type: 'text', label: 'Address Line 1'},
        address_line_2: {value: '', error: '', new_value: '', type: 'text', label: 'Address Line 2'},
        address_date_issues: {value: '', error: '', new_value: '', type: 'date', label: 'Date Issued'},

        city: {value: '', error: '', new_value: '', type: 'text', label: 'City'},
        address_country: {value: '', error: '', new_value: '', type: 'options', label: 'Country'},
        zip: {value: '', error: '', new_value: '', type: 'number', label: 'Zip Code'},
    },
    'bank_reference_letter': fileFields('Bank Reference Letter Certified'),
    'source_of_wealth': fileFields('Source of wealth'),
    'source_of_funds': fileFields('Source of funds'),
    'google_search': fileFields('Google Search'),
    'world_check': fileFields('World Check'),
}





const uboStructure = {
    location_type: {value: '', error: '', new_value: ''},
}
const mbod = {
    id: '',
    location_type: {value: '', error: '', new_value: ''},
    person_type: {value: '', error: '', new_value: ''},
    entity_type: {value: '', error: '', new_value: ''},
    supervised: {value: '', error: '', new_value: ''},
    struct: {},
}

const legalEntity = {
    'id': '',
    'name': {value: '', error: '', new_value: ''},
    'address': {
        address_line_1: {value: '', error: '', new_value: ''},
        address_line_2: {value: '', error: '', new_value: ''},
        city: {value: '', error: '', new_value: ''},
        address_country: {value: '', error: '', new_value: ''},
        zip: {value: '', error: '', new_value: ''},
    },
    'entity_aoi': fileFields('Articles of Incorporation'),
    'entity_coc': fileFields('Chamber of Commerce'),
    'entity_members': fileFields('Share Register / Register of Members'),
    'entity_soi': fileFields('Certificate of Incumbency'),
    'entity_rodo': fileFields('Register of Directors optional'),
    'entity_license': fileFields('License'),
    supervised: {value: '', error: '', new_value: ''},
    location_type: {value: '', error: '', new_value: ''},
    mbd_ubo: []
}


const objectId = (length = 24) => {
    let result = '';
    const characters = 'abcdef0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

function getFileInfo(file) {
    if (!file) {
        return null;
    }
    return { 
        file: file.serverName ? {
            id: file._id,
            serverName: file.serverName,
            filename: file.filename,
            documentType: file.documentType,
        } : null,
        id: file._id,
        comment_data_handler: file.comment_data_handler,
        new_comment_data_handler: '',
        comment_compliance_officer: file.comment_compliance_officer,
        comment_supervisor: file.comment_supervisor,
        new_comment_compliance_officer: '',
        new_comment_supervisor: '',

    }
}


function getPersonStructure2(personDB = null){
    const person = JSON.parse(JSON.stringify(personStructure2));
    let objectMap = personDB || {}
    person._id = objectMap._object_id || objectMap._id || objectId();
    person.location_type.value = objectMap.location_type || '';
    person.percentage_held.value = objectMap.percentage_held || '';
    person.title.value = objectMap.title || '';

    for (let key in person.files) {
        person.files[key] = objectMap[key] || '';
    }

    
    person.firstName.value = objectMap.firstName || '';
    person.lastName.value = objectMap.lastName || '';
    person.tinNumber.value = objectMap.tinNumber || '';
    person.passportNumber.value = objectMap.passportNumber || '';
    person.passportCountry.value = objectMap.passportCountry || '';
    person.dateIssued.value = objectMap.dateIssued || '';
    person.dateExpiry.value = objectMap.dateExpiry || '';
    person.dateOfBirth.value = objectMap.dateOfBirth || '';

    person.secondPassportType.value = objectMap.secondPassportType || '';
    person.secondPassportNumber.value = objectMap.secondPassportNumber || '';
    person.secondPassportCountry.value = objectMap.secondPassportCountry || '';
    person.placeofBirth.value = objectMap.placeofBirth || '';
    person.secondDateIssued.value = objectMap.secondDateIssued || '';
    person.secondDateExpiry.value = objectMap.secondDateExpiry || '';
    
    person.address_line_1.value = objectMap.address_line_1 || '';
    person.address_line_2.value = objectMap.address_line_2 || '';
    person.address_date_issues.value = objectMap.address_date_issues || '';
    person.city.value = objectMap.city || '';
    person.address_country.value = objectMap.address_country || '';
    person.zip.value = objectMap.zip || '';

    person.ubo_related = objectMap.ubo_related || '';
    person.mbd_related = objectMap.mbd_related || '';
    return person;
}


function getPersonStructure(location_type, map = null, files = null) {
    const person = JSON.parse(JSON.stringify(personStructure));
    if (files){
        person.passport_file = { ...person.passport_file, ...(map.passport_file && getFileInfo(files?.[map?.passport_file?.toString()]))}
        person.secondPassport_file = { ...person.secondPassport_file, ...(map.secondPassport_file && getFileInfo(files?.[map?.secondPassport_file?.toString()]))}
        person.address_file = { ...person.address_file, ...(map.address_file && getFileInfo(files?.[map?.address_file.toString()]))}
        person.bank_reference_letter = { ...person.bank_reference_letter, ...(map.bank_reference_letter && getFileInfo(files?.[map?.bank_reference_letter.toString()]))}
        
        person.source_of_funds = { ...person.source_of_funds, ...(map.source_of_funds && getFileInfo(files?.[map?.source_of_funds.toString()]))}
        person.source_of_wealth = { ...person.source_of_wealth, ...(map.source_of_wealth && getFileInfo(files?.[map?.source_of_wealth.toString()]))}
        person.google_search = { ...person.google_search, ...(map.google_search && getFileInfo(files?.[map?.google_search.toString()]))}
        person.world_check = { ...person.world_check, ...(map.world_check && getFileInfo(files?.[map?.world_check.toString()]))}
    }
    person.id = map?._id || objectId();
    if (map) {
        person.passport_file.firstName.value = map?.firstName || '';
        person.passport_file.lastName.value = map?.lastName || '';
        person.passport_file.passportNumber.value = map?.passportNumber || '';
        person.passport_file.passportCountry.value = map?.passportCountry || '';
        person.passport_file.dateIssued.value = map?.dateIssued || '';
        person.passport_file.dateExpiry.value = map?.dateExpiry || '';
        person.passport_file.tinNumber.value = map?.tinNumber || '';
        person.passport_file.dateOfBirth.value = map?.dateOfBirth || '';
        person.percentage_held.value = map?.percentage_held || '';
        person.title.value = map?.title || '';

        
        person.secondPassport_file.secondPassportNumber.value = map?.secondPassportNumber || '';
        person.secondPassport_file.secondPassportType.value = map?.secondPassportType || '';
        person.secondPassport_file.secondPassportCountry.value = map?.secondPassportCountry || '';
       
        person.secondPassport_file.secondDateIssued.value = map?.secondDateIssued || '';
        person.secondPassport_file.secondDateExpiry.value = map?.secondDateExpiry || '';
    
        person.address_file.address_line_1.value = map?.address_line_1 || '';
        person.address_file.address_line_2.value = map?.address_line_2 || '';
        person.address_file.address_date_issues.value = map?.address_date_issues || '';
        person.address_file.city.value = map?.city || '';
        person.address_file.address_country.value = map?.address_country || '';
        person.address_file.zip.value = map?.zip || '';
        person.location_type.value = map?.location_type || '';
    } else {
        person.location_type.value = location_type;
        person.location_type.new_value = location_type;
    }
    return person;
}


function getLegalEntityStructure2(entityDB = null){
    const entity = JSON.parse(JSON.stringify(legalEntityStructure2));
    let objectMap = entityDB || {}
    entity._id = objectMap._object_id || objectMap._id || objectId();
    entity.share_holder_related = objectMap.share_holder_related || '';
    entity.supervised.value = objectMap.supervised !== undefined ? objectMap.supervised : '';
 
    for (let key in entity.files) {
        entity.files[key] = objectMap[key] || '';
    }
    entity.name.value = objectMap.name || '';
    entity.address_line_1.value = objectMap.address_line_1 || '';
    entity.address_line_2.value = objectMap.address_line_2 || '';
    entity.city.value = objectMap.city || '';
    entity.address_country.value = objectMap.address_country || '';
    entity.zip.value = objectMap.zip || '';

    entity.mbd_related = objectMap.mbd_related || '';
    return entity;
}

function getLegalEntityStructure(map, files, location_type, supervised){
    const newLegalEntity = JSON.parse(JSON.stringify(legalEntity));
    newLegalEntity.id = map?._id || objectId();
    
    if (files){
        newLegalEntity.entity_aoi = { ...newLegalEntity.entity_aoi, ...(map.entity_aoi && getFileInfo(files?.[map?.entity_aoi?.toString()]))}
        
        newLegalEntity.entity_coc = { ...newLegalEntity.entity_coc, ...(map.entity_coc && getFileInfo(files?.[map?.entity_coc?.toString()]))}
        newLegalEntity.entity_members = { ...newLegalEntity.entity_members, ...(map.entity_members && getFileInfo(files?.[map?.entity_members?.toString()]))}
        newLegalEntity.entity_soi = { ...newLegalEntity.entity_soi, ...(map.entity_soi && getFileInfo(files?.[map?.entity_soi?.toString()]))}
        
        
        newLegalEntity.entity_rodo = { ...newLegalEntity.entity_rodo, ...(map.entity_rodo && getFileInfo(files?.[map?.entity_rodo.toString()]))}
        newLegalEntity.entity_license = { ...newLegalEntity.entity_license, ...(map.entity_license && getFileInfo(files?.[map?.entity_license.toString()]))}
    }

    newLegalEntity.location_type.value = location_type || '';
    newLegalEntity.supervised.value = supervised || '';


    if (map) {

        newLegalEntity.name.value = map?.name;
        newLegalEntity.address.address_line_1.value = map?.address_line_1 || '';
        newLegalEntity.address.address_line_2.value = map?.address_line_2 || '';
        newLegalEntity.address.city.value = map?.city || '';
        newLegalEntity.address.address_country.value = map?.country || '';
        newLegalEntity.address.zip.value = map?.zip || '';
        newLegalEntity.supervised.value = map?.supervised || '';

    }

    return newLegalEntity
}

function getUboStructure(location_type = 'abroad', index, id = null) {
    const newUboStructure = JSON.parse(JSON.stringify(uboStructure));
    newUboStructure.location_type.value = location_type;
    newUboStructure.person = getPersonStructure(location_type);

    const uboAll = {
        id: id || objectId(),
        location_type,
        index,
        struct: newUboStructure
    }
    if(id) {
        uboAll.api = true;
    }
    return uboAll;
}


function addPersonToUBO(ubo, map, files){
    const person = getPersonStructure(ubo.location_type, map, files);
    uboStruct.person = person;
    console.log('addPersonToUBO', uboStruct)
}
function addPersonToMBD(mbod, map, files) {
    const person = getPersonStructure(mbod.location_type, map, files);
    if (!mbod.struct.person) {
        mbod.struct.person = [person];
    } else {
        mbod.struct.person.unshift(person);
    }
    console.log('addPersonToMBD', mbod)
}

function addLegalEntitytoMDB({
    mbod, 
    is_new = false
}) {
    const legalEntity = getLegalEntityStructure(null, null, mbod.location_type.value, mbod.supervised.value);
    mbod.struct.legalEntity = legalEntity;

}



const dataStructure = {
    'textarea': {
        title: 'Listing of services provided by trust service provider to company',
        value: '',
        new_value: ''
    },
    'kyc-ma-1': fileFields('Management Agreement'),
    'kyc-ppa-2': fileFields('Principal Party Agreement'),
    
    'kyc-feeibtbvdna-3': {
        ...fileFields('Foreign Exchange Exemption issued by the Bank van de Nederlandse Antillen'),
        dateIssued: '',
        new_dateIssued: '',
    },
    'kyc-lteabibtdoea-4': {
        ...fileFields('License to Establish a Business issued by the Department of Economic Affairs'),
        dateIssued: '',
        new_dateIssued: '',
    },
    'kyc-mlibtdoea-4-1': {
        ...fileFields('Management License issued by the Department of Economic Affairs'),
        dateIssued: '',
        new_dateIssued: '',
    },
    'kyc-aoi-5': fileFields('Articles of Incorporation'),
    'kyc-attaoi-5-1': {
        ...fileFields('Amendments to the Articles of Incorporation'),
        dateIssued: '',
        new_dateIssued: '',
    },
    'kyc-reftcoc-5-2': {
        ...fileFields('Recent Extract from the Chamber of Commerce'),
        dateIssued: '',
        new_dateIssued: '',
    },
    'kyc-oc-6': fileFields('Organizational Chart'),
    'kyc-sr-7': fileFields('Shareholders\' register'),
    
    'kyc-reftcoc-7-1': fileFields('Share transfer agreement (after establishment)'),
    'kyc-sof-8': fileFields('Source of funds'),
    "ubos": [],
    'mbds': [],
    'tax-ruling': {
        title: 'Tax Ruling',
        file: null,
        new_file: ''
    },
    'cosbaof': [],
    'scfba': [],
    'kyc-aa-y1-12': {
        title: 'Annual Accounts - last book year',
        file: null,
        new_file: ''
    },
    'kyc-aa-y2-12': {
        title: 'Annual Accounts - second last book year',
        file: null,
        new_file: ''
    },
    'kyc-dogmos-13': {
        title: 'Discharge of General Meeting of Shareholders - last book year',
        file: null,
        new_file: ''
    },
    'kyc-fpt-14': {
        title: 'Filing Profit Tax - last book year',
        file: null,
        new_file: ''
    },
    'subsidiaries': [],
}


module.exports = {
    getPersonStructure,
    getLegalEntityStructure,
    getLegalEntityStructure2,
    getUboStructure,
    objectId,
    addPersonToMBD,
    addPersonToUBO,
    addLegalEntitytoMDB,
    fileFields,
    getPersonStructure2,
    dataStructure,
    getFileName,
    new_comments,
    commentHasContent,
}