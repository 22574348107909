<template>
    <div class="container">
        <div class="header">
            {{ title }}
            <div class="cross" @click="closePopupConfirm()">
                <img src="@/assets/cross.svg" alt="cross"/>
            </div>
        </div>
        <div class="confirmation-text">
            {{ message }}
        </div>
        <div class="confirmation-buttons">
        <button class="transparent cancel-btn" @click="closePopupConfirm()">No</button>
        <button class="confirm-button" @click="confirmAction()">Yes</button>
        </div>
    </div>
</template>

<script setup>
    import { ref, defineEmits, defineProps } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'

    const store = useStore();
    const router = useRouter();
    const props = defineProps(['title', 'message']);
    const emit = defineEmits(['close']);

    function closePopupConfirm() {
        emit('cancel', false);
    }
    function confirmAction(){
        emit('confirm', true);
    }
 
</script>

<style lang="scss" scoped>
    .container[class] {
        border-radius: .5rem;
        display: flex;
        flex-direction: column;
        width: 350px;
        border: 1px solid var(--border-color);
        min-height: 150px;
        padding: 20px;
        background-color: white;
    } 
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2rem;
        color: black;
    }
    .cancel-btn {
        margin-left: auto;
        margin-right: 10px;
    }
    .add-new-ticket-info {
        margin-top: auto;
        button {
            width: fit-content;
            padding: 10px 30px;
        }
    }
    .confirmation-buttons {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-top: auto;
    }
</style>