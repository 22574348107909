<template>
    <div class="subsidiary-container">
    <div class="subsidiary-content">
        <div class="sub-header" >
            <h4 class="subsidiary-title">Subsidiary List</h4>
            <button v-if="PermissionChecker.createSubsidiaries() && !selectedSnapshot" @click="addObject()">Add Subsidiary</button>
        </div>
        <table class="subsidiary-list">
            <thead>
                <tr>
                    <th>Subsidiary Name</th>
                    <th>Last Annual Account on File</th>
                </tr>
            </thead>
            <tbody class='table-content' v-if="store.state.subsidiaries">
                <tr class="account-list" v-for="subsidiary in store.state.subsidiaries" :key="subsidiary._id" @click="openObject(subsidiary)">
                    <td class="user-info"> 
                        <div class="icon-person">
                            <img src="@/assets/subsidiaries.svg" style='width: 25px;' alt="kyc" />
                        </div>
                        <div class="user-name">
                            {{ subsidiary.name }}
                        </div>
                    </td>
                    <td>{{ subsidiary.last_annual_account_on_file }}</td>
                </tr>

            </tbody>
        </table>
    </div>
 
     <PopupCover v-if="showPopups" @close="closePopup">
        <SubsidiaryPopup 
            @close="closePopup" 
            :objectInfo="objectInformation"
            @InfoUpdate="createObjectPopupHandler"
            @objectAction="objectActionHandler"
            @delete_object="deleteObjectHandler"
        />
    </PopupCover>
 </div>
 </template>
 
 <script setup>

import { ref, defineEmits, computed, withDefaults, defineProps, watch, onMounted } from 'vue'
import InpComponent from '@/components/InpComponent.vue'

import { PermissionChecker } from '@/utils/iam_utils';
import API from '../api/subsidiary'
import API_MAIN from '../api/index'
import store from '@/store/index.js';
import PopupCover from '@/components/PopupCover.vue';
import SubsidiaryPopup from '@/components/SubsidiaryPopup.vue';
import dayjs from 'dayjs'
 
import { useRouter, useRoute } from 'vue-router'

const router = useRouter();

const selectedSnapshot = computed(() => store.state.selectedSnapshot)

const user = computed(() => store.state.user);
const emit = defineEmits(['delete_person', 'commentChange'])
const objectInformation = ref(null);
const showPopups = ref(false);


async function createObjectPopupHandler(event) {
    showPopups.value = false;
    const addedSubsidiary = await API.addSubsidiary({...event.object, company_id: store.state.companyData._id});
    store.state.subsidiaries.unshift(addedSubsidiary.subsidiary)
    store.state.kyc_files.unshift(addedSubsidiary.file)
}

async function objectActionHandler() {
    showPopups.value = false;

    // store.dispatch('setValue', {key: 'selectedSnapshot', value: objectInformation.value});
    // router.push('/kyc')
}

function closePopup() {
    showPopups.value = false;
    objectInformation.value = null;
}
async function deleteObjectHandler() {
    showPopups.value = false;
    let structure = JSON.stringify(objectInformation.value);
    const result = await API_MAIN.deleteObject(objectInformation.value._id, 'subsidiary', 'Subsidiary', structure);
    if (result) {
        store.state.subsidiaries = store.state.subsidiaries.filter(object => object._id !== objectInformation.value._id)
        store.dispatch('addNotification', {text: 'Subsidiary has been deleted successfully', type: 'success'})
    }
    objectInformation.value = null;
}
function openObject(object) {
    objectInformation.value = object;
    showPopups.value = true;
}

function addObject() {
    showPopups.value = true;
}

onMounted(async() => {
    if (!user.value) {
        router.push('/login')
        return;
    }
    await API.getSubsidiaries(store.state.companyData._id, selectedSnapshot.value?._id);
});

</script>
 
<style scoped lang="scss">
    
    .icon-person {

        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 30px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 600;
        height: 30px;
        color: var(--primary-color);
        background: rgb(236, 240, 255);
    }
    .user-info {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .subsidiary-container {
        width: 100%;
    }
    
    .subsidiary-content {
        min-width: 100%;
        border: 1px solid #ccc;
        border-radius: 10px;
        width: fit-content;
    }
    
    .sub-header {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        padding: 20px;
        padding-left: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
    }
    
    .subsidiary-title {
        display: flex;
        justify-content: space-between;
        color: black;
        font-size: 20px;
    }
    
    .sub-header {
        display: flex;
        justify-content: space-between;
        button {
            width: fit-content;
            padding: 10px;
        }
    }
    .subsidiary-list {
        padding: 20px;
        width: 100%;
        border-collapse: collapse;
        th, td {
            // border: 1px solid #ccc;
            padding: 15px;
            text-align: left;
            border: none;
            padding-left: 30px;
            color: black;
        }
        td {
            padding-top: 19px;
            padding-bottom: 19px;
        }
        th {
            text-align: left;
            border: none;
        }
        tr {
            border-top: 1px solid #ccc;
        }
        tbody {
            tr {
                cursor: pointer;
                td {
                    background: white;
                }

                &:last-child {
                    td {
                        &:first-child {
                            border-bottom-left-radius: 10px;
                        }
                        &:last-child {
                            border-bottom-right-radius: 10px;
                        }
                    }
                }
                &:hover {
                    td {
                        background: #f9f9f9;
                    }
                }
            }
        }
    }
    
    .add-new td{
        padding-top: 0px;
        padding-bottom: 0px;
    
    }
    .save-button {
        padding: 10px 20px;
        width: fit-content;
        margin-left: auto;
    }
    h4 {
        margin: 0;
    }
    .add-new-subsidiary-area {
        justify-content: center;
        align-items: center;
    
    }
    .text-input {
        &:deep(input) {
            text-align: center;
        }
    }
 
 .more-actions {
    padding: 5px 0px;
    border-radius: 4px;
    width: 35px;
    height: 25px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background: #d9d9d9;

        .dropdown {
            display: flex;
            flex-direction: column;

        }
    }
    position: relative;

    .dropdown {
        display: none;
        position: absolute;
        top: 35px;
        right: 0;
        background: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        z-index: 100;
        div {
            padding: 10px;
            cursor: pointer;
            &:hover {
                background: #d9d9d9;
            }
        }
    }
}
 
</style>