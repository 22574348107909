<template>
    <div>
        <div  v-if="loading">
            Loading
        </div>
        <div class='flex w-full gap-5 column' v-else>
            <div v-if="reviewAction.actionType !== 'remove'" class='flex w-full gap-5'>
                <div class="flex-1">
                    <h3 class="header-small">
                        <text v-if="view == 'forConfirmation'">Current Version</text>
                        <text v-else>Previous Version</text>
                    </h3>
                    <div class="info-holder current-version">
                        <p class="field-label">General Fields</p>
                        <InpComponent
                            :disabled="true"
                            class="country-select flex-1"
                            label="Origin"
                            type="options"
                            :options="[{value: 'local', text: 'Resident'}, {value: 'abroad', text: 'Non-resident'}]"
                            :value="getFieldValue('location_type','current')"
                            :class="{changes: fieldChange?.location_type?.new != undefined && fieldChange?.location_type?.current}"
                        />
                        <InpComponent
                            v-if="reviewAction.ubo_related"
                            :disabled="true"
                            class="flex-1"
                            label="Percentage of shares held"
                            type="number"
                            :class="{changes: fieldChange?.percentage_held?.new != undefined && fieldChange?.percentage_held?.current}"
                            :value="getFieldValue('title','percentage_held')"
                            disabled="true"
                        />
                        <InpComponent
                            class="flex-1"
                            label="Title"
                            :class="{changes: fieldChange?.title?.new != undefined && fieldChange?.title?.current}"
                            v-if="!reviewAction.ubo_related"
                            type="options"
                            :options="[
                                { value: 'Director', text: 'Director' },
                                { value: 'Secretary', text: 'Secretary' },
                                { value: 'MD', text: 'MD' },
                                { value: 'Registered Agent', text: 'Registered Agent' },
                            ]"
                            :value="getFieldValue('title','current')"
                            disabled="true"
                        />
                        <div class="flex-1">
                            <div class="passport-fields file-area">
                            <p class="field-label">Passport fields</p>
                                <InpComponent
                                    label="First Name"
                                    type="text"
                                    :class="{changes: fieldChange?.firstName?.new != undefined && fieldChange?.firstName?.current}"
                                    :disabled="true"
                                    :value="getFieldValue('firstName','current')"
                                />
                                <InpComponent
                                    label="Last Name"
                                    :class="{changes: fieldChange?.lastName?.new != undefined && fieldChange?.lastName?.current}"
                                    type="text"
                                    :disabled="true"
                                    :value="getFieldValue('lastName','current')"
                                />
                                <InpComponent
                                    label="Date of Birth"
                                    type="date"
                                    :class="{changes: fieldChange?.dateOfBirth?.new != undefined && fieldChange?.dateOfBirth?.current}"
                                    :disabled="true"
                                    :value="getFieldValue('dateOfBirth','current')"
                                />
                                <InpComponent
                                    class="country-select"
                                    label="Issue country"
                                    type="options"
                                    :options="Utils && Utils.getCountryList()"
                                    :class="{changes: fieldChange?.placeofBirth?.new != undefined && fieldChange?.placeofBirth?.current}"
                                    :disabled="true"
                                    :value="getFieldValue('placeofBirth','current')"
                                />
                      
                                <InpComponent
                                    label="Passport Number"
                                    type="number"
                                    :class="{changes: fieldChange?.passportNumber?.new != undefined && fieldChange?.passportNumber?.current}"
                                    :disabled="true"
                                    :value="getFieldValue('passportNumber','current')"
                                />
                                <InpComponent
                                    class="country-select"
                                    label="Issue country"
                                    type="options"
                                    :options="Utils && Utils.getCountryList()"
                                    :class="{changes: fieldChange?.passportCountry?.new != undefined && fieldChange?.passportCountry?.current}"
                                    :disabled="true"
                                    :value="getFieldValue('passportCountry','current')"
                                />
                                <InpComponent
                                    label="Issue Date"
                                    type="date"
                                    :class="{changes: fieldChange?.dateIssued?.new != undefined && fieldChange?.dateIssued?.current}"
                                    :disabled="true"
                                    :value="getFieldValue('dateIssued','current')"
                                />
                                <InpComponent
                                    label="Expiry Date"
                                    :class="{changes: fieldChange?.dateExpiry?.new != undefined && fieldChange?.dateExpiry?.current}"
                                    type="date"
                                    :disabled="true"
                                    :value="getFieldValue('dateExpiry','current')"
                                />
                                <InpComponent
                                    label="Taxpayer Identification Number"
                                    type="number"
                                    :class="{changes: fieldChange?.tinNumber?.new != undefined && fieldChange?.tinNumber?.current}"
                                    :disabled="true"
                                    :value="getFieldValue('tinNumber','current')"
                                />
                            </div>
                        </div>
                        <div class="file-area flex-1">
                            <p class="field-label">Address fields</p>
                            <InpComponent
                                label="Address Line 1"
                                type="text"
                                :class="{changes: fieldChange?.address_line_1?.new != undefined && fieldChange?.address_line_1?.current}"
                                disabled="true"
                                :value="getFieldValue('address_line_1','current')"
                            />
                            <InpComponent
                                label="Address Line 2 (optional)"
                                type="text"
                                :class="{changes: fieldChange?.address_line_2?.new != undefined && fieldChange?.address_line_2?.current}"
                                disabled="true"
                                :value="getFieldValue('address_line_2','current')"
                            />
                            <InpComponent
                                label="City / Town"
                                type="text"
                                :class="{changes: fieldChange?.city?.new != undefined && fieldChange?.city?.current}"
                                disabled="true"
                                :value="getFieldValue('city','current')"
                            />
                            <InpComponent
                                class="country-select"
                                label="Country"
                                type="options"
                                :options="Utils && Utils.getCountryList()"
                                :class="{changes: fieldChange?.address_country?.new != undefined && fieldChange?.address_country?.current}"
                                disabled="true"
                                :value="getFieldValue('address_country','current')"
                            />
                            <InpComponent
                                label="Zip"
                                type="number"
                                disabled="true"
                                :class="{changes: fieldChange?.zip?.new != undefined && fieldChange?.zip?.current}"
                                :value="getFieldValue('zip','current')"
                            />
                            <InpComponent
                                v-if="person.address_date_issues"
                                class="issue-date"
                                label="Issue Date"
                                :class="{changes: fieldChange?.address_date_issues?.new != undefined && fieldChange?.address_date_issues?.current}"
                                type="date"
                                disabled="true"
                                :value="getFieldValue('address_date_issues','current')"
                            />
                        </div>
                        <div class='file-area flex-1' v-if="(person.location_type.value != 'local')">
                            <p class="field-label">Second document fields</p>
                            <div class="passport-fields ">
                                <InpComponent
                                    label="Document Type"
                                    type="options"
                                    :class="{changes: fieldChange?.secondPassportType?.new != undefined && fieldChange?.secondPassportType?.current}"
                                    disabled="true"
                                    :options="[{value: 'id_card', text: 'ID Card'}, {value: 'driver_license', text: 'Driver License'}]"
                                    :value="getFieldValue('secondPassportType','current')"
                                />
                                <InpComponent
                                    :label="'Document Number'"
                                    type="text"
                                    :disabled="true"
                                    :class="{changes: fieldChange?.secondPassportNumber?.new != undefined && fieldChange?.secondPassportNumber?.current}"
                                    disabled="true"
                                    :value="getFieldValue('secondPassportNumber','current')"
                                />
                                <InpComponent
                                    class="country-select"
                                    label="Issue country"
                                    type="options"
                                    :options="Utils && Utils.getCountryList()"
                                    :class="{changes: fieldChange?.secondPassportCountry?.new != undefined && fieldChange?.secondPassportCountry?.current}"
                                    disabled="true"
                                    :value="getFieldValue('secondPassportCountry','current')"
                                />
                                <InpComponent
                                    label="Issue Date"
                                    type="date"
                                    :class="{changes: fieldChange?.secondDateIssued?.new != undefined && fieldChange?.secondDateIssued?.current}"
                                    disabled="true"
                                    :value="getFieldValue('secondDateIssued','current')"
                                />
                                <InpComponent
                                    label="Expiry Date"
                                    type="date"
                                    :class="{changes: fieldChange?.secondDateExpiry?.new != undefined && fieldChange?.secondDateExpiry?.current}"
                                    disabled="true"
                                    :value="getFieldValue('secondDateExpiry','current')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-1">
                    <h3 class="header-small">
                        <text>New Version</text>
                    </h3>
                    <div class="info-holder proposed-changes">
                        <p class="field-label">General Fields</p>
                        <InpComponent
                            :disabled="true"
                            class="country-select flex-1"
                            label="Origin"
                            type="options"
                            :options="[{value: 'local', text: 'Resident'}, {value: 'abroad', text: 'Non-resident'}]"
                            :value="getFieldValue('location_type','new')"
                            :class="{changes: fieldChange?.location_type?.new != undefined}"

                        ></InpComponent>
                        <InpComponent
                            v-if="reviewAction.ubo_related"
                            :disabled="true"
                            class="flex-1"
                            label="Percentage of shares held"
                            type="number"
                            :value="getFieldValue('percentage_held','new')"
                            :class="{changes: fieldChange?.percentage_held?.new != undefined}"
                            disabled="true"
                        ></InpComponent>
                        <InpComponent
                            class="flex-1"
                            label="Title"
                            :class="{changes: fieldChange?.title?.new != undefined}"
                            v-if="!reviewAction.ubo_related"
                            type="options"
                            :options="[
                                { value: 'Director', text: 'Director' },
                                { value: 'Secretary', text: 'Secretary' },
                                { value: 'MD', text: 'MD' },
                                { value: 'Registered Agent', text: 'Registered Agent' },
                            ]"
                            :value="getFieldValue('title','new')"
                            disabled="true"
                        ></InpComponent>
                        <div class="flex-1">
                            <div class="passport-fields file-area">
                                <p class="field-label">Passport fields</p>
                                <InpComponent
                                    label="First Name"
                                    type="text"
                                    :class="{changes: fieldChange?.firstName?.new != undefined}"
                                    :disabled="true"
                                    :value="getFieldValue('firstName','new')"
                                />
                                <InpComponent
                                    label="Last Name"
                                    :class="{changes: fieldChange?.lastName?.new != undefined}"
                                    type="text"
                                    :disabled="true"
                                    :value="getFieldValue('lastName','new')"
                                />
                                <InpComponent
                                    label="Date of Birth"
                                    type="date"
                                    :class="{changes: fieldChange?.dateOfBirth?.new != undefined}"
                                    :disabled="true"
                                    :value="getFieldValue('dateOfBirth','new')"
                                />
                                <InpComponent
                                    class="country-select"
                                    label="Issue country"
                                    type="options"
                                    :options="Utils && Utils.getCountryList()"
                                    :class="{changes: fieldChange?.placeofBirth?.new != undefined}"
                                    :disabled="true"
                                    :value="getFieldValue('placeofBirth','new')"
                                />
                                <InpComponent
                                    label="Passport Number"
                                    type="number"
                                    :class="{changes: fieldChange?.passportNumber?.new != undefined}"
                                    :disabled="true"
                                    :value="getFieldValue('passportNumber','new')"
                                />
                                <InpComponent
                                    class="country-select"
                                    label="Issue country"
                                    type="options"
                                    :options="Utils && Utils.getCountryList()"
                                    :class="{changes: fieldChange?.passportCountry?.new != undefined}"
                                    :disabled="true"
                                    :value="getFieldValue('passportCountry','new')"
                                />
                                <InpComponent
                                    label="Issue Date"
                                    type="date"
                                    :class="{changes: fieldChange?.dateIssued?.new != undefined}"
                                    :disabled="true"
                                    :value="getFieldValue('dateIssued','new')"
                                />
                                <InpComponent
                                    label="Expiry Date"
                                    :class="{changes: fieldChange?.dateExpiry?.new != undefined}"
                                    type="date"
                                    :disabled="true"
                                    :value="getFieldValue('dateExpiry','new')"
                                />
                                <InpComponent
                                    label="Taxpayer Identification Number"
                                    type="number"
                                    :class="{changes: fieldChange?.tinNumber?.new != undefined}"
                                    :disabled="true"
                                    :value="getFieldValue('tinNumber','new')"
                                />
                            </div>
                        </div>
                        <div class="file-area flex-1">
                            <p class="field-label">Address fields</p>
                            <InpComponent
                                label="Address Line 1"
                                type="text"
                                :class="{changes: fieldChange?.address_line_1?.new != undefined}"
                                disabled="true"
                                :value="getFieldValue('address_line_1','new')"
                            />

                            <InpComponent
                                label="Address Line 2 (optional)"
                                type="text"
                                :class="{changes: fieldChange?.address_line_2?.new != undefined}"
                                disabled="true"
                                :value="getFieldValue('address_line_2','new')"
                            />

                            <InpComponent
                                label="City / Town"
                                type="text"
                                :class="{changes: fieldChange?.city?.new != undefined}"
                                disabled="true"
                                :value="getFieldValue('city','new')"
                            />

                            <InpComponent
                                class="country-select"
                                label="Country"
                                type="options"
                                :options="Utils && Utils.getCountryList()"
                                :class="{changes: fieldChange?.address_country?.new != undefined}"
                                disabled="true"
                                :value="getFieldValue('address_country','new')"
                            />

                            <InpComponent
                                label="Zip"
                                type="number"
                                disabled="true"
                                :class="{changes: fieldChange?.zip?.new != undefined}"
                                :value="getFieldValue('zip','new')"
                            />

                            <InpComponent
                                v-if="person.address_date_issues"
                                class="issue-date"
                                label="Issue Date"
                                :class="{changes: fieldChange?.address_date_issues?.new != undefined}"
                                type="date"
                                :value="getFieldValue('address_date_issues','new')"
                                disabled="true"
                            />
                                </div>
                        <div class='file-area flex-1' v-if="
                            (
                                person.location_type.value != 'local'
                            )">
                            <p class="field-label">Second document fields</p>
                            <div class="passport-fields ">

                                <InpComponent
                                    label="Document Type"
                                    type="options"
                                    :class="{changes: fieldChange?.secondPassportType?.new != undefined}"
                                    disabled="true"
                                    :options="[{value: 'id_card', text: 'ID Card'}, {value: 'driver_license', text: 'Driver License'}]"
                                    :value="getFieldValue('secondPassportType','new')"
                                />
                                <InpComponent
                                    :label="'Document Number'"
                                    type="text"
                                    :disabled="true"
                                    :class="{changes: fieldChange?.secondPassportNumber?.new != undefined}"
                                    :value="getFieldValue('secondPassportNumber','new')"
                                />
                                <InpComponent
                                    class="country-select"
                                    label="Issue country"
                                    type="options"
                                    :options="Utils && Utils.getCountryList()"
                                    :class="{changes: fieldChange?.secondPassportCountry?.new != undefined}"
                                    disabled="true"
                                    :value="getFieldValue('secondPassportCountry','new')"
                                />
                                <InpComponent
                                    label="Issue Date"
                                    type="date"
                                    :class="{changes: fieldChange?.secondDateIssued?.new != undefined}"
                                    disabled="true"
                                    :value="getFieldValue('secondDateIssued','new')"
                                />
                                <InpComponent
                                    label="Expiry Date"
                                    type="date"
                                    :class="{changes: fieldChange?.secondDateExpiry?.new != undefined}"
                                    disabled="true"
                                    :value="getFieldValue('secondDateExpiry','new')"
                                ></InpComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <div class='mt-20' v-if="reviewAction.actionType !== 'remove'">
                <h2 class="text-left m-0">Current Section State</h2>
            </div>
            <div class="width-96">
                
                <PersonComp2
                    v-if="reviewAction.ubo_related"
                    :key="`${reviewAction.person}_person_view_`"
                    :person_id="reviewAction.person" 
                    :source_of_funds="true"
                    :source_of_wealth="true"
                    :bank_reference_letter="true"
                    :google_search="true"
                    :world_check="true"
                    :reviewMode="true"
                />
                <PersonComp2
                    v-else
                    :key="`${reviewAction.person}_person_view`"
                    :person_id="reviewAction.person" 
                    :isOfficial="true"
                    :bank_reference_letter="true"
                    :world_check="true"
                    :google_search="true"
                    :reviewMode="true"
                />
            </div>
        </div>
    </div>
</template>

<script setup >

import { ref, computed, onMounted, watch } from 'vue'
import UploaderTable2 from '@/components/UploaderTable2.vue'
import CommentSection from '@/views/CommentSection.vue'
import PersonComp2 from '@/components/PersonComp2.vue'
import InpComponent from '@/components/InpComponent.vue'
import dayjs from 'dayjs';

import { useStore } from 'vuex';

import API from '@/api/index.js'

const props = defineProps(['reviewAction', 'view'])
const store = useStore();

const fieldChange = ref(JSON.parse(props.reviewAction.fields));
const person = ref(null);
const loading = ref(true);
onMounted(async() => {
    const personResponse = await API.getPersonById({id: props.reviewAction.person, dependencies: true});

    if (!personResponse.status == 200) {
        return;
    }
    store.state.kyc_person = [];
    store.state.kyc_files = [];
    person.value = personResponse.person;
    store.state.kyc_persons.push(personResponse.person);
    store.state.kyc_files.push(...personResponse.files);
    loading.value = false;

});
function getFieldValue(field, state) {
    if (
        state == 'current' && 
        fieldChange.value[field] && 
        !fieldChange.value[field].current && 
        fieldChange.value[field].new
    ) {
        return null;
    }
    if (fieldChange.value[field] && fieldChange.value[field][state]) {
        return fieldChange.value[field][state];
    }
    return person.value[field].value;
}


function renderTime(time) {
    if (!time) return '';
    return dayjs(time).format('MMM DD, YYYY [at] HH:mm');
}


</script>

<style lang="scss" scoped>

    .proposed-changes {
        margin-left: auto;
        .changes {
            background: #DEF7EC;
            &:deep(label) {
                background: #DEF7EC;
            }
        }
    }
    .current-version {
       
        .changes {
            background: #FDE8E8;
            &:deep(label) {
                background: #FDE8E8;
            }
        }
    }
</style>