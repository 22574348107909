<template>
    <div class="popup-wrapper" @click="closePopup()">
        <div class="popup-block" @click="handleClick">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { defineEmits } from 'vue';

    const emit = defineEmits(['close', 'closePopup']);

    function closePopup(event) {
        emit('close');
        emit('closePopup');
    }
    function handleClick(event) {
        event.stopPropagation();
    }
</script>



<style lang="scss">
.popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

</style>