<template>
    <div class="main-section-wrapper" :class="`${reviewMode ? 'reviewMode' : ''} ${state}`">
      <div class="wrapper-of-uploade">
        <div class="flex column gap-10">
          <div class="text" v-if="sectionFile.documentType == 'kyc-aa-y1-12'"> 
            <div style="text-align: left;">
              Annual Accounts - last two (2) book year
            </div>
          </div>
          <div class="additinal-info flex row ">
            <div v-if="yearPresent">
              <InpComponent 
                :pendingChanges="hasChanges('year')"
                type="options"
                :options="yearOptions"  
                :label="year_title"
                :class="`${getInputValue('year', true) ? 'change': ''}`"
                key_ref="year"
                :key="`year-${sectionFile.id}`"

                :disabled="disabled || !canUpload"
                :value="getInputValue('year')" 
                @inputChange="inputChangeFunc"
                :error="year_error"
              />
            </div>
            <div v-if="fiscal_year_ending_on_present" class="date-input">
              <InpComponent 
                :pendingChanges="hasChanges('fiscal_year_ending_on')"
                type="date"
                class="input-fiscal-year"
                label="Fiscal Year Ending On"
                :key="`fiscal_year_ending_on-${sectionFile.id}`"

                key_ref="fiscal_year_ending_on"
                :disabled="disabled || !canUpload"
                :class="`${getInputValue('fiscal_year_ending_on', true) ? 'change': ''}`"
                :value="getInputValue('fiscal_year_ending_on')" 
                @inputChange="inputChangeFunc"
                :error="fiscal_year_ending_on_error"
              />
            </div>
          </div>
         
        </div>
      </div>
      <div v-if="issueDatePresent" class="styled-input" :class="year_error ? 'error-present': ''">
        <InpComponent 
          :pendingChanges="hasChanges('dateIssued')"
          type="date"
          :key="`dateIssued-${sectionFile.id}`"
          :label="dateLabel"
          key_ref="dateIssued"
          :class="`${getInputValue('dateIssued', true) ? 'change': ''}`"
          :disabled="disabled || !canUpload"
          :value="getInputValue('dateIssued')" 
          @inputChange="inputChangeFunc"
          :error="year_error"
        />
      </div>
      <div v-if="banknamePresent" class="styled-input">
        <InpComponent 
          :pendingChanges="hasChanges('bank_name')"
          type="text"
          label="Bank Name"
          key_ref="bank_name"
          :key="`bank_name-${sectionFile.id}`"
          :class="`${getInputValue('bank_name', true) ? 'change': ''}`"
          :disabled="disabled || !canUpload"
          :value="getInputValue('bank_name')" 
          @inputChange="inputChangeFunc"
          :error="year_error"
        />
      </div>
      <div
          class="drop-area"
          @dragover.prevent="dragOver"
          @dragleave.prevent="dragLeave"
          @drop.prevent="dropFile"
          @click="canUpload && $refs.fileInput.click()"
          :class="{ 
            'drag-over': isDragOver, 
            'full-width': fullWidth, 
            'long-header' : headerText && headerText.length > 50, 
            'canUpload': canUpload,
            'change': fileToShow && fileToShow[state]?.filename,
            'hasChanges': hasChanges('fileInfo'),
          }"
        >
        <span class="header" :class="{'long' : headerText && headerText.length > 50}">
          <i>{{ headerText }}</i>
        </span>
      
        <div class="more-actions" v-if="filePresent" @click="eventClick">
          <img src="@/assets/download.svg" v-if="download_available" @click="Download" class="download">
          <img src="@/assets/delete.svg" v-if="delete_available" @click="DeleteFileConfirm" class="delete">
          <img src="@/assets/password-look.svg" v-if="download_available" @click="openFile" class="delete">
        </div>
        <div class='upload-section' v-if="filePresent">
          <div class="flex row justify-center align-center gap-10">
            <img src="@/assets/upload.svg" class="image-upload"/>
            <img v-if="hasChanges('fileInfo')" class="pending-changes" title="Pending changes awaiting approval" src="@/assets/info.svg">
          </div>
          <span> Attached file: 
            <b>
              <i v-if="!state || state == 'current'">{{sectionFile.file.filename }} </i>
              <i v-else-if="state">{{fileToShow?.new?.filename || sectionFile.file.filename }}</i>
            </b>
          </span>
        </div>
        <div class="upload-section" v-else-if="!selectedFile && canUpload">
          <div class="flex row justify-center align-center gap-10">
            <img src="@/assets/upload.svg" class="image-upload"/> 
          
          </div>
          <span>Drag and drop file here to upload</span>
          <div class="flex row gap-10">
            <button class="flex justify-center transparent button action-buttons">
              <img src="@/assets/plus.svg" class="plus"/>
                Attach File
            </button>
            <button style="width: fit-content" @click="doSanctionScreaningShowConfirm" v-if="canDoSanctionScreening">
              Sanction Screaning
            </button>
            <button style="width: fit-content" @click="doGoogleSearch" v-if="canDoGoogleSearch">
              Google Search
            </button>
            <button v-if="organizationalChart" @click="showChart" class="buttons structure-button">
              Chart
            </button>
          </div>
        </div>
        <p v-else-if="selectedFile">Selected File: {{ selectedFile.name }}</p>
        <p v-else>File is not attached</p>
        <input v-if="canUpload" type="file" ref="fileInput" :accept="acceptedFormats" style="display: none" @change="handleFileChange" />
      </div>
      <div class="world-check-info mt-20" v-if="sectionFile.documentType == 'world_check' && detailedView && canWriteSanctionScreeningComments">
      <div class='alert' v-if="!checkmarksConform">Sanction screening findings do not conform.</div>
      <div class="w-full text-left bold f-18 mb-10">{{getUserType}} SECTION</div>
      <div class="options flex column gap-10">
        <div class="flex align-start gap-5">
          <div class="option-desctions">
            Sanctions found  
          </div> 
          <div class="flex row gap-5">
            <div class="flex column align-start">
              <div class="flex row gap-5"> <input :disabled="disabled || reviewMode" type="radio" :checked="isChecked(`sanctions_found${commentPrefix}`, `YES`)" :name="`sanctions_found${commentPrefix}-${randomId}`" @change="inputChange" :id="`YES-sanctions_found${commentPrefix}-${randomId}`"> YES</div> 
              <div class="flex row gap-5"> <input :disabled="disabled || reviewMode" type="radio" :checked="isChecked(`sanctions_found${commentPrefix}`, `NO`)" :name="`sanctions_found${commentPrefix}-${randomId}`" @change="inputChange" :id="`NO-sanctions_found${commentPrefix}-${randomId}`"> NO</div> 
            </div>
          </div>
        </div>
        <div class="flex align-start gap-5">
          <div class="option-desctions">
            PEP  
          </div> 
          <div class="flex row gap-5">
          <div class="flex column align-start">
            <div class="flex row gap-5"> <input :disabled="disabled || reviewMode" type="radio" :checked="isChecked(`pep_found${commentPrefix}`, `YES`)" :name="`pep_found${commentPrefix}-${randomId}`" @change="inputChange" :id="`YES-pep_found${commentPrefix}-${randomId}`"> YES</div> 
            <div class="flex row gap-5"> <input :disabled="disabled || reviewMode" type="radio" :checked="isChecked(`pep_found${commentPrefix}`, `NO`)" :name="`pep_found${commentPrefix}-${randomId}`" @change="inputChange" :id="`NO-pep_found${commentPrefix}-${randomId}`"> NO</div> 
          </div>
        </div>
        </div>
        <div class="flex align-start gap-5">
          <div class="option-desctions">
            Relevant Adverse Information  
          </div> 
          <div class="flex row gap-5">
            <div class="flex column align-start">
              <div class="flex row gap-5"> <input :disabled="disabled || reviewMode" type="radio" :checked="isChecked(`relevant_adverse_information_found${commentPrefix}`, `YES`)" :name="`relevant_adverse_information_found${commentPrefix}-${randomId}`" @change="inputChange" :id="`YES-relevant_adverse_information_found${commentPrefix}-${randomId}`"> YES</div> 
              <div class="flex row gap-5"> <input :disabled="disabled || reviewMode" type="radio" :checked="isChecked(`relevant_adverse_information_found${commentPrefix}`, `NO`)" :name="`relevant_adverse_information_found${commentPrefix}-${randomId}`" @change="inputChange" :id="`NO-relevant_adverse_information_found${commentPrefix}-${randomId}`"> NO</div> 
            </div>
          </div>
        </div>
      </div>
    </div>
    <PopupCover v-if="deleteObject" @close="() => deleteObject = !deleteObject">
      <ConfirmationPopup 
        @confirm="popupConfirm"
        @cancel="() => deleteObject = !deleteObject"
        :title="'Delete Document '"
        :message="'Are you sure you want to delete this Document?'"
      />
    </PopupCover>
      
    <PopupCover v-if="confirmSanctionScreaning" @close="() => confirmSanctionScreaning = !confirmSanctionScreaning">
      <ConfirmationPopup 
        class="popup-confirm"
        @confirm="doSanctionScreaningRequest"
        @cancel="() => confirmSanctionScreaning = false"
        title='Sanction Screaning Request'
        :message="'Are you sure you want to perform a sanction screaning request? \nThe data provided will be used by third party to find any matches in sanction lists'"
      />
    </PopupCover>
    <PopupCover v-if="sanctionScreeningRequestLoading">
      <div class="container-loading">
          <div class="loader-header">Please Wait</div>
          <div class="loader" >
              <img src="@/assets/web-loader.svg" width="60px" height="60px">
          </div>
          <div class="loader-text">Performing Sanction Screaning...</div>
      </div>
    </PopupCover>
    <PopupCover v-if="showSanctionScreeningPopup">
      <div class="sanction-screanin-response">
      
        <SanctionResponseItem 
          :person_id="person_id"
          :sanctioResponse="sanctionScreaning.data"
          :search_id="sanctionScreaning.search_id"
          :sanctionRequest="sanctionScreaning.request"
          :isPopup="true"
          @close="showSanctionScreeningPopup = false"
        />
      </div>
    </PopupCover>
    <PopupCover v-if="showWorldCheckPopup" @close="handleWorldCheckClose()">
      <PopupBody class='sanction-screening-comment-popup' @close="handleWorldCheckClose()">
        <template #header>
            {{ WorldCheckMapFunction(showWorldCheckPopup) }}
        </template>

        <template #body>
          <div class="world-check-info mb-20 flex column gap-10 align-end">
            <CommentSection 
              :attrId="`${getCommentForType(showWorldCheckPopup)}`" 
              class="markdown-content small"
              :class="{disabled: complienceOfficerDisabled || preview}"
              :disabled="preview"
              :commentSection="sectionFile[`new_${getCommentForType(showWorldCheckPopup)}`] || sectionFile[`${getCommentForType(showWorldCheckPopup)}`]"
              @changeinput="quillContent" 
            />
            <button class='w-content ' @click="handleWorldCheckClose()">
              Confirm
            </button>
          </div>
        </template>
      </PopupBody>
    </PopupCover>

    <PopupCover v-if="showStructure" @close="showStructure = ''">
      <PopupBody 
        class="structure-view"
        @close="() => showStructure = ''"> 
        <template #header>
          <span>
            Corporate 
            <text> Shareholder</text>
            Structure for {{ dashboardSettings.name.new_value || dashboardSettings.name.value }}
          </span>
        </template>
        <template #body>
          <StructureChart 
            :key="'corporatesturcture'"
            :type="showStructure"
            :source="showStructure"
            :name="`Corporate Shareholder Structure for ${ dashboardSettings.name.new_value || dashboardSettings.name.value }`"
            :root="true"
          />
        </template>
      </PopupBody>
    </PopupCover>
  </div>

</template>

<script setup>
import { ref, defineEmits, computed, onMounted } from 'vue'
import InpComponent from '@/components/InpComponent.vue'

import API from '../api/index.js'
import store from '../store';
import PopupCover from './PopupCover.vue'
import ConfirmationPopup from './ConfirmationPopup.vue'
import { PermissionChecker } from '@/utils/iam_utils';
import SanctionResponseItem from '@/components/SanctionResponseItem.vue'
import PopupBody from './PopupBody.vue';
import StructureChart from '@/components/StructureChart.vue';

import { 
  
  WorldCheckMapFunction,
  getCommentForType

} from '@/utils/sanction_screening.js';

import { new_comments, commentHasContent } from '@/utils/kyc_utils.js';
import CommentSection from '@/views/CommentSection.vue';

const viewingToken = computed(() => store.state.viewingToken);


const deleteObject = ref(false);


const selectedFile = ref(null)
const props = defineProps([
  'fileId',
  'title', 
  'error', 
  'second_line_normal', 
  'fullWidth',
  'disabled',
  'reviewMode',
  'requestedFile',
  'fileFieldChange',
  'fileToShow',
  'state',
  'person_id',
  'detailedView'
])

const notificationPresent = computed(() => props.reviewMode ?  [] : store.getters.getActionRequired(props.fileId, 'kyc_file'));

const hasChanges = computed(() => (field) => {
  for (const item of notificationPresent.value) {
    let fields = JSON.parse(item.fields); 
    let fileInfo = JSON.parse(item.fileInfo);
    if (item.actionType == 'remove') {
      return true;
    }
    if (field == 'fileInfo') {
    
      if (!fileInfo) {
        return false
      }
      if (fileInfo.new) {
        return true
      }
    }
    if (fields[field]?.new) {
      return true
    }
  
  }
})


const sectionFile = computed(() => store.getters.getFileById(props.fileId, props.requestedFile));
const subsidiary = computed(() => store.getters.getSubsidiaryById(sectionFile.value.subsidiary_id));
const showWorldCheckPopup = ref(false);
const person = computed(() => store.getters.getPersonById(props.person_id));
const randomId = ref(Math.random().toString(36).substring(7));
const checkmarksConform = computed( () => store.getters.checkmarksConform(props.fileId));

const user = computed(() => store.state.user);

const isChecked = computed(() => (key, value) => {
  return (sectionFile.value[`new_${key}`] || sectionFile.value[key]) == value;
})

const getUserType = computed(() => {
  if (user.value.type == 'DATA_CONTROLLER') {
    return 'ACCOUNT MANAGER'
  }
  if (user.value.type == 'DATA_PROCESSOR') {
    return 'SUPERVISOR'
  }
  if (user.value.type == 'COMPLIANCE_OFFICER') {
    return 'COMPLIANCE OFFICER'
  }
})


const filePresent = computed(() => {
  if (sectionFile.value.file) {
    return true;
  }
  if (props.fileToShow) {
    if (props.state == 'current' && !props.fileToShow.current) {
      return false;
    }
    return true;
  }
});


const acceptedFormats = computed( () => {
  if (sectionFile.value.documentType == 'kyc-oc-6') {
    return '.pdf, .png, .jpg, .jpeg'
  }
  return '.pdf'
})

const getInputValue = (key, fromChange = false) => {
  if (!props.fileFieldChange) {
    if (!fromChange) {
      return sectionFile.value[`new_${key}`] || sectionFile.value[key];
    }
    return null;
  }
  if (!props.fileFieldChange[key]) {
    if (fromChange) {
      return null;
    }
    return null || sectionFile.value[`new_${key}`] || sectionFile.value[key];
  }
  if (!props.fileFieldChange[key][props.state]) {
    return null;
  }
  if (props.state == 'current' && !props.fileFieldChange[key].current) {
    return null;
  }
  return props.fileFieldChange[key][props.state]
}


onMounted(() => {
  if (!props.fileId) {
    throw new Error('fileId is required')
  }
})


const headerText = computed(() => {
  const documentTypeToHeaderName = {
    'kyc-ma-1': 'Management Agreement',
    'kyc-ppa-2': 'Principal Party Agreement',
    'kyc-feeibtbvdna-3': 'Foreign Exchange Exemption issued by the Bank van de Nederlandse Antillen',
    'kyc-lteabibtdoea-4': 'License to Establish a Business issued by the Department of Economic Affairs',
    'kyc-mlibtdoea-4-1': 'Management License issued by the Department of Economic Affairs',
    'kyc-aoi-5': 'Articles of Incorporation',
    'kyc-attaoi-5-1': 'Amendments to the Articles of Incorporation',
    'kyc-reftcoc-5-2': 'Recent Extract from the Chamber of Commerce',
    'kyc-oc-6': 'Organizational Chart',
    'kyc-sr-7': 'Shareholders\' register',
    'kyc-reftcoc-7-1': 'Share transfer agreement (after establishment)',
    'kyc-sof-8': 'Source of funds',
    'kyc-ruling': 'Tax Ruling',
    'kyc-aa-y1-12': 'Annual Accounts last book year',
    'kyc-aa-y2-12': 'Annual Accounts second last book year',
    'kyc-dogmos-13': 'Discharge of General Meeting of Shareholders (last book year)',
    'kyc-fpt-14': 'Filing Profit Tax (last book year)',
    'scfba': 'Signature card for bank account',
    'cosbaof': 'Copy of signed bank account opening forms',
    'passport_file': 'Certified copy of passport',
    'secondPassport_file': 'Certified copy of valid second ID',
    'address_file': 'Certified copy of proof of address  \n(not older then 3 months)',
    'bank_reference_letter': 'Certified Bank Reference Letter',
    'world_check': 'Sanctions / PEP / Relevant Adverse Information',
    'google_search': 'Google Search',
    'source_of_funds': 'Source of Funds',
    'source_of_wealth': 'Source of Wealth',

    'entity_aoi': 'Articles of Incorporation / Articles of Association / \nMemorandum of Association',
    'entity_coc': 'Chamber of Commerce / Certificate of Incorporation',
    'entity_rodo': 'Register of Directors(optional)',
    'entity_members': 'Share Register / Register of Members',
    'entity_soi': 'Certificate of Incumbency',
    'entity_license': 'License',
  }
  if (['scfba', 'cosbaof'].includes(sectionFile.value.documentType)) {
    return `${documentTypeToHeaderName[sectionFile.value.documentType]} for ${sectionFile.value.bank_name}`
  }
  if (sectionFile.value.documentType == 'subsidiaries') {
    return `${subsidiary.value?.name} annual accounts - last book year`
  }
  return documentTypeToHeaderName[sectionFile.value.documentType]
})


const canWriteSanctionScreeningComments = computed(() => {
  return ['DATA_PROCESSOR', 'DATA_CONTROLLER', 'COMPLIANCE_OFFICER'].includes(user.value.type)
})

const commentPrefix = computed( () => store.getters.getWorldCheckEditablePrefix)

const upload_available = computed(() => {
  if (props.reviewMode) {
    return false;
  }
  if (!sectionFile.value.ubo && !sectionFile.value.md && !sectionFile.value.person && !sectionFile.value.legalEntity) {
    return PermissionChecker.uploadFileCorporate()
  }
  if (!sectionFile.value.md && sectionFile.value.person) {
    return PermissionChecker.updateUBOSection()
  }
  if (sectionFile.value.md) {
    return PermissionChecker.updatembdSection()
  }
})

const download_available = computed(() => {
  if (!sectionFile.value.ubo && !sectionFile.value.md && !sectionFile.value.person && !sectionFile.value.legalEntity) {
    return PermissionChecker.downloadCorporate()
  }
  if (!sectionFile.value.md && sectionFile.value.person) {
    return PermissionChecker.downloadUbOSection()
  }
  if (sectionFile.value.md) {
    return PermissionChecker.downloadmbdSection()
  }
})

const delete_available = computed(() => {
  if (props.reviewMode) {
    return false;
  }
  if (!sectionFile.value.ubo && !sectionFile.value.md && !sectionFile.value.person && !sectionFile.value.legalEntity) {
      return PermissionChecker.deleteCorporate()
  }
  if (!sectionFile.value.md && sectionFile.value.person) {
    return PermissionChecker.deleteUBOSectionFiles()
  }
  if (sectionFile.value.md) {
    return PermissionChecker.deletembdSectionFiles()
  }
})


const showStructure = ref('')
const titleParts = computed(() => props.title && props.title.split('\n'));
const isDragOver = ref(false)
const yearOptions = computed(() => store.state.yearOptions);

const dashboardSettings = computed(() => store.state.companyData);

const fiscal_year_ending_on_error = ref('')
const year_error = ref('')
const confirmSanctionScreaning = ref(false);

const sanctionScreaning = ref(null);
const sanctionScreeningResponse = ref(null);
const showSanctionScreeningPopup = ref(false);
const sanctionScreaningRequestInfo = ref(null);
const sanctionScreeningRequestLoading = ref(false);



const banknamePresent = computed(() => {
  return [
    'scfba',
    'cosbaof'
  ].includes(sectionFile.value.documentType)
})

const dateLabel = computed(() => {
  const titleMap = {
    'kyc-feeibtbvdna-3' : 'Issue Date',
    'kyc-lteabibtdoea-4': 'Issue Date',
    'kyc-mlibtdoea-4-1': 'Issue Date',
    'kyc-attaoi-5-1': 'Issue Date',
    'kyc-reftcoc-5-2': 'Incorporation Date',
  }
  return titleMap[sectionFile.value.documentType]
})


const issueDatePresent = computed(() => {
  return [
    'kyc-feeibtbvdna-3',
    'kyc-lteabibtdoea-4',
    'kyc-mlibtdoea-4-1',
    'kyc-attaoi-5-1',
    'kyc-reftcoc-5-2',
  ].includes(sectionFile.value.documentType)

})

const yearPresent = computed(() => {
  return [
    'kyc-aa-y1-12',
    'kyc-aa-y2-12',
    'kyc-dogmos-13',
    'kyc-fpt-14',
    'subsidiaries',
  ].includes(sectionFile.value.documentType)
})

const year_title = computed(() => {
  let year_titles = {
      'kyc-aa-y2-12': 'Year 2',
      'kyc-aa-y1-12': 'Year 1',
      'subsidiaries': 'Annual accounts year',
  }
  return year_titles[sectionFile.value.documentType] || 'Year'
})

const fiscal_year_ending_on_present = computed(() => {
  return [
    'kyc-aa-y1-12',
    'kyc-aa-y2-12',
  ].includes(sectionFile.value.documentType)
})


const organizationalChart = computed(() => {
  return [
    'kyc-oc-6',
  ].includes(sectionFile.value.documentType)
})

const canDoGoogleSearch = computed(() => {
  return (
    sectionFile.value.documentType == 'google_search'
  )
})

const canDoSanctionScreening = computed(() => {
    return (
      sectionFile.value.documentType == 'world_check' && 
      dashboardSettings.value.sanction_screening_available.value == 'YES' && 
      !props.reviewMode &&
      PermissionChecker.canDoSnactionScreaning()
    )
})


function showChart(event) {
  event.stopPropagation();
  showStructure.value = 'shareholder'
}

function doGoogleSearch(event) {
  event.stopPropagation();
  if (!person.value?.firstName.value) {
    store.dispatch('addNotification', {text: 'Please fill First Name field', type: 'error'})
    return;
  }
  if (!person.value?.lastName.value) {
    store.dispatch('addNotification', {text: 'Please fill Last Name field', type: 'error'})
    return;
  }
  
  let link = 'https://www.google.com/search?q='
  
  const firstName = person.value.firstName.new_value || person.value.firstName.value;
  const lastName = person.value.lastName.new_value || person.value.lastName.value;

  link += `${firstName}+${lastName}`;
  // open link in new tab
  window.open(link, '_blank');
} 
function doSanctionScreaningShowConfirm(event) {
  event.stopPropagation();

  let missingFields = [];

  if (!person.value?.firstName.value) {
    missingFields.push('First Name');
  }
  if (!person.value?.lastName.value) {
    missingFields.push('Last Name');
  }
  if (!person.value?.dateOfBirth.value) {
    missingFields.push('Date of Birth');
  }
  if (!person.value?.passportCountry.value) {
    missingFields.push('Nationality');
  }
  if (missingFields.length > 0) {
    const errorText = `Please fill ${missingFields.join(', ')} field${missingFields.length > 1 ? 's' : ''} before using API sanction screening`
    store.dispatch('addNotification', {text: errorText, type: 'error'})
    return;
  }



  confirmSanctionScreaning.value = true;
}
function doSanctionScreaningRequest(){
  confirmSanctionScreaning.value = false;
  sanctionScreaningRequest()
}


async function sanctionScreaningRequest() {
  console.log('sanction Screaning Request', )
  sanctionScreeningRequestLoading.value = true;
  const response = await API.newDueDiligenceRequest({person_id: props.person_id});

  sanctionScreeningRequestLoading.value = false;
  
  console.log('sanctionScreaningRequest', response)
  if (response?.status == 'error' || !response?.data) {
      if (response?.requiredFileds) {
          const errorText = `Please fill ${response.requiredFileds.map(el => fieldNameMap[el]).join(', ')} fields before using API sanction screening`
          store.dispatch('addNotification', {text: errorText, type: 'error'})
      } else {
          store.dispatch('addNotification', {text: response?.message, type: 'error'})
      }
  } else {
      sanctionScreaning.value = response;
      sanctionScreeningResponse.value = response.data;
      sanctionScreaningRequestInfo.value = response.request;
      showSanctionScreeningPopup.value = true;
  }
}

function dragOver() {
  if (!canUpload.value) {
    return;
  }
  isDragOver.value = true;
}
 
const canUpload = computed(() => {
  return upload_available.value
})

const localFiscalYear = ref(sectionFile.value.fiscal_year_ending_on)
const localYear = ref(sectionFile.value.year)

function dragLeave() {
    if (!canUpload.value) {
      return;
    }
    isDragOver.value = false;
}
function dropFile(event) {
    if (!canUpload.value) {
      return;
    }
    isDragOver.value = false;
    handleFileChange(event);
}


function quillContent(event) {
  console.log('quillContent', event)
  store.commit('updateFileById',{
    id: props.fileId, 
    updates: {
      [`new_${event.id}`]: event.structure,
    }
  })
}





function handleWorldCheckClose(){
  const new_commentValue = new_comments(sectionFile.value, getCommentForType(showWorldCheckPopup.value))
  const hasContent = commentHasContent(sectionFile.value, getCommentForType(showWorldCheckPopup.value), 'old')
  const hasContentNew = commentHasContent(sectionFile.value, getCommentForType(showWorldCheckPopup.value), 'new')
  let warning = false;

  if (sectionFile.value[`new_${showWorldCheckPopup.value}`] == 'NO') {
    showWorldCheckPopup.value = false;
    return 
  }
  if (!sectionFile.value[`new_${showWorldCheckPopup.value}`] && sectionFile.value[`${showWorldCheckPopup.value}`] == 'NO') {
    showWorldCheckPopup.value = false;
    return
  }
  if (
    (!new_commentValue && !hasContent) || (new_commentValue && !hasContentNew)
  ) {
    warning = true;
  }

  if (warning){
    store.dispatch('addNotification', {
      text: `${WorldCheckMapFunction(showWorldCheckPopup.value)} will be set as NO as there are no comments provided`, type: 'error'
    })
    store.commit('updateFileById',{
      id: props.fileId, 
      updates: {
        [`new_${showWorldCheckPopup.value}`]: 'NO',
      }
    })
  }
  showWorldCheckPopup.value = false;
}


async function inputChange(event) {
  let name = event.target.name.split('-')[0];
  let value = event.target.id.split('-')[0];
  console.log('Uploader inputChange', name, value)
  if (value == 'YES') {
    showWorldCheckPopup.value = name;
  } 

  store.commit('updateFileById',{
    id: props.fileId, 
    updates: {
      [`new_${name}`]: value,
    }
  })
}

function inputChangeFunc(event) {
    const event_type = event[0];
    const event_value = event[1];
    
    if (['dateIssued', 'bank_name'].includes(event_type)) {
      store.commit('updateFileById',{
        id: props.fileId, 
        updates: {
          [`new_${event_type}`]: event_value,
        }
      })
    }
    if (event_type == 'year') {
      localYear.value = event_value
    }
    if (event_type == 'fiscal_year_ending_on') {
      localFiscalYear.value = event_value
    }

    let year_val = localYear.value;
    let fiscal_year_ending_on_val = localFiscalYear.value;
    if (year_val && fiscal_year_ending_on_val) {
      if (+year_val > +fiscal_year_ending_on_val.slice(0, 4) &&  +year_val != +fiscal_year_ending_on_val.slice(0, 4)) {
        fiscal_year_ending_on_error.value = 'Years do not match';
        year_error.value = 'Years do not match';
        return;
      }
    }
    fiscal_year_ending_on_error.value = '';
    year_error.value = '';

    if (sectionFile.value.year != year_val && year_val) {
      store.commit('updateFileById',{
        id: props.fileId, 
        updates: {
          new_year: year_val,
        }
      })
    }
    if (sectionFile.value.fiscal_year_ending_on != fiscal_year_ending_on_val && fiscal_year_ending_on_val) {
      store.commit('updateFileById',{
        id: props.fileId, 
        updates: {
          new_fiscal_year_ending_on: fiscal_year_ending_on_val,
        }
      })
    }
}

function eventClick(event) {
  event.stopPropagation();
}




function handleFileChange(event) {
  const files = event.target.files || event.dataTransfer.files;
  
  if (files.length > 0) {
    selectedFile.value = files[0];
  }
  const file = selectedFile.value;
  file.filename = file.name;
  store.commit('updateFileById',{
    id: props.fileId, 
    updates: {
      file: file,
      new_file: true,
    }
  })
}


function DeleteFileConfirm() {
  deleteObject.value = true;
}


async function popupConfirm(event){
  deleteObject.value = false;

  if (!event) {
    return
  }
  
  const result = await API.deleteObject(sectionFile.value.id, 'kyc_file', sectionFile.value.file.filename + 'file');

  if (result) {
    // store.dispatch('addNotification', {text: 'Request to delete ' + filePresentLocal.value.filename + ' has been sent', type: 'success'})
    // selectedFile.value = null;
    // filePresentLocal.value = null;
    // localFile.value = null;
  }
}

async function openFile(event) {
    await API.generateViewToken();
    event.stopPropagation();
    if (props.fileToShow && props.fileToShow[props.state]) {
      window.open(`${API.API_URL}get-kyc-file/?id=${props.fileToShow[props.state].serverName}&viewingToken=${viewingToken.value}`, '_blank');
      return;
    } else {
      window.open(`${API.API_URL}get-kyc-file/?id=${sectionFile.value.file.serverName}&viewingToken=${viewingToken.value}`, '_blank');
    }
}

async function Download(event) {
    console.log('Download', event)
    event.stopPropagation();
    if (props.fileToShow && props.fileToShow[props.state]) {
      await API.DownloadKYCFile(props.fileToShow[props.state].serverName, props.fileToShow[props.state].filename);
      return;
    } else {
      await API.DownloadKYCFile(sectionFile.value.file.serverName, sectionFile.value.file.filename);
    }
}





</script>
<style scoped lang="scss">
  .delete {
    width: 30px;
    height: 30px;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 4px;
    bottom: 0px;
    &:hover {
      cursor: pointer;
      background-color: var(--app-background);
      outline: 1px solid var(--border-color);
    }
  }
  .options {
    input {
      width: 15px;
    }
  }
  .option-desctions {
    font-weight: bold;
    text-align: left;
    width: 150px;
  }
  .download {
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    padding: 8px;
    border-radius: 4px;
    &:hover {
      cursor: pointer;
      background-color: var(--app-background);
      outline: 1px solid var(--border-color);

    }
  }
  .pending-changes {
    width: 18px;
    height: 18px;
  }
  .hasChanges[class] {
    border-color: var(--primary-color);
  }
  .image-upload {
    width: 30px;
    height: 30px;
  }
  .header {
      position: absolute;
      background: var(--app-background);
      border-radius: 5px;
      border: 1px solid var(--border-color);
      padding: 0 5px;
      left: -1.5px;
      border-bottom-left-radius: 0;
      color: black;
      font-weight: 600;
      text-align: left;
      max-width: 86%;
      top: -13px;
      font-size: 14px;
      z-index: 2;
    &.long {
      top: -35px;
    }
  }

  .drop-area {
    position: relative;
    width: 335px;
    min-width: 300px;
    height: 140px;
    margin-top: 10px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8997A5;
    border-radius: 5px;
    border-radius: .5rem;
    border-style: dashed;
    border-width: 2px;
    border-color: rgb(224 226 236);
    cursor: pointer;
    transition: all 0.3s;
    box-sizing: border-box;
    &.canUpload:hover {
      border-color: var(--primary-color);
      font-size: 15.5px;
      .header {
        border-color: var(--primary-color);

      }
    }
  }

  .button {
    width: fit-content;
    color: var(--primary-color);
  }
.margin-top-10 {
  margin-top: 10px;
}
.upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-right: 20px;
}
.input-fiscal-year {
  width: fit-content;
}
.buttons {
  height: 37px;
}
.action-buttons {
  white-space: nowrap;
}
.plus {
  width: 15px;
  height: 15px;
  margin-right: 10px;

  :hover {
    background: unset;
    cursor: pointer;
  }
}
.margin-div {
  display: flex;
  gap: 10px;
  button {
    flex: 1;
  }
}

.main-section-wrapper {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.wrapper-of-uploade {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  justify-content: flex-end;
}

.area-width {
  width: 335px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
      margin-right: 10px;
  }
}
.structure-view[class] {
  // &:deep(.container) {
      width: 90vw;
      height: 80vh;
  // }
}
.title {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}
.title-0.title-len2 {
  margin: 0;
}

.title-1.title-len2:not(.second_line_normal) {
  font-size: 16px;
  color: black;
}

.styled-input {
    display: flex;
    flex-direction: column;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #3D5873;
    text-align: left;
  }
  
  input, select {
    background: #38314e;
    border: 1px solid #2b263a;
    outline: none;
    caret-color: #2b263a;
    color: white;
    padding: 3px 10px;
    border-radius: 5px;
    font-size: 16px;
    height: 22px;
    border-top: unset;
    border-left: unset;
    border-right: unset;
    border-radius: 0;
    transition: padding 0.3s;
    &.select-elem {
      width: 100%;
      height: 30px;
    }
    &:focus {
      padding-bottom: 10px;
    }
    padding-left: 0;
  }
  .light {
    background: unset;
    color: black;
    caret-color: black;
  }
  

  .error-present {
    input, select {
      border-color: red;
      border-width: 1.5px;
    }
  }
  .light_label {
    font-size: 13px;
    margin-bottom: 0;

  }
  .error {
    color: red;
    text-align: left;
    padding-left: 14px;
  }

  .full-width[class] {
    width: 100%;
  }
  .additinal-info {
    display: flex;
    gap: 30px;
    > * {
      flex: 1;
      &.date-input {
        flex: 2;
      }
    }
  }
  .more-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

}
.more-actions {
  position: absolute;
  top: 0px;
  right: 0px;

  div {
    cursor: pointer;

    color: black;
  }
}
.long-header[class] {
  margin-top: 32px;
}
.popup-confirm[class] {
  &:deep(.header) {
    font-size: 22px;
  }
}
.reviewMode.new {
  .change {
    background: #DEF7EC;
    &:deep(label) {
        background: #DEF7EC;
    }
  }
}
.reviewMode.current {
  .change {
    background: #FDE8E8;
    &:deep(label) {
      background: #FDE8E8;
    }
  }
}

.loader img {
  animation: rotate 1s linear infinite;
}
.loader-text {
  font-size: 18px;
  color: black;
}
@keyframes rotate {
  100% {
      transform: rotate(360deg);
  }
}
.container-loading  {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 200px;
  background-color: white;
}

.loader-header {
  font-size: 24px;
  color: black;
  font-weight: bold;
}
.sanction-screanin-response {
  height: 90vh;
  min-height: 400px;
  width: 70vw;
  overflow: auto;
  background-color: white;
  padding: 20px;
  border-radius: 0.5rem;
}
.upload-section {
  i {
    word-break: break-word;
  }
}
.sanction-screening-comment-popup[class] {
  width: 900px;
  height: 350px;
}
.markdown-content {
  width: 100%;
  min-height: 250px;
}
.alert {
  color: red;
  text-align: left;
}
</style>