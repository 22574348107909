<template>
<tr v-if="sectionFile" :id="'table-row-id' + fileId" :class="`${showInfo ? 'opened' : '' } comments-shown_${shownInputsNumber} ${!(showComplienceOfficerComment || showSupervisorComment) ? 'no-action-buttons' : ''}`">
    
    <td class="kyc-item-section">
        <UploaderTable2 
            :fileId="props.fileId"
            :key="`file_${props.fileId}`"
            :headerText="header"
            :fullWidth="true"
            :requestedFile="props.reviewMode"
            :reviewMode="props.reviewMode"
            :person_id="props.person_id"
        />
    </td>


    <td class="yes-no"><text v-if="sectionFile?.file?.filename">Y</text></td>
    <td class="yes-no"><text v-if="!sectionFile?.file?.filename">N</text></td>
    <td class="document-comments">
    <div class="flex row h-full">
      <div class="world-check-info mb-20" v-if="sectionFile.documentType == 'world_check' && canWriteSanctionScreeningComments">
      <div class='alert' v-if="!checkmarksConform">Sanction screening findings do not conform.</div>
        
        <div class="options flex column gap-10">
            
            <div class="flex align-start gap-10">
              <div class="option-desctions">
                Sanctions found  
              </div> 
              <div class="flex row gap-5">
                <div class="flex column align-start">
                  <div class="flex row gap-5"> <input :disabled="reviewMode" type="radio" :checked="isChecked(`sanctions_found${commentPrefix}`, 'YES')" :name="`sanctions_found${commentPrefix}-${randomId}`" @change="inputChange" :id="`YES-sanctions_found${commentPrefix}-${randomId}`"> YES</div> 
                  <div class="flex row gap-5"> <input :disabled="reviewMode" type="radio" :checked="isChecked(`sanctions_found${commentPrefix}`, 'NO')" :name="`sanctions_found${commentPrefix}-${randomId}`" @change="inputChange" :id="`NO-sanctions_found${commentPrefix}-${randomId}`"> NO</div> 
                </div>
              </div>
            </div>

            <div class="flex align-start gap-10">
              <div class="option-desctions">
                PEP  
              </div> 
              <div class="flex row gap-5">

              <div class="flex column align-start">
                <div class="flex row gap-5"> <input :disabled="reviewMode" type="radio" :checked="isChecked(`pep_found${commentPrefix}`, 'YES')" :name="`pep_found${commentPrefix}-${randomId}`" @change="inputChange" :id="`YES-pep_found${commentPrefix}-${randomId}`"> YES</div> 
                <div class="flex row gap-5"> <input :disabled="reviewMode" type="radio" :checked="isChecked(`pep_found${commentPrefix}`, 'NO')" :name="`pep_found${commentPrefix}-${randomId}`" @change="inputChange" :id="`NO-pep_found${commentPrefix}-${randomId}`"> NO</div> 
              </div>
            
            </div>
            </div>

            <div class="flex align-start gap-10">
              <div class="option-desctions">
                Relevant Adverse Information  
              </div> 
              <div class="flex row gap-5">
                <div class="flex column align-start">
                  <div class="flex row gap-5"> <input :disabled="reviewMode" type="radio" :checked="isChecked(`relevant_adverse_information_found${commentPrefix}`, 'YES')" :name="`relevant_adverse_information_found${commentPrefix}-${randomId}`" @change="inputChange" :id="`YES-relevant_adverse_information_found${commentPrefix}-${randomId}`"> YES</div> 
                  <div class="flex row gap-5"> <input :disabled="reviewMode" type="radio" :checked="isChecked(`relevant_adverse_information_found${commentPrefix}`, 'NO')" :name="`relevant_adverse_information_found${commentPrefix}-${randomId}`" @change="inputChange" :id="`NO-relevant_adverse_information_found${commentPrefix}-${randomId}`"> NO</div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <CommentSection v-if="showAccountManagerComment && !accountManagerDisabled && sectionFile.documentType !== 'world_check'"
          attrId='comment_data_handler' 
          class="markdown-content small"
          :class="{disabled: accountManagerDisabled || reviewMode}"
          :disabled="accountManagerDisabled || reviewMode"
          :commentSection="sectionFile.new_comment_data_handler || sectionFile.comment_data_handler"
          @changeinput="quillContent" 
        />
        <CommentSection v-if="showSupervisorComment && !supervisorDisabled && sectionFile.documentType !== 'world_check'"
          attrId='comment_supervisor' 
          class="markdown-content small"
          :class="{disabled: supervisorDisabled || reviewMode}"
          :disabled="supervisorDisabled || reviewMode"
          :commentSection="sectionFile.new_comment_supervisor || sectionFile.comment_supervisor"
          @changeinput="quillContent" 
        />
        <CommentSection 
          v-if="showComplienceOfficerComment && !complienceOfficerDisabled && sectionFile.documentType !== 'world_check'"
          attrId='comment_compliance_officer' 
          class="markdown-content small"
          :class="{disabled: complienceOfficerDisabled || reviewMode}"
          :disabled="complienceOfficerDisabled || reviewMode"
          :commentSection="sectionFile.new_comment_compliance_officer || sectionFile.comment_compliance_officer"
          @changeinput="quillContent" 
        />
      </div>
    </td>
    
    <td class="document-opener" @click="handleClick()">
        <div>
            <span>See More</span>
        </div>

    </td>

    <PopupCover v-if="showInfo" @close="closePopup">
        <TableRowDialog 
            :fileId="fileId"
            :reviewMode="reviewMode"
            :person_id="person_id"
            @close="closePopup"
        />
    </PopupCover>
    <PopupCover v-if="showWorldCheckPopup" @close="handleWorldCheckClose()">
      <PopupBody class='sanction-screening-comment-popup' @close="handleWorldCheckClose()">
        <template #header>
            {{ WorldCheckMapFunction(showWorldCheckPopup) }}
        </template>
        <template #body>
          <div class="world-check-info mb-20 flex column gap-10 align-end">
            <CommentSection 
              :attrId="`${getCommentForType(showWorldCheckPopup)}`" 
              class="markdown-content-dialog small"
              :class="{disabled: preview}"
              :disabled="preview"
              :commentSection="sectionFile[`new_${getCommentForType(showWorldCheckPopup)}`] || `${sectionFile[getCommentForType(showWorldCheckPopup)]}`"
              @changeinput="quillContent" 
            />
            <button class='w-content ' @click="handleWorldCheckClose()">
              Confirm
            </button>
          </div>
        </template>
      </PopupBody>
    </PopupCover>
</tr>
<tr v-else>
    <td>NO ID {{ fileId }}</td>
</tr>

</template>
<script setup>
import { ref, defineEmits, computed, withDefaults, onMounted } from 'vue'
import PopupCover from '@/components/PopupCover.vue'

import PopupBody from '@/components/PopupBody.vue'

import TableRowDialog from '@/dialogs/TableRowDialog.vue'
import { PermissionChecker } from '@/utils/iam_utils'
import CommentSection from '@/views/CommentSection.vue';
import UploaderTable2 from '@/components/UploaderTable2.vue'
import { new_comments, commentHasContent } from '@/utils/kyc_utils';
import store from '../store';


import { 
  WorldCheckMapFunction,
  getCommentForType,
} from '@/utils/sanction_screening.js';

const emit = defineEmits(['handleFileChange', 'dateInputChangeFunc', 'commentChange', 'changeInfo', 'sanctionScreaningRequest' ],)
const user = computed(() => store.state.user);

const showInfo = ref(false)


const showAccountManagerComment = ref(false);
const showSupervisorComment = ref(false);
const showComplienceOfficerComment = ref(false);
const shownInputsNumber = ref(1);

const accountManagerDisabled = ref(true);
const supervisorDisabled = ref(true);
const complienceOfficerDisabled = ref(true);

if (user.value && user.value.type == 'DATA_CONTROLLER') {
    showAccountManagerComment.value = true
    accountManagerDisabled.value = false
}
if (user.value && user.value.type == 'DATA_PROCESSOR') {
    showAccountManagerComment.value = true
    showSupervisorComment.value = true;
    supervisorDisabled.value = false
}
if (['COMPLIANCE_OFFICER', 'EXTERNAL_AUDITOR', 'INTERNAL_AUDITOR', 'TRUST_OFFICER'].includes(user.value.type)) {
    showComplienceOfficerComment.value = true
    showAccountManagerComment.value = true
    showSupervisorComment.value = true
}
if (user.value.type == 'COMPLIANCE_OFFICER') {
    complienceOfficerDisabled.value = false
}

function closePopup(event) {
    if (sectionFile.value.documentType == 'world_check') { 
        checkCommentsState()
    }
    showInfo.value = false;
}
const commentPrefix = computed( () => store.getters.getWorldCheckEditablePrefix)

const canWriteSanctionScreeningComments = computed(() => {
  return ['DATA_PROCESSOR', 'DATA_CONTROLLER', 'COMPLIANCE_OFFICER'].includes(user.value.type)
})

const isChecked = computed(() => (key, value) => {
  return (sectionFile.value[`new_${key}`] || sectionFile.value[key]) == value;
})
const randomId = ref(Math.random().toString(36).substring(7));
const showWorldCheckPopup = ref(false);



function checkCommentsState(){
  let worldCheck = {
    [`pep_found${commentPrefix.value}`]: `pep_comment${commentPrefix.value}`,
    [`sanctions_found${commentPrefix.value}`]: `sanctions_comment${commentPrefix.value}`,
    [`relevant_adverse_information_found${commentPrefix.value}`]: `relevant_adverse_information_comment${commentPrefix.value}`,
  }
  for (let key in worldCheck) {
    const new_commentValue = new_comments(sectionFile.value, worldCheck[key])
    const hasContent = commentHasContent(sectionFile.value, worldCheck[key], 'old')
    const hasContentNew = commentHasContent(sectionFile.value, worldCheck[key], 'new')
    let warning = false;

    if (sectionFile.value[`new_${key}`] == 'NO') {
      continue
    }
    if (!sectionFile.value[`new_${key}`] && sectionFile.value[`${key}`] != 'YES') {
      continue
    }
    if (
      (!new_commentValue && !hasContent) || (new_commentValue && !hasContentNew)
    ) {
      warning = true;
    }
    if (warning){
      store.dispatch('addNotification', {
          text: `${WorldCheckMapFunction(key)} will be set as NO as there are no comments provided`, type: 'error'
      })
      store.commit('updateFileById', {
        id: props.fileId, 
        updates: {
          [`new_${key}`]: 'NO',
        }
      })
    }
  }
}


const upload_available = computed(() => {
  if (props.reviewMode) {
    return false;
  }
  if (!sectionFile.value.ubo && !sectionFile.value.md && !sectionFile.value.person && !sectionFile.value.legalEntity) {
    return PermissionChecker.uploadFileCorporate()
  }
  if (!sectionFile.value.md && sectionFile.value.person) {
    return PermissionChecker.updateUBOSection()
  }
  if (sectionFile.value.md) {
    return PermissionChecker.updatembdSection()
  }
})


const canUpload = computed(() => {
  return upload_available.value
})

const checkmarksConform = computed( () => store.getters.checkmarksConform(props.fileId));

const props = defineProps([
    'fileId',
    'header',
    'title', 
    'fileType', 
    'reviewMode',
    'person_id',
])

const sectionFile = computed(() => store.getters.getFileById(props.fileId));
const commentsToShow = ref(null)




function handleClick() {
    commentsToShow.value = 'all'
    if (showInfo.value) {
        return
    }
    showInfo.value = true;
}


function quillContent(event) {
    store.commit('updateFileById',{
        id: props.fileId, 
        updates: {
            [`new_${event.id}`]: event.structure,
        }
    })
}


function handleWorldCheckClose(){
  console.log('sectionFile.value', `${getCommentForType(showWorldCheckPopup.value)}`)
  const new_commentValue = new_comments(sectionFile.value, `${getCommentForType(showWorldCheckPopup.value)}`)
  const hasContent = commentHasContent(sectionFile.value, `${getCommentForType(showWorldCheckPopup.value)}`, 'old')
  const hasContentNew = commentHasContent(sectionFile.value, `${getCommentForType(showWorldCheckPopup.value)}`, 'new')
  let warning = false;

  if (sectionFile.value[`new_${showWorldCheckPopup.value}`] == 'NO') {
    showWorldCheckPopup.value = false;
    return 
  }
  if (!sectionFile.value[`new_${showWorldCheckPopup.value}`] && sectionFile.value[`${showWorldCheckPopup.value}`] == 'NO') {
    showWorldCheckPopup.value = false;
    return
  }
  if (
    (!new_commentValue && !hasContent) || (new_commentValue && !hasContentNew)
  ) {
    warning = true;
  }

  if (warning){
    store.dispatch('addNotification', {
      text: `${WorldCheckMapFunction(showWorldCheckPopup.value)} will be set as NO as there are no comments provided`, type: 'error'
    })
    store.commit('updateFileById',{
      id: props.fileId, 
      updates: {
        [`new_${showWorldCheckPopup.value}`]: 'NO',
      }
    })
  }
  showWorldCheckPopup.value = false;
}


async function inputChange(event) {
  let name = event.target.name.split('-')[0];
  let value = event.target.id.split('-')[0];
  console.log('Table file row inputChange', name, value)
  if (value == 'YES') {
    showWorldCheckPopup.value = name;
  } 

  store.commit('updateFileById',{
    id: props.fileId, 
    updates: {
      [`new_${name}`]: value,
    }
  })
}




</script>
<style lang="scss" scoped>
    textarea {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        resize: none;
        box-sizing: border-box;

        &:focus {
            outline: none;
            border: 1px solid var(--primary-color);
        }
    }
   
    .data-holder-comment-section {
        font-size: 12px;
        font-weight: 600;
        margin-top: 20px;
        
        text-align: left;
    }
    .no-margin {
        margin-top: 0px;
    }
    .options {
        input {
            outline: none;
            width: 15px;
            height: 15px;
        }
    }
    .option-desctions {
        font-weight: bold;
        text-align: left;
        width: 140px;
    }
    .popup-comment {
        font-size: 12px;
        padding: 5px;
        color: white;
        background-color: var(--primary-color);
        border-radius: 5px;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.2s;
   
        &:hover {
            background: #00408985;
        }
    }
    .button-holder {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 200px;
    }
    .data-holder-comment {
        height: 100%;
        min-height: 120px;
        width: 100%;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        border-width: 1.5px;
        margin-top: 10px;
    }
    .document-comments {
        position: relative;
    }
    .kyc-item-section {
        position: relative;

        // vertical-align: bottom;
    }

    td {
        padding: 10px;
        // border: 1px solid var(--border-color);
    }
    h3 {
        color: black;
        text-align: left;
        margin: 0px;
        font-size: 16px;
    }

    tr.comments-shown_1 {
        .document-comments {
            width: 61%;
            height: 100%;
        }
    }
    tr {
        position: relative;
    }
    tr.no-action-buttons {
        .document-comments {
            width: 60%;
        }
    }

    .yes-no {
        width: 20px;
        color: black;
        font-size: 16px;
        font-weight: 600;
    }
    
    tr > td:first-child {
        min-width: 33%;
        width: 33%;
    }
    tr {
        border-top: 1.5px solid var(--border-color);
    }
    .big {
        height: 100%;
        padding-top: 15px;
        box-sizing: border-box;
        &:deep(.drop-area) {
            height: 100%;
        }
    }
    tr:not(.opened) {
        &:hover {
            .document-opener {
                width: 20px;
                right: -40px;
                opacity: 1;
            }
        }
    }
    .document-opener {
        position: absolute;
        transition: all 0.4s;
        position: absolute;
        right: -20px;
        overflow: hidden;
        opacity: 0;
        top: 0;
        bottom: 0;
        width: 0;
        background-color: var(--primary-color);
        cursor: pointer;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            text-orientation: sideways-right;
            writing-mode: vertical-rl;
            color: white;
            font-weight: 600;
            font-size: 16px;
            text-align: center;
        }
    }
   
    .button-action {
        height: 100%;
        button {
            margin-left: auto;
            margin-top: 5px;
            margin-right: 5px;
        }
    }
    .sanction-screening-comment-popup[class] {
      width: 900px;
      height: 350px;
    }
    .markdown-content {
        width: 100%;
    }
    .markdown-content-dialog {
      min-height: 250px;
      width: 100%;
    }
    .alert {
      color: red;
      text-align: left;
    }
</style>