<template>
    <div class="table-content" >
        <div class="container" v-if="ticket">
            <div class="details">
                <div class="container-header">
                    Ticket details
                </div>
                <div class="ticket-info">
                       <div class="description-header bold-text">Description</div>
                    <div class="ticket-subject">{{ ticket.subject }}</div>
                    <div class="ticketid-title">Ticket ID</div>
                    <div class="ticketid-value bold-text">{{ ticket.ticket_id }}</div>
                    <div class="ticket-severity info-label">Severity</div>
                    <div class="ticket-severity-value bold-text">{{ ticket.severity }}</div>

                    <div class="ticket-status info-label">Status</div>
                    <div v-if="user.is_super_user && ticket.status != 'closed'" class="ticket-status-value bold-text flex align-center status-change">
                        {{ticket.status}} -- {{ status_changed }}
                        <InpComponent
                            label=""
                            class="status-select"
                            type="options"
                            :options="[{value: 'open', text: 'Open'}, {value: 'pending', text: 'Pending'}, {value: 'closed', text: 'Closed'}]"
                            key_ref="status"
                            :value="ticket.status"
                            @inputChange="inputChange"/>
                            <button v-if="statusChanged" @click="saveTicketStatus">
                                Save
                            </button>
                    </div>
                    <div v-else class="ticket-status-value bold-text">{{ ticket.status }}</div>
                </div>
            </div>
            <div class="details">
                <div class="container-header">
                    Disscusion 
                    <button class="add-more-details transparent" @click="addMoreInfo">
                        Add more details
                    </button>
                </div>
                <div class="message-list">
                    <div v-for="message in messages" :key="message.id">
                        <div class="message">
                            <div class="message-header">
                                <div class="message-author"><text v-if="message.type == 'admin'">Support </text>{{ message.username || message.user_id || message.admin_id }}</div>
                                <div class="message-date">{{ dayjs(message.created_at).format('MMM DD, YYYY hh:mm A') }}</div>
                            </div>
                            <div class="message-content" v-html="message.message"></div>
                            <div class="message-attachments" v-if="message.attachment && message.attachment.length">
                                <div class='attachment' v-for="attachment of message.attachment">
                                    <img src="@/assets/file.svg" class='fileicon'>
                                    <div class="filename">
                                        <div class="name-of-the-file">{{ attachment.filename }}</div>
                                        <div class="size-of-the-file">{{ Utils.calculateFileSize(attachment.filesize) }}</div>
                                    </div>
                                    <img src="@/assets/download.svg" class='download' @click="download(attachment)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PopupCover  v-if="showPopups" @close="closePopup">
            <CreateTicket 
                class='popup-content'
                @close="closePopup" 
                @ticketInfoUpdate="createTickethandler"/>
        </PopupCover>
    </div>
</template>

<script setup>
    import { ref,onMounted, computed } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter, useRoute } from 'vue-router'
    import API from '../api/index.js'
    import dayjs from 'dayjs'
    import Utils from '@/utils';

    import CreateTicket from '../components/CreateTicket.vue'
    import PopupCover from '../components/PopupCover.vue'
import InpComponent from '@/components/InpComponent.vue'


    const route = useRoute();
    const ticketId = route.params.id;
    const store = useStore();

    const user = store.state.user;
    const ticket = ref(null);
    const messages = ref(null);
    const showPopups = ref(false);

    const status_changed = ref(null);
    const loading = ref(false)


    onMounted(async () => {
        const response = await API.getTicket(ticketId);
        ticket.value = response.ticket;
        messages.value = response.messages;
    })
    
    const statusChanged = computed(() => status_changed.value && status_changed.value != ticket.value.status);

    function addMoreInfo(){
        showPopups.value = true
    }
    async function download(attachment){
        await API.downloadSupportFile(attachment)
    }
    function attachment(file) {
        
    }
    async function createTickethandler(ticketInfo){
        console.log('ticketInfo', ticketInfo)
        if (loading.value == true){
            return;
        }
        loading.value = true;
        const ticket = ticketInfo.ticket;
        const fileInfo = ticketInfo.files;

        if (!ticket.content) {
            loading.value = false;
            return
        }
        let fileIds = []
        for (let file of fileInfo) {
            let fileId = await API.uploadSupportFile(file);
            fileIds.push(fileId);
        }

        const result = await API.sendTicketMessage({
            ticket: {
                content:ticket.content, 
                ticket_id: ticketId
            },
            files: fileIds
        })
        if (result.status == 'success'){
            messages.value.unshift(result.message)
            closePopup();
        }
    }
    async function saveTicketStatus(){
        const result = await API.updateTicketStatus({
            ticket_id: ticketId,
            status: status_changed.value
        })
        if (result.status == 'success'){
            ticket.value.status = status_changed.value
            status_changed.value = null
        }
    }
    function inputChange(event){
        status_changed.value = event[1]
    }
    function closePopup(){
        showPopups.value = false
    }

</script>


<style lang="scss" scoped>

.download {
    margin-left: auto;
    cursor: pointer;
    width: 15px;
    height: 15px;
}

.filename {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.message-attachments {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.size-of-the-file {
    font-size: 0.8rem;
    color: rgb(116 119 127);
}
.name-of-the-file {
    display: flex;
    align-items: center;
    color: black;
    font-size: 0.9rem;

    text-align: left;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}
.fileicon {
    width: 40px;
    height: 40px;
}

.attachment {
    border-radius: .5rem;
    border-width: 1px;
    -moz-column-gap: .5rem;
    column-gap: .5rem;
    grid-template-columns: fit-content(100%) 1fr;
    border-color: rgb(224 226 236);
    background-color: rgb(255 255 255);
    padding: .8rem;


    display: flex;
    align-items: center;
}

.message-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: calc(100vh - 250px);
    overflow-y: auto;
    padding: 1rem 1.5rem;
}
.message-content {
    font-size: 0.9rem;
    line-height: 1.5rem;
    color: rgb(24 28 34);
    text-align: left;
    &:deep(p) {
        margin: 0;
    }
    
}
.status-select {
    margin-top: 5px;
    width: fit-content;
}

.message-author {
    text-align: left;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    width: 50%;
    word-break: break-word;
    color: black;
}
.message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.message-date {
    color: rgb(91 94 102);
    background: rgb(224 226 236);
    border-radius: .125rem;
    height: 1rem;
    text-wrap: nowrap;
    width: fit-content;
    font-size: .75rem;
    font-weight: 700;
    line-height: 1rem;
    padding: .125rem;
}
.message {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 10px;
    background-color: rgb(246 246 252);
    border: 1px solid var(--border-color);
    padding: .75rem;
}
.table-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.container {
    display: flex;
    gap: 20px;
    width: 1000px;
    padding-top: 60px
}
.popup-content {
    max-width: 600px;
    padding-top: 20px;
    &:deep(.header) {
        padding-bottom: 0;
    }
    &:deep(.add-new-ticket-info) {
        margin-top: 0;
    }
}

.container-header {
    align-items: center;
    border-bottom-width: 1px;
    box-sizing: content-box;
    display: flex;
    flex-direction: row;
    height: 2.5rem;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid var(--border-color);

    box-sizing: content-box;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.75rem;
    margin: 0;
    --tw-text-opacity: 1;
    color: rgb(24, 28, 34);

    button {
        width: fit-content;
        padding: 10px 30px;

    }
}
.details {
    max-width: 500px;
    flex: 1;
    background: white;
    border: 1px solid var(--border-color);
    border-radius: 15px;
    height: fit-content;

}
.ticket-info {
    text-align: left;
    padding: 1rem 1.5rem;

}

.bold-text {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: rgb(24 28 34);   
}
.ticket-subject {
    font-size: 0.9rem;
    line-height: 1.75rem;
    color: rgb(24 28 34);
}
.ticketid-title {
    font-size: .875rem;
    line-height: 1.25rem;
    margin-top: 1rem;
    color: rgb(116 119 127)
}
.info-label {
    font-size: .775rem;
    line-height: 1.25rem;
    margin-top: 1rem;
    margin-bottom: -8px;
    color: rgb(116 119 127)
}
.status-change {
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0;

    button {
        width: fit-content;
        padding: 10px 30px;
        margin: 0;
    }
}
</style>