<template>
    <thead>
        <tr class="header-of-tables">
            <td>Available Documents</td>
            <td>Y</td>
            <td>N</td>
            <td v-if="showAccountManagerComment">Account Manager Comments</td>
            <td v-if="showSupervisorComment">Supervisor Comments</td>
            <td v-if="showComplienceOfficerComment">Complience Officer Comments</td>

            <!-- <td v-if="(showComplienceOfficerComment || showSupervisorComment || otherComments) && !preview">Other comments</td> -->
            <td></td>
        </tr>
    </thead>
</template>


<script setup>
import { ref, onMounted, computed } from 'vue'
import store from '../store';


const props = defineProps(['preview'])

const user = computed(() => store.state.user);
const showAccountManagerComment = ref(false)
const showSupervisorComment = ref(false)
const showComplienceOfficerComment = ref(false)
const otherComments = ref(false)
onMounted(() => {
    if (user.value && user.value.type == 'DATA_CONTROLLER') {
        showAccountManagerComment.value = true
    }
    if (user.value && user.value.type == 'DATA_PROCESSOR') {
        showSupervisorComment.value = true
    }
    if (['COMPLIANCE_OFFICER'].includes(user.value.type)) {
        showComplienceOfficerComment.value = true
    }
    if (["EXTERNAL_AUDITOR", 'INTERNAL_AUDITOR', 'TRUST_OFFICER'].includes(user.value.type)) {
        otherComments.value = true
    }
})

</script>
