<template>
    <div>
        <div  v-if="loading">
            Loading
        </div>
        <div class='flex w-full gap-5' v-else>
            <KycView v-if="reviewAction.actionType == 'remove'"
                :id="reviewAction.kyc_review"
                :reviewMode="true"
            />
            <div v-else class='flex w-full gap-5'>
                <div class="flex-1">
                    <h3 class="header-small">
                        <text v-if="view == 'forConfirmation'">Current Version</text>
                        <text v-else>Previous Version</text>
                    </h3>
                    <div class="info-holder current-version">


                        <div class="section flex-1 border-rounded-gray" style="flex: 2">
                            <h3 v-if="companyData.company_type == 'corporate'"> Company Information </h3>
                            <h3 v-else> Account Holder Information </h3>
                            <div class="flex gap-40 full-width">
                                <div class="flex column flex-1">
                                    
                                    <InpComponent
                                        v-if="companyData.company_type == 'corporate'"
                                        class="country-select flex-1"
                                        label="Company Type"
                                        type="options"
                                        :disabled="true"
                                        :options="[{value: 'local', text: 'Onshore'}, {value: 'abroad', text: 'Offshore'}]"
                                        placeholder="Company Type"
                                        :value="getFieldValue('location_type','current')"
                                        :class="{changes: hasChanges('location_type', 'current')}"
                                    
                                        />
                                    <InpComponent
                                        v-else
                                        class="country-select flex-1"
                                        label="Client Type"
                                        type="options"
                                        :disabled="true"
                                        :options="[
                                            {value: 'bank', text: 'Bank'}, 
                                            {value: 'credit_union', text: 'Credit Union'},
                                            {value: 'pention', text: 'Pension'},
                                            {value: 'insurance', text: 'Insurance'},
                                            {value: 'player', text: 'Player'}
                                        ]"
                                        placeholder="Company Type"
                                        :value="getFieldValue('account_holder_type','current')"
                                        :class="{changes: hasChanges('account_holder_type', 'current')}"

                                    />
                                    <InpComponent
                                        v-if="companyData.company_type == 'account_holder'"
                                        :disabled="!canEditInputs"
                                        type="text" 
                                        label="First Name"
                                        :value="getFieldValue('first_name','current')"
                                        :class="{changes: hasChanges('first_name', 'current')}"
                                        
                                        />
                                    <InpComponent
                                        v-if="companyData.company_type == 'account_holder'"
                                        :disabled="!canEditInputs"
                                        type="text" 
                                        label="Last Name"
                                        :value="getFieldValue('last_name','current')"
                                        :class="{changes: hasChanges('last_name', 'current')}"
                                    />
                                    <InpComponent
                                        v-if="companyData.company_type == 'corporate'"
                                        :disabled="!canEditInputs"
                                        type="text" 
                                        label="Name"
                                        :value="getFieldValue('name','current')"
                                        :class="{changes: hasChanges('name', 'current')}"
                                        />

                                    <InpComponent
                                        v-if="companyData.company_type == 'corporate'"
                                        :disabled="!canEditInputs"
                                        type="text" 
                                        label="Chamber of Commerce"
                                        :value="getFieldValue('chamber_of_commerce','current')"
                                        :class="{changes: hasChanges('chamber_of_commerce', 'current')}"

                                        />
                                    <InpComponent
                                        v-if="companyData.company_type == 'corporate'"
                                        :disabled="!canEditInputs"
                                        type="text" 
                                        label="Activity"
                                        :value="getFieldValue('activity','current')"
                                        :class="{changes: hasChanges('activity', 'current')}"
                                    />
                                    <InpComponent
                                        v-if="companyData.company_type == 'corporate'"
                                        label="Address Line 1"
                                        type="text"
                                        :disabled="!canEditInputs"
                                        placeholder="123 Main St"
                                        :value="getFieldValue('address_line_1','current')"
                                        :class="{changes: hasChanges('address_line_1', 'current')}"
                                    />
                                    <InpComponent
                                        v-if="companyData.company_type == 'corporate'"
                                        label="Address Line 2 (optional)"
                                        :disabled="!canEditInputs"
                                        type="text"
                                        placeholder=""
                                        :value="getFieldValue('address_line_2','current')"
                                        :class="{changes: hasChanges('address_line_2', 'current')}"
                                    />
                                    <InpComponent
                                        v-if="companyData.company_type == 'corporate'"
                                        label="City / Town"
                                        :disabled="!canEditInputs"
                                        type="text"
                                        placeholder="London"
                                        :value="getFieldValue('city','current')"
                                        :class="{changes: hasChanges('city', 'current')}"
                                    />
                                    <InpComponent
                                        v-if="companyData.company_type == 'corporate'"
                                        class="country-select"
                                        label="Country"
                                        :disabled="!canEditInputs"
                                        type="options"
                                        :options="Utils && Utils.getCountryList()"
                                        placeholder="Canada"
                                        :value="getFieldValue('address_country','current')"
                                        :class="{changes: hasChanges('address_country', 'current')}"
                                    />
                                    <InpComponent
                                        v-if="companyData.company_type == 'corporate'"
                                        label="Zip"
                                        type="text"
                                        :disabled="!canEditInputs"
                                        placeholder="00000"
                                        :key_ref="'zip'"
                                        :value="getFieldValue('zip','current')"
                                        :class="{changes: hasChanges('zip', 'current')}"
                                    />
                                </div>
                            </div>
                        </div>


                        <div class="flex column gap-20" style="flex:3">
                            <div v-if="companyData.company_type !== 'account_holder'" class="section inner-section flex-1 border-rounded-gray">
                                    <h3 class="">Annual Accounts</h3>
                                    <div class="flex column">
                                        <div class="label-like"> Annual accounts confirm calendar year</div>
                                        <div class="flex row gap-10 mb-15"  
                                            :class="{changes: hasChanges('annual_account_conform_calendar_year', 'current')}"
                                        >
                                            <div class="flex align-center justify-center">
                                                <input 
                                                    :disabled="true"
                                                    type="radio" 
                                                    id="YES-annual_account_conform_calendar_year_" 
                                                    style="margin: 0 5px"
                                                    name="annual_account_conform_calendar_year_"
                                                    value="YES" 
                                                    :checked="getFieldValue('annual_account_conform_calendar_year', 'current') == 'YES'" 
                                                    >
                                                <label for="YES-annual_account_conform_calendar_year_">YES</label>
                                            </div>
                                            <div class="flex">
                                                <input 
                                                    :disabled="!canEditInputs"
                                                    type="radio" 
                                                    id="NO-annual_account_conform_calendar_year_" 
                                                    style="margin: 0 5px"
                                                    name="annual_account_conform_calendar_year_"
                                                    value="NO" 
                                                    :checked="getFieldValue('annual_account_conform_calendar_year', 'current') == 'NO'" 
                                                >
                                                <label for="NO-annual_account_conform_calendar_year_">NO</label>
                                            </div>
                                        </div>

                                        <InpComponent
                                            :disabled="!canEditInputs"
                                            type="options"
                                            :options="yearOptions"  
                                            label="Current fiscal year"
                                            :value="getFieldValue('current_fiscal_year','current')"
                                            :class="{changes: hasChanges('current_fiscal_year', 'current')}"
                                        />
                                        <InpComponent
                                            :disabled="!canEditInputs"
                                            v-if="companyData?.annual_account_conform_calendar_year.value == 'NO'"
                                            type="date"
                                            label="Fiscal Year runs from"
                                            :value="getFieldValue('fiscal_year_starts_on','current')"
                                            :class="{changes: hasChanges('fiscal_year_starts_on', 'current')}"
                                        />
                                        <InpComponent
                                            :disabled="!canEditInputs"
                                            v-if="companyData?.annual_account_conform_calendar_year.value == 'NO'"
                                            type="date"
                                            label="Fiscal Year runs up till"
                                            :value="getFieldValue('fiscal_year_ending_on','current')"
                                            :class="{changes: hasChanges('fiscal_year_ending_on', 'current')}"
                                        
                                            />
                                        <InpComponent
                                            :disabled="!canEditInputs"
                                            type="options"
                                            :options="yearOptions"  
                                            label="Last Annual Accounts on file"
                                            :value="getFieldValue('last_annual_account_on_file','current')"
                                            :class="{changes: hasChanges('last_annual_account_on_file', 'current')}"
                                        />
                                        <InpComponent
                                            :disabled="!canEditInputs"
                                            type="date"
                                            label="Last General Meeting of Shareholders"
                                            key_ref="last_board_meeting"
                                            :value="getFieldValue('last_board_meeting','current')"
                                            :class="{changes: hasChanges('last_board_meeting', 'current')}"
                                        />
                                </div>
                            </div>

                            <div class="section inner-section risk-section flex-1 border-rounded-gray">
                                <h3 class="header">Risk Classification</h3>
                                <div class="flex row gap-10 sections"
                                    :class="{changes: hasChanges('risk_level', 'current')}"
                                >
                                    <div class="flex align-center">
                                        <input class='mt-0' 
                                            :disabled="!canEditInputs" type="radio" 
                                            id="low-risk_level_" name="risk_level_" value="low" 
                                            :checked="getFieldValue('risk_level','current') == 'low'" 
                                            >
                                        <label for="low">LOW</label>
                                    </div>
                                    <div class="flex align-center">
                                        <input class='mt-0' :disabled="!canEditInputs" type="radio" id="medium-risk_level_" name="risk_level_" value="medium" 
                                        :checked="getFieldValue('risk_level','current') == 'medium'"
                                        >
                                        <label for="medium">MEDIUM</label>
                                    </div>
                                    <div class="flex align-center">
                                        <input class='mt-0' :disabled="!canEditInputs" type="radio" id="high-risk_level_" name="risk_level_" value="high" :checked="getFieldValue('risk_level','current') == 'high'">
                                        <label for="high">HIGH</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex column gap-20" style="flex: 3">

                            <div class="section inner-section flex-1 kyc-review-date border-rounded-gray">
                                <h3>Kyc Review</h3>
                                <div class="flex column">
                                    <InpComponent
                                        :disabled="!canEditInputs"
                                        type="date" 
                                        label="Date previous compliance review"
                                        :value="getFieldValue('previous_review_date','current')"
                                        :class="{changes: hasChanges('previous_review_date', 'current')}"
                                    />
                                    <InpComponent
                                        :disabled="!canEditInputs && companyData?.next_review_date_type.value != 'manual'"
                                        type="date"  
                                        :label="`Date proposed compliance review ${companyData?.next_review_date_type.value != 'manual' ? '(calculated automatically)' : ''}`"
                                        :value="getFieldValue('next_review_date','current')"
                                        :class="{changes: hasChanges('next_review_date', 'current')}"
                                    />
                                    <InpComponent
                                        :disabled="!canEditInputs"
                                        type="date" 
                                        label="Date final compliance review"
                                        :value="getFieldValue('date_final_compliance_review','current')"
                                        :class="{changes: hasChanges('date_final_compliance_review', 'current')}"
                                    />
                                </div>
                            </div>


                            <div v-if="companyData.company_type !== 'account_holder'" class="section inner-section flex-1 border-rounded-gray">
                                <h3>Profit Tax</h3>
                                <div class="flex column">
                                    <InpComponent
                                        :disabled="!canEditInputs"
                                        type="options"
                                        :options="yearOptions"                               
                                        label="Last filed Profit Tax on file "
                                        :value="getFieldValue('last_filed_profit_tax_on_file','current')"
                                        :class="{changes: hasChanges('last_filed_profit_tax_on_file', 'current')}"
                                        />
                                    <div class="label-like"> Extension Profit Tax</div>

                                    <div class="flex row gap-10 mb-15" 
                                    
                                        :class="{changes: hasChanges('extension_profit_tax', 'current')}"
                                    >
                                        <div class="flex align-center justify-center">
                                            <input 
                                                :disabled="!canEditInputs"
                                                type="radio" 
                                                id="YES-extension_profit_tax_" 
                                                :checked="getFieldValue('extension_profit_tax','current') == 'YES'"
                                                style="margin: 0 5px"
                                                name="extension_profit_tax_"
                                                value="YES" 
                                            >
                                            <label for="YES">YES</label>
                                        </div>
                                        <div class="flex">
                                            <input 
                                                :disabled="!canEditInputs"
                                                type="radio" 
                                                id="NO-extension_profit_tax_" 
                                                style="margin: 0 5px"
                                                name="extension_profit_tax_"
                                                :checked="getFieldValue('extension_profit_tax','current') == 'NO'"
                                                value="NO" 
                                            >
                                            <label for="NO">NO</label>
                                        </div>
                                    </div>


                                    <InpComponent
                                        :disabled="!canEditInputs"
                                        v-if="companyData?.extension_profit_tax == 'YES'"
                                        type="date"
                                        label="Extension up till"
                                        :value="getFieldValue('extension_up_till','current')"
                                        :class="{changes: hasChanges('extension_up_till', 'current')}"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="flex row gap-20 w-full">
                        <div class="section inner-section border-rounded-gray h-fit"
                            style="flex: 21;"
                        >
                                <div class="flex column">

                                    <div class="label-like"> Allow sanction screaning via third party API</div>

                                    <div class="flex row gap-10 mb-15" 
                                        :class="{changes: hasChanges('sanction_screening_available', 'current')}"
                                    >
                                        <div class="flex align-center justify-center">
                                            <input 
                                                :disabled="!canEditInputs"
                                                type="radio" 
                                                id="YES-sanction_screening_available_" 
                                                style="margin: 0 5px"
                                                :checked="getFieldValue('sanction_screening_available','current') == 'YES'" 
                                                value="YES" 
                                            >
                                            <label for="YES">YES</label>
                                        </div>
                                        <div class="flex">
                                            <input 
                                                :disabled="!canEditInputs"
                                                type="radio" 
                                                id="NO-sanction_screening_available_" 
                                                style="margin: 0 5px"
                                                name="sanction_screening_available"
                                                value="NO" 
                                                :checked="getFieldValue('sanction_screening_available','current') == 'NO'" 
                                            >
                                            <label for="NO">NO</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div>
                </div>
                <div class="flex-1">
                    <h3 class="header-small">
                        <text>New Version</text>
                    </h3>
                    <div class="info-holder proposed-changes">
                        <div class="section flex-1 border-rounded-gray" style="flex: 2">
                            <h3 v-if="companyData.company_type == 'corporate'"> Company Information </h3>
                            <h3 v-else> Account Holder Information </h3>
                            <div class="flex gap-40 full-width">
                                <div class="flex column flex-1">
                                    
                                    <InpComponent
                                        v-if="companyData.company_type == 'corporate'"
                                        class="country-select flex-1"
                                        label="Company Type"
                                        type="options"
                                        :disabled="true"
                                        :options="[{value: 'local', text: 'Onshore'}, {value: 'abroad', text: 'Offshore'}]"
                                        placeholder="Company Type"
                                        :value="getFieldValue('location_type','new')"
                                        :class="{changes: hasChanges('location_type', 'new')}"
                                    
                                        />
                                    <InpComponent
                                        v-else
                                        class="country-select flex-1"
                                        label="Client Type"
                                        type="options"
                                        :disabled="true"
                                        :options="[
                                            {value: 'bank', text: 'Bank'}, 
                                            {value: 'credit_union', text: 'Credit Union'},
                                            {value: 'pention', text: 'Pension'},
                                            {value: 'insurance', text: 'Insurance'},
                                            {value: 'player', text: 'Player'}
                                        ]"
                                        placeholder="Company Type"
                                        :value="getFieldValue('account_holder_type','new')"
                                        :class="{changes: hasChanges('account_holder_type', 'new')}"

                                    />
                                    <InpComponent
                                        v-if="companyData.company_type == 'account_holder'"
                                        :disabled="!canEditInputs"
                                        type="text" 
                                        label="First Name"
                                        :value="getFieldValue('first_name','new')"
                                        :class="{changes: hasChanges('first_name', 'new')}"
                                        
                                        />
                                    <InpComponent
                                        v-if="companyData.company_type == 'account_holder'"
                                        :disabled="!canEditInputs"
                                        type="text" 
                                        label="Last Name"
                                        :value="getFieldValue('last_name','new')"
                                        :class="{changes: hasChanges('last_name', 'new')}"
                                    />
                                    <InpComponent
                                        v-if="companyData.company_type == 'corporate'"
                                        :disabled="!canEditInputs"
                                        type="text" 
                                        label="Name"
                                        :value="getFieldValue('name','new')"
                                        :class="{changes: hasChanges('name', 'new')}"
                                        />

                                    <InpComponent
                                        v-if="companyData.company_type == 'corporate'"
                                        :disabled="!canEditInputs"
                                        type="text" 
                                        label="Chamber of Commerce"
                                        :value="getFieldValue('chamber_of_commerce','new')"
                                        :class="{changes: hasChanges('chamber_of_commerce', 'new')}"

                                        />
                                    <InpComponent
                                        v-if="companyData.company_type == 'corporate'"
                                        :disabled="!canEditInputs"
                                        type="text" 
                                        label="Activity"
                                        :value="getFieldValue('activity','new')"
                                        :class="{changes: hasChanges('activity', 'new')}"
                                    />
                                    <InpComponent
                                        v-if="companyData.company_type == 'corporate'"
                                        label="Address Line 1"
                                        type="text"
                                        :disabled="!canEditInputs"
                                        placeholder="123 Main St"
                                        :value="getFieldValue('address_line_1','new')"
                                        :class="{changes: hasChanges('address_line_1', 'new')}"
                                    />
                                    <InpComponent
                                        v-if="companyData.company_type == 'corporate'"
                                        label="Address Line 2 (optional)"
                                        :disabled="!canEditInputs"
                                        type="text"
                                        placeholder=""
                                        :value="getFieldValue('address_line_2','new')"
                                        :class="{changes: hasChanges('address_line_2', 'new')}"
                                    />
                                    <InpComponent
                                        v-if="companyData.company_type == 'corporate'"
                                        label="City / Town"
                                        :disabled="!canEditInputs"
                                        type="text"
                                        placeholder="London"
                                        :value="getFieldValue('city','new')"
                                        :class="{changes: hasChanges('city', 'new')}"
                                    />
                                    <InpComponent
                                        v-if="companyData.company_type == 'corporate'"
                                        class="country-select"
                                        label="Country"
                                        :disabled="!canEditInputs"
                                        type="options"
                                        :options="Utils && Utils.getCountryList()"
                                        placeholder="Canada"
                                        :value="getFieldValue('address_country','new')"
                                        :class="{changes: hasChanges('address_country', 'new')}"
                                    />
                                    <InpComponent
                                        v-if="companyData.company_type == 'corporate'"
                                        label="Zip"
                                        type="text"
                                        :disabled="!canEditInputs"
                                        placeholder="00000"
                                        :key_ref="'zip'"
                                        :value="getFieldValue('zip','new')"
                                        :class="{changes: hasChanges('zip', 'new')}"
                                    />
                                </div>
                            </div>
                        </div>


                        <div class="flex column gap-20" style="flex:3">
                            <div v-if="companyData.company_type !== 'account_holder'" class="section inner-section flex-1 border-rounded-gray">
                                    <h3 class="">Annual Accounts</h3>
                                    <div class="flex column">
                                        <div class="label-like"> Annual accounts confirm calendar year</div>
                                        <div class="flex row gap-10 mb-15"  
                                            :class="{changes: hasChanges('annual_account_conform_calendar_year', 'new')}"
                                        >
                                            <div class="flex align-center justify-center">
                                                <input 
                                                    :disabled="true"
                                                    type="radio" 
                                                    id="YES-annual_account_conform_calendar_year" 
                                                    style="margin: 0 5px"
                                                    name="annual_account_conform_calendar_year"
                                                    value="YES" 
                                                    :checked="getFieldValue('annual_account_conform_calendar_year', 'new') == 'YES'" 
                                                    >
                                                <label for="YES-annual_account_conform_calendar_year">YES</label>
                                            </div>
                                            <div class="flex">
                                                <input 
                                                    :disabled="!canEditInputs"
                                                    type="radio" 
                                                    id="NO-annual_account_conform_calendar_year" 
                                                    style="margin: 0 5px"
                                                    name="annual_account_conform_calendar_year"
                                                    value="NO" 
                                                    :checked="getFieldValue('annual_account_conform_calendar_year', 'new') == 'NO'" 
                                                >
                                                <label for="NO-annual_account_conform_calendar_year">NO</label>
                                            </div>
                                        </div>

                                        <InpComponent
                                            :disabled="!canEditInputs"
                                            type="options"
                                            :options="yearOptions"  
                                            label="Current fiscal year"
                                            :value="getFieldValue('current_fiscal_year','new')"
                                            :class="{changes: hasChanges('current_fiscal_year', 'new')}"
                                        />
                                        <InpComponent
                                            :disabled="!canEditInputs"
                                            v-if="companyData?.annual_account_conform_calendar_year.value == 'NO'"
                                            type="date"
                                            label="Fiscal Year runs from"
                                            :value="getFieldValue('fiscal_year_starts_on','new')"
                                            :class="{changes: hasChanges('fiscal_year_starts_on', 'new')}"
                                        />
                                        <InpComponent
                                            :disabled="!canEditInputs"
                                            v-if="companyData?.annual_account_conform_calendar_year.value == 'NO'"
                                            type="date"
                                            label="Fiscal Year runs up till"
                                            :value="getFieldValue('fiscal_year_ending_on','new')"
                                            :class="{changes: hasChanges('fiscal_year_ending_on', 'new')}"
                                        
                                            />
                                        <InpComponent
                                            :disabled="!canEditInputs"
                                            type="options"
                                            :options="yearOptions"  
                                            label="Last Annual Accounts on file"
                                            :value="getFieldValue('last_annual_account_on_file','new')"
                                            :class="{changes: hasChanges('last_annual_account_on_file', 'new')}"
                                        />
                                        <InpComponent
                                            :disabled="!canEditInputs"
                                            type="date"
                                            label="Last General Meeting of Shareholders"
                                            key_ref="last_board_meeting"
                                            :value="getFieldValue('last_board_meeting','new')"
                                            :class="{changes: hasChanges('last_board_meeting', 'new')}"
                                        />
                                </div>
                            </div>

                            <div class="section inner-section risk-section flex-1 border-rounded-gray">
                                <h3 class="header">Risk Classification</h3>
                                <div class="flex row gap-10 sections"
                                    :class="{changes: hasChanges('risk_level', 'new')}"
                                >
                                    <div class="flex align-center">
                                        <input class='mt-0' 
                                            :disabled="!canEditInputs" type="radio" 
                                            id="low-risk_level" name="risk_level" value="low" 
                                            :checked="getFieldValue('risk_level','new') == 'low'" 
                                            >
                                        <label for="low">LOW</label>
                                    </div>
                                    <div class="flex align-center">
                                        <input class='mt-0' :disabled="!canEditInputs" type="radio" id="medium-risk_level" name="risk_level" value="medium" 
                                        :checked="getFieldValue('risk_level','new') == 'medium'"
                                        >
                                        <label for="medium">MEDIUM</label>
                                    </div>
                                    <div class="flex align-center">
                                        <input class='mt-0' :disabled="!canEditInputs" type="radio" id="high-risk_level" name="risk_level" value="high" :checked="getFieldValue('risk_level','new') == 'high'">
                                        <label for="high">HIGH</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex column gap-20" style="flex: 3">

                            <div class="section inner-section flex-1 kyc-review-date border-rounded-gray">
                                <h3>Kyc Review</h3>
                                <div class="flex column">
                                    <InpComponent
                                        :disabled="!canEditInputs"
                                        type="date" 
                                        label="Date previous compliance review"
                                        :value="getFieldValue('previous_review_date','new')"
                                        :class="{changes: hasChanges('previous_review_date', 'new')}"
                                    />
                                    <InpComponent
                                        :disabled="!canEditInputs && companyData?.next_review_date_type.value != 'manual'"
                                        type="date"  
                                        :label="`Date proposed compliance review ${companyData?.next_review_date_type.value != 'manual' ? '(calculated automatically)' : ''}`"
                                        :value="getFieldValue('next_review_date','new')"
                                        :class="{changes: hasChanges('next_review_date', 'new')}"
                                    />
                                    <InpComponent
                                        :disabled="!canEditInputs"
                                        type="date" 
                                        label="Date final compliance review"
                                        :value="getFieldValue('date_final_compliance_review','new')"
                                        :class="{changes: hasChanges('date_final_compliance_review', 'new')}"
                                    />
                                </div>
                            </div>


                            <div v-if="companyData.company_type !== 'account_holder'" class="section inner-section flex-1 border-rounded-gray">
                                <h3>Profit Tax</h3>
                                <div class="flex column">
                                    <InpComponent
                                        :disabled="!canEditInputs"
                                        type="options"
                                        :options="yearOptions"                               
                                        label="Last filed Profit Tax on file "
                                        :value="getFieldValue('last_filed_profit_tax_on_file','new')"
                                        :class="{changes: hasChanges('last_filed_profit_tax_on_file', 'new')}"
                                        />
                                    <div class="label-like"> Extension Profit Tax</div>

                                    <div class="flex row gap-10 mb-15" 
                                    
                                        :class="{changes: hasChanges('extension_profit_tax', 'new')}"
                                    >
                                        <div class="flex align-center justify-center">
                                            <input 
                                                :disabled="!canEditInputs"
                                                type="radio" 
                                                id="YES-extension_profit_tax" 
                                                :checked="getFieldValue('extension_profit_tax','new') == 'YES'"
                                                style="margin: 0 5px"
                                                name="extension_profit_tax"
                                                value="YES" 
                                            >
                                            <label for="YES">YES</label>
                                        </div>
                                        <div class="flex">
                                            <input 
                                                :disabled="!canEditInputs"
                                                type="radio" 
                                                id="NO-extension_profit_tax" 
                                                style="margin: 0 5px"
                                                name="extension_profit_tax"
                                                :checked="getFieldValue('extension_profit_tax','new') == 'NO'"
                                                value="NO" 
                                            >
                                            <label for="NO">NO</label>
                                        </div>
                                    </div>


                                    <InpComponent
                                        :disabled="!canEditInputs"
                                        v-if="companyData?.extension_profit_tax == 'YES'"
                                        type="date"
                                        label="Extension up till"
                                        :value="getFieldValue('extension_up_till','new')"
                                        :class="{changes: hasChanges('extension_up_till', 'new')}"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="flex row gap-20 w-full">
                        <div class="section inner-section border-rounded-gray h-fit"
                            style="flex: 21;"
                        >
                                <div class="flex column">

                                    <div class="label-like"> Allow sanction screaning via third party API</div>

                                    <div class="flex row gap-10 mb-15" 
                                        :class="{changes: hasChanges('sanction_screening_available', 'new')}"
                                    >
                                        <div class="flex align-center justify-center">
                                            <input 
                                                :disabled="!canEditInputs"
                                                type="radio" 
                                                id="YES-sanction_screening_available" 
                                                style="margin: 0 5px"
                                                :checked="getFieldValue('sanction_screening_available','new') == 'YES'" 
                                                value="YES" 
                                            >
                                            <label for="YES">YES</label>
                                        </div>
                                        <div class="flex">
                                            <input 
                                                :disabled="!canEditInputs"
                                                type="radio" 
                                                id="NO-sanction_screening_available" 
                                                style="margin: 0 5px"
                                                name="sanction_screening_available"
                                                value="NO" 
                                                :checked="getFieldValue('sanction_screening_available','new') == 'NO'" 
                                            >
                                            <label for="NO">NO</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup >

import { ref, computed, onMounted, watch } from 'vue'
import Shareholder from '@/components/Shareholder.vue'
import InpComponent from '@/components/InpComponent.vue'
import dayjs from 'dayjs';
import KycView from '@/views/KycView.vue';
import { useStore } from 'vuex';

import API from '@/api/index.js'

const props = defineProps(['reviewAction', 'view'])
const store = useStore();

const fieldChange = ref(JSON.parse(props.reviewAction.fields));
const companyData = computed(() => store.state.companyData);

const loading = ref(true);
onMounted(async() => {
    if (props.reviewAction.actionType == 'remove') {
        await API.getKycFilesV2({snapshot_id: null, company_id: props.reviewAction.kyc_review});
    }
    await API.loadDashboardData({company_id: props.reviewAction.kyc_review});
    loading.value = false;
});

const canEditInputs = ref(false);

function hasChanges(field, version) {
    if (version == 'current') {
        return fieldChange.value[field]?.new != undefined && fieldChange.value[field]?.current
    }
    return fieldChange.value[field]?.new != undefined;

}

function getFieldValue(field, state) {
    if (
        state == 'current' && 
        fieldChange.value[field] && 
        !fieldChange.value[field].current && 
        fieldChange.value[field].new
    ) {
        return null;
    }
    if (fieldChange.value[field] && fieldChange.value[field][state]) {
        return fieldChange.value[field][state];
    }
    return companyData.value[field].value;
}



function renderTime(time) {
    if (!time) return '';
    return dayjs(time).format('MMM DD, YYYY [at] HH:mm');
}


</script>

<style lang="scss" scoped>

    .proposed-changes {
        margin-left: auto;
        .changes {
            background: #DEF7EC;
            &:deep(label) {
                background: #DEF7EC;
            }
        }
    }
    .current-version {
       
        .changes {
            background: #FDE8E8;
            &:deep(label) {
                background: #FDE8E8;
            }
        }
    }
</style>