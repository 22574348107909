<template>
    <div>
        <div  v-if="loading">
            Loading
        </div>
        <div class='flex w-full gap-5' v-else>
            <TransactionProfile v-if="reviewAction.actionType == 'remove'"
                :transactionProfile_id="transactionProfile._id"
                :company_id="transactionProfile.company_id"
                :reviewMode="true"
            />
            <div v-else class='flex w-full gap-5'>
                <div class="flex-1">
                    <h3 class="header-small">
                        <text v-if="view == 'forConfirmation'">Current Version</text>
                        <text v-else>Previous Version</text>
                    </h3>
                    <div class="info-holder current-version">
                        <div class="section flex-1" style="flex: 2">
                            <h3> Client Information </h3>
                            <div class="flex gap-40 full-width">
                                <div class="flex column flex-1">
                                    <InpComponent
                                        label="Customer Name"
                                        type="text"
                                        :disabled="true"
                                        :value="customerName"
                                    />
                                    <InpComponent
                                        label="Account Number"
                                        type="text"
                                        :disabled="true"
                                        :value="`${accountNumber.account_number.new_value || accountNumber.account_number.value || ''}`"
                                    />
                                    <InpComponent
                                        label="Bank Name"
                                        type="options"
                                        :disabled="true"
                                        :options="bankOptions"
                                        :value="getFieldValue('name_bank','current')"
                                        :class="{changes: hasChanges('name_bank', 'current')}"
                                    />
                                    <InpComponent
                                        label="Currency"
                                        type="options"
                                        :disabled="true"
                                        :options="currencyListOption"
                                        :key_ref="'currency'"
                                        :value="getFieldValue('currency','current')"
                                        :class="{changes: hasChanges('currency', 'current')}"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="section flex-1" style="flex: 2">
                            <h3> Jurisdictions </h3>
                            <div class="comment-area w-full">
                                <div class="comment-header mb-10">
                                    Countries of expected incoming payments
                                </div>
                                <CommentSection
                                    attrId="expected_transaction_incoming_info"
                                    class="comment-section"
                                    :disabled="inputsDisabled"
                                    :commentSection="getFieldValue('expected_transaction_incoming_info','current')"
                                    :class="{changes: hasChanges('expected_transaction_incoming_info', 'current')}"
                                />
                            </div>
                            <div class="comment-area w-full">
                                <div class="comment-header mb-10">
                                    Countries of expected outgoing payments
                                </div>
                                <CommentSection
                                    attrId="expected_transaction_outgoing_info"
                                    class="comment-section"
                                    :disabled="inputsDisabled"
                                    :commentSection="getFieldValue('expected_transaction_outgoing_info','current')"
                                    :class="{changes: hasChanges('expected_transaction_outgoing_info', 'current')}"
                                />
                            </div>
                        </div>

                        <div class="section flex-1" style="flex: 2">
                            <h3>Expected turnover</h3>

                            <div class="section fields top-info flex column gap-10 flex-1">
                                <div class="comment-header mb-10">
                                    Information about incoming payments
                                </div>
                                <div class="flex column gap-20">
                                    <div class="checkbox-area">
                                        <div class="checkbox-header">
                                            Average incoming transaction value/size
                                        </div>
                                        <div class="options">
                                            <div class="flex gap-10 align-center"
                                            :class="{changes: hasChanges('transaction_value_incoming', 'current','less_than_3_000')}"
                                            >
                                                <input :disabled="inputsDisabled" type="radio" 
                                                    name="transaction_value_incoming"  
                                                    :checked="getFieldValue('transaction_value_incoming','current') == 'less_than_3_000'"
                                                    id="less_than_3_000-transaction_value_incoming">
                                                <div class="option-desctions">
                                                    Less than 3,000 {{ getFieldValue('currency','current') }}
                                                </div>
                                            </div>
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('transaction_value_incoming', 'current','3_001_10_000')}"
                                            >
                                                <input :disabled="inputsDisabled" type="radio" 
                                                    name="transaction_value_incoming"  
                                                    :checked="getFieldValue('transaction_value_incoming','current') == '3_001_10_000'"
                                                    id="3_001_10_000-transaction_value_incoming">
                                                <div class="option-desctions">
                                                    From 3 001 {{ getFieldValue('currency','current') }} to 10 000 {{ getFieldValue('currency','current') }}
                                                </div>
                                            </div>
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('transaction_value_incoming', 'current','10_001_50_000')}"
                                            >
                                                <input :disabled="inputsDisabled" type="radio" 
                                                    name="transaction_value_incoming"  
                                                    :checked="getFieldValue('transaction_value_incoming','current') == '10_001_50_000'"
                                                    id="10_001_50_000-transaction_value_incoming">
                                                <div class="option-desctions">
                                                    From 10 001 {{ getFieldValue('currency','current') }} to 50 000 {{ getFieldValue('currency','current') }}
                                                </div>
                                            </div>
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('transaction_value_incoming', 'current','more_than_50_000')}"
                                            >
                                                <input :disabled="inputsDisabled" type="radio" 
                                                    name="transaction_value_incoming"  
                                                    :checked="getFieldValue('transaction_value_incoming','current') == 'more_than_50_000'"
                                                    id="more_than_50_000-transaction_value_incoming">
                                                <div class="option-desctions">
                                                    More than 50 000 {{ getFieldValue('currency','current') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="checkbox-area">
                                        <div class="checkbox-header">
                                            Planned monthly incoming payments turnover using on <b>{{ getFieldValue('name_bank','current') }}</b> account 
                                        </div>
                                        <div class="options">
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('monthly_turnover_incoming', 'current','less_than_10_000')}"
                                            >
                                                <input :disabled="inputsDisabled" 
                                                    type="radio" 
                                                    name="monthly_turnover_incoming"  
                                                    :checked="getFieldValue('monthly_turnover_incoming','current') == 'less_than_10_000'"
                                                    id="less_than_10_000-monthly_turnover_incoming">
                                                <div class="option-desctions">
                                                    Less than 10,000 {{ getFieldValue('currency','current') }}
                                                </div>
                                            </div>
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('monthly_turnover_incoming', 'current','10_001_50_000')}"
                                            >
                                                <input :disabled="inputsDisabled" 
                                                    type="radio" 
                                                    name="monthly_turnover_incoming"  
                                                    :checked="getFieldValue('monthly_turnover_incoming','current') == '10_001_50_000'"
                                                    id="10_001_50_000-monthly_turnover_incoming">
                                                <div class="option-desctions">
                                                    From 10 001 {{ getFieldValue('currency','current') }} to 50 000 {{ getFieldValue('currency','current') }}
                                                </div>
                                            </div>
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('monthly_turnover_incoming', 'current','50_001_100_000')}"
                                            >
                                                <input :disabled="inputsDisabled" 
                                                    type="radio" 
                                                    name="monthly_turnover_incoming"  
                                                    :checked="getFieldValue('monthly_turnover_incoming','current') == '50_001_100_000'"
                                                    id="50_001_100_000-monthly_turnover_incoming">
                                                <div class="option-desctions">
                                                    From 50 001 {{ getFieldValue('currency','current') }} to 100 000 {{ getFieldValue('currency','current') }}
                                                </div>
                                            </div>
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('monthly_turnover_incoming', 'current','more_than_100_000')}"
                                            >
                                                <input :disabled="inputsDisabled" 
                                                    type="radio" 
                                                    name="monthly_turnover_incoming"  
                                                    :checked="getFieldValue('monthly_turnover_incoming','current') == 'more_than_100_000'"
                                                    id="more_than_100_000-monthly_turnover_incoming">
                                                <div class="option-desctions">
                                                    More than 100 000 {{ getFieldValue('currency','current') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="checkbox-area">
                                        <div class="checkbox-header">
                                            Monthly frequency of incoming payments
                                        </div>
                                        <div class="options">
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('monthly_frequency_of_transaction_incoming', 'current','less_than_10')}"
                                            >
                                                <input :disabled="inputsDisabled" 
                                                    type="radio" 
                                                    name="monthly_frequency_of_transaction_incoming"  
                                                    :checked="getFieldValue('monthly_frequency_of_transaction_incoming','current') == 'less_than_10'"
                                                    id="less_than_10-transaction_value_incoming">
                                                <div class="option-desctions">
                                                    Up to 10
                                                </div>
                                            </div>
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('monthly_frequency_of_transaction_incoming', 'current','11_50')}"
                                            >
                                                <input :disabled="inputsDisabled" 
                                                    type="radio" 
                                                    name="monthly_frequency_of_transaction_incoming"  
                                                    :checked="getFieldValue('monthly_frequency_of_transaction_incoming','current') == '11_50'"
                                                    id="11_50-monthly_frequency_of_transaction_incoming">
                                                <div class="option-desctions">
                                                    From 11 to 50
                                                </div>
                                            </div>
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('monthly_frequency_of_transaction_incoming', 'current','51_100')}"
                                            >
                                                <input :disabled="inputsDisabled" 
                                                    type="radio" 
                                                    name="monthly_frequency_of_transaction_incoming"  
                                                    :checked="getFieldValue('monthly_frequency_of_transaction_incoming','current') == '51_100'"
                                                    id="51_100-monthly_frequency_of_transaction_incoming">
                                                <div class="option-desctions">
                                                    From 51 to 100
                                                </div>
                                            </div>
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('monthly_frequency_of_transaction_incoming', 'current','more_than_100')}"
                                            >
                                                <input :disabled="inputsDisabled" 
                                                    type="radio" 
                                                    name="monthly_frequency_of_transaction_incoming"  
                                                    :checked="getFieldValue('monthly_frequency_of_transaction_incoming','current') == 'more_than_100'"
                                                    id="more_than_100-monthly_frequency_of_transaction_incoming">
                                                <div class="option-desctions">
                                                    More than 100       
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="section flex-1">
                            <div class="checkbox-area">
                                <div class="checkbox-header mb-10">
                                    Main counterparties for outgoing payments (name/jurisdiction)
                                </div>
                                <InpComponent
                                    class="flex-1"
                                    label="Name *"
                                    type="text"
                                    :disabled="inputsDisabled"
                                    :value="getFieldValue('outgoing_counterparty','current')"
                                    :class="{changes: hasChanges('outgoing_counterparty', 'current')}"
                                />
                                <div class="checkbox-header remark">
                                    * - Mandatory field
                                </div>
                                <InpComponent
                                    class="flex-1"
                                    label="Name"
                                    type="text"
                                    :disabled="inputsDisabled"
                                    :value="getFieldValue('outgoing_counterparty_2','current')"
                                    :class="{changes: hasChanges('outgoing_counterparty_2', 'current')}"
                                />
                                <InpComponent
                                    class="flex-1"
                                    label="Name"
                                    type="text"
                                    :disabled="inputsDisabled"
                                    :value="getFieldValue('outgoing_counterparty_3','current')"
                                    :class="{changes: hasChanges('outgoing_counterparty_3', 'current')}"
                                />
                            </div>
                        </div>
                        <div class="section flex-1">
                            <div class="checkbox-area">
                                <div class="checkbox-header mb-10">
                                    Main counterparties for incoming payments (name/jurisdiction)
                                </div>
                                <InpComponent
                                    class="flex-1"
                                    label="Name *"
                                    type="text"
                                    :disabled="inputsDisabled"
                                    :value="getFieldValue('incoming_counterparty','current')"
                                    :class="{changes: hasChanges('incoming_counterparty', 'current')}"
                                />
                                <div class="checkbox-header remark">
                                    * - Mandatory field
                                </div>
                                <InpComponent
                                    class="flex-1"
                                    label="Name"
                                    type="text"
                                    :disabled="inputsDisabled"
                                    :value="getFieldValue('incoming_counterparty_2','current')"
                                    :class="{changes: hasChanges('incoming_counterparty_2', 'current')}"
                                />
                                <InpComponent
                                    class="flex-1"
                                    label="Name"
                                    type="text"
                                    :disabled="inputsDisabled"
                                    :value="getFieldValue('incoming_counterparty_3','current')"
                                    :class="{changes: hasChanges('incoming_counterparty_3', 'current')}"
                                />
                            </div>
                        </div>
                        <div class="section flex-1">
                            <div class="section-title-area ">
                            </div>
                            <div class="comment-area w-full">
                                <div class="comment-header mb-10">
                                    Comments to the transaction profile
                                </div>
                                <CommentSection
                                    attrId="comments"
                                    class="comment-section big"
                                    :disabled="inputsDisabled"
                                    :value="getFieldValue('comments','current')"
                                    :class="{changes: hasChanges('comments', 'current')}"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-1">
                    <h3 class="header-small">
                        <text>New Version</text>
                    </h3>
                    <div class="info-holder proposed-changes">


                        <div class="section flex-1" style="flex: 2">
                            <h3> Client Information </h3>
                            <div class="flex gap-40 full-width">
                                <div class="flex column flex-1">
                                    
                                    <InpComponent
                                        label="Customer Name"
                                        type="text"
                                        :disabled="true"
                                        :value="customerName"
                                    />
                                    <InpComponent
                                        label="Account Number"
                                        type="text"
                                        :disabled="true"
                                        :value="`${accountNumber.account_number.new_value || accountNumber.account_number.value || ''}`"
                                    />
                                    <InpComponent
                                        label="Bank Name"
                                        type="options"
                                        :disabled="true"
                                        :options="bankOptions"
                                        :value="getFieldValue('name_bank','new')"
                                        :class="{changes: hasChanges('name_bank', 'new')}"
                                    />
                                    <InpComponent
                                        label="Currency"
                                        type="options"
                                        :disabled="true"
                                        :options="currencyListOption"
                                        :key_ref="'currency'"
                                        :value="getFieldValue('currency','new')"
                                        :class="{changes: hasChanges('currency', 'new')}"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="section flex-1" style="flex: 2">
                            <h3> Jurisdictions </h3>
                            <div class="comment-area w-full">
                                <div class="comment-header mb-10">
                                    Countries of expected incoming payments
                                </div>
                                <CommentSection
                                    attrId="expected_transaction_incoming_info"
                                    class="comment-section"
                                    :disabled="inputsDisabled"
                                    :commentSection="getFieldValue('expected_transaction_incoming_info','new')"
                                    :class="{changes: hasChanges('expected_transaction_incoming_info', 'new')}"
                                />
                            </div>
                            <div class="comment-area w-full">
                                <div class="comment-header mb-10">
                                    Countries of expected outgoing payments
                                </div>
                                <CommentSection
                                    attrId="expected_transaction_outgoing_info"
                                    class="comment-section"
                                    :disabled="inputsDisabled"
                                    :commentSection="getFieldValue('expected_transaction_outgoing_info','new')"
                                    :class="{changes: hasChanges('expected_transaction_outgoing_info', 'new')}"
                                />
                            </div>
                        </div>

                        <div class="section flex-1" style="flex: 2">
                            <h3>Expected turnover</h3>

                            <div class="section fields top-info flex column gap-10 flex-1">
                                <div class="comment-header mb-10">
                                    Information about incoming payments
                                </div>
                                <div class="flex column gap-20">
                                    <div class="checkbox-area">
                                        <div class="checkbox-header">
                                            Average incoming transaction value/size
                                        </div>
                                        <div class="options">
                                            <div class="flex gap-10 align-center"
                                            :class="{changes: hasChanges('transaction_value_incoming', 'new','less_than_3_000')}"
                                            >
                                                <input :disabled="inputsDisabled" type="radio" 
                                                    name="transaction_value_incoming"  
                                                    :checked="getFieldValue('transaction_value_incoming','new') == 'less_than_3_000'"
                                                    id="less_than_3_000-transaction_value_incoming_">
                                                <div class="option-desctions">
                                                    Less than 3,000 {{ getFieldValue('currency','new') }}
                                                </div>
                                            </div>
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('transaction_value_incoming', 'new','3_001_10_000')}"
                                            >
                                                <input :disabled="inputsDisabled" type="radio" 
                                                    name="transaction_value_incoming"  
                                                    :checked="getFieldValue('transaction_value_incoming','new') == '3_001_10_000'"
                                                    id="3_001_10_000-transaction_value_incoming_">
                                                <div class="option-desctions">
                                                    From 3 001 {{ getFieldValue('currency','new') }} to 10 000 {{ getFieldValue('currency','new') }}
                                                </div>
                                            </div>
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('transaction_value_incoming', 'new','10_001_50_000')}"
                                            >
                                                <input :disabled="inputsDisabled" type="radio" 
                                                    name="transaction_value_incoming"  
                                                    :checked="getFieldValue('transaction_value_incoming','new') == '10_001_50_000'"
                                                    id="10_001_50_000-transaction_value_incoming_">
                                                <div class="option-desctions">
                                                    From 10 001 {{ getFieldValue('currency','new') }} to 50 000 {{ getFieldValue('currency','new') }}
                                                </div>
                                            </div>
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('transaction_value_incoming', 'new','more_than_50_000')}"
                                            >
                                                <input :disabled="inputsDisabled" type="radio" 
                                                    name="transaction_value_incoming"  
                                                    :checked="getFieldValue('transaction_value_incoming','new') == 'more_than_50_000'"
                                                    id="more_than_50_000-transaction_value_incoming_">
                                                <div class="option-desctions">
                                                    More than 50 000 {{ getFieldValue('currency','new') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="checkbox-area">
                                        <div class="checkbox-header">
                                            Planned monthly incoming payments turnover using on <b>{{ getFieldValue('name_bank','new') }}</b> account 
                                        </div>
                                        <div class="options">
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('monthly_turnover_incoming', 'new','less_than_10_000')}"
                                            >
                                                <input :disabled="inputsDisabled" 
                                                    type="radio" 
                                                    name="monthly_turnover_incoming"  
                                                    :checked="getFieldValue('monthly_turnover_incoming','new') == 'less_than_10_000'"
                                                    id="less_than_10_000-monthly_turnover_incoming_">
                                                <div class="option-desctions">
                                                    Less than 10,000 {{ getFieldValue('currency','new') }}
                                                </div>
                                            </div>
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('monthly_turnover_incoming', 'new','10_001_50_000')}"
                                            >
                                                <input :disabled="inputsDisabled" 
                                                    type="radio" 
                                                    name="monthly_turnover_incoming"  
                                                    :checked="getFieldValue('monthly_turnover_incoming','new') == '10_001_50_000'"
                                                    id="10_001_50_000-monthly_turnover_incoming_">
                                                <div class="option-desctions">
                                                    From 10 001 {{ getFieldValue('currency','new') }} to 50 000 {{ getFieldValue('currency','new') }}
                                                </div>
                                            </div>
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('monthly_turnover_incoming', 'new','50_001_100_000')}"
                                            >
                                                <input :disabled="inputsDisabled" 
                                                    type="radio" 
                                                    name="monthly_turnover_incoming"  
                                                    :checked="getFieldValue('monthly_turnover_incoming','new') == '50_001_100_000'"
                                                    id="50_001_100_000-monthly_turnover_incoming_">
                                                <div class="option-desctions">
                                                    From 50 001 {{ getFieldValue('currency','new') }} to 100 000 {{ getFieldValue('currency','new') }}
                                                </div>
                                            </div>
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('monthly_turnover_incoming', 'new','more_than_100_000')}"
                                            >
                                                <input :disabled="inputsDisabled" 
                                                    type="radio" 
                                                    name="monthly_turnover_incoming"  
                                                    :checked="getFieldValue('monthly_turnover_incoming','new') == 'more_than_100_000'"
                                                    id="more_than_100_000-monthly_turnover_incoming_">
                                                <div class="option-desctions">
                                                    More than 100 000 {{ getFieldValue('currency','new') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="checkbox-area">
                                        <div class="checkbox-header">
                                            Monthly frequency of incoming payments
                                        </div>
                                        <div class="options">
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('monthly_frequency_of_transaction_incoming', 'new','less_than_10')}"
                                            >
                                                <input :disabled="inputsDisabled" 
                                                    type="radio" 
                                                    name="monthly_frequency_of_transaction_incoming"  
                                                    :checked="getFieldValue('monthly_frequency_of_transaction_incoming','new') == 'less_than_10'"
                                                    id="less_than_10-transaction_value_incoming_">
                                                <div class="option-desctions">
                                                    Up to 10
                                                </div>
                                            </div>
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('monthly_frequency_of_transaction_incoming', 'new','11_50')}"
                                            >
                                                <input :disabled="inputsDisabled" 
                                                    type="radio" 
                                                    name="monthly_frequency_of_transaction_incoming"  
                                                    :checked="getFieldValue('monthly_frequency_of_transaction_incoming','new') == '11_50'"
                                                    id="11_50-monthly_frequency_of_transaction_incoming_">
                                                <div class="option-desctions">
                                                    From 11 to 50
                                                </div>
                                            </div>
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('monthly_frequency_of_transaction_incoming', 'new','51_100')}"
                                            >
                                                <input :disabled="inputsDisabled" 
                                                    type="radio" 
                                                    name="monthly_frequency_of_transaction_incoming"  
                                                    :checked="getFieldValue('monthly_frequency_of_transaction_incoming','new') == '51_100'"
                                                    id="51_100-monthly_frequency_of_transaction_incoming_">
                                                <div class="option-desctions">
                                                    From 51 to 100
                                                </div>
                                            </div>
                                            <div class="flex gap-10 align-center"
                                                :class="{changes: hasChanges('monthly_frequency_of_transaction_incoming', 'new','more_than_100')}"
                                            >
                                                <input :disabled="inputsDisabled" 
                                                    type="radio" 
                                                    name="monthly_frequency_of_transaction_incoming"  
                                                    :checked="getFieldValue('monthly_frequency_of_transaction_incoming','new') == 'more_than_100'"
                                                    id="more_than_100-monthly_frequency_of_transaction_incoming_">
                                                <div class="option-desctions">
                                                    More than 100       
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="section flex-1">
                            <div class="checkbox-area">
                                <div class="checkbox-header mb-10">
                                    Main counterparties for outgoing payments (name/jurisdiction)
                                </div>
                                <InpComponent
                                    class="flex-1"
                                    label="Name *"
                                    type="text"
                                    :disabled="inputsDisabled"
                                    :value="getFieldValue('outgoing_counterparty','new')"
                                    :class="{changes: hasChanges('outgoing_counterparty', 'new')}"
                                />
                                <div class="checkbox-header remark">
                                    * - Mandatory field
                                </div>
                                <InpComponent
                                    class="flex-1"
                                    label="Name"
                                    type="text"
                                    :disabled="inputsDisabled"
                                    :value="getFieldValue('outgoing_counterparty_2','new')"
                                    :class="{changes: hasChanges('outgoing_counterparty_2', 'new')}"
                                />
                                <InpComponent
                                    class="flex-1"
                                    label="Name"
                                    type="text"
                                    :disabled="inputsDisabled"
                                    :value="getFieldValue('outgoing_counterparty_3','new')"
                                    :class="{changes: hasChanges('outgoing_counterparty_3', 'new')}"
                                />
                            </div>
                        </div>
                        
                        <div class="section flex-1">
                            <div class="checkbox-area">
                                <div class="checkbox-header mb-10">
                                    Main counterparties for incoming payments (name/jurisdiction)
                                </div>
                                <InpComponent
                                    class="flex-1"
                                    label="Name *"
                                    type="text"
                                    :disabled="inputsDisabled"
                                    :value="getFieldValue('incoming_counterparty','new')"
                                    :class="{changes: hasChanges('incoming_counterparty', 'new')}"
                                />
                                <div class="checkbox-header remark">
                                    * - Mandatory field
                                </div>
                                <InpComponent
                                    class="flex-1"
                                    label="Name"
                                    type="text"
                                    :disabled="inputsDisabled"
                                    :value="getFieldValue('incoming_counterparty_2','new')"
                                    :class="{changes: hasChanges('incoming_counterparty_2', 'new')}"
                                />
                                <InpComponent
                                    class="flex-1"
                                    label="Name"
                                    type="text"
                                    :disabled="inputsDisabled"
                                    :value="getFieldValue('incoming_counterparty_3','new')"
                                    :class="{changes: hasChanges('incoming_counterparty_3', 'new')}"
                                />
                            </div>
                        </div>
                        <div class="section flex-1">
                            <div class="section-title-area ">
                            </div>
                            <div class="comment-area w-full">
                                <div class="comment-header mb-10">
                                    Comments to the transaction profile
                                </div>
                                <CommentSection
                                    attrId="comments"
                                    class="comment-section big"
                                    :disabled="inputsDisabled"
                                    :value="getFieldValue('comments','new')"
                                    :class="{changes: hasChanges('comments', 'new')}"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup >

import { ref, computed, onMounted, watch } from 'vue'
import InpComponent from '@/components/InpComponent.vue'
import CommentSection from '@/views/CommentSection.vue'
import TransactionProfile from './TransactionProfile.vue';
import dayjs from 'dayjs';
import KycView from '@/views/KycView.vue';
import { useStore } from 'vuex';

import API from '@/api/index.js'

const props = defineProps(['reviewAction', 'view'])
const store = useStore();

const fieldChange = ref(JSON.parse(props.reviewAction.fields));
const companyData = ref();
const transactionProfile = ref(null);
const loading = ref(true);


const customerName = computed(() => {
    if (companyData.value.company_type === 'corporate') {
        return companyData.value.name.new_value || companyData.value.name.value
    }
    return `${companyData.value.first_name.new_value || companyData.value.first_name.value} ${companyData.value.last_name.new_value || companyData.value.last_name.value}`
})
const accountNumber = ref(null);


onMounted(async() => {

    const companyDataResponse = await API.loadDashboardData({company_id: props.reviewAction.company_id});
    companyData.value = companyDataResponse.companyData;

    const transactionResponse = await API.loadTransactionProfileData(props.reviewAction.transaction_profile);
    transactionProfile.value = transactionResponse.transactionProfile;
    
    const accountNumberResponse = await API.getAccountNumberById(transactionResponse.transactionProfile.account_number);
    accountNumber.value = accountNumberResponse.accountNumber;
    store.state.kyc_accountNumbers.push(accountNumberResponse.accountNumber);
    store.state.kyc_transactionalProfile.push(transactionResponse.transactionProfile);

    loading.value = false;

});

const inputsDisabled = ref(true);

function hasChanges(field, version, value = undefined) {
    if (version == 'current') {
        return (
            fieldChange.value[field]?.new != undefined 
            && fieldChange.value[field]?.current 
            && (value == undefined
                || (value && fieldChange.value[field]?.current == value)
            )
        )
    }
    return (
        fieldChange.value[field]?.new != undefined
        && (value == undefined
            || (value && fieldChange.value[field]?.new == value)
        )
    )
}


function getFieldValue(field, state) {
    if (
        state == 'current' && 
        fieldChange.value[field] && 
        !fieldChange.value[field].current && 
        fieldChange.value[field].new
    ) {
        return null;
    }
    if (fieldChange.value[field] && fieldChange.value[field][state]) {
        return fieldChange.value[field][state];
    }
    return transactionProfile.value[field].value;
}



function renderTime(time) {
    if (!time) return '';
    return dayjs(time).format('MMM DD, YYYY [at] HH:mm');
}


</script>

<style lang="scss" scoped>

    .proposed-changes {
        margin-left: auto;
        .changes {
            background: #DEF7EC;
            &:deep(label) {
                background: #DEF7EC;
            }
        }
    }
    .current-version {
       
        .changes {
            background: #FDE8E8;
            &:deep(label) {
                background: #FDE8E8;
            }
        }
    }
    .comment-section {
        &:deep(.editor-section) {
            min-height: 100px;
        }
        &.big {
            &:deep(.editor-section) {
                min-height: 250px;
            }
        }
    }
    h3 {
        text-align: left;
    }
    .comment-header {
        text-align: left;
        font-size: 14px;
        font-weight: 600;
    }
    .options input {
        margin: 5px;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        margin-left: 0;
    }
    .section-title-area {
        text-align: left;
        min-width: 300px;
        font-weight: 600;
    }
    .checkbox-header {
        text-align: left;
        font-size: 13px;
    }
</style>