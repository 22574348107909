<template>
    <div class="main-wrapper flex column gap-40"
    >
    
        <div class="flex column gap30">
            <div class="flex row " v-if="canBeDeleted">
                <div style="margin-left: auto" >
                    <button @click="DeleteUbo()">Delete</button>
                </div>
            </div>
            
            <PersonComp2
                :key="`${UboPerson._id}_ubo`"
                :person_id="UboPerson._id" 
                :source_of_funds="true"
                :source_of_wealth="true"
                :bank_reference_letter="true"
                :google_search="true"
                :world_check="true"  
                :reviewMode="reviewMode"
                :hideShare="hideShare"
            />
        </div>
    </div>
</template>


<script setup>
import { ref, computed, defineProps } from 'vue'
import { useStore } from 'vuex'
import PersonComp2 from '@/components/PersonComp2.vue'
import { PermissionChecker } from '@/utils/iam_utils'

const props = defineProps([ 'ubo_id', 'reviewMode', 'hideShare'])
const store = useStore();


const sectionUbo = computed(() => store.getters.getUboById(props.ubo_id))
const UboPerson = computed(() => store.getters.getPersonOfUboById(props.ubo_id))

const companyData = computed(() => store.state.companyData);

const canBeDeleted = computed(() => {
    if (props.reviewMode) {
        return false;
    }
    if (companyData.value.company_type === 'account_holder') {
        return false;
    } 
    if (sectionUbo.value.share_holder_related) {
        return false;
    }
    if(sectionUbo.value.mbd_related) { 
        return PermissionChecker.deletembdSection()
    } else {
        return PermissionChecker.deleteUBOSection()
    }
})

async function DeleteUbo(){
    if (window.confirm('Are you sure you want to delete this UBO?')) {
        store.dispatch( 
            'deleteUbo', {
                ubo_id: props.ubo_id, 
            }
        )
    }
}

</script>


<style scoped lang="scss">
    .flex {
        display: flex;
        &.row {
            flex-direction: row;
        }
        &.column {
            flex-direction: column;
        }
    }
    .main-wrapper {
        border-radius: 5px;
        background-color: white;
        padding: 20px;
        border: 1px solid #e0e0e0;

    }
    .passport-fields {
        margin-top: 20px;
    }
    .gap30 {
        gap: 30px;
    }
    textarea {
        resize: none; 
        box-sizing: border-box;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        width: 685px;
        height: 150px;
    }
    .add-new {
        margin-left: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .margin-top-40 {
        margin-top: 40px;
    }
    .country-select {
        max-width: 340px;
    }
    .type-selector {
        display: flex;
        flex-direction: row;
        gap: 30px;
    }
    .wrapper-main {
        border: 1px solid #e0e0e0;
    }
</style>