<template>
    <div class='chart-container w-full flex row gap-40' :class="companyData.company_type">
        <!-- <canvas id="acquisitions"></canvas> -->
        <div class="chart-section bg-white" style="flex:2" @click="showShareHolderRediness = 'corporate'" v-if="companyData.company_type == 'corporate'">
            <img src="@/assets/password-look.svg" class="delete">
            <div class="p-20 element-ch">
                <h3>Corporate Section Progress: ({{ filledDocsGeneral.filledDocuments }} of {{ filledDocsGeneral.needed_docs }} documents filled in)</h3>
                <apexchart type="donut" :options="getOptions('general').options" :series="generalSection"></apexchart>
            </div>
        </div>
        <div class="chart-section bg-white  pointer" style="flex:3" @click="showShareHolderRediness = 'shareholders'">
            <img src="@/assets/password-look.svg" class="delete">
            <div class="p-20 element-ch">
                <h3>{{ getTitle }}: ({{ initShareholderCheck.filled }} of {{ initShareholderCheck.needed }} documents filled in)</h3>
                <apexchart type="donut" :options="getOptions('sh').options" :series="shareholderSeries"></apexchart>
            </div>
        </div>
        <div class="chart-section bg-white pointer" style="flex:3" @click="showShareHolderRediness = 'managing_directors'" v-if="companyData.company_type == 'corporate'">
            <img src="@/assets/password-look.svg" class="delete">
            <div class="p-20 element-ch">
                <h3>Managing Directors Section Progress: ({{ ManagingDirectorsCheck.filled }} of {{ ManagingDirectorsCheck.needed }} documents filled in)</h3>
                <apexchart type="donut" :options="getOptions('md').options" :series="mdSeries"></apexchart>
            </div>
        </div>
        
        <PopupCover 
            class="detailed-breakdown"
            v-if="showShareHolderRediness " @close="showShareHolderRediness = ''"
            >
            <PopupBody 
                @close="() => showShareHolderRediness = ''"> 
                <template #header>
                    <text v-if="showShareHolderRediness != 'corporate' ">
                        {{ `Corporate ${showShareHolderRediness == 'shareholders' ? 'Shareholder': 'Managing Directors'} Progress ${companyData.name.new_value || companyData.name.value}` }}
                    </text>
                    <text v-else>
                        {{ `Corporate Section Progress for ${companyData.name.new_value || companyData.name.value}` }}
                    </text>
                </template>
                <template #body>
                    <div class="flex body-popup column gap-10">
                        <div class='info-holder' v-for="item of components" :key="item.name">
                            <div class="flex column text-left">
                                <div class="flex row gap-10">
                                    <p class="text-info">{{ item.name }}: ({{ item.filledDocuments }} of {{ item.needed }} documents filled in)</p>
                                    <div class='take-a-look' @click="scrollTo(item)">
                                        Go to Section
                                    </div>
                                </div>
                                <ProgressBar :small="true" :current="item.filledDocuments" :total="item.needed" />
                            </div>
                        </div>
                    </div>
                </template>
            </PopupBody>
        
        </PopupCover>
    </div>
</template>

<script setup>

import { ref, defineEmits, onMounted, computed } from 'vue' 
import ProgressBar from './ProgressBar.vue';
import PopupCover from './PopupCover.vue';
import PopupBody from './PopupBody.vue';
import { useStore } from 'vuex'
const store = useStore()


const corporateFiles = computed(() => store.getters.getCorporateFilesReadiness)
const companyData = computed(() => store.state.companyData)
const fileMap = computed(() => store.getters.getFilesMapID)
const shareHolderStructure = computed(() => store.getters.getStructureForChart({
    type: 'shareholder', 
    root: true,
    full: true
}));

const MDStructure = computed(() => store.getters.getStructureForChart({
    type: 'entity', 
    source: 'entity',
    root: true,
    full: true
}));

const showShareHolderRediness = ref(false)

const person = computed(() => {
    return store.state.kyc_persons;
})


const getTitle = computed(() => {
    let title = 'Shareholders Section Progress';
    if (companyData.value.company_type == 'account_holder') {
        title = 'Account Holder Section Progress';
    }
    return title;

})

const generalSection = computed( () => {
    let needed = filledDocsGeneral.value.needed_docs;
    let current = filledDocsGeneral.value.filledDocuments;

    let part = 100 / needed;
    let filled = part * current;
    let rest = 100 - filled

    return [filled, rest]
})

const mdSeries = computed( () => {
    let needed = ManagingDirectorsCheck.value.needed;
    let current = ManagingDirectorsCheck.value.filled;

    let part = 100 / needed;
    let filled = part * current;
    let rest = 100 - filled

    return [filled, rest]
})

const shareholderSeries = computed( () => {
    let needed = initShareholderCheck.value.needed;
    let current = initShareholderCheck.value.filled;

    let part = 100 / needed;
    let filled = part * current;
    let rest = 100 - filled

    return [filled, rest]
})

const components = computed( () => {
    if (showShareHolderRediness.value == 'shareholders') {
        return initShareholderCheck.value.components
    } else if (showShareHolderRediness.value == 'corporate') {
        return Object.values(filledDocsGeneral.value.components).filter(el => el.needed > 0)
    } else if (showShareHolderRediness.value == 'managing_directors') {
        return ManagingDirectorsCheck.value.components
    }
})


const getOptions = computed(() => (type) => {
    console.log('type', type)
    const chartDef = {
        series: [30, 70],

        options: {
            chart: {
                type: "donut",
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: "80%",
                    },
                },
            },
            stroke: {
                show: false,
            },
            dataLabels: {
                enabled: false, // Hide default labels
            },
            legend: {
                show: false,
            },
            colors: ["#0073EF", "#EAEAEA"], // Active + Background color
            labels: ["Completed", "Remaining"], // Labels for the donut chart
            states: {
                hover: {
                    filter: {
                        type: "none",
                    },
                },
            },
            annotations: {
                position: "front",
                texts: [
                    {
                        text: "75%", // Change dynamically if needed
                        x: "50%", 
                        y: "52%",
                        fontSize: "18px",
                        fontWeight: "bold",
                        textAnchor: "middle",
                    },
                ],
            },
            tooltip: {
                theme: "dark", // Options: "dark", "light"
                style: {
                    fontSize: '12px',
                    fontFamily: 'Arial',
                    color: '#fff', // Text color
                },
                fillSeriesColor: false, // Prevents series color from affecting tooltip text
                background: {
                    color: '#333', // Background color
                }
            }
        }
    }
    let progress = null
    if (type == 'general') {
        progress = generalSection.value
    }
    if ( type == 'sh') {
        progress = shareholderSeries.value
    }
    if (type == 'md') {
        progress = mdSeries.value
    }
    chartDef.options.annotations.texts[0].text = +(progress[0]).toFixed(2) + '%'
    return chartDef
})

function checkEntity(accumulator, entity, parent_type) {
    let needed = 0;
    let filledDocuments = 0;
    let fields_to_check = getEntity();
    if (parent_type == 'corporate_shareholder' || entity.supervised.value.toString() == 'false') {
        fields_to_check = fields_to_check.filter( el => el.docType != 'entity_license')
    }
    needed += fields_to_check.length;
    for (let cur of fields_to_check) {
        const file = fileMap.value[entity.files[cur.docType]]
        filledDocuments += checkFile(file, cur)
    }
    accumulator.filled += filledDocuments;
    accumulator.needed += needed;

    let typeTitle = parent_type.replace('_', ' ');
    typeTitle = typeTitle.charAt(0).toUpperCase() + typeTitle.slice(1);
    accumulator.components.push({
        id: entity._id,
        type: 'entity',
        name: typeTitle + ' ' + entity.name.value,
        filledDocuments,
        needed
    })
}

function scrollTo(item) {
    const element = document.getElementById('id' + item.id);
    console.log('element id' + item.id)
    if (element) {
        showShareHolderRediness.value = '';
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
}

function checkPerson(accumulator, person, type = 'UBO', withComponents = false) {
    let needed = 0;
    let filledDocuments = 0;
    let fields_to_check = getPerson();


    if (type == 'Official') {
        fields_to_check = fields_to_check.filter( el => ![
            'source_of_funds', 
            'source_of_wealth'
        ].includes(el.docType))
    }
    if (person.location_type.value == 'local') {
        fields_to_check = fields_to_check.filter( el => el.docType != 'secondPassport_file')
    } 
    needed += fields_to_check.length;

    for (let cur of fields_to_check) {
        const file = fileMap.value[person.files[cur.docType]]
        let result = checkFile(file, cur, person)
        
        filledDocuments += result
        if (withComponents) {
            accumulator.components.push({
                id: file.id,
                needed: 1,
                filledDocuments: result,
                name: documentTypeToHeaderName[cur.docType] || cur.docType,
            })
        }
    }
    accumulator.filled += filledDocuments;
    accumulator.needed += needed;
    if (!withComponents) {
        accumulator.components.push({
            id: person._id,
            type: 'person',
            name: type + ' ' + person?.firstName?.value + ' ' + person?.lastName?.value,
            filledDocuments,
            needed
        })
    }

}


function checkCorporate(accumulator, corporate) {
    let managingDirectors = corporate.children.filter( el => el.type == 'corporate');
    let shareholders = corporate.children.filter( el => el.type == 'shareholder');
    let officials = corporate.children.filter( el => el.type == 'official');
    let entity = corporate.entity;
    
    if (corporate.entity_type == 'entity' || corporate.type == 'corporate_shareholder') {
        checkEntity(accumulator, entity, corporate.type);
    }
    for (let managingDirector of managingDirectors) {
        checkCorporate(accumulator, managingDirector);
    }
    for (let shareholder of shareholders) {
        calculateShareholderNeededFiles(accumulator, shareholder);
    }
    for (let official of officials) {
        checkPerson(accumulator, {
            ...official,
            location_type: { value: corporate.location_type.value },
        }, 'Official');
    }
    if (corporate.entity_type == 'person' && corporate.type == 'corporate') {
        if (officials.length == 0) {
            let fields_to_check = getPerson();
            accumulator.needed += fields_to_check.length;
            accumulator.components.push({
                name: `Missing At least one Official for managing dirrector Section`,
                filledDocuments: 0,
                needed: fields_to_check.length,
            })
        }
    }
    if (corporate.type == 'corporate_shareholder') {
        if (managingDirectors.length == 0) {
            let fields_to_check = getPerson();
            accumulator.needed += fields_to_check.length;
            accumulator.components.push({
                id: `-md${corporate._id}`,
                name: `Missing At least one Managing Director Section for ${entity.name.value}`,
                filledDocuments: 0,
                needed: fields_to_check.length,
            })
        }
    }
    if (corporate.type == 'corporate') {
        //check number of officials if none add missing
        let officials = corporate.children.filter( el => el.type == 'official');
        if (officials.length == 0) {
            let fields_to_check_number = getPerson().length;


            // officials does not need source of funds and source of wealth
            fields_to_check_number = fields_to_check_number - 2;
            if (corporate.location_type.value == 'local') {
                fields_to_check_number = fields_to_check_number - 1;
            }

            accumulator.needed += fields_to_check_number;
            accumulator.components.push({
                id: `-officials${corporate._id}`,
                name: `Missing At least one Official for ${entity.name.value}`,
                filledDocuments: 0,
                needed: fields_to_check_number,
            })
        }
    }
    if (
        !(
            (corporate.type == 'corporate' && corporate.entity_type == 'entity' && entity.supervised.value.toString() == 'false') ||
            (corporate.type == 'corporate_shareholder')
        )
    ) {
        return
    }
    if (corporate.location_type.value == 'local') {
        return;
    }



    if (shareholders.length == 0) {
        console.log('corporate')
        let fields_to_check = getPerson();
        accumulator.needed += fields_to_check.length;
        accumulator.components.push({
            id: `-sh-h${corporate._id}`,
            name: `Missing UBO section for ${entity.name.value}`,
            filledDocuments: 0,
            needed: fields_to_check.length,
        })
    } else {
        let totalSharesCovered = 0;
        shareholders.forEach( shareholder => {
            totalSharesCovered += shareholder.percentage_held;
        })
        const remainingShares = 100 - totalSharesCovered;
        if (remainingShares > 0) {
            let fields_to_check = getPerson();
            accumulator.needed += fields_to_check.length;
            accumulator.components.push({
                id: `-sh-h${corporate._id}`,
                name: `Missing at least one shareholder of ${remainingShares}% for ${entity.name.value}`,
                filledDocuments: 0,
                needed: fields_to_check.length,
            })
        }
    }
}

const ManagingDirectorsCheck = computed(() => {
    let info = {
        needed: 0,
        filled: 0,
        components: []
    }
    let managingDirectors = MDStructure.value[0].children.filter( el => el.type == 'corporate');
    console.log('managingDirectors', managingDirectors)
    
    for (let managingDirector of managingDirectors) {
        checkCorporate(info, managingDirector)
    }

    if (managingDirectors.length == 0) {
        let fields_to_check = getPerson();
        info.needed += fields_to_check.length;
        info.components.push({
            id: 'md_corporate',
            name: `Missing Managind Director for ${companyData.value.name.value}`,
            filledDocuments: 0,
            needed: fields_to_check.length,
        })
    }
    return info;
})


const initShareholderCheck = computed(() => {
    
    let info = {
        needed: 0,
        filled: 0,
        components: []
    }

    if (companyData.value.company_type == 'account_holder') {
        checkPerson(info, person.value[0], 'UBO', true);
        return info;

    }

    let shareholders = shareHolderStructure.value[0].children;

    for (let shareholder of shareholders) {
        calculateShareholderNeededFiles(info, shareholder);
    }
    if (shareholders.length == 0) {
        let fields_to_check = getPerson();
        info.needed += fields_to_check.length;
        info.components.push({
            id: `shareholder_corporate`,
            name: `Missing Shareholder for ${companyData.value.name.value}`,
            filledDocuments: 0,
            needed: fields_to_check.length,
        })
    }
    return info;
})


function calculateShareholderNeededFiles(accumulator, shareholder){
    let child = shareholder.children[0];
    if (child.type == 'ubo') {
        checkPerson(accumulator, child);
    } else {
        checkCorporate(accumulator, child);
    }
}

function getEntity() {
    const docs = [
        {docType: 'entity_aoi', file: true},
        {docType: 'entity_coc', file: true},
        {docType: 'entity_members', file: true},
        {docType: 'entity_rodo', file: true},
        {docType: 'entity_soi', file: true},
        {docType: 'entity_license', file: true,},
    ]
    return docs;
}


function getPerson(){
    const docs = [
        {docType: 'passport_file', file: true, additinal_person_fields: [
            'firstName', 'lastName', 'passportNumber', 'passportCountry', 'dateIssued', 'dateExpiry', 'tinNumber'
        ]}, 
        {docType: 'address_file', file: true, additinal_person_fields: [
            'address_line_1', 'address_date_issues', 'city', 'address_country', 'zip', 
        ]},
        {docType: 'secondPassport_file', file: true, additinal_person_fields: [
            'secondPassportNumber', 'secondPassportType', 'secondPassportCountry', 'secondDateIssued', 'secondDateExpiry', 
        ]},
        {docType: 'bank_reference_letter', file: true},
        {docType: 'source_of_funds', file: true},
        {docType: 'source_of_wealth', file: true},
        {docType: 'google_search', file: true},
        {docType: 'world_check', file: true},
    ]
    return docs;
}



function checkFile(fileArea, settings, person) {
    if(!fileArea || !fileArea?.file?.filename) {
        return 0;
    }
    if (!settings.additinal_fields && !settings.additinal_person_fields) {
        return 1;
    }

    let filled = true;
    if (settings.additinal_fields) {
        for(let field of settings.additinal_fields) {
            if (!fileArea[field] || !fileArea[field]) {
                filled = false;
                break;
            }
        }
    }

    if (settings.additinal_person_fields) {
        for(let field of settings.additinal_person_fields) {
            if (!person[field].value) {
                console.log('fileArea', field)

                filled = false;
                break;
            }
        }
    }
   
    if (filled) {
        return 1;
    }
    return 0;
}


const documentTypeToHeaderName = {
    'kyc-ma-1': 'Management Agreement',
    'kyc-ppa-2': 'Principal Party Agreement',
    'kyc-feeibtbvdna-3': 'Foreign Exchange Exemption issued by the Bank van de Nederlandse Antillen',
    'kyc-lteabibtdoea-4': 'License to Establish a Business issued by the Department of Economic Affairs',
    'kyc-mlibtdoea-4-1': 'Management License issued by the Department of Economic Affairs',
    'kyc-aoi-5': 'Articles of Incorporation',
    'kyc-attaoi-5-1': 'Amendments to the Articles of Incorporation',
    'kyc-reftcoc-5-2': 'Recent Extract from the Chamber of Commerce',
    'kyc-oc-6': 'Organizational Chart',
    'kyc-sr-7': 'Shareholders\' register',
    'kyc-reftcoc-7-1': 'Share transfer agreement (after establishment)',
    'kyc-sof-8': 'Source of funds',
    'kyc-ruling': 'Tax Ruling',
    'kyc-aa-y1-12': 'Annual Accounts last book year',
    'kyc-aa-y2-12': 'Annual Accounts second last book year',
    'kyc-dogmos-13': 'Discharge of General Meeting of Shareholders (last book year)',
    'kyc-fpt-14': 'Filing Profit Tax (last book year)',
    'scfba': 'Signature card for bank account',
    'cosbaof': 'Copy of signed bank account opening forms',
    'subsidiaries': 'Subsidiaries',
    
    'passport_file': 'Certified copy of passport',
    'secondPassport_file': 'Certified copy of valid second ID',
    'address_file': 'Certified copy of proof of address  \n(not older then 3 months)',
    'bank_reference_letter': 'Certified Bank Reference Letter',
    'world_check': 'Sanctions / PEP / Relevant Adverse Information',
    'google_search': 'Google Search',
    'source_of_funds': 'Source of Funds',
    'source_of_wealth': 'Source of Wealth',
    
    'entity_aoi': 'Articles of Incorporation / Articles of Association / \nMemorandum of Association',
    'entity_coc': 'Chamber of Commerce / Certificate of Incorporation',
    'entity_rodo': 'Register of Directors(optional)',
    'entity_members': 'Share Register / Register of Members',
    'entity_soi': 'Certificate of Incumbency',
    'entity_license': 'License',
}


const generalSectionDocs = ref([
    {docType: 'kyc-ma-1', file: true}, 
    {docType: 'kyc-ppa-2', file: true}, 
    {docType: 'kyc-feeibtbvdna-3', file: true,  additinal_fields: ['dateIssued']}, 
    {docType: 'kyc-lteabibtdoea-4', file: true, additinal_fields: ['dateIssued']}, 
    {docType: 'kyc-mlibtdoea-4-1', file: true, additinal_fields: ['dateIssued']}, 
    {docType: 'kyc-aoi-5', file: true}, 
    {docType: 'kyc-attaoi-5-1', file: true, additinal_fields: ['dateIssued']}, 
    {docType: 'kyc-reftcoc-5-2', file: true, additinal_fields: ['dateIssued']}, 
    {docType: 'kyc-oc-6', file: true}, 
    {docType: 'kyc-sr-7', file: true}, 
    {docType: 'kyc-reftcoc-7-1', file: true}, 
    {docType: 'kyc-sof-8', file: true}, 
    {docType: 'kyc-ruling', file: true}, 
    {docType: 'cosbaof', file: true, additinal_fields: ['bank_name']}, 
    {docType: 'scfba', file: true, additinal_fields: ['bank_name']}, 
    {docType: 'kyc-aa-y1-12', file: true, additinal_fields: ['year', 'fiscal_year_ending_on']}, 
    {docType: 'kyc-aa-y2-12', file: true, additinal_fields: ['year', 'fiscal_year_ending_on']},
    {docType: 'kyc-dogmos-13', file: true, additinal_fields: ['year']}, 
    {docType: 'kyc-fpt-14', file: true, additinal_fields: ['year']},
    {docType: 'subsidiaries', file: true, additinal_fields: ['year']},
])


const filledDocsGeneral = computed(() => {

    let components = generalSectionDocs.value.reduce(( acc, cur ) => {
        acc[cur.docType] = { 
            filledDocuments: 0,
            needed: 1, 
            name: documentTypeToHeaderName[cur.docType] || cur.docType, 
            id: cur.docType,
        }
        return acc
    }, {})

    let settingsToCheck = generalSectionDocs.value;
    if (companyData.value?.location_type?.value == 'local') {
        settingsToCheck = settingsToCheck.filter(el => !['kyc-ma-1', 'kyc-ppa-2', 'kyc-feeibtbvdna-3', 'kyc-dogmos-13'].includes(el.docType))
        delete components['kyc-ma-1'];
        delete components['kyc-ppa-2'];
        delete components['kyc-feeibtbvdna-3'];
        delete components['kyc-dogmos-13'];
    }
    let filledDocuments = 0;
    let needed_docs_list = settingsToCheck.filter( el => el.docType != 'subsidiaries');
    let needed_docs = needed_docs_list.length;
    let subsidiariesList = corporateFiles.value.filter( el => el.documentType == 'subsidiaries');
    let subsidiaries = subsidiariesList.length;
    let scfbaList = corporateFiles.value.filter( el => el.documentType == 'scfba')
    let scfba = scfbaList.length;
    let cosbaofList = corporateFiles.value.filter( el => el.documentType == 'cosbaof')
    let cosbaof = cosbaofList.length;

    needed_docs += subsidiaries;
    needed_docs += scfba - 1;
    needed_docs += cosbaof - 1;
    components['subsidiaries'].needed = subsidiaries;
    components['scfba'].needed = scfba;
    components['cosbaof'].needed = cosbaof;
    settingsToCheck.forEach( cur => {
        const fileAreas = corporateFiles.value.filter( file => file.documentType == cur.docType)
        for (let fileArea of fileAreas) {
            
            let result = checkFile(fileArea, cur);
            filledDocuments += result;

            if (result) {
                components[cur.docType].filledDocuments += result;
            }
        }
    })
    return {
        components: components,
        filledDocuments, 
        needed_docs
    }
})

onMounted(() => {
});

</script>

<style lang="scss" scoped>
    .shareholder-section {
        position: relative;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        border: 1px solid var(--border-color);
    }

    .detailed-breakdown {
        &:deep(.container) {
            width: 700px;
        }
        p {
            margin: 0;
            font-size: 12px;
        }
    }


    .labels {
        display: flex;
        gap: 10px 
    }
    .holder-label {
        // position: absolute;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        width: 280px;
    }
    .chart-section {
        width: 282px;
    }
    .body-popup {
        max-height: 80vh;
        overflow-y: auto;
        box-sizing: border-box;
        width: 700px;
    }
    h3 {

        font-size: 14px;
        margin-top: 0;
        text-align: left;
    }
    .chart-container {
        position: relative;
        // flex-direction: row;

        align-items: stretch;

        &.account_holder {
            width: 368px;
        }
    }
    
    .shareholder-section {
        background: white;
    }
    .tip {
        width: 40px;
        height: 20px;
    }

    .take-a-look {
        // width: 20px;
        // height: 18px;
        font-size: 12px;
        text-decoration: underline;
        color: var(--primary-color);
        cursor: pointer;
        margin-left: 10px;
        display: none;
        &:hover {
            color: #0060c6;
        }
    }

    .info-holder {
        :hover {
            .take-a-look {
                display: block;
            }
        }
    }
    canvas {
        width: 400px;
        height: 400px;
        max-height: 400px;
        max-width: 400px;
    }
  
    .bg-white {
        background-color: white;
        border: 1px solid var(--border-color);
        border-radius: 5px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        min-height: 100px;
        position: relative;

        img {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 20px;
            height: 20px;
        }
    }
    .element-ch {
        box-sizing: border-box;
        display: flex;
        height: 100%;

        flex-direction: column;
        justify-content: space-between;
    }
</style>