
// Sample data structure
const nodes = [];
const moves = [];
const overlapList = [];
const spaceBetween = 70;
const heightDiff = 100;
const overlapQueue = [];


function overlaps(a, b) {
    // no horizontal overlap
    if (a.x1 >= b.x2 || b.x1 >= a.x2) return false;

    // no vertical overlap
    if (a.y1 >= b.y2 || b.y1 >= a.y2) return false;

    return true;
}

function findCommonParent(node1, node2) {
    let ancestors1 = getAncestors(node1);
    let ancestors2 = getAncestors(node2);
    let managingDirectors = []
    // Compare ancestors starting from the root and find the common parent
    for (let ancestor1 of ancestors1) {
        
        if (ancestor1.type == 'corporate'){
            let index = managingDirectors.findIndex((el) => el.id === ancestor1.id);
            if (index === -1) {
                managingDirectors.push(ancestor1);
            }
        }


        for (let ancestor2 of ancestors2) {
            if (ancestor2.type == 'corporate'){
                let index = managingDirectors.findIndex((el) => el.id === ancestor2.id);
                if (index === -1) {
                    managingDirectors.push(ancestor2);
                }
            }
            if (ancestor1.id === ancestor2.id) {
                return {element: ancestor1, managingDirectors};  // Found common parent
            }
        }
    }
    return null;  // No common parent found
}

function getAncestors(node) {
    let ancestors = [];
    let currentNode = node;

    // Traverse up the tree until there are no more parents
    while (currentNode) {
        ancestors.push(currentNode);
        currentNode = currentNode.parent; // Adjust to your tree structure
    }

    return ancestors;
}


class Node {
    constructor(data, parent, drawingEl) {

        this.type = data.type;
        this.name = data.name
        this.chartDrawer = drawingEl
        this.percentage_held = data.percentage_held;
        this.children = [];
        this.entity_type = data.entity_type;
        this.supervised = data.supervised;
        this.missing = data.missing;
        this.label = data.label;
        this.managing_directors = [];
        this.moves = [];
        this.position = { x: 0, y: 0 }; // top left position
        this.height = 100;
        this.parent = parent;
        this.calculated = false;
        this.positionChanges = [];
        this.id = Math.random().toString(36).substr(2, 9);
        this.extraWidth = 0;
        this.element = null
    }
    width() {
        let width = 200;
        if (this.type == 'corporate') {
            width = 350;
            if (this.entity_type == 'person') {
                width = 150;
            }
        }

        return width + this.extraWidth;
    }
    currentDepth() {
        let depth = 0;
        let currentNode = this;
        while (currentNode.parent) {
            depth++;
            currentNode = currentNode.parent;
        }
        return depth;
    }
    typeToName(){
        if (this.type == 'corporate') {
            return 'Managing Director';
        } else if (this.type == 'corporate_shareholder') {
            return 'Shareholder';
        } else if (this.type == 'main_for_sh') {
            return 'Main Reporting Entity';
        } else if (this.type == 'ubo') {
            return 'UBO';
        } 

        return this.type;
    }
    tabs(){
        let str = '';
        for(let i = 0; i < this.currentDepth(); i++){
            str += `_ `;
        }
        return str;
    }
    branchWidth() {
        let width = 0;
        if (this.children.length == 0) {
            return this.width();
        }
        let mostLeftChild = this.getMostLeftChild();
        let mostRightChild = this.findRightmostNode();
        // console.log(`${this.tabs()}mostLeftChild`, mostLeeftChild.name, 'mostRightChild', mostRightChild.name)
        width = mostRightChild.position.x - mostLeftChild.position.x + mostRightChild.width();
        if (width < this.width()) {
            width = this.width();
        }
        return width;
    }
    getDeepestChild() {
        if (this.children.length == 0) {
            return this;
        }
        let deepestChild = this.children[0];
        for(let child of this.children){
            let childDeepest = child.getDeepestChild();
            if (childDeepest.currentDepth() > deepestChild.currentDepth()) {
                deepestChild = childDeepest;
            }
        }
        return deepestChild;
    }
    getMostLeftChild(){
        if (this.children.length == 0) {
            return this;
        }
        if (!this.children[0].calculated) {
            return this;
        }
        let mostLeftChild = this.children[0];
        return mostLeftChild.getMostLeftChild();
    }

    findLeftmostNode(node = this) {
        let maxPosition = node.position.x;
        let rightmostNode = node;

        function traverseTree(currentNode) {
            if (!currentNode) return;

            if (currentNode.position.x < maxPosition) {
                maxPosition = currentNode.position.x;
                rightmostNode = currentNode;
            }

            if (currentNode.children) {
                for (let child of currentNode.children) {
                    traverseTree(child);
                }
            }

            if (currentNode.managing_directors) {
                for (let child of currentNode.managing_directors) {
                    traverseTree(child);
                }
            }
        }

        traverseTree(node);
        return rightmostNode;
    }

    findRightmostNode(node = this) {
        let maxPosition = node.position.x + node.width();
        let rightmostNode = node;

        function traverseTree(currentNode) {
            if (!currentNode) return;

            if (currentNode.position.x + currentNode.width() > maxPosition) {
                maxPosition = currentNode.position.x + currentNode.width();
                rightmostNode = currentNode;
            }

            if (currentNode.children) {
                for (let child of currentNode.children) {
                    traverseTree(child);
                }
            }

            if (currentNode.managing_directors) {
                for (let child of currentNode.managing_directors) {
                    traverseTree(child);
                }
            }
        }

        traverseTree(node);
        return rightmostNode;
    }
    drawLine(from){

        const x1 = from.position.x + from.width() / 2;
        const y1 = from.position.y + from.height / 2;


        const x2 = this.position.x + this.width() / 2 - (this.type == 'corporate' ? 70 : 0);
        const y2 = this.position.y + this.height / 2;
        if (from.percentage_held){
            const percentage = from.percentage_held;
            const percentageText = document.createElement('div');
            percentageText.className = 'percentage';
            percentageText.innerHTML = percentage + '%';
            percentageText.style.position = 'absolute';
            percentageText.style.left = ((x1 + x2) / 2) - 17 + 'px';
            percentageText.style.top = (y1 + y2) / 2 - 10 + 'px';
            this.chartDrawer.drawingElement.appendChild(percentageText);
        }
        // Calculate distance and angle
        const dx = x2 - x1;
        const dy = y2 - y1;
        const length = Math.sqrt(dx * dx + dy * dy);
        const angle = Math.atan2(dy, dx) * (180 / Math.PI);

        let line = document.createElement('div');
        // Position and rotate line
        line.style.width = length + "px";
        line.style.top = y1 + "px";
        line.className = 'line';
        line.style.left = x1 + "px";
        line.style.transform = `rotate(${angle}deg)`;

        this.chartDrawer.drawingElement.appendChild(line);    
    }

    getNeededSpace() {
        let neededWidth = 0;
        let children = 0;
        let managing_directors = 0;
        for(let chield of this.children){
            if (!chield.calculated) {
                continue;
            }
            children++;
            let chieldWidth = chield.branchWidth();
            // console.log(`${this.tabs()}chieldWidth ${chield.name}`, chieldWidth)
            neededWidth += chieldWidth + spaceBetween;
        }
        if (children > 0) {
            neededWidth -=  spaceBetween;
        }
        for (let managingDirector of this.managing_directors){
            if (!managingDirector.calculated) {
                continue;
            }
            managing_directors++;
            neededWidth += managingDirector.branchWidth() + spaceBetween;
        }
        
        if (managing_directors > 0) {
            neededWidth -= spaceBetween;
        }
        return neededWidth;
    }

    
    evenOutManagingDirectors() {
        // console.log('even out managing directors', this.name)
        let currentLeft = this.managing_directors[0].position.x;

        for(let md of this.managing_directors){
            
            if (!md.calculated) {
                continue;
            }

            let curerntPositionLeft = md.position.x;
            let difference = currentLeft - curerntPositionLeft;
            md.moveElement(difference, 0);
            currentLeft += md.branchWidth() + spaceBetween / 2;
        }
    }
    evenOutelements() {
        
        let neededWidth = this.getNeededSpace();
        let currentWidth = this.branchWidth();
        let widthDiff = neededWidth - currentWidth;

        // console.log(`${this.tabs()}currentWidth`, currentWidth)
        // console.log(`${this.tabs()}neededWidth`, neededWidth)
        // console.log(`${this.tabs()}widthDiff`, widthDiff)

        let centerX = this.position.x + (this.width() / 2);
        let currentLeft = centerX - (neededWidth / 2 );
        // console.log(`${this.tabs()}currentLeft, centerX`, currentLeft, centerX)

        let spacing = widthDiff / (this.children.length - 1); 

        for (let child of this.children){
            if (!child.calculated) {
                continue;
            }
            let childLeft = child.position.x
            let childMostLeft = child.getMostLeftChild().position.x;
            
            // console.log('childPosition', child.name, {
            //     childLeft,
            //     childMostLeft
            // })

            let difference = currentLeft - child.getMostLeftChild().position.x;
            
            child.moveElement(difference, 0);
            currentLeft += child.branchWidth() + spaceBetween;
            // console.log(`${this.tabs()}currentLeft`, currentLeft)
        }
        
        // for (let managingDirector of this.managing_directors){
        //     if (!managingDirector.calculated) {
        //         continue;
        //     }
        //     let difference = currentLeft - managingDirector.position.x;
        //     managingDirector.moveElement(difference, 0);
        //     currentLeft += managingDirector.branchWidth() + spaceBetween;
        // }
    }
    moveManagingDirrectorRight(){
        this.moveElement(spaceBetween, 0);
    }
    center(){
        if (this.children.length < 2) {
            return
        }
        let centerX = this.position.x + (this.width() / 2);
        let leftPosition = this.children[0].position.x;
        let rightPosition = this.children[this.children.length - 1].position.x + this.children[this.children.length - 1].width();
        let childrenMiddle = leftPosition + (rightPosition - leftPosition) / 2;
        let diff = childrenMiddle - centerX;
        this.moveElement(diff, 0, false);
    }
    checkOverlap(parent = null) {
        if (this.type == 'official') {
            return
        }
        let positions = {
            x1: this.position.x,
            y1: this.position.y,
            x2: this.position.x + this.width(),
            y2: this.position.y + this.height
        }
        for(let node of this.chartDrawer.nodes) {
            if (!node.calculated || node.id == this.id || node.type == 'official') {
                continue;
            }
            let elementPositions = {
                x1: node.position.x,
                y1: node.position.y,
                x2: node.position.x + node.width(),
                y2: node.position.y + node.height
            }
            if (overlaps(positions, elementPositions)) {
                overlapList.push({ node1: this.name, node2: node.name });
                // console.log(`${this.tabs()}overlaps`, this.name, node.name)
                let commonElResult = findCommonParent(this, node);
                let commonEl = commonElResult.element;
                if (this.type == 'corporate' && node.type == 'corporate') {
                    commonEl.evenOutManagingDirectors();
                } else if (commonElResult.managingDirectors.length > 1) {
                    commonElResult.managingDirectors[1].moveManagingDirrectorRight()
                } else {
                    commonEl.evenOutelements();
                }
            }
        }
        if (!parent) {
            return
        }
        for(let i = 0; i< 600; i++) {
            let node = overlapQueue.pop();
            if (!node) {
                break;
            }
            node.checkOverlap();
        }
    }

    
    buildTree() {
        if (this.type == 'official') {
            return
        }
        this.calculated = true;
        if (this.parent) {
            if (['corporate'].includes(this.type)) {
                this.position.y = this.parent.position.y;
                this.position.x = this.parent.position.x + this.parent.width() + spaceBetween * 0.5;
            } else if (['official'].includes(this.type)) {
                this.position.y = this.parent.position.y;
                this.position.x = this.parent.position.x + this.parent.width() + spaceBetween * 0.5;
            }  
            else {
                let yPosition = this.parent.position.y - (this.height +  heightDiff);
                this.position.y = yPosition;
                this.position.x = this.parent.position.x;
            }
        } 

        this.checkOverlap(true); 
        for(let chield of this.children){
            chield.buildTree();
        }
        for(let managingDirector of this.managing_directors){
            managingDirector.buildTree();
        }
        // this.center();
    }

    createOfficial(item) {
        const element = document.createElement('div');
        element.className = `node ${item.type} ${item.missing ? 'missing' : ''}`;
        element.style.marginLeft = '20px';
        element.innerHTML = item.content();
        return element
    }


    addChild(child) {
        this.children.push(child);
    }
    addManagingDirector(managingDirector) {
        this.managing_directors.push(managingDirector);
    }
    content() {
        if (this.type == 'official') {
            return `<strong>${this.name}</strong>`
        }

        return `
            <div>(${this.typeToName()})</div>
            <div>${this.name}</div>
            <div>${this.label ? this.label : ''}</div>
        `
    }

    draw() {
        if (!this.parent) {

            this.chartDrawer.drawingElement.innerHTML = '';
        }
        let wrapper = document.createElement('div');
        let officials = null;
        wrapper.onclick = () =>{
            console.log('clicked', this)     
        }
        if (this.type == 'official') {
            return;
        }
        
        const element = document.createElement('div');
        element.className = `node ${this.type}`;

        if (this.missing) {
            element.classList.add('missing');
        }
        wrapper.className = 'wrapper';
        wrapper.style.width = this.width() + 'px';  
        wrapper.style.height = this.height + 'px';
        wrapper.style.position = 'absolute';
        wrapper.style.left = this.position.x + 'px';
        wrapper.style.top = this.position.y + 'px';
        wrapper.style.display = 'flex';

        element.style.width = '200px';
        element.style.minWidth = '200px';
        element.style.height = '100%';
        // element.style.position = 'absolute';
        element.innerHTML = this.content();

        if (this.type == 'ubo') {
            element.style.width = this.height + 'px';
            element.style.height = this.height + 'px';
            element.style.borderRadius = '50%';
            wrapper.style.justifyContent = 'center';
        
        }

        if (this.type == 'corporate') {
            officials = document.createElement('div');
            officials.className = 'officials';
            for (let i = 0; i < this.children.length; i++) {
                const child = this.children[i];
                if (child.type == 'official') {
                    const official = this.createOfficial(child);
                    officials.appendChild(official);
                }
            }
        }
        if (this.type != 'corporate' || (this.type == 'corporate' && this.entity_type != 'person')) {
            wrapper.appendChild(element);
        } 

        if (this.type == 'corporate') {
            wrapper.appendChild(officials);
        }
        this.element = wrapper;
        this.chartDrawer.drawingElement.appendChild(wrapper);
        for(let i = 0; i < this.children.length; i++) {
            const child = this.children[i];
            if (child.type == 'official') {
                continue;
            }

            child.draw();
            this.drawLine(child);
        }

        for(let i = 0; i < this.managing_directors.length; i++) {
            const managingDirector = this.managing_directors[i];
            managingDirector.draw();
        }
        if (this.type == 'corporate' && this.id != this.chartDrawer.mainNode.id) {
            // console.log('aaaaaaaaaaaaaaaa')
            let left = this.position.x - spaceBetween * 0.25;
            let top = this.position.y;

            let elem = document.createElement('div');
            elem.style.position = 'absolute';
            elem.style.top = top + 'px';
            elem.style.left = left + 'px';

            elem.style.width = '3px';
            elem.style.height = this.height + 'px';
            elem.style.background = 'black';
            this.chartDrawer.drawingElement.appendChild(elem);
        }
    }
    moveElement(dx, dy, cascade = true) {
        if (!this.calculated) {
            return
        }
        if (dx == 0 && dy == 0) {
            return
        }
        overlapQueue.push(this);

        // console.log(`${this.tabs()}moveElement`, this.name, dx, dy)
        this.position.x += dx;
        this.position.y += dy;
        this.positionChanges.push({ dx, dy });
        moves.push({ name: this.name, dx, dy });
        this.moves.push(moves.length)

        if (cascade) {
            this.children.forEach(child => {
                child.moveElement(dx, dy);
            });
        }
        
        this.managing_directors.forEach(managingDirector => {
            managingDirector.moveElement(dx, dy);
        });
    }
    setPosition(x, y) {
        this.position.x = x;
        this.position.y = y;
    }
}



function removeNodes(tree) {
    return tree.reduce((acc, node) => {
        // Process children first if present
        if (node.children && node.children.length > 0) {
            node.children = removeNodes(node.children);
            if (
                ['corporate_shareholder', 'main_for_sh'].includes(node.type) ||
                (node.type == 'corporate' && node.supervised == false && node.entity_type == 'entity')
            ) {
                addRemainingShares(node)
                checkMissingMD(node)
            }
        } else {
            if(
                (
                    ['corporate_shareholder', 'main_for_sh'].includes(node.type) ||
                    ( node.type == 'corporate' && node.supervised == false && node.entity_type == 'entity' )
                ) &&
                
                ( node.location_type.value != 'local' )
            ) {
                node.children = [{
                    name: 'Missing Shares (100%)',
                    type: 'corporate_shareholder',
                    percentage_held: 100,
                    missing: true,
                }];
            } 
            if (['main_for_sh', 'corporate_shareholder'].includes(node.type)) {
                console.log('Adding Missing MD', node)
                node.children.push({
                    name: 'Missing',
                    type: 'corporate',
                    missing: true,
                })
            }
        }
        if (node.type == 'corporate') {
            checkMissingOfficials(node)
        }
        if (node.children && node.children.length > 0) {
            node.children = node.children.sort((a, b) => {
                if (a.type === 'corporate') return 1; // Move 'missing' to the end
                if (b.type === 'corporate') return -1;
                return -1
            });
        }
        
        // If node's type matches the one to remove, append its children to the parent
        if (['shareholder'].includes(node.type)) {
            if (node.children) {
                node.children.forEach(child => {
                    child.percentage_held = node.percentage_held;
                });
                acc.push(...node.children); // Append the children of the node
            }
        } else {
            // Keep the node in the result if its type doesn't match
            acc.push(node);
        }

        return acc;
    }, []);
}
function getTreeDepth(node) {
    if (!node) return 0;
    if (!node.children || node.children.length === 0) return 1;
    
    let maxChildDepth = 0;
    for (let child of node.children) {
        maxChildDepth = Math.max(maxChildDepth, getTreeDepth(child));
    }
    
    return 1 + maxChildDepth;
}
function checkMissingMD(node) {
    if (node.type == 'corporate') {
        return;
    }
    let foundMD = false;
    node.children.forEach(child => {
        if (['corporate','official'].includes(child.type)) {
            foundMD = true;
        }
    });
    if (!foundMD) {
        node.children.unshift({
            name: 'Missing',
            type: 'corporate',
            missing: true,
        })
    }
}
function addRemainingShares(node){
    let total_held = 0;

    node.children.forEach(child => {
        total_held += parseFloat(child.percentage_held) || 0;
    });
    let remaining = +(100 - total_held).toFixed(2);
    if (remaining > 1) {
        node.children.push({
            name: 'Missing Shares (' + remaining + '%)',
            type: 'corporate_shareholder',
            percentage_held: remaining,
            missing: true,
        })
    }
}

function checkMissingOfficials(node) {
    let total_held = 0;

    node.children.forEach(child => {
        if (child.type == 'official') {
            total_held += 1;
        }
    });

    if (total_held == 0) {
        node.children.push({
            name: 'Missing Official',
            type: 'official',
            shape: 'circle',
            missing: true,
        })
    }
}

// let recalculatedStructure = [
//     {
//         "name": "New Compnay 0",
//         "type": "main_for_sh",
//         "children": [
//             {
//                     'name': 'Element 1',
//                     'type': 'corporate_shareholder',
//             },
            
//             {
//                     'name': 'Element 1',
//                     'type': 'corporate',
//                     'children': [
//                         {
//                             'type': 'official',
//                             'name': 'Element 1',
//                             'label': 'Label 1',
//                         },
//                         {
//                             'type': 'official',
//                             'name': 'Element 1',
//                             'label': 'Label 1',
//                         },
//                         {
//                             'type': 'official',
//                             'name': 'Element 1',
//                             'label': 'Label 1',
//                         }
//                     ]
//             },
//             {
//                     'name': 'Element 1',
//                     'type': 'corporate',
                    
//             },
//             {
//                     'name': 'Element 1',
//                     'type': 'corporate_shareholder',
//             },
            
//         ]
//     }
// ]


function findDeepestNode(node, depth = 0, result = { maxDepth: -1, deepestNode: null }) {
    if (!node || node.type == 'official') return;

    if (!node.children || node.children.length === 0) {
        if (depth > result.maxDepth) {
            result.maxDepth = depth;
            result.deepestNode = node;
        }
    }
    if (!node.managing_directors || node.managing_directors.length === 0) {
        if (depth > result.maxDepth) {
            result.maxDepth = depth;
            result.deepestNode = node;
        }
    }

    for (const child of node.children || []) {
        findDeepestNode(child, depth + 1, result);
    }
    for (const child of node.managing_directors || []) {
        findDeepestNode(child, depth + 1, result);
    }
    return result.deepestNode;
}


class ChartDrawer {
    constructor(selector, structure, scaleToFit, fullChart, type) {
        this.structure = structure;
        this.nodes = [];
        this.drawingElement = document.querySelector(selector);
        this.mainNode = null;
        this.scaleToFit = scaleToFit;
        this.fullChart = fullChart;
        this.shareholderLayerCompleted = false;
        this.managingDirectorLayerCompleted = false;
        this.managingDirectorsForShareholder = false;
        this.corporateForManagingDirector = false;
        this.officialsExtracted = false;
        this.type = type;
    }

    traverseDFSRecursive(node, parent) {
        if (!node) return;
        let children = !node.children ? [] : node.children.filter(child => child.type != 'corporate');
        let managing_directors = !node.children ? [] : node.children.filter(child => child.type == 'corporate');
        let structureNode = new Node(node, parent, this);
        this.nodes.push(structureNode);
        if (!this.mainNode){
            this.mainNode = structureNode;
        }
        if (parent && parent.type == 'corporate') {
            if (node.type == 'official') {
                parent.addChild(structureNode);
                return
            }
        }
    
        if (parent) {
            if (node.type == 'corporate') {
                parent.addManagingDirector(structureNode);
            } else {
                parent.addChild(structureNode);
            }
        } 
        for (let child of children) {
            this.traverseDFSRecursive(child, structureNode);
        }
        for (let managingDirector of managing_directors) {
            this.traverseDFSRecursive(managingDirector, structureNode);
        }

    }
    clearMDs(node) {
        if (node.id != this.mainNode.id) {
            node.managing_directors = [];
        }
        for (let child of node.children) {
            this.clearMDs(child);
        }
    }
    adjustAccorginToFullPartial(node) {
        for (let mds of this.mainNode.managing_directors) {
            if (this.type == 'entity') {
                for (let child of mds.children) {
                    if (child.type !== 'official') {
                        child.children = [];
                        child.managing_directors = [];
                    }
                }
            } else {
                mds.children = mds.children.filter(child => child.type == 'official')

            }
        }
        if (this.fullChart) {
            return
        }
        for (let children of this.mainNode.children) {
            children.children = [];
            children.managing_directors = [];
        }

     
        if (this.mainNode.type == 'corporate' && this.mainNode.supervised) {
            this.mainNode.children = this.mainNode.children.filter(child => child.type == 'official')
        }
    }
    drawChart() {
        const recalculatedStructure = removeNodes(this.structure);
        this.traverseDFSRecursive(recalculatedStructure[0], null);
        this.adjustAccorginToFullPartial();
        this.clearMDs(this.mainNode);
        this.mainNode.setPosition(15, 15);
        this.mainNode.buildTree();
        let most_left = this.mainNode.findLeftmostNode();
        let deepestChild = findDeepestNode(this.mainNode);
        if(most_left.position.x < 0) {
            this.mainNode.moveElement(-most_left.position.x + 20, 0);
        }
        if(deepestChild.position.y < 0) {
            this.mainNode.moveElement(0, -deepestChild.position.y + 10);
        }

        this.mainNode.draw();
        
        let depth = getTreeDepth(this.mainNode);
        let width = this.mainNode.branchWidth();
        let height = depth * (this.mainNode.height + heightDiff) - heightDiff + 30;
        
        let parent = this.drawingElement.parentElement;
        parent.style.width = width + 100 + 'px';
        // parent.style.minHeight = height  + 'px';
        
        parent.style.height = '100%';

        if (this.scaleToFit) {
            this.drawingElement.parentElement.style.width = '100%'
            const parentWidth = 300;
            const parentHeight = 240;
            const scaleX = parentWidth / width;
            const scaleY = parentHeight / height;
            const scale = Math.min(scaleX, scaleY);
            this.drawingElement.style.transform = `scale(${scale})`;
            parent.style.height = height * scale + 'px';
        }
    }

}


export default ChartDrawer;