<template>
    <div class="user-management-view">
        <div class="usermanagement-header">User Management</div>
        <CompanyAcounts/>

    </div>
</template>

<script setup>
    import { ref, computed, onMounted, watch, defineEmits } from 'vue'
    import { PermissionChecker } from '@/utils/iam_utils';
    import CompanyAcounts from '@/components/CompanyAcounts.vue'
    import { useRouter, useRoute } from 'vue-router'

    const router = useRouter()

    onMounted(() => {
        if (!PermissionChecker.viewUser()) {
            console.log('redirecting')
            router.push('/');
        } 
    })
</script>

<style lang="scss" scoped>

    .usermanagement-header {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: left;
        color: black;
        padding-top: 20px;
    }
    .user-management-view {
        display: flex;
        padding: 20px;
        flex-direction: column;
        max-width: 1350px;
        margin: auto;
    }

</style>